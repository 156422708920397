import { ComponentProps, FC, useEffect, useState } from 'react'
import { Button } from '@sequencehq/core-components'
import {
  dashboard20240730Client,
  ServerAsset
} from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { FilePickerButton } from '@sequencehq/core-components/src/components/FilePickerButton'

type Props = ComponentProps<typeof Button> & {
  onFilePicked?: (file: File) => void
  onUploadSuccess: (asset: ServerAsset) => void
  accept: string
}

export const UploadAssetButton: FC<Props> = ({
  onFilePicked,
  onUploadSuccess,
  accept,
  ...props
}) => {
  const [file, setFile] = useState<File | null>(null)

  const handleFilesPicked = (files: File[]) => {
    setFile(files[0])
    onFilePicked?.(files[0])
  }

  useEffect(() => {
    if (file) {
      uploadFile(file)
        .then(res => {
          if (!res.data) {
            console.error('No data returned from upload')
            return
          }
          onUploadSuccess(res.data)
        })
        .catch(console.error)

      setFile(null)
    }
  }, [file, onUploadSuccess])

  return (
    <FilePickerButton
      accept="image/jpeg,image/png"
      {...props}
      onFilesPicked={handleFilesPicked}
    />
  )
}

const uploadFile = async (file: File) => {
  const formData = new FormData()
  formData.append('file', file)

  return await dashboard20240730Client.postAssets({
    formData
  })
}
