import { Button, Flex } from '@chakra-ui/react'
import { ArrowDownTrayIcon } from '@heroicons/react/16/solid'
import Page from 'components/Page'
import { openOverlay } from 'features/overlay'
import { useDispatch } from 'features/store'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { FC, memo } from 'react'
import { Outlet } from 'react-router-dom'
import { CustomersMagicTable } from './CustomersMagicTable'
import { CustomersContextProvider } from './hooks/useCustomersContext'

const Customers: FC = memo(() => {
  const dispatch = useDispatch()
  const flags = useFlags()

  const menuButtons = (
    <Button
      variant="component-library-secondary"
      onClick={() =>
        dispatch(
          openOverlay({
            content: 'importCustomersModal'
          })
        )
      }
      leftIcon={
        <ArrowDownTrayIcon width="16px" height="16px" color="inherit" />
      }
      iconSpacing="4px"
    >
      Import
    </Button>
  )

  return (
    <CustomersContextProvider>
      <Flex flexDirection="column" flex={1}>
        <Flex position="sticky" top={0} height="100vh">
          <Page
            title="Customers"
            addNewConfig={
              flags?.customerParentChildRelationship
                ? {
                    buttonText: 'New customer',
                    href: 'create'
                  }
                : {
                    buttonText: 'New customer',
                    overlayPayload: {
                      content: 'createCustomerModal',
                      data: {}
                    }
                  }
            }
            menuButtons={menuButtons}
            paddingBottom={0}
          >
            <CustomersMagicTable />
          </Page>
          <Outlet />
        </Flex>
      </Flex>
    </CustomersContextProvider>
  )
})

export default Customers
