import { useCallback, useEffect, useMemo, useState } from 'react'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { SerializedError } from '@reduxjs/toolkit'
import {
  Dashboardv20240509Api,
  dashboardv20240509Client
} from '@sequencehq/api/dashboard/v20240509'
import * as Sentry from '@sentry/react'

export interface Price extends Dashboardv20240509Api.GetPrice.Price {}

export type PriceCurrency = Price['currency']

type UseLoadPrices = (props?: { currency?: PriceCurrency }) => {
  loading: boolean
  error?: FetchBaseQueryError | SerializedError
  data?: {
    prices: Price[]
  }
}

export const useLoadPrices: UseLoadPrices = props => {
  const [loadedPrices, setLoadedPrices] = useState<Price[] | undefined>()
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState<
    FetchBaseQueryError | SerializedError | undefined
  >()

  const getPrices = useCallback(async () => {
    setLoading(true)
    const result = await dashboardv20240509Client.getPrices({
      currency: props?.currency
    })

    if (result.error) {
      setError({
        status: result.res.status,
        data: result.res.statusText
      })
    }

    if (result.data) {
      setLoadedPrices(result.data.items)
    }

    setLoading(false)
  }, [props?.currency])

  useEffect(() => {
    getPrices().catch(e => Sentry.captureException(e))
  }, [getPrices])

  const data = useMemo(() => {
    if (isLoading || error || !loadedPrices) {
      return
    }

    return {
      prices: loadedPrices
    }
  }, [error, loadedPrices, isLoading])

  return {
    loading: isLoading,
    error: error,
    data
  }
}
