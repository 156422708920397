import {
  Modal,
  ModalContextProvider,
  PercentageInput,
  InputSelectControlField,
  SimpleModalUI,
  withUnmountOnModalClose,
  Tooltip
} from '@sequencehq/core-components'
import { type ReactNode, useState } from 'react'
import type { TaxCategory, TaxRate } from 'Settings/domain/taxRates.types'
import { Box, Divider, Flex, Text } from '@chakra-ui/react'
import { Label } from '@sequencehq/forms'
import {
  decimalFromPercentage,
  percentageFromDecimal,
  useForm
} from '@sequencehq/utils'
import { required } from '@sequencehq/validation'
import { GreyGrey10, GreyGrey30, GreyGrey60 } from '@sequencehq/design-tokens'
import { useQuery } from '@sequencehq/api/utils'
import { dashboardv99990101Client } from '@sequencehq/api/dashboard/v99990101'
import {
  countriesAlpha2,
  countriesAlpha2Emojis,
  type CountriesAlpha2
} from '@sequencehq/api/commonEnums'
import InformationCircleIcon from '@heroicons/react/24/solid/InformationCircleIcon'

type FormValues = {
  country: CountriesAlpha2 | undefined
  taxCategories: Record<TaxCategory['id'], TaxRate['amount']>
}

interface Props {
  trigger: ReactNode | ((openModal: () => void) => ReactNode)
  onSubmit: (
    values: FormValues,
    taxRatesForCountry?: TaxRate[]
  ) => Promise<void>
  taxRatesForCountry?: TaxRate[]
  countriesWithTaxRates?: CountriesAlpha2[]
}

const getDefaultValues = (
  taxCategories: TaxCategory[],
  taxRatesForCountry?: TaxRate[]
): FormValues => {
  const country = taxRatesForCountry?.[0]?.country
  // If a country is given, it means we are editing tax rates
  if (country) {
    return {
      country,
      taxCategories: taxCategories.reduce((acc, category) => {
        const taxRate = taxRatesForCountry?.find(
          rate => rate.taxCategoryId === category.id
        )

        return {
          ...acc,
          [category.id]: taxRate?.amount
            ? percentageFromDecimal(taxRate.amount)
            : ''
        }
      }, {} as FormValues['taxCategories'])
    }
  }

  return {
    country: undefined,
    taxCategories: taxCategories.reduce((acc, category) => {
      return {
        ...acc,
        [category.id]: ''
      }
    }, {} as FormValues['taxCategories'])
  }
}

const TaxRatesModalUI = withUnmountOnModalClose(
  ({
    onSubmit,
    taxRatesForCountry = [],
    countriesWithTaxRates
  }: Omit<Props, 'trigger'>) => {
    const isEditing = taxRatesForCountry.length > 0

    const { data } = useQuery(dashboardv99990101Client.getTaxCategories)
    const taxCategories = data?.items ?? []

    const [, setIsDirty] = useState(false)
    const [isValid] = useState(true)

    const taxRateCategoriesFields: {
      property: `taxCategories.${string}`
    }[] = taxCategories.map(category => ({
      property: `taxCategories.${category.id}`
    }))

    const defaultValues = getDefaultValues(taxCategories, taxRatesForCountry)

    const { fields: fieldsConfig } = useForm<FormValues>({
      value: defaultValues,
      showValidationErrors: !isValid,
      fieldConfiguration: [
        {
          property: 'country',
          validation: [required]
        },
        ...taxRateCategoriesFields
      ],
      onChange: newData => {
        if (newData.taxCategories) {
          newData.taxCategories = Object.entries(newData.taxCategories).reduce(
            (acc, [catgoryId, amount]) => {
              return {
                ...acc,
                [catgoryId]: amount ? decimalFromPercentage(amount) : ''
              }
            },
            {}
          )
        }

        setFormData(newData)
        setIsDirty(true)
      }
    })

    const [formData, setFormData] = useState<FormValues>(defaultValues)

    const [editedTaxRates, setEditedTaxRates] = useState<TaxRate['id'][]>([])

    const handleSubmit = async (formValues: FormValues) => {
      // We want to pass only the countries tax rates that have been edited
      const modifiedTaxCategories = Object.entries(
        formValues.taxCategories || {}
      ).reduce((acc, [taxCategoryId, amount]) => {
        if (editedTaxRates.includes(taxCategoryId)) {
          return {
            ...acc,
            [taxCategoryId]: amount
          }
        }

        return acc
      }, {} as FormValues['taxCategories'])

      const formDataWithOnlyModifiedTaxCategories = {
        ...formValues,
        taxCategories: modifiedTaxCategories
      }

      await onSubmit(formDataWithOnlyModifiedTaxCategories, taxRatesForCountry)
    }

    return (
      <SimpleModalUI
        title={isEditing ? 'Edit tax rate' : 'Add tax rate'}
        cancelButtonText="Cancel"
        submitButtonText={isEditing ? 'Edit tax rate' : 'Add tax rate'}
        closeOnCancel
        closeOnSubmit
        onSubmit={() => {
          void handleSubmit(formData)
        }}
      >
        <Flex flexDirection="column" gap={2}>
          <InputSelectControlField
            label="Country"
            onChange={value =>
              fieldsConfig.country.onChange(value as CountriesAlpha2)
            }
            options={Object.entries(countriesAlpha2).map(
              ([countryCode, countryName]) => ({
                label: `${
                  countriesAlpha2Emojis[countryCode as CountriesAlpha2]
                } ${countryName}`,
                value: countryCode,
                // If we're creating and there's already a tax rate configured for a country,
                // disable it in the dropdown
                disabled:
                  !isEditing &&
                  countriesWithTaxRates?.includes(
                    countryCode as CountriesAlpha2
                  )
              })
            )}
            validationErrors={fieldsConfig.country.validationErrors}
            initialValue={fieldsConfig.country.value || ''}
            placeholder="Select a country..."
            disabled={isEditing}
          />

          {taxCategories.length > 0 && (
            <>
              <Divider mb={2} borderBottomColor={GreyGrey30} />
              <Box>
                <Label>Tax rates</Label>
                <Flex flexDirection="column" gap={2}>
                  {taxCategories.map(category => (
                    <Flex
                      key={category.id}
                      backgroundColor={GreyGrey10}
                      height="40px"
                      paddingLeft={4}
                      paddingRight={1}
                      borderRadius={6}
                      py={2}
                      justifyContent="space-between"
                      align="center"
                    >
                      <Flex gap={1} align="center">
                        <Text>{category.name}</Text>
                        {category.description ? (
                          <Tooltip placement="top" label={category.description}>
                            <InformationCircleIcon
                              color={GreyGrey60}
                              height="20px"
                            />
                          </Tooltip>
                        ) : null}
                      </Flex>
                      <Box width="80px">
                        <PercentageInput
                          variant="v2"
                          precision={2}
                          placeholder="0"
                          value={
                            fieldsConfig[`taxCategories.${category.id}`].value
                          }
                          onChange={value => {
                            setEditedTaxRates(prevState => [
                              // Set to make sure the categories in array are not duplicated
                              ...new Set([...prevState, category.id])
                            ])

                            fieldsConfig[
                              `taxCategories.${category.id}`
                            ].onChange(value as never)
                          }}
                        />
                      </Box>
                    </Flex>
                  ))}
                </Flex>
              </Box>
            </>
          )}
        </Flex>
      </SimpleModalUI>
    )
  }
)

const TaxRatesModal = ({ trigger, ...props }: Props) => {
  return (
    <ModalContextProvider>
      <Modal.Trigger>{trigger}</Modal.Trigger>
      <TaxRatesModalUI {...props} />
    </ModalContextProvider>
  )
}

export default TaxRatesModal
