import {
  dashboardv99990101Client,
  type Dashboardv99990101Api
} from '@sequencehq/api/dashboard/v99990101'
import type { TaxCategory, TaxRate } from 'Settings/domain/taxRates.types'
import { useMutation } from '@sequencehq/api/utils'
import { useNotifications } from 'lib/hooks/useNotifications'
import * as Sentry from '@sentry/react'
import type { CountriesAlpha2 } from '@sequencehq/api/commonEnums'

type CreateTaxRateBody = Dashboardv99990101Api.PostTaxRate.CreateTaxRateBody

export type CreateTaxRateInput = {
  country: CountriesAlpha2 | undefined
  taxCategories: Record<TaxCategory['id'], TaxRate['amount']>
}

export function useCreateTaxRate() {
  const notifications = useNotifications()

  const { mutateAsync: postTaxRate, isError } = useMutation(
    dashboardv99990101Client.postTaxRate
  )

  return async (newTaxRateInput: CreateTaxRateInput) => {
    const country = newTaxRateInput.country

    if (!country) {
      return
    }

    const taxRatesToCreate: { body: CreateTaxRateBody }[] = Object.entries(
      newTaxRateInput.taxCategories
    ).map(([categoryId, amount]) => {
      const generatedTaxRateName = `${country} tax ${categoryId}`

      return {
        body: {
          name: generatedTaxRateName,
          invoiceName: generatedTaxRateName,
          invoiceShortName: generatedTaxRateName,
          isPublished: true,
          taxCategoryId: categoryId,
          amount,
          country
        }
      }
    })

    await Promise.all(
      taxRatesToCreate.map(taxRateToCreate => {
        return postTaxRate(taxRateToCreate, {
          onError: err => {
            Sentry.captureException(err)
          }
        })
      })
    )

    if (isError) {
      notifications.displayNotification('Failed to create tax rates', {
        type: 'error'
      })
      return
    }

    notifications.displayNotification('Tax rates created', {
      type: 'success'
    })
  }
}
