import {
  CubeReducerState,
  DisabledReasonType,
  PostActionStage
} from 'modules/Cube/domain/cube.domain.types'
import deepmerge from 'deepmerge'
import { compose, set, uniq } from 'lodash/fp'
import { getRecurrenceDateAvailable } from 'modules/Cube/domain/postActionStages/queries/availableFeatures/schedule/getRecurrenceDateAvailable'
import { getAvailableTaxRates } from 'modules/Cube/domain/postActionStages/queries/getAvailableTaxRates'
import { arrayToIdKeyedObject } from '@sequencehq/utils'
import { getRollUpBillingAvailable } from './queries/availableFeatures/schedule/getRollUpBillingAvailable'

/**
 * If the recurrence date is not available, then we need should set it to the 1st.
 * This ensures that the timeline is correct, and any other behaviours which depend
 * on this date.
 * @param prevState
 */
export const cleanRecurrenceDate = (
  prevState: CubeReducerState
): CubeReducerState => {
  const recurrenceDateAvailable = getRecurrenceDateAvailable(prevState)
  /**
   * We should clean up if the reason for the field being disabled is
   * because of price frequency mismatches and it is a valid status
   * for us to be editing the field.
   */
  if (
    recurrenceDateAvailable.reasons.find(
      reason => reason.reasonType === DisabledReasonType.PriceFrequencyMismatch
    ) &&
    !recurrenceDateAvailable.reasons.find(
      reason => reason.reasonType === DisabledReasonType.InvalidStatus
    )
  ) {
    return deepmerge(prevState, {
      data: {
        schedule: {
          recurrenceDayOfMonth: 1
        }
      }
    })
  }

  return prevState
}

/**
 * It is possible for the customer to be changed, and their tax
 * status change with them. Therefore, we should ensure that if the current
 * tax rate is not suitable for the new customer, we clean it up.
 */
export const cleanTaxRate = (prevState: CubeReducerState): CubeReducerState => {
  const availableTaxRates = getAvailableTaxRates(prevState)
  if (availableTaxRates[prevState.data.schedule.taxRateId]) {
    return prevState
  }

  return deepmerge(prevState, {
    data: {
      schedule: {
        taxRateId: ''
      }
    }
  })
}

/**
 * Many parts of the product rely on these version ids existing - so ensure that
 * they are always valid. A notable scenario where 'bad data' can leak in is after
 * an update, where the data for billing schedule versions is returned before price
 * data is.
 * @param prevState
 * @returns
 */
export const cleanPriceReferences = (
  prevState: CubeReducerState
): CubeReducerState => {
  return deepmerge(
    prevState,
    {
      data: {
        phases: Object.values(prevState.data.phases).reduce((acc, phase) => {
          return {
            ...acc,
            [phase.id]: {
              priceIds: phase.priceIds.filter(
                priceId => prevState.data.prices[priceId]
              )
            }
          }
        }, {}),
        discounts: Object.values(prevState.data.discounts).reduce(
          (acc, discount) => {
            const priceIdsInVersion =
              Object.values(prevState.data.phases).find(phase =>
                phase.discountIds?.includes(discount.id)
              )?.priceIds ?? []

            return {
              ...acc,
              [discount.id]: {
                priceIds: discount.priceIds.filter(
                  priceId =>
                    prevState.data.prices[priceId] &&
                    priceIdsInVersion.includes(priceId)
                )
              }
            }
          },
          {}
        ),
        minimums: Object.values(prevState.data.minimums).reduce(
          (acc, minimum) => {
            const priceIdsInVersion =
              Object.values(prevState.data.phases).find(phase =>
                phase.minimumIds.includes(minimum.id)
              )?.priceIds ?? []

            return {
              ...acc,
              [minimum.id]: {
                scope: {
                  priceIds: minimum.scope.priceIds.filter(
                    priceId =>
                      prevState.data.prices[priceId] &&
                      priceIdsInVersion.includes(priceId)
                  )
                }
              }
            }
          },
          {}
        )
      }
    },
    {
      arrayMerge: (_, source: unknown[]) => source
    }
  )
}

/**
 * Remove any prices which refer to non-existent products. This must be performed
 * before cleaning up price references, so that any references to cleaned prices are, in turn, cleaned.
 * @param prevState
 * @returns
 */
export const cleanProductReferences = (
  prevState: CubeReducerState
): CubeReducerState => {
  return set(
    'data.prices',
    arrayToIdKeyedObject(
      Object.values(prevState.data.prices).filter(
        price => prevState.data.products[price.productId]
      )
    )
  )(prevState)
}

/**
 * Ensure that any references to discounts are cleaned up if the discount
 * is missing.
 * @param prevState
 * @returns
 */
export const cleanDiscountReferences = (
  prevState: CubeReducerState
): CubeReducerState => {
  return deepmerge(
    prevState,
    {
      data: {
        phases: Object.values(prevState.data.phases).reduce((acc, phase) => {
          return {
            ...acc,
            [phase.id]: {
              discountIds: phase.discountIds?.filter(
                discountId => prevState.data.discounts[discountId]
              )
            }
          }
        }, {})
      }
    },
    {
      arrayMerge: (_, source: unknown[]) => source
    }
  )
}

export const cleanAnyInvalidCurrencies = (
  prevState: CubeReducerState
): CubeReducerState => {
  const { currency } = prevState.data.common

  const isAvailable =
    currency && prevState.configuration.currency.enabled.includes(currency)

  if (isAvailable) {
    return prevState
  }

  return deepmerge(prevState, {
    data: {
      common: {
        currency: undefined
      }
    }
  })
}

export const cleanAnyInvalidCustomerIds = (
  prevState: CubeReducerState
): CubeReducerState => {
  const { customerId } = prevState.data.common

  const customerExists = prevState.data.customers[customerId]

  if (customerExists) {
    return prevState
  }

  return deepmerge(prevState, {
    data: {
      common: {
        customerId: null
      }
    }
  })
}

/**
 * For minimums, we need to remove any minimums which are attempting to be
 * applied to prices of multiple frequencies, or are specific minimums with
 * no applicable prices.
 * @param prevState
 * @returns
 */

export const cleanAnyInvalidMinimums = (
  prevState: CubeReducerState
): CubeReducerState => {
  return deepmerge(
    prevState,
    {
      data: {
        phases: Object.values(prevState.data.phases).reduce((acc, phase) => {
          return {
            ...acc,
            [phase.id]: {
              minimumIds: phase.minimumIds?.filter(minimumId => {
                const minimum = prevState.data.minimums[minimumId]
                if (!minimum) {
                  return false
                }

                const recurringBillingFrequencies = uniq(
                  (minimum.scope.target === 'specific'
                    ? minimum.scope.priceIds
                    : phase.priceIds
                  )
                    .map(priceId => prevState.data.prices[priceId])
                    .map(
                      price =>
                        'usageMetricId' in price.structure &&
                        price?.billingFrequency
                    )
                    .filter(freq => freq && freq !== 'ONE_TIME')
                )

                if (recurringBillingFrequencies.length !== 1) {
                  return false
                }

                if (
                  minimum.scope.target === 'specific' &&
                  minimum.scope.priceIds.length === 0
                ) {
                  return false
                }

                return true
              })
            }
          }
        }, {})
      }
    },
    {
      arrayMerge: (_, source: unknown[]) => source
    }
  )
}

/**
 * For discounts, we need to remove any discounts which are attempting to be
 * applied to prices of multiple frequencies, or are specific discounts with
 * no prices.
 * @param prevState
 * @returns
 */
export const cleanAnyInvalidDiscounts = (
  prevState: CubeReducerState
): CubeReducerState => {
  return deepmerge(
    prevState,
    {
      data: {
        phases: Object.values(prevState.data.phases).reduce((acc, phase) => {
          return {
            ...acc,
            [phase.id]: {
              discountIds: phase.discountIds?.filter(discountId => {
                const discount = prevState.data.discounts[discountId]
                if (!discount) {
                  return false
                }

                const recurringBillingFrequencies = uniq(
                  (discount.applyToAllPrices
                    ? phase.priceIds
                    : discount.priceIds
                  )
                    .map(priceId => prevState.data.prices[priceId])
                    .map(price => price?.billingFrequency)
                    .filter(freq => freq !== 'ONE_TIME')
                )

                if (recurringBillingFrequencies.length > 1) {
                  return false
                }

                if (
                  !discount.applyToAllPrices &&
                  discount.priceIds.length === 0
                ) {
                  return false
                }

                return true
              })
            }
          }
        }, {})
      }
    },
    {
      arrayMerge: (_, source: unknown[]) => source
    }
  )
}

/**
 * Remove any contacts belonging to a previously set customer
 * @param prevState
 * @returns
 */
export const cleanCustomerContacts = (
  prevState: CubeReducerState
): CubeReducerState => {
  return set(
    'data.contacts',
    arrayToIdKeyedObject(
      Object.values(prevState.data.contacts).filter(
        contact => contact.customerId === prevState.data.common.customerId
      )
    )
  )(prevState)
}

/**
 * Turn off roll up billing if not compatible with current customer
 * @returns
 */
export const cleanRollUpBilling = (
  prevState: CubeReducerState
): CubeReducerState => {
  const rollUpBillingAvailable =
    getRollUpBillingAvailable(prevState)?.available.enabled

  if (rollUpBillingAvailable) {
    return prevState
  }

  return set('data.schedule.rollUpBilling', false)(prevState)
}

export const cleanup: PostActionStage = () => prevState => {
  return compose(
    cleanRollUpBilling,
    cleanAnyInvalidCurrencies,
    cleanAnyInvalidCustomerIds,
    cleanAnyInvalidMinimums,
    cleanAnyInvalidDiscounts,
    cleanDiscountReferences,
    cleanPriceReferences,
    cleanProductReferences,
    cleanRecurrenceDate,
    cleanTaxRate,
    cleanCustomerContacts
    //For some reason, after 8 compositions, the type system stopped being happy! But as long as each
    //compose function has the correct interface, the functions will detect the issues with the return
    //value if there is one
  )(prevState) as CubeReducerState
}
