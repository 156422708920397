import { Box, Flex, Spinner } from '@chakra-ui/react'
import { PhotoIcon, TrashIcon } from '@heroicons/react/16/solid'
import { ServerAsset } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { Button } from '@sequencehq/core-components'
import { IndigoIndigo50 } from '@sequencehq/design-tokens'
import {
  useSelectCoverImageSrc,
  useStoreCoverImageAsset
} from 'Cube/domain/subdomain/coverImage'
import { UploadAssetButton } from 'modules/Assets/UploadAssetButton'
import { FC, PropsWithChildren, useCallback, useState } from 'react'

const TRANSITION_DURATION = 150

export const CoverImageEditor: FC = () => {
  const assetSrc = useSelectCoverImageSrc()
  const storeAsset = useStoreCoverImageAsset()
  const [file, setFile] = useState<File | null>(null)

  // We store this so there's no content jump when the image finishes uploading,
  // and so we can transition out without the image disappearing immediately
  const [imageSrc, setImageSrc] = useState<string | undefined>(assetSrc)

  const handleFilePicked = useCallback((pickedFile: File) => {
    setFile(pickedFile)
    setImageSrc(fileToImageSrc(pickedFile))
  }, [])

  const handleUploadSuccess = useCallback(
    (serverAsset: ServerAsset) => {
      setFile(null)
      storeAsset(serverAsset)
    },
    [storeAsset]
  )

  const removeCoverImage = () => {
    storeAsset(null)
    setTimeout(() => {
      setImageSrc(undefined)
    }, TRANSITION_DURATION)
  }

  const showImage = !!assetSrc || !!file

  return (
    <div>
      <ShowOnGroupHover>
        <UploadAssetButton
          leadingIcon={<PhotoIcon width={16} />}
          variant="secondary"
          onFilePicked={handleFilePicked}
          onUploadSuccess={handleUploadSuccess}
          style={{
            display: imageSrc ? 'none' : undefined,
            position: 'relative',
            left: 95,
            top: 16
          }}
          accept="image/jpeg,image/png"
        >
          Add cover
        </UploadAssetButton>
      </ShowOnGroupHover>

      <Flex
        transition={`height ${TRANSITION_DURATION}ms ease-out, opacity ${TRANSITION_DURATION}ms ease-out`}
        position="relative"
        justifyContent="center"
        alignItems="center"
        height={showImage ? '240px' : 0}
        opacity={showImage ? 1 : 0}
        backgroundImage={imageSrc ? `url(${imageSrc})` : undefined}
        backgroundSize="100% auto"
        backgroundPosition="50% 50%"
        backgroundRepeat="no-repeat"
        mt={!imageSrc ? 3 : undefined}
      >
        {!!file && (
          <Spinner color={IndigoIndigo50} width="36px" height="36px" />
        )}
        {assetSrc && (
          <ShowOnGroupHover>
            <Button
              variant="secondary"
              icon={<TrashIcon width={16} />}
              style={{ position: 'absolute', top: 8, right: 8 }}
              aria-label="remove cover image"
              onClick={removeCoverImage}
            />
          </ShowOnGroupHover>
        )}
      </Flex>
    </div>
  )
}

const ShowOnGroupHover: FC<PropsWithChildren> = ({ children }) => (
  <Box
    _groupHover={{ opacity: 1 }}
    opacity={0}
    transition="opacity 0.15s ease-out"
  >
    {children}
  </Box>
)

function fileToImageSrc(file: File): string {
  return URL.createObjectURL(file)
}
