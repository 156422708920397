import { Box, Link } from '@chakra-ui/react'
import {
  InputSelectControlField,
  SimpleModal
} from '@sequencehq/core-components'
import {
  GreyGrey70,
  IndigoIndigo50,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { useState } from 'react'

const CSV_FORMAT_OPTIONS = [
  {
    label: 'Sequence',
    value: 'SEQUENCE'
  },
  {
    label: 'Xero',
    value: 'XERO'
  },
  {
    label: 'QuickBooks',
    value: 'QUICKBOOKS'
  },
  {
    label: 'Netsuite',
    value: 'NETSUITE'
  }
]
const INITIAL_STATE = 'SEQUENCE'
export const ExportCSVModal = (props: { trigger: React.ReactNode }) => {
  const [csvFormat, setCsvFormat] = useState<string>(INITIAL_STATE)
  /**
   * TODO: Create a SimpleAsyncModal, which is more or less this
   * but with a spinner on the submit and an async submit. Seems a shame
   * to drop the SimpleModal setup entirely for this pretty common
   * case! Then use that to implement the download functionality. Alternatively,
   * we could detect the onSubmit being a promise and use that to drive the spinner,
   * but that feels a bit more magic-y and liable to get in the way at some point.
   */
  return (
    <SimpleModal
      contentProps={{
        minHeight: '160px'
      }}
      title="Download CSV"
      trigger={props.trigger}
      cancelButtonText="Cancel"
      submitButtonText="Download CSV"
      onSubmit={() => {
        alert(`TODO: Download CSV with format ${csvFormat}`)
        setCsvFormat(INITIAL_STATE)
      }}
    >
      <InputSelectControlField
        data-testid="select.csvFormat"
        description={
          <Box {...Lato13Regular} color={GreyGrey70}>
            Choose which format you want to download.{' '}
            <Link
              href="https://docs.sequencehq.com/getting-started/overview"
              target="_blank"
              color={IndigoIndigo50}
            >
              Learn more
            </Link>
          </Box>
        }
        label="CSV format"
        initialValue={csvFormat}
        onChange={setCsvFormat}
        options={CSV_FORMAT_OPTIONS}
      />
    </SimpleModal>
  )
}
