import { useState, useEffect, useMemo } from 'react'
import {
  useGetInsightsUsageEventsQuery,
  useGetUniqueUsageEventTypesQuery
} from 'features/api'
import { StringDate } from '@sequencehq/utils'

interface DateRange {
  start: StringDate
  end: StringDate
}

interface EventTypeData {
  date: string
  value: number
}

interface UseEventTypeWidgets {
  unit: string
  eventType: string
  setEventType: (eventType: string) => void
  data: EventTypeData[]
  isFetching: boolean
  total: number
  eventTypeField: {
    value: string
    label: string
    onChange: (eventType: string) => void
    options: { value: string; label: string }[]
  }
  unitTickValues: number[]
}

export const useEventTypesWidget = (
  dateRange: DateRange
): UseEventTypeWidgets => {
  const { data: eventTypesData, isFetching: isFetchingEventTypes } =
    useGetUniqueUsageEventTypesQuery({ search: '' })

  const eventTypes = useMemo(() => {
    return (
      eventTypesData?.value()?.items.map(v => ({ label: v, value: v })) || []
    )
  }, [eventTypesData])

  const [eventType, setEventType] = useState<string>('')

  const {
    data,
    refetch,
    isFetching: isFetchingInsightsUsageEvents
  } = useGetInsightsUsageEventsQuery({
    dateFrom: dateRange.start,
    dateTo: dateRange.end,
    eventType
  })

  const insightsUsageEvents = data?.value()

  const transformedData = useMemo(() => {
    if (!insightsUsageEvents) {
      return []
    }

    return insightsUsageEvents.eventsByDay.map(
      ({ date, eventCount }: { date: string; eventCount: number }) => ({
        date,
        value: eventCount
      })
    )
  }, [insightsUsageEvents])

  const unitTickValues = useMemo(() => {
    if (!transformedData || transformedData.length === 0) {
      return [0, 0]
    }

    const max = Math.max(...transformedData.map(d => d.value))
    return [0, max / 2, max]
  }, [transformedData])

  const eventTypeField = {
    value: eventType,
    label: eventType,
    onChange: setEventType,
    options: eventTypes
  }

  useEffect(() => {
    void refetch()
  }, [eventType, dateRange, refetch])

  useEffect(() => {
    setEventType(eventTypes[0]?.value || '')
  }, [eventTypes])

  return {
    eventType,
    setEventType,
    data: transformedData,
    isFetching: isFetchingInsightsUsageEvents || isFetchingEventTypes,
    total: insightsUsageEvents?.totalEventCount || 0,
    unit: '',
    eventTypeField,
    unitTickValues
  }
}
