import { Box, Flex } from '@chakra-ui/react'
import { StandardLayout } from '../common/RevRecLayout'
import { Button, InputSelectControl } from '@sequencehq/core-components'
import { ArrowDownTrayIcon } from '@heroicons/react/16/solid'
import { ExportCSVModal } from '../common/ExportCSVModal'
import { useAvailableCurrencies } from '../utils/useAvailableCurrencies'
import { useSelectedCurrency } from '../utils/useSelectedCurrency'
import { Currency } from '@sequencehq/core-models'

export const RevRecHomeHeader = () => {
  const { availableCurrencies } = useAvailableCurrencies()
  const { currency, setCurrency } = useSelectedCurrency()

  return (
    <Box>
      <StandardLayout.Header
        title={<StandardLayout.Title>Revenue recognition</StandardLayout.Title>}
        actions={
          <Flex gap="8px">
            <Box width="84px">
              {!!currency && (
                <InputSelectControl
                  options={
                    availableCurrencies?.map(ac => ({
                      label: ac,
                      value: ac
                    })) ?? []
                  }
                  initialValue={currency}
                  onChange={newCurrency => setCurrency(newCurrency as Currency)}
                />
              )}
            </Box>
            <ExportCSVModal
              trigger={
                <Button
                  variant="secondary"
                  leadingIcon={<ArrowDownTrayIcon width="16px" />}
                >
                  Export to CSV
                </Button>
              }
            />
          </Flex>
        }
      />
    </Box>
  )
}
