import { Box, Grid, GridItem } from '@chakra-ui/react'
import {
  GreyGrey0,
  GreyGrey30,
  GreyWhite,
  Lato14Bold
} from '@sequencehq/design-tokens'
import { ReactNode } from 'react'

/**
 * After RevRec wraps, consider moving this into core-component as a layout template?
 * @params header - The header content. Basic styling is provided by StandardLayout.Header
 * @params content - The main content area. Basic styling is provided by StandardLayout.Content. The content
 * area will scroll if the content is larger than the content area.
 * @params inspector - The inspector content. An optional 'slot' in the template for placing the standard
 * Inspector widget.
 * @returns
 */
const StandardLayoutBase = (props: {
  header: ReactNode
  content: ReactNode
  inspector?: ReactNode
}) => {
  return (
    <Grid
      gridTemplateAreas={`"header header" "content inspector"`}
      gridTemplateColumns="1fr auto"
      gridTemplateRows="auto 1fr"
      /**
       * A lenient minWidth is used here since it will primarily impact
       * how we handle scrolling in the header, since the content area already
       * scrolls.
       */
      minWidth="600px"
      /**
       * This ensures that the layout will be bounded correctly if a constraint is
       * present, but will otherwise extend to full height by default.
       */
      height="100vh"
      maxHeight="100%"
      width="100%"
      backgroundColor={GreyGrey0}
      overflow="hidden"
    >
      <GridItem gridArea="header">{props.header}</GridItem>
      <GridItem gridArea="content" height="100%" overflow="auto">
        {props.content}
      </GridItem>
      {props.inspector && (
        <GridItem gridArea="inspector">{props.inspector}</GridItem>
      )}
    </Grid>
  )
}

const StandardLayoutHeader = (props: {
  title?: ReactNode
  actions?: ReactNode
}) => {
  return (
    <Grid
      gridArea="header"
      gridTemplateColumns="1fr auto"
      height="48px"
      alignItems="center"
      padding={`0 16px`}
      borderBottom={`1px solid ${GreyGrey30}`}
      backgroundColor={GreyWhite}
    >
      <GridItem>{props.title}</GridItem>
      <GridItem>{props.actions}</GridItem>
    </Grid>
  )
}

const StandardLayoutTitle = (props: { children: ReactNode }) => {
  return <Box {...Lato14Bold}>{props.children}</Box>
}

export const StandardLayout = Object.assign(StandardLayoutBase, {
  Header: StandardLayoutHeader,
  Title: StandardLayoutTitle
})
