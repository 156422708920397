import { useEffect, useState } from 'react'
import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dist/clients/dashboard/v20240730'

export type XeroIntegration = {
  enabled: boolean
  defaultLedgerAccount: string | undefined
  creditDefaultLedgerAccount: string | undefined
  ledgerAccounts: {
    id: string
    name: string
    code: string
  }[]
}

export const useXeroIntegration = () => {
  const [xeroIntegration, setXeroIntegration] = useState<
    XeroIntegration | undefined
  >(undefined)
  const [hasError, setHasError] = useState(false)
  const [isFetching, setIsFetching] = useState(false)

  useEffect(() => {
    const fetchIntegrations = async () => {
      setIsFetching(true)
      try {
        const result = await dashboard20240730Client.getIntegration('Xero')
        const xeroResult =
          result.data as DashboardApi20240730.GetIntegration.XeroIntegration
        setXeroIntegration(
          xeroResult
            ? {
                enabled: xeroResult.enabled,
                defaultLedgerAccount:
                  xeroResult.ledgerAccounts.defaults.chargeAccount,
                creditDefaultLedgerAccount:
                  xeroResult.ledgerAccounts.defaults.creditAccount,
                ledgerAccounts: xeroResult.ledgerAccounts.options
              }
            : undefined
        )
        setHasError(result.error)
      } finally {
        setIsFetching(false)
      }
    }

    void fetchIntegrations()
  }, [])

  return {
    loading: isFetching,
    hasError,
    xeroIntegration
  }
}
