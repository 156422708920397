import {
  ModalContextProvider,
  SimpleModalUI
} from '@sequencehq/core-components'
import { ContactModalForm } from 'modules/CustomerContacts'
import { useModalCreateRecipient } from './useModalCreateRecipient'

type Props = {
  onClose: () => void
}

export const ModalCreateRecipient = ({ onClose }: Props) => {
  const { modalTitle, fieldsConfig, buttonSubmit, buttonCancel } =
    useModalCreateRecipient({
      onClose
    })

  return (
    <ModalContextProvider isOpen={true}>
      <SimpleModalUI
        title={modalTitle}
        onSubmit={buttonSubmit.onSubmit}
        submitButtonText="Add recipient"
        submitDisabled={buttonSubmit.disabled}
        cancelButtonText="Cancel"
        onCancel={buttonCancel.onCancel}
      >
        <ContactModalForm fieldsConfig={fieldsConfig} />
      </SimpleModalUI>
    </ModalContextProvider>
  )
}
