import { PageTitle, Button } from '@sequencehq/core-components'
import { StandardLayout } from '../common/RevRecLayout'
import { Flex } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { GreyGrey60, Lato14Bold } from '@sequencehq/design-tokens'
import { ArrowDownTrayIcon, ChevronRightIcon } from '@heroicons/react/16/solid'
import formatInTimeZone from 'date-fns-tz/formatInTimeZone'
import { UTCDate } from '@date-fns/utc'
import { ExportCSVModal } from '../common/ExportCSVModal'
import { useSelectedDates } from './content/selectorHooks'

const RevenueRecognitionHomeBreadcrumb = () => {
  return (
    <Link
      to="/revenue-recognition"
      style={{
        color: GreyGrey60,
        textDecoration: 'none'
      }}
    >
      <Flex gap="4px" {...Lato14Bold} alignItems="center">
        Revenue recognition
        <ChevronRightIcon
          width="16px"
          color={GreyGrey60}
          style={{ position: 'relative', top: '1px' }}
        />
      </Flex>
    </Link>
  )
}

export const RevRecReportDetailHeader = () => {
  const dates = useSelectedDates()

  /**
   * TODO - 'PageTitle' is an older component which is how we set the
   * title in the <head>. We should consider how to better handle this to
   * make it a smidge easier to find. Maybe as part of defining module routes?
   */
  return (
    <StandardLayout.Header
      actions={
        <ExportCSVModal
          trigger={
            <Button
              variant="secondary"
              leadingIcon={<ArrowDownTrayIcon width="16px" />}
            >
              Export to CSV
            </Button>
          }
        />
      }
      title={
        <StandardLayout.Title>
          <PageTitle title={['Revenue Recognition', 'A month']} />
          <Flex gap="4px" {...Lato14Bold} alignItems="center">
            <RevenueRecognitionHomeBreadcrumb />
            {formatInTimeZone(new UTCDate(dates.startDate), 'UTC', 'MMMM yyyy')}
          </Flex>
        </StandardLayout.Title>
      }
    />
  )
}
