import { Center, Spinner } from '@chakra-ui/react'
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'

import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dashboard/v20240730'

export type Data = DashboardApi20240730.GetCustomers.CustomersList | null

type LoadDataParams = {
  sortBy?: 'legalName' | 'email' | 'alias' | 'label'
  sortOrder?: 'ASC' | 'DESC'
  legalName?: string
  email?: string
  alias?: string
  includeArchived?: boolean
  after?: string
  before?: string
  limit?: number
}

type LoadDataFunction = (params?: LoadDataParams) => Promise<Data>

type MagicTableDataObject = {
  data: {
    value: () => Data
  }
  isFetching: boolean
}

type MagicTableAdapter = [LoadDataFunction, MagicTableDataObject]

export type CustomersContextData = {
  refetchCustomers: () => Promise<Data>
  magicTableAdapter: () => MagicTableAdapter
}

const CustomersContext = createContext<CustomersContextData | undefined>(
  undefined
)

export const CustomersContextProvider = ({ children }: PropsWithChildren) => {
  const [state, setState] = useState<{
    data: Data
    loading: boolean
    error: boolean
  }>({
    data: null,
    loading: false,
    error: false
  })

  const loadData = useCallback(
    /*
        This should include all params for filtering and pagination, and pass them
        into the underlying api call.
      */
    async ({
      sortBy,
      sortOrder,
      legalName,
      email,
      alias,
      includeArchived,
      after,
      before,
      limit
    }: {
      sortBy?: 'legalName' | 'email' | 'alias' | 'label'
      sortOrder?: 'ASC' | 'DESC'
      legalName?: string
      email?: string
      alias?: string
      includeArchived?: boolean
      after?: string
      before?: string
      limit?: number
    } = {}): Promise<Data> => {
      if (state.loading) {
        return null
      }
      setState(ps => ({ ...ps, loading: true }))
      const response = await dashboard20240730Client.getCustomers({
        sortBy,
        sortOrder,
        legalName,
        email,
        alias,
        includeArchived,
        after,
        before,
        limit
      })
      if (response.error) {
        throw new Error('Could not load customers')
      }

      const data = response.data
        ? {
            ...response.data,
            items: response.data?.items
          }
        : null

      setState({
        data,
        loading: false,
        error: response.error
      })

      return data
    },
    [state.loading]
  )

  /*
       This adapter is the key part - it provides an interface that matches up to the lazy
       rtk-query hook, and it's this adapter that we pass into MagicTable as the `useLazyQuery`
       prop.
     */
  const magicTableAdapter = useCallback(() => {
    return [
      (
        params: {
          sortBy?: 'legalName' | 'email' | 'alias' | 'label'
          sortOrder?: 'ASC' | 'DESC'
          legalName?: string
          email?: string
          alias?: string
          includeArchived?: boolean
          after?: string
          before?: string
          limit?: number
        } = {}
      ) => loadData(params),
      {
        data: {
          value: () => state.data
        },
        isFetching: state.loading
      }
    ]
  }, [loadData, state])

  const context = useMemo(
    () => ({
      refetchCustomers: loadData,
      magicTableAdapter
    }),
    [loadData, magicTableAdapter]
  )

  if (!context) {
    return (
      <Center height="100%" width="100%">
        <Spinner />
      </Center>
    )
  }
  return (
    // @ts-expect-error - type issue with magic table adapter
    <CustomersContext.Provider value={context}>
      {children}
    </CustomersContext.Provider>
  )
}

export const useCustomersContext = () => {
  const context = useContext(CustomersContext)
  if (!context) {
    throw new Error(
      'useCustomersContext must be used within a CustomersContextProvider'
    )
  }
  return context
}
