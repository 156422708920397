import { useCubeContext } from 'modules/Cube/communication/internal/cube.domain.context'
import {
  Common,
  Customer,
  ValidationResult
} from 'modules/Cube/domain/cube.domain.types'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

type CustomerObject = {
  label: Customer['legalName']
  value: Customer['id']
}

type CustomerSelectionField = {
  options: CustomerObject[]
  value: Customer['id']
  onChange: (newValue: Customer['id']) => void
  disabled: boolean
  validationErrors: ValidationResult[]
  onAddNew: () => void
}
export type WidgetView = 'customerSelection' | 'customerView'

type UseCustomerWidget = () => {
  customer: Customer
  showEditButton: boolean
  showCancelButton: boolean
  addCustomerDisabled: boolean
  showCustomerSelection: boolean
  showCustomerView: boolean
  updateView: (newView: WidgetView) => void
  fieldConfig: {
    customer: CustomerSelectionField
    rollUpBilling: {
      hidden: boolean
      isDisabled: boolean
      validationErrors: ValidationResult[]
      value: boolean
      onChange: (value: boolean) => void
      childAccounts: string[]
      childAccountCount: number
    }
  }
}

export const useCustomerWidget: UseCustomerWidget = () => {
  const navigate = useNavigate()
  const [view, setView] = useState<'customerSelection' | 'customerView'>(
    'customerView'
  )
  const cubeContext = useCubeContext()

  const { customers } = cubeContext.queries.rawData.data

  const customerId = cubeContext.queries.rawData.data.common.customerId
  const customer = customers[customerId]

  const availableCustomers = useMemo(() => {
    return Object.values(cubeContext.queries.rawData.data.customers)
      .map(availableCustomer => ({
        label: availableCustomer.legalName,
        value: availableCustomer.id
      }))
      .sort((a, b) => (a.label.toUpperCase() < b.label.toUpperCase() ? -1 : 1))
  }, [cubeContext.queries.rawData.data.customers])

  const loadFullCustomer = useCallback(
    async (newCustomerId: string) => {
      const fullCustomer =
        (await cubeContext.mutators.external.in.customer({
          customerId: newCustomerId
        })) ?? {}

      cubeContext.mutators.updateData({
        customers: {
          [newCustomerId]: fullCustomer
        }
      })
      return
    },
    [cubeContext.mutators]
  )

  const updateCustomer = useCallback(
    (newCustomerId: Common['customerId']) => {
      setView('customerView')

      loadFullCustomer(newCustomerId).catch(() => {
        // eslint-disable-next-line no-console
        console.error(`Failed to load full customer with id ${newCustomerId}`)
      })

      return cubeContext.mutators.updateData({
        common: {
          customerId: newCustomerId
        }
      })
    },
    [cubeContext, loadFullCustomer]
  )

  const showCustomerSelection = Boolean(
    !customerId || view === 'customerSelection'
  )

  const showCustomerView = Boolean(customerId && view !== 'customerSelection')

  const showEditButton =
    cubeContext.queries.availableFeatures.common.editCustomer.available
      .visible && !showCustomerSelection

  const showCancelButton = Boolean(showCustomerSelection && customerId)

  const updateView = useCallback((newView: WidgetView) => {
    return setView(newView)
  }, [])

  const childAccounts = useMemo(() => {
    return customer?.organizations?.some(
      org => org?.owner?.id === customerId && org?.members?.length !== 0
    )
      ? customer?.organizations?.flatMap(
          org => org?.members?.map(member => member?.name) ?? []
        ) ?? []
      : customer?.aliases
          ?.map(alias => alias.label || alias.value)
          .sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1)) ?? []
  }, [customer?.aliases, customer?.organizations, customerId])

  const fieldConfig = useMemo(
    () => ({
      customer: {
        disabled: false,
        value: cubeContext.queries.rawData.data.common.customerId,
        onChange: updateCustomer,
        options: availableCustomers,
        validationErrors:
          cubeContext.queries.validation.activeValidationResults?.common
            .customerId ?? [],
        onAddNew: () => {
          setView('customerView')
          navigate('./customers/new')
        }
      },
      rollUpBilling: {
        hidden:
          !cubeContext.queries.availableFeatures.schedule.rollUpBilling
            .available.visible,
        isDisabled:
          !cubeContext.queries.availableFeatures.schedule.rollUpBilling
            .available.enabled,
        validationErrors: [],
        onChange: (value: boolean) => {
          cubeContext.mutators.updateData({
            schedule: {
              rollUpBilling: value
            }
          })
        },
        value: cubeContext.queries.rawData.data.schedule.rollUpBilling,
        childAccounts,
        childAccountCount: childAccounts.length
      }
    }),
    [
      cubeContext.queries.rawData.data.common.customerId,
      cubeContext.queries.rawData.data.schedule.rollUpBilling,
      cubeContext.queries.validation.activeValidationResults?.common.customerId,
      cubeContext.queries.availableFeatures.schedule.rollUpBilling.available
        .visible,
      cubeContext.queries.availableFeatures.schedule.rollUpBilling.available
        .enabled,
      cubeContext.mutators,
      updateCustomer,
      availableCustomers,
      childAccounts,
      navigate
    ]
  )

  return {
    customer,
    showEditButton,
    showCancelButton,
    addCustomerDisabled:
      !cubeContext.queries.availableFeatures.common.editCustomer.available
        .enabled,
    showCustomerSelection: showCustomerSelection,
    showCustomerView,
    updateView,
    fieldConfig
  }
}
