import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  ModalFooter,
  Skeleton,
  Spinner
} from '@chakra-ui/react'
import {
  EditTimeTravellerFormMutation,
  EditTimeTravellerFormProps
} from 'components/EditTimeTraveller/types'
import { FormErrors } from '@sequencehq/forms'
import { DateInputField } from 'components/FormFields'
import { handleFormResponse } from 'lib/formValidation'
import { noReturn } from 'lib/noReturn'
import { FC } from 'react'
import { Form } from 'react-final-form'
import { required } from '@sequencehq/validation'
import { useDispatch } from 'features/store'
import { closeOverlay } from 'features/overlay'
import { useGetTimeTravellersByIdQuery } from 'features/api'
import { MutationFormProps } from 'components/Form/types'

const EditTimeTravellerForm: FC<
  MutationFormProps<EditTimeTravellerFormMutation> & EditTimeTravellerFormProps
> = ({ billingSchedule, submitForm, formId }) => {
  const dispatch = useDispatch()

  const timeTravellerId = billingSchedule.timeTravellerId || ''

  const { data, isLoading } = useGetTimeTravellersByIdQuery({
    id: timeTravellerId
  })

  const timeTraveller = data?.value()

  const isDisabled = isLoading || timeTraveller?.status === 'IN_PROGRESS'

  if (isLoading) {
    return <Spinner></Spinner>
  }

  return (
    <Form
      keepDirtyOnReinitialize
      initialValues={{
        frozenTime: timeTraveller?.frozenTime
      }}
      onSubmit={async (values, form) => {
        const res = await submitForm({
          id: timeTravellerId,
          updateTimeTravellerEndpointUpdateTimeTravellerRequestModel: {
            frozenTime: values.frozenTime
          }
        })

        return handleFormResponse(res, form.getRegisteredFields())
      }}
      render={({ handleSubmit, submitError, submitting }) => {
        const dayzedDate = new Date()
        dayzedDate.setDate(dayzedDate.getDate() - 1)
        return (
          <>
            <Flex
              as="form"
              px={10}
              py={10}
              flexDirection="column"
              onSubmit={noReturn(handleSubmit)}
              id={formId}
            >
              <FormErrors formError={submitError} />
              {timeTraveller?.status === 'IN_PROGRESS' && (
                <>
                  <Alert status="warning">
                    <AlertIcon />
                    Previous time travel is still in progress, please wait
                  </Alert>
                  <Box height={2} />
                </>
              )}

              <Skeleton isLoaded={!isLoading}>
                <DateInputField
                  fieldName="frozenTime"
                  fieldLabel="Date"
                  minDate={
                    timeTraveller?.frozenTime
                      ? new Date(timeTraveller.frozenTime)
                      : dayzedDate
                  }
                  isSelectable={() => true}
                  validate={required}
                  isDisabled={isDisabled}
                />
                <Box height={2} />
              </Skeleton>
            </Flex>
            <ModalFooter>
              <Button
                variant="secondary"
                mr={3}
                onClick={() => {
                  dispatch(closeOverlay())
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                form={formId}
                isLoading={submitting}
                isDisabled={isDisabled}
              >
                Begin time travel 🛸
              </Button>
            </ModalFooter>
          </>
        )
      }}
    />
  )
}

export default EditTimeTravellerForm
