import {
  ActionHandler,
  LoadAction
} from 'modules/Cube/domain/cube.domain.types'
import { INITIAL_CUBE_STATE } from 'modules/Cube/domain/cube.constants'
import { getResolvedPhaseData } from 'modules/Cube/domain/postActionStages/queries/getResolvedPhaseData'
import { UTCDate } from '@date-fns/utc'

export const load: ActionHandler<LoadAction> = () => action => {
  /**
   * When loading, we will remove any existing state, and start over. Therefore
   * we do not use the prevState here and instead use the initial value.
   *
   * Note: it may be the case in the future that we may wish to retain some
   * data, like the configuration for the editor, but for now we will reset everything
   * and look to introduce further options to the action if that is indeed the case.
   **/

  const loadedData = {
    ...INITIAL_CUBE_STATE,
    ...action.payload,
    editor: {
      ...INITIAL_CUBE_STATE.editor,
      loaded: true,
      lastLoadedAt: new UTCDate()
    }
  }

  return {
    ...loadedData,
    initialData: loadedData.data,
    initialQueries: {
      resolvedPhases: getResolvedPhaseData(loadedData)
    }
  }
}
