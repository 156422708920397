import { SentryConfig, getReactSentryConfig } from '@sequencehq/utils'

const { environment } = getReactSentryConfig({
  VITE_SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
  VITE_SENTRY_ENVIRONMENT: import.meta.env.VITE_SENTRY_ENVIRONMENT
})

const isProduction = ['production'].includes(environment)
const isSandbox = ['sandbox'].includes(environment)
const isDemo = ['demo'].includes(environment)
const isTest = ['test'].includes(environment)

export function isProductionEnv(): boolean {
  return isProduction
}

export function isProductionOrDemoEnv(): boolean {
  return isProduction || isDemo
}

export function isSandboxEnv(): boolean {
  return isSandbox
}

export function isDemoEnv(): boolean {
  return isDemo
}

export function isTestEnv(): boolean {
  return isTest
}

export function getEnvironment(): SentryConfig['environment'] {
  return environment
}
