import {
  Dashboardv99990101Api,
  dashboardv99990101Client
} from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { useCallback } from 'react'

type UseSaveProduct = () => {
  createProduct: (
    newProduct: Dashboardv99990101Api.PostProduct.ProductBody
  ) => Promise<Dashboardv99990101Api.PostProduct.Product | null>
}

export const useSaveProduct: UseSaveProduct = () => {
  const createProduct = useCallback(
    async (newProduct: Dashboardv99990101Api.PostProduct.ProductBody) => {
      const response = await dashboardv99990101Client.postProduct({
        body: newProduct
      })

      if (response.error || !response.data) {
        return null
      }

      return response.data
    },
    []
  )

  return {
    createProduct
  }
}
