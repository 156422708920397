import { TextAreaField, TextField } from '@sequencehq/core-components'
import { Flex } from '@chakra-ui/react'
import { useProductForm } from './useProductForm'
import { TaxCategoryField } from './TaxCategoryField'

export const AddProductForm = () => {
  const { fieldsConfig } = useProductForm()

  return (
    <Flex gap="24px" direction="column" data-testid="pricingEditor.addProduct">
      <Flex direction="column">
        <TextField
          label="Product name"
          value={fieldsConfig.name.value}
          onChange={fieldsConfig.name.onChange}
          validationErrors={fieldsConfig.name.validationErrors}
          isDisabled={fieldsConfig.name.disabled}
          placeholder="Enter a product name"
          autoFocus
          data-testid="addProduct.name"
        />
        <TextAreaField
          label="Description"
          value={fieldsConfig.label.value}
          onChange={fieldsConfig.label.onChange}
          validationErrors={fieldsConfig.label.validationErrors}
          isDisabled={fieldsConfig.label.disabled}
          placeholder="Enter a product description"
        />

        <TaxCategoryField
          data-testid="addProduct.taxCategoryId"
          field={fieldsConfig.taxCategoryId}
          formChangeset={{}}
        />
      </Flex>
    </Flex>
  )
}
