import {
  DashboardApi20240730,
  dashboard20240730Client
} from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { useCallback, useState } from 'react'

type Data = DashboardApi20240730.GetQuotes.QuotesList | null

export const useQuoteMagicTableLoader = () => {
  const [state, setState] = useState<{
    data: Data
    loading: boolean
    error: boolean
  }>({
    data: null,
    loading: false,
    error: false
  })

  const loadData = useCallback(
    /*
        This should include all params for filtering and pagination, and pass them
        into the underlying api call.
      */
    async ({
      customerId,
      includeArchived,
      after,
      before,
      limit
    }: {
      customerId?: string
      includeArchived?: boolean
      after?: string
      before?: string
      limit?: number
    } = {}): Promise<DashboardApi20240730.GetQuotes.QuotesList | null> => {
      if (state.loading) {
        return null
      }
      setState(ps => ({ ...ps, loading: true }))
      const response = await dashboard20240730Client.getQuotes({
        customerId,
        includeArchived,
        after,
        before,
        limit
      })
      if (response.error) {
        throw new Error('Could not load quotes')
      }
      setState({
        data: response.data,
        loading: false,
        error: response.error
      })
      return response.data
    },
    [state.loading]
  )

  /*
       This adapter is the key part - it provides an interface that matches up to the lazy
       rtk-query hook, and it's this adapter that we pass into MagicTable as the `useLazyQuery`
       prop.
     */
  const magicTableLoader = useCallback(() => {
    return [
      (
        params: {
          customerId?: string
          includeArchived?: boolean
          after?: string
          before?: string
          limit?: number
        } = {}
      ) =>
        loadData(params).then(res => ({
          data: {
            value: () => res
          }
        })),
      {
        data: {
          value: () => state?.data
        },
        isFetching: state.loading
      }
    ]
  }, [loadData, state])

  return {
    loadData,
    magicTableLoader
  }
}
