import { useDisclosure } from '@chakra-ui/react'
import { UTCDate } from '@date-fns/utc'
import isEqual from 'date-fns/isEqual'
import { pick } from 'lodash/fp'
import { useCubeContext } from 'modules/Cube/communication/internal/cube.domain.context'
import { CubeDomainInterface } from 'modules/Cube/domain/cube.domain'
import {
  CubeReducerState,
  CubeStatus
} from 'modules/Cube/domain/cube.domain.types'
import { cubeDeveloperLog } from 'modules/Cube/utils/cubeDeveloperLog'
import { objectsAreEqualIgnoreUndefined } from 'modules/Cube/utils/objectsAreEqualIgnoreUndefined'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'
import { hasPresentationChanged } from '../QuoteEditorContent/ContentEditor/utils/presentation'

enum AvailableModal {
  Publish = 'publish',
  Accept = 'accept',
  Execute = 'execute'
}

type UseQuoteEditorHeader = () => {
  title: string
  isArchived: boolean
  syncing: boolean
  hasBeenPublished: boolean
  isReadyToSign: boolean
  modals: {
    [AvailableModal.Publish]: {
      active: boolean
      onClose: () => void
      onConfirm: (data: {
        sendRecipientsEmail: boolean
        emailMessage: string | undefined
      }) => Promise<void>
      submitting: boolean
      recipientCountText: string
    }
    [AvailableModal.Accept]: {
      active: boolean
      onClose: () => void
      onConfirm: () => void
      submitting: boolean
    }
    [AvailableModal.Execute]: {
      active: boolean
      onClose: () => void
      onConfirm: () => void
      submitting: boolean
    }
  }
  features: {
    publish: {
      visible: boolean
      disabled: boolean
      onClick: () => void
    }
    accept: {
      visible: boolean
      disabled: boolean
      onClick: () => void
    }
    execute: {
      visible: boolean
      disabled: boolean
      onClick: () => void
    }
    preview: {
      visible: boolean
      disabled: boolean
      onClick: () => void
    }
  }
  badgeUnpublishedChanges: {
    visible: boolean
  }
  publishedLinkPopover: {
    isOpen: boolean
    onClose: () => void
  }
}

const comparableProperties = pick([
  'common',
  'phases',
  'quote',
  'discounts',
  'minimums',
  'prices'
])

export const useQuoteEditorHeader: UseQuoteEditorHeader = () => {
  const navigate = useNavigate()
  const cubeContext = useCubeContext()
  const [activeModal, setActiveModal] = useState<AvailableModal | null>(null)
  const [submitting, setSubmitting] = useState(false)
  const [displaySyncing, setDisplaySyncing] = useState(false)
  const [needsSync, setNeedsSync] = useState(false)
  const [previousData, setPreviousData] = useState<
    CubeDomainInterface['queries']['rawData']['data'] | undefined
  >()
  const [previousLastLoadedAt, setLastLoadedAt] = useState<
    UTCDate | undefined
  >()
  const publishedLinkPopover = useDisclosure()
  const enablePublishedLinkPopover = useRef<boolean>(false)

  const modals = useMemo(() => {
    return {
      [AvailableModal.Publish]: {
        active: activeModal === AvailableModal.Publish,
        submitting,
        onClose: () => setActiveModal(null),
        onConfirm: async (data: {
          sendRecipientsEmail: boolean
          emailMessage: string | undefined
        }) => {
          setSubmitting(true)
          await cubeContext.mutators.external.out.quote?.publish(data)
          setSubmitting(false)
          setActiveModal(null)
          enablePublishedLinkPopover.current = true
        },
        recipientCountText: (() => {
          const { contacts } = cubeContext.queries.rawData.data.quote

          if (contacts.length === 1) {
            return '1 recipient'
          }

          return `${contacts.length} recipients`
        })()
      },
      [AvailableModal.Accept]: {
        active: activeModal === AvailableModal.Accept,
        submitting,
        onClose: () => setActiveModal(null),
        onConfirm: async () => {
          setSubmitting(true)

          /**
           * Eagerly mark the quote as accepted so the autosave hook doesn't try
           * to set the status back to draft when accepting a quote
           */
          cubeContext.mutators.updateData({
            common: {
              status: CubeStatus.QuoteAccepted
            }
          })

          if (cubeContext.mutators.external.out.quote?.accept) {
            await cubeContext.mutators.external.out.quote.accept()
          }
          setSubmitting(false)
          setActiveModal(null)
        }
      },
      [AvailableModal.Execute]: {
        active: activeModal === AvailableModal.Execute,
        submitting,
        onClose: () => setActiveModal(null),
        onConfirm: async () => {
          setSubmitting(true)
          if (cubeContext.mutators.external.out.quote?.execute) {
            await cubeContext.mutators.external.out.quote.execute()
          }
          setSubmitting(false)
          setActiveModal(null)
        }
      }
    }
  }, [activeModal, cubeContext, submitting])

  const features = useMemo(() => {
    return {
      publish: {
        visible:
          cubeContext.queries.availableFeatures.quote.publish.available.visible,
        disabled:
          !cubeContext.queries.availableFeatures.quote.publish.available
            .enabled,
        onClick: () => {
          if (cubeContext.queries.validation.validationErrorsPresent.publish) {
            cubeContext.mutators.updateEditor({
              activeValidationSet: 'publish'
            })
            return
          }
          setActiveModal(AvailableModal.Publish)
        }
      },
      accept: {
        visible:
          cubeContext.queries.availableFeatures.quote.accept.available.visible,
        disabled:
          !cubeContext.queries.availableFeatures.quote.accept.available.enabled,
        onClick: () => setActiveModal(AvailableModal.Accept)
      },
      execute: {
        visible:
          cubeContext.queries.availableFeatures.quote.execute.available.visible,
        disabled:
          !cubeContext.queries.availableFeatures.quote.execute.available
            .enabled,
        onClick: () => {
          if (cubeContext.queries.validation.validationErrorsPresent.execute) {
            cubeContext.mutators.updateEditor({
              activeValidationSet: 'execute'
            })
            return
          }

          setActiveModal(AvailableModal.Execute)
        }
      },
      preview: {
        visible:
          cubeContext.queries.availableFeatures.quote.preview.available.visible,
        disabled:
          !cubeContext.queries.availableFeatures.quote.preview.available
            .enabled,
        onClick: () => {
          navigate(
            `/quotes/${cubeContext.queries.rawData.data.common.id}/preview`
          )
        }
      }
    }
  }, [cubeContext, navigate])

  const persistChanges = useDebouncedCallback(async () => {
    setDisplaySyncing(true)
    await cubeContext.mutators.external.out.save({
      reload:
        cubeContext.queries.rawData.data.common.status ===
          CubeStatus.QuotePublished ||
        cubeContext.queries.rawData.data.common.status ===
          CubeStatus.QuoteReadyToSign,
      showValidationErrors: false
    })
    setDisplaySyncing(false)
    setNeedsSync(false)
  }, 1000)

  useEffect(() => {
    /**
     * Only allow autosaving on draft and published quotes
     */
    if (!cubeContext.queries.availableFeatures.quote.edit.available.enabled) {
      /**
       * Allow the start date to be updated before executing a quote
       */
      if (
        cubeContext.queries.rawData.data.common.status ===
          CubeStatus.QuoteAccepted &&
        previousData?.common.startDate?.toISOString() !==
          cubeContext.queries.rawData.data.common.startDate?.toISOString()
      ) {
        void persistChanges()
      }

      setPreviousData(cubeContext.queries.rawData.data)

      return
    }

    if (
      !previousLastLoadedAt ||
      !cubeContext.queries.rawData.editor.lastLoadedAt ||
      !isEqual(
        previousLastLoadedAt,
        cubeContext.queries.rawData.editor.lastLoadedAt
      )
    ) {
      setPreviousData(undefined)
      setLastLoadedAt(cubeContext.queries.rawData.editor.lastLoadedAt)
    }

    if (
      !previousData &&
      previousLastLoadedAt &&
      cubeContext.queries.rawData.editor.lastLoadedAt &&
      isEqual(
        previousLastLoadedAt,
        cubeContext.queries.rawData.editor.lastLoadedAt
      )
    ) {
      setPreviousData(cubeContext.queries.rawData.data)
      return
    }

    if (
      !cubeContext.queries.rawData.editor.savingSchedule &&
      previousData !== undefined &&
      previousLastLoadedAt &&
      cubeContext.queries.rawData.editor.lastLoadedAt &&
      isEqual(
        previousLastLoadedAt,
        cubeContext.queries.rawData.editor.lastLoadedAt
      ) &&
      (!objectsAreEqualIgnoreUndefined(
        comparableProperties(cubeContext.queries.rawData.data),
        comparableProperties(previousData)
      ) ||
        hasPresentationChanged(
          cubeContext.queries.rawData.data.presentation,
          previousData.presentation
        ))
    ) {
      cubeDeveloperLog(
        '%c[Cube] Data needs to be synced',
        'color: lime; background-color: black;',
        () => ({
          currentData: cubeContext.queries.rawData.data,
          previousLastLoadedAt,
          currentLastLoadedAt: cubeContext.queries.rawData.editor.lastLoadedAt,
          previousData: previousData,
          needsSync,
          hasPresentationChanged: hasPresentationChanged(
            cubeContext.queries.rawData.data.presentation,
            previousData.presentation
          ),
          detailedEquality: Object.entries(
            comparableProperties(cubeContext.queries.rawData.data)
          ).reduce(
            (acc, [prop, value]) => ({
              ...acc,
              [prop]: objectsAreEqualIgnoreUndefined(
                value,
                previousData[prop as keyof CubeReducerState['data']]
              )
            }),
            {}
          )
        })
      )
      setPreviousData(cubeContext.queries.rawData.data)
      setNeedsSync(true)
      if (
        cubeContext.queries.rawData.data.common.status ===
          CubeStatus.QuotePublished ||
        cubeContext.queries.rawData.data.common.status ===
          CubeStatus.QuoteReadyToSign
      ) {
        cubeContext.mutators.updateData({
          common: {
            status: CubeStatus.QuoteDraft
          }
        })
      }
      void persistChanges()
    }
  }, [
    cubeContext.queries.rawData.editor.savingSchedule,
    cubeContext.queries.rawData.data,
    previousData,
    cubeContext.mutators,
    cubeContext.queries.rawData.editor.lastLoadedAt,
    previousLastLoadedAt,
    persistChanges,
    needsSync,
    cubeContext.queries.availableFeatures.quote.edit.available.enabled
  ])

  const title = useMemo(() => {
    const titleValue =
      cubeContext.queries.rawData.data.common.title || 'Untitled'
    if (cubeContext.queries.rawData.data.common.isArchived) {
      return `${titleValue} (archived)`
    } else {
      return titleValue
    }
  }, [cubeContext])

  const badgeUnpublishedChanges = useMemo(
    () => ({
      visible:
        cubeContext.queries.availableFeatures.quote.deleteLatestDraft.available
          .visible
    }),
    [
      cubeContext.queries.availableFeatures.quote.deleteLatestDraft.available
        .visible
    ]
  )

  /**
   * Wait for the quote to become published after confirming the publish modal,
   * before showing the link popover
   */
  useEffect(() => {
    if (
      !enablePublishedLinkPopover.current ||
      cubeContext.queries.rawData.data.common.status !==
        CubeStatus.QuotePublished
    ) {
      return
    }

    publishedLinkPopover.onOpen()
    enablePublishedLinkPopover.current = false
  }, [cubeContext.queries.rawData.data.common.status, publishedLinkPopover])

  return {
    title: title,
    isArchived: cubeContext.queries.rawData.data.common.isArchived,
    isReadyToSign: Boolean(
      cubeContext.queries.rawData.data.quote.readyToSignAt
    ),
    hasBeenPublished: !!cubeContext.queries.rawData.data.quote.publishedAt,
    syncing: displaySyncing,
    modals,
    features,
    badgeUnpublishedChanges,
    publishedLinkPopover: {
      isOpen: publishedLinkPopover.isOpen,
      onClose: publishedLinkPopover.onClose
    }
  }
}
