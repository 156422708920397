import { ReactNode, useMemo } from 'react'
import { useInvoiceEditorContext } from 'InvoiceEditor/hooks/useInvoiceEditorContext'
import { IntegrationService } from '@sequencehq/core-models'
import { useLoadIntegrations } from 'InvoiceEditor/hooks/useLoadIntegrations'
import { integrationName } from 'lib/integrations/integrationName'
import { getLogo } from 'lib/integrations/logos'
import { Dashboardv20240509Api } from '@sequencehq/api/dist/clients/dashboard/v20240509'

type BaseIntegration = {
  name: string
  logo: ReactNode
  invoiceUrl?: string
  customerUrl?: string
}

type IntegrationAction = {
  available: boolean
  actionHandler: () => void
}

type UnusedIntegrationAction = {
  available: false
}

export type AccountingIntegration = BaseIntegration & {
  actions: {
    collection: UnusedIntegrationAction
    customer: IntegrationAction
    invoice: IntegrationAction
  }
}

export type PaymentsIntegration = BaseIntegration & {
  actions: {
    collection: IntegrationAction
    customer: UnusedIntegrationAction
    invoice: UnusedIntegrationAction
  }
}

export type Integration = AccountingIntegration | PaymentsIntegration

type UseIntegrations = () => {
  data: {
    customerName: string
    integrations: (AccountingIntegration | PaymentsIntegration)[]
    missingIntegrations: string[]
  }
}

const baseIntegrationObject = (
  integration: Dashboardv20240509Api.GetIntegrations.Integration
): BaseIntegration => ({
  name: integrationName(integration.service),
  logo: getLogo(integration.service, 24)
})

export const useIntegrations: UseIntegrations = () => {
  const {
    data: contextData,
    functions: contextFunctions,
    derived: { queries: contextQueries }
  } = useInvoiceEditorContext()
  const { data: integrationsData, loading: loadingIntegrations } =
    useLoadIntegrations()

  const linkedInvoiceIntegrations:
    | Record<IntegrationService, { externalId: string; url: string }>
    | Record<string, never> = useMemo(() => {
    return contextData.invoice.linkedServices.reduce((acc, service) => {
      return {
        ...acc,
        [service.externalService]: {
          externalId: service.externalId,
          url: service.externalUrl
        }
      }
    }, {})
  }, [contextData.invoice.linkedServices])

  const linkedCustomerIntegrations:
    | Record<IntegrationService, { externalId: string; url: string }>
    | Record<string, never> = useMemo(() => {
    if (!contextData.customer.externalIds) {
      return {}
    }

    return contextData.customer.externalIds.reduce((acc, service) => {
      return {
        ...acc,
        [service.key]: {
          externalId: service.value,
          url: service.externalUrl
        }
      }
    }, {})
  }, [contextData.customer.externalIds])

  const connectedAccountingIntegrations = useMemo(() => {
    if (!integrationsData) {
      return []
    }

    return integrationsData.integrations.filter(
      integration =>
        integration.state === 'CONNECTED' && integration.type === 'Accounting'
    )
  }, [integrationsData])

  const connectedPaymentIntegrations = useMemo(() => {
    if (!integrationsData) {
      return []
    }

    return integrationsData.integrations.filter(
      integration =>
        integration.state === 'CONNECTED' && integration.type === 'Payments'
    )
  }, [integrationsData])

  const integrations: (AccountingIntegration | PaymentsIntegration)[] =
    useMemo(() => {
      if (loadingIntegrations || !integrationsData) {
        return []
      }

      const accountingIntegrations: AccountingIntegration[] =
        connectedAccountingIntegrations.map(accountingIntegration => {
          const { externalId: externalCustomerId, url: externalCustomerUrl } =
            linkedCustomerIntegrations[accountingIntegration.service] ?? {}
          const { externalId: externalInvoiceId, url: externalInvoiceUrl } =
            linkedInvoiceIntegrations[accountingIntegration.service] ?? {}

          const customerLinkExists = Boolean(externalCustomerId)
          const invoiceLinkExists = Boolean(externalInvoiceId)

          return {
            ...baseIntegrationObject(accountingIntegration),
            customerUrl: externalCustomerUrl,
            invoiceUrl: externalInvoiceUrl,
            actions: {
              collection: { available: false },
              customer: {
                available:
                  contextQueries.availableFeatures
                    .canSyncCustomerToIntegrations &&
                  !customerLinkExists &&
                  !invoiceLinkExists,
                actionHandler: () =>
                  contextFunctions.linkCustomerToIntegration(
                    accountingIntegration.service
                  )
              },
              invoice: {
                available:
                  contextQueries.availableFeatures
                    .canSyncInvoiceToIntegrations &&
                  customerLinkExists &&
                  !invoiceLinkExists &&
                  accountingIntegration.enabled,
                actionHandler: () =>
                  contextFunctions.syncInvoiceToIntegration(
                    accountingIntegration.service,
                    contextData.invoice.linkedServices
                  )
              }
            }
          }
        })

      const paymentIntegrations: PaymentsIntegration[] =
        connectedPaymentIntegrations.map(paymentIntegration => {
          const { url: stripeInvoiceUrl } =
            linkedInvoiceIntegrations['Stripe'] ?? {}

          return {
            ...baseIntegrationObject(paymentIntegration),
            invoiceUrl: stripeInvoiceUrl,
            actions: {
              collection: {
                available:
                  contextQueries.availableFeatures
                    .canUpdatePaymentCollectionMethod &&
                  !contextData.invoice.paymentOptions.includes('LINK'),
                actionHandler: () =>
                  contextFunctions.addCollection(
                    'STRIPE',
                    contextData.invoice.paymentOptions
                  )
              },
              customer: { available: false },
              invoice: {
                available: false
              }
            }
          }
        })

      return [...accountingIntegrations, ...paymentIntegrations]
    }, [
      loadingIntegrations,
      integrationsData,
      connectedAccountingIntegrations,
      connectedPaymentIntegrations,
      linkedCustomerIntegrations,
      linkedInvoiceIntegrations,
      contextQueries.availableFeatures.canSyncCustomerToIntegrations,
      contextQueries.availableFeatures.canSyncInvoiceToIntegrations,
      contextQueries.availableFeatures.canUpdatePaymentCollectionMethod,
      contextFunctions,
      contextData.invoice.linkedServices,
      contextData.invoice.paymentOptions
    ])

  const missingIntegrations = useMemo(() => {
    const integrationsMissingCustomerLink = integrations.filter(
      integration =>
        integration.actions.customer.available && !integration.customerUrl
    )

    return integrationsMissingCustomerLink.map(({ name }) => name)
  }, [integrations])

  return {
    data: {
      customerName: contextData.recipient.customerLegalName,
      integrations,
      missingIntegrations
    }
  }
}
