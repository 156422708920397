import { useFlags } from 'launchdarkly-react-client-sdk'
import { TaxRatesSettings } from 'Settings/view/taxRatesSettings/TaxRatesSettings'
import TaxRateSettings from 'components/TaxRateSettings'
import { AvalaraTaxRatesSettings } from 'Settings/view/taxRatesSettings/avalara/AvalaraTaxRatesSettings'

const TaxRatesSettingsRoute = () => {
  const flags = useFlags()

  if (flags.showNewTaxRatesSettings) {
    // Mock - sub out for an API call that checks if Avalara is enabled
    const isAvalaraIntegrationEnabled = false
    if (isAvalaraIntegrationEnabled) {
      return <AvalaraTaxRatesSettings />
    }

    return <TaxRatesSettings />
  }

  return <TaxRateSettings />
}

export default TaxRatesSettingsRoute
