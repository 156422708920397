import {
  Box,
  Button,
  Center,
  Flex,
  Skeleton,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { PlusIcon } from '@heroicons/react/16/solid'
import { Badge } from '@sequencehq/core-components'
import { toQuoteStatusBadgeProps } from '@sequencehq/core-models'
import {
  GreyGrey30,
  Lato13Bold,
  Lato13Regular,
  Lato14Bold
} from '@sequencehq/design-tokens'
import { dateTimeWithFormat } from '@sequencehq/formatters'
import { useGetUsersByIdQuery } from 'features/api'
import { useCustomerContext } from 'Customer/hooks/useCustomerContext'
import { useGetQuotes } from 'Customer/hooks/useGetQuotes'
import { Link, useNavigate } from 'react-router-dom'

export const QuoteTable = () => {
  const navigate = useNavigate()
  const { customer } = useCustomerContext()
  const quotes = useGetQuotes(customer.id, 12)

  if (!quotes) {
    return (
      <Center height="100%" width="100%" mt={4}>
        <Spinner />
      </Center>
    )
  }

  const isArchived = Boolean(customer.archivedAt)

  return (
    <Box p={4}>
      <Flex justifyContent="space-between" alignItems="center">
        <Text {...Lato14Bold}>Quotes</Text>
        <Button
          {...Lato13Bold}
          variant="component-library-ghost"
          leftIcon={
            <PlusIcon
              color={isArchived ? undefined : '#5F5CFF'}
              height={16}
              width={16}
            />
          }
          isDisabled={isArchived}
          {...(!isArchived && {
            as: Link,
            to: `/quotes/new?customerId=${customer.id}`
          })}
        >
          Create quote
        </Button>
      </Flex>
      <TableContainer
        borderRadius="lg"
        overflow="hidden"
        border={`1px solid ${GreyGrey30}`}
        mt={2}
        width="100%"
        boxShadow="0px 0px 2px 0px rgba(0, 0, 0, 0.02), 0px 1px 1px 0px rgba(0, 0, 0, 0.05)"
      >
        <Table variant="v2" width="100%">
          <Thead>
            <Tr>
              <Th>Title</Th>
              <Th width={120}>Number</Th>
              <Th width={120}>Status</Th>
              <Th width={200}>Owner</Th>
              <Th width={200}>Expires</Th>
            </Tr>
          </Thead>
          <Tbody>
            {quotes.items.length > 0 ? (
              quotes.items.map(quote => {
                return (
                  <Tr
                    key={quote.id}
                    onClick={() => navigate(`/quotes/${quote.id}`)}
                  >
                    <Td>
                      <Text
                        {...Lato13Bold}
                        as={Link}
                        to={`/quotes/${quote.id}`}
                        color="#5F5CFF"
                        onClick={e => e.stopPropagation()}
                      >
                        {quote.title}
                      </Text>
                    </Td>
                    <Td>{quote.quoteNumber}</Td>
                    <Td>
                      <Badge
                        {...toQuoteStatusBadgeProps({ status: quote.status })}
                      />
                    </Td>
                    <Td>
                      <QuoteOwner createdBy={quote.createdBy} />
                    </Td>
                    <Td>
                      {quote.expiresAt
                        ? dateTimeWithFormat(quote.expiresAt, 'd MMM yyyy')
                        : quote.expiryDays
                        ? `${quote.expiryDays} days after publishing`
                        : ''}
                    </Td>
                  </Tr>
                )
              })
            ) : (
              <Tr
                sx={{
                  cursor: 'auto!important',
                  _hover: {
                    bgColor: 'inherit!important'
                  }
                }}
              >
                <Td colSpan={5}>
                  <EmptyContent />
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  )
}

const EmptyContent = () => {
  return (
    <Box width="400px" margin="12px auto" textAlign="center">
      <Text {...Lato13Bold} color="gray.90">
        No quotes to display
      </Text>
      <Text {...Lato13Regular} color="gray.80">
        You have not created a quote for this customer yet.
      </Text>
    </Box>
  )
}

const QuoteOwner = ({ createdBy }: { createdBy: string }) => {
  const { data, isFetching } = useGetUsersByIdQuery({
    id: createdBy
  })

  if (isFetching || !data) {
    return <Skeleton width="100px" height="20px" />
  }

  return <>{data.value()?.email}</>
}
