import {
  Button,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Grid,
  GridItem
} from '@chakra-ui/react'
import { TextAreaField, TextField } from '@sequencehq/core-components'
import { GreyGrey30, Lato16Bold } from '@sequencehq/design-tokens'
import { TaxCategoryField } from 'common/drawers/PricingEditor/view/layout/listPrice/ProductForm/TaxCategoryField'
import { useEditProduct } from 'modules/Products/drawers/EditProduct/useEditProduct'
import { Product } from 'Products/types'

export const EditProductForm = (props: {
  onClose: () => void
  onEdit?: (updatedProduct: Product) => void
}) => {
  const {
    canUpdate,
    changeset,
    fieldsConfig,
    functions: { editProduct }
  } = useEditProduct()

  const handleUpdate = () => {
    void editProduct()?.then(updatedProduct => {
      if (props.onEdit && updatedProduct) {
        props.onEdit(updatedProduct)
      }

      props.onClose()
    })
  }

  return (
    <Drawer isOpen onClose={props.onClose}>
      <DrawerOverlay
        display="flex"
        alignContent="center"
        justifyContent="center"
      />
      <DrawerContent
        margin="8px 12px"
        borderRadius="8px"
        style={{
          width: 'fit-content',
          maxWidth: '100%',
          minWidth: '550px'
        }}
      >
        <Grid
          templateAreas={`
              "header"
              "content"
              "footer"
            `}
          templateRows={'56px 1fr 64px'}
          width="100%"
          height="100%"
        >
          <Flex
            gridArea="header"
            borderBottom={`1px solid ${GreyGrey30}`}
            {...Lato16Bold}
            height="56px"
            px="24px"
            alignItems="center"
            justify="space-between"
          >
            Edit product
            <DrawerCloseButton
              style={{
                position: 'inherit'
              }}
            />
          </Flex>

          <GridItem gridArea="content" overflow="auto">
            <Flex gap="24px" padding="24px" direction="column">
              <Flex direction="column" data-testid="editProduct">
                <TextField
                  data-testid="editProduct.name"
                  label="Product name"
                  value={fieldsConfig.name.value}
                  onChange={fieldsConfig.name.onChange}
                  validationErrors={fieldsConfig.name.validationErrors}
                  isDisabled={fieldsConfig.name.disabled}
                  autoFocus
                />
                <TextAreaField
                  label="Description"
                  value={fieldsConfig.label.value}
                  onChange={fieldsConfig.label.onChange}
                  validationErrors={fieldsConfig.label.validationErrors}
                  isDisabled={fieldsConfig.label.disabled}
                />

                <TaxCategoryField
                  data-testid="editProduct.taxCategoryId"
                  field={fieldsConfig.taxCategoryId}
                  formChangeset={changeset}
                />
              </Flex>
            </Flex>
          </GridItem>

          <Flex
            gridArea="footer"
            align="center"
            borderTop={`1px solid ${GreyGrey30}`}
            gap="8px"
            height="100%"
            justify="flex-end"
            padding="24px"
          >
            <Button variant="secondary2" onClick={props.onClose}>
              Cancel
            </Button>
            <Button
              data-testid="editProduct.save"
              variant="primary2"
              isDisabled={!canUpdate}
              onClick={handleUpdate}
            >
              Update
            </Button>
          </Flex>
        </Grid>
      </DrawerContent>
    </Drawer>
  )
}
