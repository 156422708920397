import {
  DashboardApi20240730,
  dashboard20240730Client
} from '@sequencehq/api/dashboard/v20240730'
import { useCallback } from 'react'
import { ApiError } from 'features/api/integratedApi.generated.ts'

type UseUpdateCustomer = () => {
  editCustomer: (
    id: string,
    updateCustomerBody: DashboardApi20240730.PutCustomer.PutCustomerBody
  ) => Promise<DashboardApi20240730.PutCustomer.PutCustomerResponse | null>
}

export const useUpdateCustomer: UseUpdateCustomer = () => {
  const editCustomer = useCallback(
    async (
      id: string,
      updateCustomerBody: DashboardApi20240730.PutCustomer.PutCustomerBody
    ) => {
      const response = await dashboard20240730Client
        .putCustomer({
          id,
          body: updateCustomerBody
        })
        .then(async result => {
          if (result?.error) {
            const errorResponse = (await result.res.json()) as ApiError

            throw new Error(
              errorResponse?.error?.errors?.[0]?.message ??
                'Something went wrong, please try again.'
            )
          }

          return result?.data
        })
      return response
    },
    []
  )
  return {
    editCustomer
  }
}
