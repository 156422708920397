import { Dashboardv20240509Api } from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'
import {
  v1ApiCreateListPriceResult,
  v1ApiCreateProductResult
} from '../communication/external/v1/ports/list.api.v1/entitySaving'

// TODO: we really need to make this a util somewhere!
export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends object | undefined
    ? RecursivePartial<T[P]>
    : T[P]
}

export type ApiListPrice = Dashboardv20240509Api.GetListPrice.ListPrice
export type ApiPricingTier =
  | Dashboardv20240509Api.CommonModels.PricingStructure.GraduatedPricingTier
  | Dashboardv20240509Api.CommonModels.PricingStructure.VolumeFixedPricingTier
  | Dashboardv20240509Api.CommonModels.PricingStructure.VolumePercentagePricingTier
  | Dashboardv20240509Api.CommonModels.PricingStructure.SeatBasePricingTier

export type Product = {
  id: string
  name: string
  label?: string
  taxCategoryId?: string
}

export type PricingEditorLedgerIntegration = {
  id: string
  defaultLedgerAccount?: string
  options: {
    id: string
    name: string
    code: string
  }[]
}

type UsageMetricParameter = {
  id: string
  usageMetricId: string
  name: string
  type: 'INTEGER' | 'POSITIVE_INTEGER'
  description: string
  defaultValue: string
}

export type UsageMetric = {
  id: string
  name: string
  aggregationType: 'COUNT' | 'UNIQUE' | 'SUM' | 'CUSTOM'
  parameters: Array<UsageMetricParameter>
}

export type BillingType = 'IN_ARREARS' | 'IN_ADVANCE'

export const availableBillingTypeOptions: {
  value: BillingType
  label: string
}[] = [
  { label: 'In arrears', value: 'IN_ARREARS' },
  { label: 'In advance', value: 'IN_ADVANCE' }
]

export type PricingModel =
  | 'STANDARD'
  | 'LINEAR'
  | 'VOLUME'
  | 'GRADUATED'
  | 'PACKAGED'
  | 'SEAT_BASED_LINEAR'
  | 'SEAT_BASED_GRADUATED'

type BasePrice<PM extends PricingModel, S> = {
  id: string
  common: {
    pricingModel: PM
    productId: string
    currency: Currency
    name: string
    integrationIds: Record<PricingEditorLedgerIntegration['id'], string>
    listPriceId?: string
  }
  modelSpecific: S
}

export type AvailableStandardFrequency =
  | 'ONE_TIME'
  | 'MONTHLY'
  | 'QUARTERLY'
  | 'YEARLY'

export type UsageTierType = 'FIXED' | 'PERCENTAGE'

export type LinearPriceType = 'FIXED' | 'PERCENTAGE'

export type UsageCalculationMode = 'BILLING_PERIOD' | 'CUMULATIVE' | 'PERIODIC'

export type UsageCalculationFrequency = 'MONTHLY' | 'QUARTERLY' | 'YEARLY'

type ProrationStrategy =
  | 'USE_FIRST'
  | 'USE_MAXIMUM'
  | 'PRORATE_INCREMENTS'
  | 'PRORATE_ALL_CHANGES'

export type PriceEditorTier = {
  id: string
  firstUnit: string
  lastUnit: string
  unitPrice: string
  flatFee: string
}

export type PriceEditorPercentageTier = {
  id: string
  firstUnit: string
  lastUnit: string
  maxPrice: string
  minPrice: string
  unitPercentage: string
  flatFee: string
}
export type StandardPrice = BasePrice<
  'STANDARD',
  {
    price: string
    billingType: BillingType
    billingFrequency: AvailableStandardFrequency | 'ONE_TIME'
  }
>
export type LinearPrice = BasePrice<
  'LINEAR',
  {
    price: string
    percentage: string
    usageMetricId: string
    linearPriceType: LinearPriceType
    billingFrequency: AvailableStandardFrequency
    billingType: 'IN_ARREARS'
    minPrice: string
    maxPrice: string
    parameters: Record<string, string>
  }
>
export type GraduatedPrice = BasePrice<
  'GRADUATED',
  {
    usageMetricId: string
    tiers: PriceEditorTier[]
    percentageTiers: PriceEditorPercentageTier[]
    usageTierType: UsageTierType
    usageCalculationMode: UsageCalculationMode
    usageCalculationPeriod?: {
      frequency: UsageCalculationFrequency
      interval: number
    }
    billingFrequency: AvailableStandardFrequency
    billingType: 'IN_ARREARS'
    parameters: Record<string, string>
  }
>
export type VolumePrice = BasePrice<
  'VOLUME',
  {
    usageMetricId: string
    tiers: PriceEditorTier[]
    percentageTiers: PriceEditorPercentageTier[]
    usageTierType: UsageTierType
    billingFrequency: AvailableStandardFrequency
    billingType: 'IN_ARREARS'
    includePercentageLimits: boolean
    parameters: Record<string, string>
  }
>
export type PackagedPrice = BasePrice<
  'PACKAGED',
  {
    usageMetricId: string
    pricePerPackage: string
    packageSize: string
    billingFrequency: AvailableStandardFrequency
    billingType: 'IN_ARREARS'
    parameters: Record<string, string>
  }
>
export type SeatOveragesBillingFrequency = 'NEVER' | AvailableStandardFrequency

export type SeatBasedProrationStrategy = ProrationStrategy
export type SeatBasedLinearPrice = BasePrice<
  'SEAT_BASED_LINEAR',
  {
    seatMetricId: string
    pricePerSeat: string
    contractedMinimumSeats: string
    prorationStrategy: SeatBasedProrationStrategy
    overagesBillingFrequency?: SeatOveragesBillingFrequency
    billingFrequency: AvailableStandardFrequency
    billingType: BillingType
  }
>
export type SeatBasedGraduatedPrice = BasePrice<
  'SEAT_BASED_GRADUATED',
  {
    seatMetricId: string
    pricePerSeat: string
    contractedMinimumSeats: string
    prorationStrategy: SeatBasedProrationStrategy
    overagesBillingFrequency?: SeatOveragesBillingFrequency
    billingFrequency: AvailableStandardFrequency
    billingType: BillingType
    tiers: PriceEditorTier[]
  }
>

export type Price =
  | StandardPrice
  | LinearPrice
  | GraduatedPrice
  | VolumePrice
  | PackagedPrice
  | SeatBasedGraduatedPrice
  | SeatBasedLinearPrice

type BreadcrumbStep = {
  name: string
  activeInMode: EditorMode[]
}

export enum EditorMode {
  ADD_PRODUCT = 'ADD_PRODUCT',
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  REVIEW = 'REVIEW'
}

/**
 * Internal types for domain implementation
 */
export type PricingEditorReducerState = {
  data: {
    pricingEditorData: {
      common: {
        id: string
        productId: string
        listPriceId?: string
        name: string
        pricingModel: PricingModel
        currency: Currency
        integrationIds: Record<PricingEditorLedgerIntegration['id'], string>
      }
      STANDARD: StandardPrice['modelSpecific']
      LINEAR: LinearPrice['modelSpecific']
      VOLUME: VolumePrice['modelSpecific']
      GRADUATED: GraduatedPrice['modelSpecific']
      PACKAGED: PackagedPrice['modelSpecific']
      SEAT_BASED_LINEAR: SeatBasedLinearPrice['modelSpecific']
      SEAT_BASED_GRADUATED: SeatBasedGraduatedPrice['modelSpecific']
    }
    integrations: Record<string, PricingEditorLedgerIntegration>
    listPrices: Record<string, Price>
    product: Product
    usageMetrics: Record<string, UsageMetric>
  }
  configuration: {
    availableCurrencies: Currency[]
    availableStandardFrequencies: AvailableStandardFrequency[]
    currency?: Currency
    mode: EditorMode
    priceType: PricingEditorVariant
  }
  editor: {
    loaded: boolean
    showValidationErrors: boolean
    steps: BreadcrumbStep[]
    valid: boolean
  }
  queries: {
    availableFeatures: {
      common: {
        breadcrumb: VisibleEnabledFeatureAvailable
        form: VisibleEnabledFeatureAvailable
        review: VisibleEnabledFeatureAvailable
        save: VisibleEnabledFeatureAvailable
      }
      listPrice: {
        addProduct: VisibleEnabledFeatureAvailable
      }
      variantPrice: {
        canChangeListPrice: VisibleEnabledFeatureAvailable
      }
    }
    breadcrumbSteps: {
      name: string
      active: boolean
    }[]
    formattedPricingEditorData: string
  }
  initialData: {
    mode: EditorMode
    price: Price
    product: Product
  }
}

type Action<T extends string, P> = {
  type: T
  payload: P
}

export type LoadAction = Action<
  'load',
  Pick<PricingEditorReducerState, 'data' | 'configuration' | 'initialData'>
>

export type UpdateEditorAction = Action<
  'updateEditor',
  Partial<PricingEditorReducerState['editor']>
>

export type UpdateConfigurationAction = Action<
  'updateConfiguration',
  Partial<PricingEditorReducerState['configuration']>
>

export type UpdatePricingEditorDataAction = Action<
  'updatePricingEditorData',
  RecursivePartial<PricingEditorReducerState['data']['pricingEditorData']>
>

export type UpdateProductAction = Action<
  'updateProduct',
  Partial<PricingEditorReducerState['data']['product']>
>

export type PricingEditorActions =
  | LoadAction
  | UpdateEditorAction
  | UpdatePricingEditorDataAction
  | UpdateConfigurationAction
  | UpdateProductAction

export type ActionHandler<A extends PricingEditorActions> = (
  prevState: PricingEditorReducerState
) => (action: A) => PricingEditorReducerState

export type PostActionContext = {
  preActionState: PricingEditorReducerState
  action: PricingEditorActions
}
export type PostActionStage = (
  ctx: PostActionContext
) => (prevState: PricingEditorReducerState) => PricingEditorReducerState

export type VisibleEnabledFeatureAvailable = {
  available: {
    visible: boolean
    enabled: boolean
  }
}

/**
 * Domain interface
 */
export type PricingEditorDomainInterface = {
  queries: PricingEditorReducerState['queries'] & {
    rawData: Pick<
      PricingEditorReducerState,
      'data' | 'configuration' | 'editor' | 'initialData'
    >
  }
  mutators: {
    external: {
      in: {
        load: () => Promise<{
          data: Pick<PricingEditorReducerState, 'data' | 'configuration'> | null
          error: PricingEditorPortErrors | null
        }>
      }
      out: {
        save: () => Promise<void>
      }
    }
    common: {
      updateEditor: (
        editorSettings: Partial<PricingEditorReducerState['editor']>
      ) => void
      updateConfiguration: (
        configuration: Partial<PricingEditorReducerState['configuration']>
      ) => void
      updatePricingEditorData: (
        newData: RecursivePartial<
          PricingEditorReducerState['data']['pricingEditorData']
        >
      ) => void
      updateProduct: (
        newProduct: Partial<PricingEditorReducerState['data']['product']>
      ) => void
    }
  }
}

/**
 * Port implementation type
 */
export type PricingEditorDomainOutput = PricingEditorDomainInterface['queries']
export type PricingEditorDomainInput = Pick<
  PricingEditorReducerState,
  'data' | 'configuration' | 'initialData'
>

export enum PricingEditorPortErrors {
  NotFound = 'notFound',
  Other = 'other'
}

export type PricingEditorPortImplementation = {
  in: {
    load: () => Promise<{
      data: PricingEditorDomainInput | null
      error: PricingEditorPortErrors | null
    }>
  }
  out: {
    save: (data: PricingEditorDomainOutput) => Promise<{
      success: boolean
      error: PricingEditorPortErrors | null
      data: {
        price: v1ApiCreateListPriceResult
        product?: v1ApiCreateProductResult
      }
    }>
  }
}

export type PricingEditorVariant = 'list' | 'variant'
