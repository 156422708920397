import { useNavigate, useParams } from 'react-router-dom'
import { FC } from 'react'
import invariant from 'tiny-invariant'
import { CreateCustomerForm } from 'components/Customers/components/drawers/CreateCustomer/CreateCustomerForm'
import { CustomersContextProvider } from 'components/Customers/hooks/useCustomersContext'

const CreateCustomerDrawerForm: FC = () => {
  const navigate = useNavigate()
  const onSuccess = () => {
    navigate(`/invoices`)
  }

  return (
    <CustomersContextProvider>
      <CreateCustomerForm
        onClose={() => navigate(`/invoices`)}
        onSuccess={onSuccess}
      />
    </CustomersContextProvider>
  )
}

type UrlParams = {
  customerId: string
  scheduleId: string
}

export const InvoicesCustomerDrawerForm = () => {
  const { customerId } = useParams<UrlParams>()

  invariant(customerId, 'customerId is required')

  if (customerId === 'new') {
    return <CreateCustomerDrawerForm />
  }

  return null
}
