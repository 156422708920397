import { FC, memo } from 'react'

import { Flex, Text } from '@chakra-ui/react'
import {
  IdentificationIcon,
  TagIcon,
  ViewColumnsIcon
} from '@heroicons/react/16/solid'
import BanknotesIcon from '@heroicons/react/24/outline/BanknotesIcon'
import { Badge, KebabMenu, MenuItemBuilder } from '@sequencehq/core-components'
import {
  BillingScheduleModel,
  BillingScheduleStatus,
  PriceModel,
  billingScheduleStatus,
  toBillingScheduleStatusBadgeProps
} from '@sequencehq/core-models'
import { GreyGrey60 } from '@sequencehq/design-tokens'
import { dateTimeWithFormat } from '@sequencehq/formatters'
import {
  MagicTable,
  MagicTableCell,
  MagicTableCellScheduleStatus,
  MagicTableFilterConfig
} from '@sequencehq/tables'
import MagicTableAutoLoader from 'components/AutoLoader/MagicTableAutoLoader'
import { BillingSchedulesSegmentedFilters } from 'components/BillingSchedules/components/BillingSchedulesSegmentedFilters'
import { useBillingSchedulesLoader } from 'components/BillingSchedules/hooks/useBillingSchedulesLoader.ts'
import {
  BillingSchedulesFilters,
  BillingSchedulesView
} from 'components/BillingSchedules/types'
import { CurrentUserId } from 'components/CurrentUserId/CurrentUserId'
import EmptyState from 'components/Loading/EmptyState'
import Page from 'components/Page'
import {
  TagTypes,
  useGetApiPlansPlanIdQuery,
  useGetCustomersByIdQuery
} from 'features/api'
import { openOverlay } from 'features/overlay'
import { useSelector } from 'features/store'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useGetMagicTableFilterOptions } from 'lib/magicTableSupport/useGetMagicTableFilterOptions'
import { ExtractQueryParams } from 'lib/types'
import { ExtractAttributeTypeFromUnion } from 'lib/types/extractAttributeTypeFromUnion'

const viewBuilder: MenuItemBuilder<BillingScheduleModel> = (
  schedule: BillingScheduleModel
) => {
  return {
    status: 'LIVE',
    action: ({ navigate }) => navigate(`/billing-schedules/${schedule.id}`),
    label: 'View/Edit schedule'
  }
}

const activateBuilder: MenuItemBuilder<BillingScheduleModel> = (
  schedule: BillingScheduleModel,
  options
) => {
  return {
    status: schedule.status === 'DRAFT' ? 'LIVE' : 'DISABLED',
    action: ({ dispatch }) =>
      dispatch(
        openOverlay({
          content: 'startBillingModal',
          data: {
            billingScheduleId: schedule.id,
            onSuccess: options?.onSuccess
          }
        })
      ),
    label: 'Start schedule'
  }
}

const archiveBuilder: MenuItemBuilder<BillingScheduleModel> = (
  schedule: BillingScheduleModel,
  options
) => {
  return {
    status: !schedule.archivedAt ? 'LIVE' : 'DISABLED',
    action: ({ dispatch }) =>
      dispatch(
        openOverlay({
          content: 'archiveBillingScheduleModal',
          data: {
            billingScheduleId: schedule.id,
            status: schedule.status,
            onSuccess: options?.onSuccess
          }
        })
      ),
    label: 'Archive schedule'
  }
}

const timeTravelBuilder: MenuItemBuilder<BillingScheduleModel> = (
  billingSchedule: BillingScheduleModel
) => {
  const { timeTravellerId } = billingSchedule

  return {
    status: billingSchedule.status != 'DRAFT' ? 'LIVE' : 'DISABLED',
    action: ({ dispatch }) =>
      dispatch(
        openOverlay({
          content: timeTravellerId
            ? 'editTimeTravellerModal'
            : 'createTimeTravellerModal',
          data: billingSchedule
        })
      ),
    label: timeTravellerId ? 'Update time travel' : 'Start time travel',
    featureFlag: 'timeTravel'
  }
}

export const menuItemBuilders = [
  viewBuilder,
  activateBuilder,
  timeTravelBuilder,
  archiveBuilder
]

export const statusFilterOptions = () => {
  const statuses: BillingScheduleStatus[] = Object.keys(
    billingScheduleStatus.keys
  ) as BillingScheduleStatus[]
  const options = []

  for (const status of statuses) {
    // PENDING is stored as ACTIVE in the database,
    // so we need to do some backend work to make it possible to filter by PENDING
    if (status === 'PENDING') {
      continue
    } else {
      options.push({
        label: toBillingScheduleStatusBadgeProps({ status }).children,
        value: status
      })
    }
  }

  return options
}

const BillingSchedules: FC = memo(() => {
  const flags = useFlags()

  const { fetchCustomerNameOptions } = useGetMagicTableFilterOptions()

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const { magicTableAdapter, reload } = useBillingSchedulesLoader()

  const resetKey = useSelector(
    state => state.apiCaching.tags['BillingSchedules']
  )

  const emptyContent = {
    title: 'Automate your billing process',
    description:
      'Create a new billing schedule for a customer and put invoicing on autopilot.',
    linkCopy: 'Learn more about billing schedules.',
    docLink: `https://docs.sequencehq.com/billing/create-billing-schedules`,
    buttonCopy: 'Add new billing schedule',
    route: '/billing-schedules/new'
  }

  const filters: MagicTableFilterConfig<BillingSchedulesFilters>[] = [
    {
      type: 'multiSelect',
      paramName: 'customerId',
      label: 'Customer',
      icon: IdentificationIcon,
      options: [],
      optionsFunc: fetchCustomerNameOptions,
      format: (value: string) => <Text>{value}</Text>
    },
    {
      type: 'multiSelect',
      paramName: 'billingScheduleStatus',
      options: statusFilterOptions(),
      format: status => (
        <Badge
          {...toBillingScheduleStatusBadgeProps({
            status: status as BillingScheduleStatus
          })}
        />
      ),
      label: 'Status',
      optionsSortFn: () => 0,
      icon: ViewColumnsIcon
    },
    {
      paramName: 'includeArchivedSchedules',

      type: 'toggle',
      label: 'Show archived schedules'
    }
  ]

  if (flags?.showBillingScheduleLabelField) {
    filters.push({
      type: 'text',
      paramName: 'label',
      label: 'Label',
      icon: TagIcon
    })
  }

  const additionalToolbarComponentsV3 = flags?.segmentedFilters
    ? {
        additionalToolbarComponentsV3: {
          start: <BillingSchedulesSegmentedFilters />
        }
      }
    : {}

  return (
    <Flex flexDirection="column" flex={1}>
      <Flex position="sticky" top={0} height="100vh">
        <Page
          title="Schedules"
          addNewConfig={{
            buttonText: 'New schedule',
            href: '/billing-schedules/new'
          }}
          paddingBottom={0}
        >
          <CurrentUserId>
            {userId => (
              <MagicTable<BillingSchedulesView, TagTypes>
                entityNamePlural="billing schedules"
                entityIcon={props => <BanknotesIcon {...props} />}
                sequenceUserId={userId}
                resetKey={resetKey}
                //@ts-expect-error - TODO: fix this once magic table is refactored not to be tightly coupled with rtk-query
                useLazyQuery={magicTableAdapter}
                rowPath={(row: BillingSchedulesView['model']) =>
                  `/billing-schedules/${row.id}`
                }
                columns={[
                  {
                    id: 'customer',
                    accessorFn: row => ({
                      customerId: row.customerId,
                      billingScheduleArchivedAt: row.archivedAt,
                      rollUpBilling: row.rollUpBilling
                    }),
                    header: 'Customer',
                    cell: value => {
                      const { billingScheduleArchivedAt, customerId } =
                        value.getValue<{
                          customerId: BillingScheduleModel['customerId']
                          billingScheduleArchivedAt: BillingScheduleModel['archivedAt']
                          rollUpBilling: BillingScheduleModel['rollUpBilling']
                        }>()

                      return (
                        <MagicTableAutoLoader<
                          ExtractQueryParams<typeof useGetCustomersByIdQuery>,
                          {
                            customerId: string
                            customerName: string
                            customerArchived: boolean
                          }
                        >
                          queryParams={{
                            id: customerId
                          }}
                          useQuery={useGetCustomersByIdQuery}
                          extract={customer => ({
                            customerId: customer?.id ?? '-',
                            customerName: customer?.legalName ?? ' - ',
                            customerArchived: !!customer?.archivedAt
                          })}
                        >
                          {({ customerName, customerArchived }) => (
                            <MagicTableCell
                              textColor={
                                billingScheduleArchivedAt && GreyGrey60
                              }
                              text={`${customerName}${
                                customerArchived ? ' (archived)' : ''
                              }`}
                            />
                          )}
                        </MagicTableAutoLoader>
                      )
                    }
                  },
                  {
                    id: 'status',
                    header: 'Status',
                    accessorFn: (row: BillingScheduleModel) => ({
                      status: row.status,
                      archivedAt: row.archivedAt
                    }),
                    cell: value => {
                      const { status, archivedAt } = value.getValue<{
                        status: BillingScheduleModel['status']
                        archivedAt: BillingScheduleModel['archivedAt']
                      }>()

                      return (
                        <MagicTableCellScheduleStatus
                          status={status}
                          isArchived={!!archivedAt}
                        />
                      )
                    }
                  },

                  {
                    id: 'products',
                    header: 'Products',
                    accessorFn: (model: BillingScheduleModel) => ({
                      planId: model.type === 'PLAN' ? model.planId : undefined,
                      prices:
                        model.type === 'CUSTOM' ? model.prices : undefined,
                      archivedAt: model.archivedAt
                    }),
                    cell: value => {
                      const { planId, prices, archivedAt } = value.getValue<{
                        planId: ExtractAttributeTypeFromUnion<
                          BillingScheduleModel,
                          'planId'
                        >
                        prices: ExtractAttributeTypeFromUnion<
                          BillingScheduleModel,
                          'prices'
                        >
                        archivedAt: ExtractAttributeTypeFromUnion<
                          BillingScheduleModel,
                          'archivedAt'
                        >
                      }>()

                      if (prices) {
                        return (
                          <MagicTableCell
                            textColor={archivedAt && GreyGrey60}
                            text={prices.map(p => p.name).join(', ')}
                          />
                        )
                      }

                      if (planId) {
                        return (
                          <MagicTableAutoLoader<
                            ExtractQueryParams<
                              typeof useGetApiPlansPlanIdQuery
                            >,
                            { prices: PriceModel[] }
                          >
                            queryParams={{ planId }}
                            useQuery={useGetApiPlansPlanIdQuery}
                            extract={plan => ({ prices: plan?.prices || [] })}
                          >
                            {plan => {
                              if (plan.prices.length) {
                                return (
                                  <MagicTableCell
                                    textColor={archivedAt && GreyGrey60}
                                    text={plan.prices
                                      .map(p => p.name)
                                      .join(', ')}
                                  />
                                )
                              } else {
                                return null
                              }
                            }}
                          </MagicTableAutoLoader>
                        )
                      }

                      return null
                    }
                  },
                  {
                    id: 'startDate',
                    accessorFn: (row: BillingScheduleModel) => ({
                      archivedAt: row.archivedAt,
                      startDate: row.startDate
                    }),
                    header: 'Start date',
                    cell: value => {
                      const { startDate, archivedAt } = value.getValue<{
                        startDate: BillingScheduleModel['startDate']
                        archivedAt: BillingScheduleModel['archivedAt']
                      }>()

                      return (
                        <MagicTableCell
                          textColor={archivedAt && GreyGrey60}
                          text={dateTimeWithFormat(startDate, 'd MMM yyyy')}
                        />
                      )
                    }
                  },
                  {
                    id: 'endDate',
                    accessorFn: (row: BillingScheduleModel) => ({
                      archivedAt: row.archivedAt,
                      endDate: row.endDate
                    }),
                    header: 'End date',
                    cell: value => {
                      const { endDate, archivedAt } = value.getValue<{
                        endDate: BillingScheduleModel['endDate']
                        archivedAt: BillingScheduleModel['archivedAt']
                      }>()

                      return (
                        <MagicTableCell
                          textColor={archivedAt && GreyGrey60}
                          text={
                            endDate
                              ? dateTimeWithFormat(endDate, 'd MMM yyyy')
                              : 'Open ended'
                          }
                        />
                      )
                    }
                  }
                ]}
                filters={filters}
                emptyState={props => (
                  <EmptyState emptyContent={emptyContent} {...props} />
                )}
                kebabMenu={(model: BillingScheduleModel, props) => (
                  <KebabMenu
                    menuItems={menuItemBuilders.map(builder =>
                      // eslint-disable-next-line @typescript-eslint/no-misused-promises
                      builder(model, { onSuccess: reload })
                    )}
                    renderListInPortal={true}
                    flags={flags}
                    {...props}
                  />
                )}
                {...additionalToolbarComponentsV3}
              />
            )}
          </CurrentUserId>
        </Page>
      </Flex>
    </Flex>
  )
})

export default BillingSchedules
