import { Box, Flex, Grid, Icon } from '@chakra-ui/react'
import { Badge, Card } from '@sequencehq/core-components'
import {
  GreyGrey70,
  Lato13Regular,
  Lato14Bold
} from '@sequencehq/design-tokens'
import { useStatusWidget } from './useStatusWidget'

export const StatusWidget = () => {
  const hook = useStatusWidget()

  return (
    <Card width="360px" data-testId="statusWidget">
      <Grid templateRows="auto auto">
        <Box {...Lato14Bold} padding="4px 0 20px">
          Status
        </Box>
        <Grid templateColumns={'1fr 1fr'} rowGap="4px" padding="0">
          <Flex
            {...Lato13Regular}
            color={GreyGrey70}
            height="24px"
            alignItems="center"
          >
            Health
          </Flex>
          <Flex height="24px" alignItems="center" justifyContent="flex-end">
            <Badge sentiment={hook.health.sentiment} textStyle={Lato13Regular}>
              <Icon as={hook.health.icon} height="16px" width="16px" />
              {hook.health.label}
            </Badge>
          </Flex>
        </Grid>
      </Grid>
    </Card>
  )
}
