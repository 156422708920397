import { Box, Flex, Icon, Text } from '@chakra-ui/react'
import {
  Card,
  InputSelectControlField,
  Popover,
  Switch
} from '@sequencehq/core-components'
import {
  GreyGrey10,
  GreyGrey20,
  GreyGrey60,
  Lato13Bold,
  Lato13Regular,
  Lato14Bold
} from '@sequencehq/design-tokens'
import SpinnerContainer from 'components/Loading/SpinnerContainer'
import { ConfigurationManagementProps } from 'Integrations/domain'
import { useXeroConfigurationManagement } from 'modules/Integrations/integrationsConfig/xero/XeroConfigurationManagement/useXeroConfigurationManagement'
import QuestionMarkCircleIcon from '@heroicons/react/16/solid/QuestionMarkCircleIcon'
import { IntegrationReconnectBanner } from 'Integrations/view/integrationManagement/IntegrationReconnectBanner'
import { XeroIntegrationManagementInspector } from '../XeroIntegrationManagementInspector/XeroIntegrationManagementInspector'

export const XeroConfigurationManagement = (
  props: ConfigurationManagementProps
) => {
  const { fieldsConfig, loading } = useXeroConfigurationManagement(props)

  if (loading) {
    return <SpinnerContainer />
  }

  return (
    <Flex grow={1} width="100%">
      <Flex flexDirection="column" gap="20px" grow={1} padding="16px">
        <IntegrationReconnectBanner />
        <Box
          width="100%"
          p={4}
          bgColor={GreyGrey20}
          as={Flex}
          gap={2}
          borderRadius="8px"
          alignItems="center"
        >
          <Switch
            data-testid="switch.importCustomerWorkflow"
            checked={fieldsConfig.importCustomerWorkflow.value}
            onChange={fieldsConfig.importCustomerWorkflow.onChange}
          />

          <Flex gap={1} alignItems="center">
            <Text {...Lato13Regular}>
              Integration is{' '}
              <Text as="span" {...Lato13Bold}>
                {fieldsConfig.importCustomerWorkflow.status === 'ACTIVE'
                  ? 'active'
                  : 'inactive'}
              </Text>
            </Text>
            <Popover
              header="Pausing integrations"
              content="Some Integrations can be paused temporarily if needed. When paused, your settings will be persisted."
              position="top"
            >
              <Icon as={QuestionMarkCircleIcon} color={GreyGrey60} mt={1} />
            </Popover>
          </Flex>
        </Box>
        <Card>
          <Box>
            <Box {...Lato14Bold} pb="16px">
              Default ledger accounts
            </Box>
            <Flex
              backgroundColor={GreyGrey10}
              borderRadius="6px"
              padding="16px"
              gap={4}
              flexWrap="wrap"
            >
              <InputSelectControlField
                styles={{
                  wrapper: {
                    marginBottom: 0,
                    maxWidth: '336px'
                  }
                }}
                placeholder="Select an account"
                data-testid="select.ledgerAccount"
                options={fieldsConfig.defaultLedgerAccount.options}
                label="Default ledger account"
                initialValue={fieldsConfig.defaultLedgerAccount.value}
                onChange={fieldsConfig.defaultLedgerAccount.onChange}
                validationErrors={
                  fieldsConfig.defaultLedgerAccount.validationErrors
                }
                disabled={fieldsConfig.defaultLedgerAccount.disabled}
                matchWidth
              />
              <InputSelectControlField
                styles={{
                  wrapper: {
                    marginBottom: 0,
                    maxWidth: '336px'
                  }
                }}
                placeholder="Select an account"
                data-testid="select.creditLedgerAccount"
                label="Default deferred income ledger account"
                initialValue={fieldsConfig.defaultCreditLedgerAccount.value}
                options={fieldsConfig.defaultCreditLedgerAccount.options}
                onChange={fieldsConfig.defaultCreditLedgerAccount.onChange}
                validationErrors={
                  fieldsConfig.defaultCreditLedgerAccount.validationErrors
                }
                disabled={fieldsConfig.defaultCreditLedgerAccount.disabled}
                matchWidth
              />
              <InputSelectControlField
                styles={{
                  wrapper: {
                    marginBottom: 0,
                    maxWidth: '336px'
                  }
                }}
                placeholder="Select an account"
                data-testid="select.minimumLedgerAccount"
                label="Default ledger account for minimums"
                initialValue={fieldsConfig.defaultMinimumLedgerAccount.value}
                options={fieldsConfig.defaultMinimumLedgerAccount.options}
                onChange={fieldsConfig.defaultMinimumLedgerAccount.onChange}
                validationErrors={
                  fieldsConfig.defaultMinimumLedgerAccount.validationErrors
                }
                disabled={fieldsConfig.defaultMinimumLedgerAccount.disabled}
                matchWidth
              />
            </Flex>
          </Box>
        </Card>
        <Card>
          <Box>
            <Box {...Lato14Bold} pb="16px">
              Tax codes
            </Box>
            <Flex
              backgroundColor={GreyGrey10}
              borderRadius="6px"
              padding="16px"
              gap={4}
              flexWrap="wrap"
            >
              <InputSelectControlField
                styles={{
                  wrapper: {
                    marginBottom: 0,
                    maxWidth: '336px'
                  }
                }}
                placeholder="Select a tax code"
                data-testid="select.untaxedCustomerTaxCode"
                options={fieldsConfig.untaxedCustomerTaxCode.options}
                label="Tax exempt and reverse charged customers"
                initialValue={fieldsConfig.untaxedCustomerTaxCode.value}
                onChange={fieldsConfig.untaxedCustomerTaxCode.onChange}
                validationErrors={
                  fieldsConfig.untaxedCustomerTaxCode.validationErrors
                }
                flip
                disabled={fieldsConfig.untaxedCustomerTaxCode.disabled}
                infoPopover={{
                  title:
                    'Tax code for any tax exempt or reverse charged customers',
                  body: 'For all other customers the default tax code set up in Xero for the associated ledger account will be used.'
                }}
                matchWidth
              />
            </Flex>
          </Box>
        </Card>
      </Flex>
      <XeroIntegrationManagementInspector
        importCustomerWorkflowFieldConfig={fieldsConfig.importCustomerWorkflow}
      />
    </Flex>
  )
}
