import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dashboard/v20240730'
import { IntegrationServices } from '@sequencehq/api/dist/utils/commonEnums'
import { getIntegrationName } from 'Integrations/utils/getIntegrationName'
import { useCallback, useEffect, useMemo, useState } from 'react'

interface LoadedData {
  customer: DashboardApi20240730.GetCustomer.Customer | null
}

enum Status {
  UNINITIALIZED = 'UNINITIALIZED',
  LOADING = 'LOADING',
  LOADED = 'LOADED'
}

type UseLinkCustomerToService = (props: {
  customerId: string
  service: IntegrationServices
  canUnlink?: boolean
}) => {
  isLoaded: boolean
  customerName: string | undefined
  serviceName: string
  linkedAccount: {
    value: string | undefined
    href: string | undefined
    label: string
    canUnlink: boolean
    onUnlink: () => void
  }
  onLink: () => void
}

export const useLinkCustomerToService: UseLinkCustomerToService = props => {
  const [status, setStatus] = useState<Status>(Status.UNINITIALIZED)
  const [loadedData, setLoadedData] = useState<LoadedData>({
    customer: null
  })

  /**
   * A simple load function which will grab the customer and the integration
   * customers for the given service.
   */
  const load = useCallback(
    async ({
      customerId
    }: {
      customerId: string
      service: IntegrationServices
    }) => {
      setStatus(Status.LOADING)

      const customer = await dashboard20240730Client.getCustomer({
        id: customerId
      })

      setLoadedData({
        customer: customer.data ?? null
      })

      setStatus(Status.LOADED)
    },
    []
  )

  /**
   * Grab the current data, for this service, from the customer object.
   */
  const currentLinkedAccount = useMemo(() => {
    return (loadedData.customer?.integrationIds ?? []).find(
      int => int.service === props.service
    )
  }, [loadedData, props.service])

  /**
   * Create the data, and handlers, for driving the display of the linked
   * account.
   */
  const linkedAccount = useMemo(() => {
    return {
      value: currentLinkedAccount?.id,
      href: currentLinkedAccount?.url,
      label: currentLinkedAccount?.id ?? '',
      canUnlink: props.canUnlink ?? true,
      onUnlink: () => {
        void load({ customerId: props.customerId, service: props.service })
      }
    }
  }, [
    currentLinkedAccount?.id,
    currentLinkedAccount?.url,
    props.canUnlink,
    load,
    props.customerId,
    props.service
  ])

  const onLink = useCallback(() => {
    void load({ customerId: props.customerId, service: props.service })
  }, [load, props.customerId, props.service])

  const serviceName = useMemo(() => {
    return getIntegrationName(props.service)
  }, [props.service])

  /**
   * Automatically reload data if the customerId or service changes.
   */
  useEffect(() => {
    void load({ customerId: props.customerId, service: props.service })
  }, [props.customerId, props.service, load])

  return {
    isLoaded: status === Status.LOADED,
    customerName: loadedData.customer?.legalName,
    serviceName,
    linkedAccount,
    onLink
  }
}
