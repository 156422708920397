import {
  CubeReducerState,
  Phase,
  Discount,
  Minimum,
  CubeEditorData
} from 'modules/Cube/domain/cube.domain.types'
import { LoadedBillingScheduleData } from 'modules/Cube/communication/external/billingSchedule.api.v1/ports/useLoadBillingScheduleEditor'

import { apiDatesAdapters } from 'modules/Cube/communication/external/billingSchedule.api.v1/utils/apiDates.adapters'

import deepmerge from 'deepmerge'
import { scheduleStatusAdapter } from 'modules/Cube/utils/status.adapter'
import { INITIAL_CUBE_STATE } from 'modules/Cube/domain/cube.constants'
import { sortArrayByStartDate } from 'modules/Cube/communication/external/billingSchedule.api.v1/utils/sortArrayByStartDate'
import { UTCDate } from '@date-fns/utc'
import { durationsToDateAdapter } from 'modules/Cube/utils/durationToDate.adapter'
import { arrayToIdKeyedObject } from '@sequencehq/utils'
import { groupBy } from 'lodash/fp'

export const loadBillingScheduleFromPhases = (
  billingScheduleData: LoadedBillingScheduleData
): {
  common: CubeEditorData['common']
  schedule: CubeEditorData['schedule']
  phases: CubeEditorData['phases']
  minimums: Record<Minimum['id'], Minimum>
  discounts: Record<Discount['id'], Discount>
} => {
  const { phases, minimums, allPhaseDiscounts } =
    billingScheduleData.billingSchedule.phases.reduce(
      (acc, phase) => {
        const minimumsInPhase = (phase.minimums ?? []).map(minimum => ({
          id: crypto.randomUUID(),
          name: '',
          value: `${minimum.amount}`,
          scope: {
            target: minimum.restrictToPrices?.length ? 'specific' : 'allUsage',
            priceIds: minimum.restrictToPrices
          }
        })) as Minimum[]

        const phaseDiscounts = (phase.discounts ?? []).map(discount => ({
          id: crypto.randomUUID(),
          amount: discount.amount,
          message: discount.message,
          priceIds: discount.restrictToPrices,
          discountCalculationType: discount.type,
          duration: 'FOREVER',
          applyToAllPrices: !discount.restrictToPrices.length
        })) as Discount[]

        return deepmerge(acc, {
          phases: [
            {
              id: phase.id,
              startDate: apiDatesAdapters.in(phase.startDate),
              endDate: phase.endDate
                ? apiDatesAdapters.in(phase.endDate)
                : undefined,
              minimumIds: minimumsInPhase.map(({ id }) => id),
              priceIds: phase.priceIds,
              discountIds: phaseDiscounts.map(({ id }) => id)
            }
          ],
          minimums: minimumsInPhase,
          allPhaseDiscounts: phaseDiscounts
        })
      },
      {} as {
        phases: (Omit<Phase, 'duration'> & {
          startDate: UTCDate | undefined
          endDate: UTCDate | undefined
        })[]
        minimums: Minimum[]
        allPhaseDiscounts: Discount[]
      }
    )

  const sortedPhases: Array<Phase> = sortArrayByStartDate<
    Omit<Phase, 'duration'> & {
      startDate: UTCDate | undefined
      endDate: UTCDate | undefined
    }
  >(phases).map(({ startDate, endDate, ...rest }) => ({
    ...rest,
    duration: durationsToDateAdapter.out(startDate)(endDate)
  }))

  const currency = billingScheduleData.prices[0]?.currency ?? undefined

  return {
    common: {
      id: billingScheduleData.billingSchedule.id,
      startDate: apiDatesAdapters.in(
        billingScheduleData.billingSchedule.startDate
      ),
      status: scheduleStatusAdapter.in(
        billingScheduleData.billingSchedule.status
      ),
      currency,
      title: '',
      alias: '',
      customerId: billingScheduleData.billingSchedule.customerId,
      isArchived: Boolean(billingScheduleData.billingSchedule.archivedAt),
      phaseIds: sortedPhases.map(({ id }) => id),
      createdAt: new UTCDate(billingScheduleData.billingSchedule.createdAt)
    },
    schedule: {
      stripePayment:
        billingScheduleData.billingScheduleSettings?.paymentProvider ===
        'STRIPE',
      recurrenceDayOfMonth:
        billingScheduleData.billingSchedule.recurrenceDayOfMonth ?? 1,
      purchaseOrderNumber:
        billingScheduleData.billingSchedule.purchaseOrderNumber ?? '',
      reference: billingScheduleData.billingSchedule.reference ?? '',
      label: billingScheduleData.billingSchedule.label ?? '',
      autoIssueInvoices:
        billingScheduleData.billingSchedule.autoIssueInvoices ?? false,
      taxRateId:
        billingScheduleData.billingSchedule.taxRates?.[0]?.taxRateId ?? '',
      rollUpBilling: billingScheduleData.billingSchedule.rollUpBilling ?? false
    },
    phases: arrayToIdKeyedObject(sortedPhases),
    minimums: arrayToIdKeyedObject(minimums),
    discounts: arrayToIdKeyedObject(allPhaseDiscounts)
  }
}

const transformListPrices = (
  listPrices: LoadedBillingScheduleData['listPrices']
) => {
  return groupBy('productId', listPrices)
}

/**
 * A collection of loader/transformers to create loadAction data from a variety of sources (e.g.
 * different Api versions, imports, or potentially even quotes in the future)
 * @param billingScheduleData
 * @returns
 */
export const phasesAdapterIn = (
  billingScheduleData: LoadedBillingScheduleData,
  configuration: CubeReducerState['configuration']
): Pick<CubeReducerState, 'data' | 'configuration'> => {
  const transformedBillingScheduleData =
    loadBillingScheduleFromPhases(billingScheduleData)

  return {
    data: {
      ...transformedBillingScheduleData,
      quote: INITIAL_CUBE_STATE.data.quote,
      presentation: INITIAL_CUBE_STATE.data.presentation,
      customers: arrayToIdKeyedObject(billingScheduleData.customers),
      taxRates: arrayToIdKeyedObject(billingScheduleData.taxRates),
      products: arrayToIdKeyedObject(billingScheduleData.products),
      prices: arrayToIdKeyedObject(billingScheduleData.prices),
      listPrices: transformListPrices(billingScheduleData.listPrices),
      merchantBranding: INITIAL_CUBE_STATE.data.merchantBranding,
      contacts: INITIAL_CUBE_STATE.data.contacts
    },
    configuration: {
      ...configuration,
      currency: {
        default: billingScheduleData.enabledCurrencies[0],
        enabled: billingScheduleData.enabledCurrencies
      }
    }
  }
}
