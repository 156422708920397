import { Box, Center, Flex, Icon, Text } from '@chakra-ui/react'
import EllipsisHorizontalIcon from '@heroicons/react/24/outline/EllipsisHorizontalIcon'
import {
  KebabMenu,
  HorizontalSelectorField,
  SelectFieldWithActions,
  SelectField,
  TextField
} from '@sequencehq/core-components'
import { Lato14Bold } from '@sequencehq/design-tokens'
import {
  AvailableStandardFrequency,
  UsageTierType
} from 'common/drawers/PricingEditor/domain'
import { useVolumePriceForm } from './useVolumePriceForm'
import { ReadOnlyDetails } from '../common/ReadOnlyDetails'
import { PricingInformation } from '../common/PricingInformation'
import { UsageMetricParameterFields } from '../common/UsageMetrics/UsageMetricParameterFields'
import { useState } from 'react'
import ChevronDownIcon from '@heroicons/react/20/solid/ChevronDownIcon'
import ChevronRightIcon from '@heroicons/react/20/solid/ChevronRightIcon'
import { FixedTierTableInput } from '../inputs/FixedTierTableInput/FixedTierTableInput'
import { PercentageTierTableInput } from '../inputs/PercentageTierTableInput/PercentageTierTableInput'
import { PercentageTierWithLimitsTableInput } from '../inputs/PercentageTierWithLimitsTableInput/PercentageTierWithLimitsTableInput'
import { kebabMenuItems } from './volumePrice.utils'
import { ExternalLedgerForm } from '../common/ExternalLedgerForm/ExternalLedgerForm'

export const VolumePriceVariantPriceForm = () => {
  const { fieldsConfig, currency, priceDetails } = useVolumePriceForm()
  const [showAdditionalOptions, setShowAdditionalOptions] = useState(false)

  return (
    <>
      <Box marginBottom="16px">
        <ReadOnlyDetails>
          {priceDetails.map(({ label, value }) => (
            <ReadOnlyDetails.Item key={label} label={label} value={value} />
          ))}
        </ReadOnlyDetails>
      </Box>

      <SelectField
        label="List price"
        data-testid="common-field-listPriceId"
        value={fieldsConfig.listPriceId.value ?? ''}
        onChange={fieldsConfig.listPriceId.onChange}
        options={fieldsConfig.listPriceId.options}
        validationErrors={fieldsConfig.listPriceId.validationErrors}
        isDisabled={fieldsConfig.listPriceId.disabled}
        placeholder="Select list price"
      />

      <TextField
        label="Display name (appears on invoice)"
        data-testid="standard-field-name"
        value={fieldsConfig.name.value}
        onChange={fieldsConfig.name.onChange}
        validationErrors={fieldsConfig.name.validationErrors}
        isDisabled={fieldsConfig.name.disabled}
        placeholder="Enter a display name"
      />

      <Box paddingBottom="0px">
        <HorizontalSelectorField
          label="Type"
          value={fieldsConfig.usageTierType.value}
          options={fieldsConfig.usageTierType.options}
          onChange={newValue =>
            fieldsConfig.usageTierType.onChange(newValue as UsageTierType)
          }
          validationErrors={fieldsConfig.usageTierType.validationErrors}
          isDisabled={fieldsConfig.usageTierType.disabled}
        />
        <Box
          paddingTop="8px"
          marginBottom="0px"
          paddingBottom="0px"
          width="100%"
        >
          <Flex flexDirection="row">
            <Box {...Lato14Bold} marginBottom="8px">
              Tiers
            </Box>
            <Flex grow="1"></Flex>
            {fieldsConfig.usageTierType.value === 'PERCENTAGE' && (
              <Center height="14px" paddingBottom="8px">
                <KebabMenu
                  kebabIcon={<EllipsisHorizontalIcon height="20px" />}
                  menuItems={kebabMenuItems({
                    includePercentageLimits:
                      fieldsConfig.includePercentageLimits.value,
                    onChange: fieldsConfig.includePercentageLimits.onChange
                  })}
                  renderListInPortal={true}
                />
              </Center>
            )}
          </Flex>
          {fieldsConfig.usageTierType.value === 'FIXED' ? (
            <FixedTierTableInput
              value={fieldsConfig.tiers.value}
              onChange={fieldsConfig.tiers.onChange}
              isDisabled={fieldsConfig.tiers.disabled}
              currency={currency}
              validationErrors={fieldsConfig.tiers.validationErrors}
            />
          ) : fieldsConfig.includePercentageLimits.value ? (
            <PercentageTierWithLimitsTableInput
              value={fieldsConfig.percentageTiers.value}
              onChange={fieldsConfig.percentageTiers.onChange}
              isDisabled={fieldsConfig.percentageTiers.disabled}
              currency={currency}
              validationErrors={fieldsConfig.percentageTiers.validationErrors}
            />
          ) : (
            <PercentageTierTableInput
              value={fieldsConfig.percentageTiers.value}
              onChange={fieldsConfig.percentageTiers.onChange}
              isDisabled={fieldsConfig.percentageTiers.disabled}
              currency={currency}
              validationErrors={fieldsConfig.percentageTiers.validationErrors}
            />
          )}
        </Box>
      </Box>

      <Box
        display="flex"
        gap="4px"
        my="16px"
        padding="8px 0px"
        cursor="pointer"
        onClick={() => setShowAdditionalOptions(!showAdditionalOptions)}
      >
        <Text {...Lato14Bold} color="#5F5CFF">
          Show additional fields
        </Text>
        {showAdditionalOptions ? (
          <Icon as={ChevronDownIcon} color="#5F5CFF" w="20px" h="20px" />
        ) : (
          <Icon as={ChevronRightIcon} color="#5F5CFF" w="20px" h="20px" />
        )}
      </Box>
      <Box
        width="100%"
        data-testid="common-fields"
        marginBottom="16px"
        visibility={showAdditionalOptions ? 'visible' : 'hidden'}
      >
        <PricingInformation
          value={fieldsConfig.pricingModel.value}
          isDisabled={fieldsConfig.pricingModel.disabled}
          options={fieldsConfig.pricingModel.options}
          validationErrors={fieldsConfig.pricingModel.validationErrors}
          onChange={fieldsConfig.pricingModel.onChange}
          description={fieldsConfig.pricingModel.description}
        />

        <HorizontalSelectorField
          label="Frequency"
          data-testid="common-field-billingFrequency"
          value={fieldsConfig.billingFrequency.value}
          options={fieldsConfig.billingFrequency.options}
          onChange={newValue =>
            fieldsConfig.billingFrequency.onChange(
              newValue as AvailableStandardFrequency
            )
          }
          validationErrors={fieldsConfig.billingFrequency.validationErrors}
          isDisabled={fieldsConfig.billingFrequency.disabled}
        />
        <SelectFieldWithActions
          label="Usage Metric"
          options={fieldsConfig.usageMetricId.options}
          validationErrors={fieldsConfig.usageMetricId.validationErrors}
          value={fieldsConfig.usageMetricId.value}
          onChange={fieldsConfig.usageMetricId.onChange}
          isDisabled={fieldsConfig.usageMetricId.disabled}
          placeholder="Select usage metric"
          onAddNew={fieldsConfig.usageMetricId.onAddNew}
        />
        <UsageMetricParameterFields
          fieldsConfig={fieldsConfig}
          usageMetricId={fieldsConfig.usageMetricId.value}
        ></UsageMetricParameterFields>

        <ExternalLedgerForm />
      </Box>
    </>
  )
}
