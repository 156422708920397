import { Center } from '@chakra-ui/react'
import {
  ArchiveBoxIcon,
  EllipsisHorizontalIcon,
  PencilIcon,
  TagIcon
} from '@heroicons/react/16/solid'

import {
  Button,
  SequenceMenu,
  SequenceMenuItem,
  SequenceMenuItemProps
} from '@sequencehq/core-components'
import { GreyGrey60 } from '@sequencehq/design-tokens'

import { UseContactTableInterface } from './useContactTable'
import { CustomerContact } from 'modules/CustomerContacts/domain/CustomerContacts.domain.types'
import { EditContactModal } from '../../modals/EditContactModal'
import { MarkAsPrimaryBillingContactDialog } from '../../modals/MarkAsPrimaryBillingContactDialog'
import { RemoveContactDialog } from '../../modals/RemoveContactDialog'

export const KebabMenu = ({
  contact,
  hook: { editContact, markAsPrimaryBillingContact, archiveContact }
}: {
  contact: CustomerContact
  hook: UseContactTableInterface
}) => (
  <SequenceMenu
    width={226}
    alignment="bottom-right"
    items={[
      (props: Partial<SequenceMenuItemProps>) => (
        <EditContactModal
          key="edit"
          contactId={contact.id}
          onSubmit={() => {
            props.setIsOpen?.(false)
          }}
          onCancel={() => {
            props.setIsOpen?.(false)
          }}
          trigger={
            <SequenceMenuItem
              uuid="edit"
              label="Edit contact"
              onClick={() => {
                editContact.editContactById(contact.id)
              }}
              iconLeft={<PencilIcon width={16} />}
            />
          }
        />
      ),
      contact.billingPreference !== 'PRIMARY' &&
        ((props: Partial<SequenceMenuItemProps>) => (
          <MarkAsPrimaryBillingContactDialog
            key="mark-primary-dialog-menu"
            title={`Mark ${contact.email} as primary billing contact`}
            trigger={
              <SequenceMenuItem
                uuid="markPrimary"
                label="Mark as primary billing contact"
                iconLeft={<TagIcon width={16} />}
              />
            }
            onConfirm={() => {
              void markAsPrimaryBillingContact(contact.id)
              props.setIsOpen?.(false)
            }}
            onCancel={() => {
              props.setIsOpen?.(false)
            }}
          />
        )),
      contact.billingPreference !== 'PRIMARY' &&
        ((props: Partial<SequenceMenuItemProps>) => (
          <RemoveContactDialog
            key="remove-contact-dialog-menu"
            title={`Archive ${contact.email}`}
            trigger={
              <SequenceMenuItem
                uuid="archive"
                label="Archive contact"
                iconLeft={<ArchiveBoxIcon width={16} />}
                style={{ color: 'red' }}
              />
            }
            onConfirm={() => {
              void archiveContact(contact.id)
              props.setIsOpen?.(false)
            }}
            onCancel={() => {
              props.setIsOpen?.(false)
            }}
          />
        ))
    ].filter(Boolean)}
    menuButton={
      <Button variant="ghost" size="24px">
        <Center flexShrink="0">
          <EllipsisHorizontalIcon
            width="16px"
            height="16px"
            color={GreyGrey60}
          />
        </Center>
      </Button>
    }
  />
)
