import { Badge, Inspector, Switch } from '@sequencehq/core-components'
import {
  AccordionButton,
  AccordionIcon,
  Accordion,
  AccordionItem,
  Box,
  Flex,
  AccordionPanel,
  Icon,
  Text
} from '@chakra-ui/react'
import { InformationCircleIcon } from '@heroicons/react/16/solid'
import { Lato14Bold, Lato14Regular } from '@sequencehq/design-tokens'
import { useStatusWidget } from 'Integrations/view/integrationManagement/IntegrationManagementSidebar/useStatusWidget'
import { ValidationError } from '@sequencehq/utils'

type Props = {
  importCustomerWorkflowFieldConfig: {
    disabled: boolean
    validationErrors: ValidationError[]
    value: boolean
    onChange: (value: boolean) => void
  }
}

export const XeroIntegrationManagementInspector: React.FC<Props> = ({
  importCustomerWorkflowFieldConfig
}) => {
  const { health } = useStatusWidget()

  return (
    <Inspector>
      <Inspector.ItemGroup>
        <Inspector.IconItem
          content={
            <Inspector.Content padding="0px">
              <Accordion
                allowMultiple={true}
                defaultIndex={[0, 1]}
                mt="8px"
                px="8px"
              >
                <AccordionItem border="none">
                  <AccordionButton px="8px">
                    <Box
                      as={Flex}
                      flex="1"
                      gap={1}
                      textAlign="left"
                      borderRadius="8px"
                      {...Lato14Bold}
                    >
                      <span>Status</span>
                      <AccordionIcon />
                    </Box>
                  </AccordionButton>
                  <AccordionPanel
                    pb={4}
                    px={2}
                    as={Flex}
                    gap={4}
                    flexDirection="column"
                  >
                    <Flex
                      width="100%"
                      justifyContent="space-between"
                      alignItems="center"
                      {...Lato14Regular}
                    >
                      <p>Health</p>
                      <Badge
                        sentiment={health.sentiment}
                        textStyle={Lato14Regular}
                        containerStyle={{
                          backgroundColor: 'none'
                        }}
                      >
                        <Icon as={health.icon} height="16px" width="16px" />
                        <Text {...Lato14Regular}>{health.label}</Text>
                      </Badge>
                    </Flex>
                    <Flex
                      width="100%"
                      justifyContent="space-between"
                      alignItems="flex-end"
                      {...Lato14Regular}
                    >
                      <p>Active</p>
                      <Switch
                        data-testid="inspector.switch.importCustomerWorkflow"
                        onChange={importCustomerWorkflowFieldConfig.onChange}
                        checked={importCustomerWorkflowFieldConfig.value}
                      />
                    </Flex>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Inspector.Content>
          }
          icon={<InformationCircleIcon height={16} />}
        />
      </Inspector.ItemGroup>
    </Inspector>
  )
}
