import { CollapsedTable } from './CollapsedTable'
import { ExpandedTable } from './ExpandedTable'
import { useExpanded } from './selectorHooks'

export const ReportDetail = () => {
  const { expanded } = useExpanded()

  if (expanded) {
    return <ExpandedTable />
  }

  return <CollapsedTable />
}
