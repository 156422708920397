import { SeatTypeModel } from '@sequencehq/core-models'
import { useListPriceEditorContext } from 'modules/Products/drawers/ListPriceEditor/useListPriceEditorContext'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

export const useCreateSeatTypeOutletConnector = () => {
  const navigate = useNavigate()
  const listPriceEditorContext = useListPriceEditorContext()

  const handleSucess = useCallback(
    (seatType: SeatTypeModel) => {
      listPriceEditorContext.functions.updateFormData({
        SEAT_BASED_LINEAR: {
          seatTypeId: seatType.id
        }
      })
      navigate('..')
    },
    [navigate, listPriceEditorContext.functions]
  )

  const handleCancel = useCallback(() => {
    navigate('..')
  }, [navigate])

  return {
    onSuccess: handleSucess,
    onCancel: handleCancel
  }
}
