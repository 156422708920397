import {
  PostActionStage,
  PricingEditorPrice,
  PricingModel,
  StandardPrice
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.types'
import pick from 'lodash/fp/pick'
import { NEW_PRICE_PATTERN } from 'modules/Cube/domain/cube.constants'

const transitionStandardBillingFrequency: PropertyTransition<StandardPrice> = {
  target: 'billingFrequency',
  value: source =>
    source.billingFrequency === 'ONE_TIME'
      ? { billingFrequency: 'MONTHLY' }
      : {}
}
/**
 * TODO: come back to this to type better!
 */
type PropertyTransition<
  SourceModel extends PricingEditorPrice = PricingEditorPrice,
  TargetModel extends PricingEditorPrice = PricingEditorPrice
> =
  | string
  | {
      target: string
      value: (
        model: SourceModel['modelSpecific']
      ) => Partial<TargetModel['modelSpecific']>
    }

const availableTransitions: Partial<
  Record<PricingModel, Partial<Record<PricingModel, PropertyTransition[]>>>
> = {
  VOLUME: {
    GRADUATED: [
      'tiers',
      'percentageTiers',
      'billingFrequency',
      'usageMetricId',
      'parameters'
    ],
    LINEAR: ['usageMetricId', 'billingFrequency', 'parameters'],
    PACKAGED: ['usageMetricId', 'billingFrequency', 'parameters'],
    STANDARD: ['billingFrequency']
  },
  GRADUATED: {
    VOLUME: [
      'tiers',
      'percentageTiers',
      'billingFrequency',
      'usageMetricId',
      'parameters'
    ],
    LINEAR: ['usageMetricId', 'billingFrequency', 'parameters'],
    PACKAGED: ['usageMetricId', 'billingFrequency', 'parameters'],
    STANDARD: ['billingFrequency']
  },
  LINEAR: {
    VOLUME: ['billingFrequency', 'usageMetricId', 'parameters'],
    GRADUATED: ['usageMetricId', 'billingFrequency', 'parameters'],
    PACKAGED: ['usageMetricId', 'billingFrequency', 'parameters'],
    STANDARD: ['billingFrequency']
  },
  PACKAGED: {
    VOLUME: ['billingFrequency', 'usageMetricId', 'parameters'],
    GRADUATED: ['usageMetricId', 'billingFrequency', 'parameters'],
    LINEAR: ['usageMetricId', 'billingFrequency', 'parameters'],
    STANDARD: ['billingFrequency']
  },
  STANDARD: {
    VOLUME: [transitionStandardBillingFrequency] as PropertyTransition[],
    GRADUATED: [transitionStandardBillingFrequency] as PropertyTransition[],
    LINEAR: [transitionStandardBillingFrequency] as PropertyTransition[]
  },
  SEAT_BASED_LINEAR: {
    SEAT_BASED_GRADUATED: [
      'seatTypeId',
      'minimumSeats',
      'billingFrequency',
      'overagesBillingFrequency',
      'prorationStrategy',
      'billingType'
    ]
  },
  SEAT_BASED_GRADUATED: {
    SEAT_BASED_LINEAR: [
      'seatTypeId',
      'minimumSeats',
      'billingFrequency',
      'overagesBillingFrequency',
      'prorationStrategy',
      'billingType'
    ]
  }
}

export const transitionPriceModelData: PostActionStage =
  ({ preActionState }) =>
  prevState => {
    /**
     * Only trigger the transition if the selected price hasn't changed and the
     * price is a new one
     */
    if (
      preActionState.editor.selectedPrice !== prevState.editor.selectedPrice ||
      !prevState.editor.selectedPrice?.match(NEW_PRICE_PATTERN)
    ) {
      return prevState
    }

    const oldPricingModel = preActionState.data.formData.common.pricingModel
    const newPricingModel = prevState.data.formData.common.pricingModel
    if (oldPricingModel === newPricingModel) {
      return prevState
    }

    const propertiesToTransition =
      availableTransitions[oldPricingModel]?.[newPricingModel]
    if (!propertiesToTransition) {
      return prevState
    }

    return {
      ...prevState,
      data: {
        ...prevState.data,
        formData: {
          ...prevState.data.formData,
          [newPricingModel]: {
            ...prevState.data.formData[newPricingModel],
            ...pick(
              propertiesToTransition.filter(
                prop => typeof prop === 'string'
              ) as string[]
            )(prevState.data.formData[oldPricingModel]),
            ...propertiesToTransition.reduce((acc, transition) => {
              if (typeof transition === 'string') {
                return acc
              }
              return {
                ...acc,
                ...transition.value(prevState.data.formData[oldPricingModel])
              }
            }, {})
          }
        }
      }
    }
  }
