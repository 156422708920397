import { Badge, MenuItemBuilder, Toast } from '@sequencehq/core-components'
import {
  InvoiceModel,
  InvoicePaymentStatus,
  toInvoicePaymentStatusBadgeProps
} from '@sequencehq/core-models'
import integratedApi from 'features/api'
import { openOverlay } from 'features/overlay'
import * as Sentry from '@sentry/react'
import { UseToastOptions, Box, Flex } from '@chakra-ui/react'
import { DownloadInvoicePdfProps } from 'InvoiceEditor/components/Header/useInvoicePdfDownload'

const { putInvoicesByIdPaymentStatus } = integratedApi.endpoints

const finalizeAndSendBuilder: MenuItemBuilder<InvoiceModel> = (
  invoice: InvoiceModel
) => {
  return {
    status: invoice.status === 'DRAFT' ? 'LIVE' : 'DISABLED',
    label: 'Send Invoice',
    action: ({ dispatch }) =>
      dispatch(
        openOverlay({
          content: 'finalizeAndSendInvoiceModal',
          data: {
            id: invoice.id,
            customerName: invoice.customerLegalCompanyName,
            billingScheduleId: invoice.billingScheduleId,
            customerEmails: invoice.customerEmails
          }
        })
      )
  }
}

export const finalizeBuilder: MenuItemBuilder<InvoiceModel> = (
  invoice: InvoiceModel
) => {
  return {
    status: invoice.status === 'DRAFT' ? 'LIVE' : 'DISABLED',
    label: 'Finalize Invoice',
    action: ({ dispatch }) =>
      dispatch(
        openOverlay({
          content: 'finalizeInvoiceModal',
          data: {
            id: invoice.id,
            customerName: invoice.customerLegalCompanyName
          }
        })
      )
  }
}

export const sendBuilder: MenuItemBuilder<InvoiceModel> = (
  invoice: InvoiceModel
) => {
  return {
    status: invoice.status === 'FINAL' ? 'LIVE' : 'DISABLED',
    label: 'Send Invoice',
    action: ({ dispatch }) =>
      dispatch(
        openOverlay({
          content: 'sendInvoiceModal',
          data: {
            id: invoice.id,
            customerName: invoice.customerLegalCompanyName,
            billingScheduleId: invoice.billingScheduleId,
            customerEmails: invoice.customerEmails
          }
        })
      )
  }
}

const createCreditNoteBuilder: MenuItemBuilder<InvoiceModel> = (
  invoice: InvoiceModel
) => {
  return {
    status: invoice.status === 'SENT' ? 'LIVE' : 'DISABLED',
    label: 'Create credit note',
    action: ({ dispatch }) =>
      dispatch(
        openOverlay({
          content: 'createCreditNoteFromInvoiceModal',
          data: {
            invoiceId: invoice.id,
            currency: invoice.currency,
            customerId: invoice.customerId
          }
        })
      )
  }
}

const previewBuilder: MenuItemBuilder<InvoiceModel> = (
  invoice: InvoiceModel
) => {
  return {
    status: 'LIVE',
    action: ({ navigate }) => navigate(`/invoices/${invoice.id}`),
    label: invoice.status === 'DRAFT' ? 'View/Edit invoice' : 'View invoice'
  }
}

const downloadBuilder: (
  downloadPdf: (props: DownloadInvoicePdfProps) => void
) => MenuItemBuilder<InvoiceModel> = download => (invoice: InvoiceModel) => {
  return {
    status: 'LIVE',
    action: () => {
      download({
        invoiceId: invoice.id,
        invoiceNumber: invoice.invoiceNumber,
        invoiceStatus: invoice.status,
        customerId: invoice.customerId
      })
    },
    label: 'Download invoice as PDF'
  }
}

export const voidBuilder: MenuItemBuilder<InvoiceModel> = (
  invoice: InvoiceModel
) => {
  return {
    status:
      invoice.status === 'DRAFT' || invoice.status === 'FINAL'
        ? 'LIVE'
        : 'DISABLED',
    action: ({ dispatch }) =>
      dispatch(
        openOverlay({
          content: 'voidInvoiceModal',
          data: {
            id: invoice.id,
            customerName: invoice.customerLegalCompanyName
          }
        })
      ),
    label: <Box color={'red.70'}>Void Invoice</Box>
  }
}

const paidBuilder: (
  successToast: (options: UseToastOptions) => void
) => MenuItemBuilder<InvoiceModel> = toast => (invoice: InvoiceModel) => {
  return {
    status:
      invoice.status === 'SENT' && invoice.linkedServices.length === 0
        ? 'LIVE'
        : 'DISABLED',
    action: ({ dispatch }) => {
      dispatch(
        putInvoicesByIdPaymentStatus.initiate({
          id: invoice.id,
          updateInvoicePaymentStatusEndpointRequestModel: {
            paymentStatus: 'PAID'
          }
        })
      )
        .then(() =>
          toast({
            id: `paid${invoice.invoiceNumber || ''}`,
            position: 'bottom',
            isClosable: true,
            render: () => (
              <Toast
                type="success"
                title={`Invoice ${
                  invoice.invoiceNumber || ''
                } is marked as paid`}
                isClosable={true}
              ></Toast>
            )
          })
        )
        .catch(e => Sentry.captureException(e))
    },
    label: invoicePaymentStatusMenuLabel('PAID')
  }
}

const partiallyPaidBuilder: (
  successToast: (options: UseToastOptions) => void
) => MenuItemBuilder<InvoiceModel> = toast => (invoice: InvoiceModel) => {
  return {
    status:
      invoice.status === 'SENT' && invoice.linkedServices.length === 0
        ? 'LIVE'
        : 'DISABLED',
    action: ({ dispatch }) => {
      dispatch(
        putInvoicesByIdPaymentStatus.initiate({
          id: invoice.id,
          updateInvoicePaymentStatusEndpointRequestModel: {
            paymentStatus: 'PARTIALLY_PAID'
          }
        })
      )
        .then(() =>
          toast({
            id: `partially_paid${invoice.invoiceNumber || ''}`,
            position: 'bottom',
            isClosable: true,
            render: () => (
              <Toast
                type="success"
                title={`Invoice ${
                  invoice.invoiceNumber || ''
                } is marked as partially paid`}
                isClosable={true}
              ></Toast>
            )
          })
        )
        .catch(e => Sentry.captureException(e))
    },
    label: invoicePaymentStatusMenuLabel('PARTIALLY_PAID')
  }
}

const uncollectibleBuilder: (
  successToast: (options: UseToastOptions) => void
) => MenuItemBuilder<InvoiceModel> = toast => (invoice: InvoiceModel) => {
  return {
    status:
      invoice.status === 'SENT' && invoice.linkedServices.length === 0
        ? 'LIVE'
        : 'DISABLED',
    action: ({ dispatch }) => {
      dispatch(
        putInvoicesByIdPaymentStatus.initiate({
          id: invoice.id,
          updateInvoicePaymentStatusEndpointRequestModel: {
            paymentStatus: 'UNCOLLECTIBLE'
          }
        })
      )
        .then(() =>
          toast({
            id: `uncollectible${invoice.invoiceNumber || ''}`,
            position: 'bottom',
            isClosable: true,
            render: () => (
              <Toast
                type="success"
                title={`Invoice ${
                  invoice.invoiceNumber || ''
                } is marked as uncollectible`}
                isClosable={true}
              ></Toast>
            )
          })
        )
        .catch(e => Sentry.captureException(e))
    },
    label: invoicePaymentStatusMenuLabel('UNCOLLECTIBLE')
  }
}

const unpaidBuilder: (
  successToast: (options: UseToastOptions) => void
) => MenuItemBuilder<InvoiceModel> = toast => (invoice: InvoiceModel) => {
  return {
    status:
      invoice.status === 'SENT' && invoice.linkedServices.length === 0
        ? 'LIVE'
        : 'DISABLED',
    action: ({ dispatch }) => {
      dispatch(
        putInvoicesByIdPaymentStatus.initiate({
          id: invoice.id,
          updateInvoicePaymentStatusEndpointRequestModel: {
            paymentStatus: 'UNPAID'
          }
        })
      )
        .then(() =>
          toast({
            id: `unpaid${invoice.invoiceNumber || ''}`,
            position: 'bottom',
            isClosable: true,
            render: () => (
              <Toast
                type="success"
                title={`Invoice ${
                  invoice.invoiceNumber || ''
                } is marked as unpaid`}
                isClosable={true}
              ></Toast>
            )
          })
        )
        .catch(e => Sentry.captureException(e))
    },
    label: invoicePaymentStatusMenuLabel('UNPAID')
  }
}

export const menuItemBuilders: (
  successToast: (options: UseToastOptions) => void,
  downloadPdf: (props: DownloadInvoicePdfProps) => void
) => MenuItemBuilder<InvoiceModel>[] = (toast, download) => [
  previewBuilder,
  finalizeAndSendBuilder,
  finalizeBuilder,
  sendBuilder,
  voidBuilder,
  createCreditNoteBuilder,
  paidBuilder(toast),
  partiallyPaidBuilder(toast),
  uncollectibleBuilder(toast),
  unpaidBuilder(toast),
  downloadBuilder(download)
]

const invoicePaymentStatusMenuLabel = (status: InvoicePaymentStatus) => (
  <Flex direction={'row'} lineHeight="28px">
    Mark as <Box w="1"></Box>{' '}
    <Badge {...toInvoicePaymentStatusBadgeProps({ status })} />
  </Flex>
)
