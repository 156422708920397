import { apiDatesAdapters } from 'modules/Cube/communication/external/billingSchedule.api.v1/utils/apiDates.adapters'
import { Price } from 'modules/Cube/domain/cube.domain.types'
import { CubeDomainInterface } from 'modules/Cube/domain/cube.domain'
import { SaveBillingScheduleData } from 'modules/Cube/communication/external/billingSchedule.api.v1/ports/useSaveBillingScheduleEditor'
import { uniqBy, compose } from 'lodash/fp'
import { NEW_PRODUCT_PATTERN } from 'modules/Cube/domain/cube.constants'

type SaveDataComposition = (
  existingData: CubeDomainInterface['queries']['rawData']['data']
) => (
  data: CubeDomainInterface['queries']
) => (prevData: SaveBillingScheduleData) => SaveBillingScheduleData

export const createBaseBillingSchedulePhasesApiData = (
  domainQueries: CubeDomainInterface['queries']
): SaveBillingScheduleData => {
  const allPrices = Object.values(domainQueries.resolvedPhases)
    .flatMap(phase => phase.prices)
    .filter(Boolean)

  return {
    billingSchedule: {
      rollUpBilling: domainQueries.rawData.data.schedule.rollUpBilling,
      phases: Object.values(domainQueries.resolvedPhases).map(
        resolvedPhase => ({
          priceIds: resolvedPhase.prices.map(({ id }) => id),
          startDate: apiDatesAdapters.out(resolvedPhase.dates.absolute.start),
          endDate: apiDatesAdapters.out(resolvedPhase.dates.absolute.end),
          minimums: resolvedPhase.minimums.map(minimum => ({
            amount: parseFloat(minimum.value),
            restrictToPrices:
              minimum.scope.target === 'allUsage' ? [] : minimum.scope.priceIds
          })),
          discounts: resolvedPhase.discounts.map(discount => ({
            amount: discount.amount,
            restrictToPrices: discount.priceIds,
            type: discount.discountCalculationType,
            message: discount.message
          }))
        })
      ),
      customerId: domainQueries.rawData.data.common.customerId,
      startDate: apiDatesAdapters.out(domainQueries.pricingDates.start),
      endDate: apiDatesAdapters.out(domainQueries.pricingDates.end),
      taxRates: domainQueries.rawData.data.schedule.taxRateId
        ? allPrices.map(price => ({
            priceId: price.id,
            taxRateId: domainQueries.rawData.data.schedule.taxRateId
          }))
        : [],
      autoIssueInvoices: domainQueries.rawData.data.schedule.autoIssueInvoices,
      recurrenceDayOfMonth:
        domainQueries.rawData.data.schedule.recurrenceDayOfMonth,
      purchaseOrderNumber:
        domainQueries.rawData.data.schedule.purchaseOrderNumber,
      reference: domainQueries.rawData.data.schedule.reference,
      label: domainQueries.rawData.data.schedule.label
    },
    billingScheduleSettings: {
      paymentProvider: domainQueries.rawData.data.schedule.stripePayment
        ? 'STRIPE'
        : 'NONE'
    },
    prices: {
      new: [],
      deleted: []
    },
    products: {
      new: []
    }
  }
}

/**
 * Price creation is rather challenging - we need to perform a
 * replacement on discounts, tax rates, and schedules in order
 * to correctly scope them to prices.
 *
 * The complexity here is that we are leaking some desire to optimise
 * the number of priceIds generated to the FE, which means if we can
 * help it we don't want to create unnecessary new prices.
 *
 * However, a scenario in which we must create new prices is when a
 * discount is present for a price, as a discount has no inherent way
 * to scope to a billing schedule version right now on the API.
 *
 * Therefore, we must replace existing prices, on save, if a discount
 * is assigned to that priceId for any version (phase).
 * @param existingData
 * @returns
 */
export const createPhasedPricesSaveData: SaveDataComposition =
  existingData => domainQueries => prevData => {
    const allPrices = uniqBy<Price>('id')(
      Object.values(domainQueries.resolvedPhases)
        .flatMap(({ prices }) => prices)
        .filter(Boolean)
        .map(price => ({
          ...price,
          integrationIds:
            price.integrationIds?.filter(i => i.service && i.id) ?? []
        }))
    )

    const allExistingPrices = Object.values(existingData.phases)
      .flatMap(phase =>
        phase.priceIds.map(
          priceId => domainQueries.rawData.data.prices[priceId]
        )
      )
      .filter(Boolean)

    /**
     * Take the new prices and extract any replacements, and then run a replacement against
     * discounts, billing schedule prices, and tax rates
     */
    return {
      ...prevData,
      prices: {
        new: allPrices.filter(price => {
          return !allExistingPrices.find(
            existingPrice => existingPrice.id === price.id
          )
        }),
        /**
         * Note that we don't expect to do anything with deleted prices today, but it's
         * useful to calculate them to drive functionality such as prompting for an effective date.
         */
        deleted: allExistingPrices.filter(
          existingPrice =>
            !allPrices.find(price => price.id === existingPrice.id)
        )
      }
    }
  }

export const createProductsSaveData: SaveDataComposition =
  () => data => prevData => {
    return {
      ...prevData,
      products: {
        new: Object.values(data.rawData.data.products).filter(product =>
          product.id.match(NEW_PRODUCT_PATTERN)
        )
      }
    }
  }

export const phasesAdapterOut =
  (existingData: CubeDomainInterface['queries']['rawData']['data']) =>
  (domainQueries: CubeDomainInterface['queries']): SaveBillingScheduleData => {
    const baseData = createBaseBillingSchedulePhasesApiData(domainQueries)

    return compose(
      createPhasedPricesSaveData(existingData)(domainQueries),
      createProductsSaveData(existingData)(domainQueries)
    )(baseData)
  }
