import { useCallback, useEffect, useMemo, useState } from 'react'

import { useStytchB2BClient, useStytchMember } from '@stytch/react/dist/b2b'
import * as Sentry from '@sentry/react'

import { isDemoEnv } from 'lib/environment/environment'
import {
  NavigationV3ContextType,
  Workspace
} from 'components/NavigationV3/NavigationV3Provider.types'
import { DEMO_MODE_ORG_NAME } from 'components/NavigationV3/NavigationV3Provider.constants'
import { deleteIntermediateSessionTokenCookie } from 'lib/auth/stytch'

type UseFetchStytchDataForNavigationV3 = () => {
  userEmail: NavigationV3ContextType['data']['userEmail'] | undefined
  userWorkspaces: NavigationV3ContextType['data']['userWorkspaces'] | undefined
  workspaceId: NavigationV3ContextType['data']['workspaceId'] | undefined
  workspaceName: NavigationV3ContextType['data']['workspaceName'] | undefined
}

export const useFetchStytchDataForNavigationV3: UseFetchStytchDataForNavigationV3 =
  () => {
    const stytch = useStytchB2BClient()
    const { member: user } = useStytchMember()

    const [userWorkspaces, setUserWorkspaces] = useState<
      Workspace[] | undefined
    >()

    /**
     * userEmail
     */
    const userEmail = useMemo(() => {
      if (!user) {
        return undefined
      }

      return user.email_address
    }, [user])

    /**
     * userWorkspaces
     */
    const fetchWorkspaces = useCallback(
      async () => stytch.discovery.organizations.list(),
      [stytch]
    )

    useEffect(() => {
      const populateWorkspaces = async () => {
        if (isDemoEnv()) {
          return
        }

        const fetchedWorkspaces = await fetchWorkspaces()

        const parsedWorkspaces: Workspace[] =
          fetchedWorkspaces.discovered_organizations.map(org => ({
            id: org.organization.organization_id,
            name: org.organization.organization_name
          }))

        setUserWorkspaces(parsedWorkspaces)
      }

      try {
        void populateWorkspaces()
      } catch (e) {
        deleteIntermediateSessionTokenCookie()
        Sentry.captureException(e, { extra: { hideReportDialog: true } })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * Workspace name
     */
    const workspaceName = useMemo(() => {
      if (isDemoEnv()) {
        return DEMO_MODE_ORG_NAME
      }

      if (
        !user ||
        typeof userWorkspaces === 'undefined' ||
        (typeof userWorkspaces !== 'undefined' && userWorkspaces.length === 0)
      ) {
        return
      }

      return (
        userWorkspaces.find(workspace => workspace.id === user.organization_id)
          ?.name ?? ''
      )
    }, [user, userWorkspaces])

    /**
     * Workspace ID
     */
    const workspaceId = useMemo(() => {
      if (
        !user ||
        typeof userWorkspaces === 'undefined' ||
        (typeof userWorkspaces !== 'undefined' && userWorkspaces.length === 0)
      ) {
        return
      }

      return (
        userWorkspaces.find(workspace => workspace.id === user.organization_id)
          ?.id ?? ''
      )
    }, [user, userWorkspaces])

    return {
      userEmail,
      userWorkspaces,
      workspaceId,
      workspaceName
    }
  }
