import { useCallback, useMemo, useState } from 'react'
import { FormFields, useForm } from '@sequencehq/utils'
import { required } from '@sequencehq/validation'
import { useLoadIntegrationCustomers } from 'InvoiceEditor/hooks/useLoadIntegrationCustomers'
import { IntegrationService } from '@sequencehq/core-models'
import { useInvoiceEditorContext } from 'InvoiceEditor/hooks/useInvoiceEditorContext'
import { AdapterCustomerWithIntegrationsModel } from 'InvoiceEditor/domainManagement/invoiceEditorAdapter'
import { InvoiceEditorReducerState } from 'InvoiceEditor/domainManagement/invoiceEditor.types'
import { integrationName } from 'lib/integrations/integrationName'

type CustomerLink = {
  externalId: string
}

type UseCustomerIntegrationLinkModal = (props: {
  integrationService: IntegrationService
  handlers: {
    onConfirm: (
      customer: AdapterCustomerWithIntegrationsModel,
      integrationService: IntegrationService,
      existingExternalLinks: InvoiceEditorReducerState['data']['customer']['externalIds']
    ) => void
  }
}) => {
  data: {
    customerName: string
    fields: FormFields<CustomerLink>
    integrationName: string
  }
  loading: boolean
  onLinkCustomer: () => void
}

export const useCustomerIntegrationLinkModal: UseCustomerIntegrationLinkModal =
  ({ handlers, integrationService }) => {
    const { data: invoiceEditorContext } = useInvoiceEditorContext()

    const { data: integrationData, loading } = useLoadIntegrationCustomers({
      integrationService
    })

    const [showValidationErrors, setShowValidationErrors] =
      useState<boolean>(false)

    const existingCustomer = useMemo(() => {
      const { customer } = invoiceEditorContext

      return {
        companyName: customer.companyName ?? '',
        accountEmail: customer.accountEmail ?? '',
        address: {
          line1: customer.address?.line1 ?? '',
          line2: customer.address?.line2,
          town: customer.address?.town ?? '',
          state: customer.address?.state,
          postcode: customer.address?.postcode ?? '',
          country: customer.address?.country ?? 'GB'
        },
        taxStatus: customer.taxStatus ?? 'TAX_EXEMPT'
      }
    }, [invoiceEditorContext])

    const alreadyLinkedCustomers = useMemo(() => {
      if (loading || !integrationData) {
        return []
      }

      return integrationData.integrationCustomers
        .filter(({ isLinked }) => isLinked)
        .map(({ id }) => id)
    }, [integrationData, loading])

    const { fields, queries } = useForm<CustomerLink>({
      value: { externalId: '' },
      showValidationErrors,
      fieldConfiguration: [
        {
          property: 'externalId',
          options:
            loading || !integrationData
              ? []
              : integrationData.integrationCustomers.map(
                  integrationCustomer => ({
                    value: integrationCustomer.id,
                    label: integrationCustomer.companyName,
                    disabled: alreadyLinkedCustomers.includes(
                      integrationCustomer.id
                    )
                  })
                ),
          validation: [required]
        }
      ]
    })

    const onLinkCustomer = useCallback(() => {
      if (!queries.isValid || !fields.externalId.value) {
        setShowValidationErrors(true)
        return
      }

      // TODO: we should probably translate the externalIds at the invoice editor adapter level because its only relevant for the API layer
      const updatedCustomer = {
        id: invoiceEditorContext.recipient.customerId,
        ...existingCustomer,
        externalIds: [
          ...invoiceEditorContext.customer.externalIds.map(
            ({ key, value }) => ({ key, value })
          ),
          {
            key: integrationService,
            value: fields.externalId.value
          }
        ]
      }

      handlers.onConfirm(
        updatedCustomer,
        integrationService,
        invoiceEditorContext.customer.externalIds
      )
    }, [
      queries.isValid,
      fields.externalId.value,
      invoiceEditorContext.recipient.customerId,
      invoiceEditorContext.customer.externalIds,
      existingCustomer,
      integrationService,
      handlers
    ])

    const data = useMemo(() => {
      return {
        customerName: invoiceEditorContext.recipient.customerLegalName,
        fields,
        integrationName: integrationName(integrationService)
      }
    }, [
      fields,
      integrationService,
      invoiceEditorContext.recipient.customerLegalName
    ])

    return {
      data,
      loading,
      onLinkCustomer
    }
  }
