import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { useQuery } from '@sequencehq/api/utils'
import { Currency } from '@sequencehq/api/utils/commonEnums'

interface JournalReportDetailData {
  totals: {
    debit: number
    credit: number
  }
  currency: Currency
  groups: Array<{
    customer: {
      id: string
      name: string
    }
    product: {
      id: string
      name: string
    }
    ledgerAccounts: Array<{
      name: string
      debit?: number
      credit?: number
    }>
  }>
}

/**
 * Loads the more expansive details for a given journal report.
 * @returns
 */
export const useJournalReportDetail = (props: {
  dates: {
    startDate: string
    endDate: string
  }
  currency: Currency | null
}): {
  detail: JournalReportDetailData | null
  isLoading: boolean
  error: boolean
} => {
  const journalDetailResponse = useQuery(
    dashboardv99990101Client.getJournals,
    {
      from: props.dates.startDate,
      to: props.dates.endDate,
      /**
       * TODO: This is where a skipToken is ideal, but we need to ponder how to
       * introduce it for the simple case. For now, we'll just set the param to
       * a default value to appease Typescript, and rely on `enabled` to control
       * when the query kicks off.
       */
      currency: props.currency ?? 'GBP'
    },
    {
      enabled: !!props.currency,
      select: data => {
        if (!data) {
          return null
        }

        return {
          date: props.dates.startDate,
          totals: {
            debit: parseFloat(data.summary.debits),
            credit: parseFloat(data.summary.credits)
          },
          currency: data.summary.currency,
          groups: data.journals.map(journal => ({
            customer: journal.customer,
            product: journal.product,
            ledgerAccounts: journal.entries.map(entry => ({
              name: entry.account.name,
              debit: entry.direction === 'DEBIT' ? entry.amount : undefined,
              credit: entry.direction === 'CREDIT' ? entry.amount : undefined
            }))
          }))
        }
      }
    }
  )

  return {
    error: Boolean(journalDetailResponse.error),
    isLoading: journalDetailResponse.isLoading,
    detail: journalDetailResponse.data ?? null
  }
}
