import { Inspector } from '@sequencehq/core-components'
import { useBillingScheduleInspector } from './useBillingScheduleInspector'
import {
  LinkSalesforceAccountWidget,
  SalesforceInspectorItem,
  StripeInspectorItem,
  StripePaymentWidget
} from 'Integrations/index'

export const BillingScheduleInspector = () => {
  const hook = useBillingScheduleInspector()

  return (
    <Inspector>
      <Inspector.ItemGroup>
        <SalesforceInspectorItem>
          <LinkSalesforceAccountWidget customerId={hook.customerId} />
        </SalesforceInspectorItem>
        <StripeInspectorItem alwaysShow>
          <StripePaymentWidget
            fieldData={hook.stripe.data}
            onChange={hook.stripe.onChange}
            disabled={!hook.stripe.available}
          />
        </StripeInspectorItem>
      </Inspector.ItemGroup>
    </Inspector>
  )
}
