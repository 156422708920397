import { createContext, useContext } from 'react'

export type SeatSnapshotContextType = {
  data: {
    resetKey: number
  }
  functions: {
    updateResetKey: () => void
  }
}

const SeatSnapshotContext = createContext<SeatSnapshotContextType>({
  data: {
    resetKey: 0
  },
  functions: {
    updateResetKey: () => {}
  }
})

export const useSeatSnapshotContext = () => useContext(SeatSnapshotContext)
export const SeatSnapshotProvider = SeatSnapshotContext.Provider
