import { Outlet, useOutletContext } from 'react-router-dom'
import {
  SeatSnapshotContextType,
  useSeatSnapshotContext
} from 'components/SeatSnapshots/hooks/useSeatSnapshotContext'

export const useSeatSnapshotOutletContext = () =>
  useOutletContext<SeatSnapshotContextType>()

export const SeatSnapshotOutletProvider = () => {
  const seatSnapshotContext = useSeatSnapshotContext()

  return <Outlet context={seatSnapshotContext} />
}
