import { IntegrationServices } from '@sequencehq/api/utils/commonEnums'
import { useIntegrationsDomainContext } from 'Integrations/communication'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useMemo } from 'react'

export const useIntegrationActive = (
  integrationId: IntegrationServices
): boolean => {
  const integrationsContext = useIntegrationsDomainContext()
  const flags = useFlags()

  const integrationActive = useMemo(() => {
    return Boolean(
      integrationsContext.queries.activeIntegrations
        .filter(int => int.id !== 'Salesforce' || flags.useInspector)
        .find(int => int.id === integrationId)?.enabled
    )
  }, [
    integrationsContext.queries.activeIntegrations,
    integrationId,
    flags.useInspector
  ])

  return integrationActive
}
