import { CreateCustomerForm } from 'components/Customers/components/drawers/CreateCustomer/CreateCustomerForm'
import { useCreateCustomerOutletConnector } from 'components/Customers/components/drawers/CreateCustomer/hooks/useCreateCustomerOutletConnector'
import { CustomersContextProvider } from 'components/Customers/hooks/useCustomersContext'

export const CreateCustomerRouteComponent = () => {
  const { onCancel, onSuccess } = useCreateCustomerOutletConnector()

  return (
    <CustomersContextProvider>
      <CreateCustomerForm onClose={onCancel} onSuccess={onSuccess} />
    </CustomersContextProvider>
  )
}
