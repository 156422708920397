import { Currency } from '@sequencehq/api/utils/commonEnums'
import { toCurrencySymbol } from '@sequencehq/core-models'

/**
 * A simple formatter for displaying the total for the graphs.
 * (Maybe could become a generic util in the lib directory? One to consider
 * when extracting useful bits of out of this module at the end.)
 * @param param0
 * @returns
 */
export const formatTotal = ({
  currency,
  value
}: {
  currency: Currency
  value: number
}): string =>
  `${toCurrencySymbol(currency)}${Intl.NumberFormat('en-GB', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  }).format(value)}`

/**
 * Produces the value labels we will render onto the Y axis of the graph.
 * Like the above, this could become a standard global util, especially if
 * we make it more intelligent and add a number of points based on the graph
 * height passed in.
 * @param data
 * @returns
 */
export const calculateYAxisLabels = (
  data: Array<{ value: number; date: string }>
): Array<number> => {
  if (data.length === 0) {
    return [0, 0]
  }

  /**
   * When we have data, we will produce three items on the Y-axis - zero,
   * the mid point, and the maximum value.
   */
  const max = Math.max(...data.map(d => d.value))
  return [0, max / 2, max]
}
