import {
  Dashboardv20240509Api,
  dashboardv20240509Client
} from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { useCallback, useState } from 'react'

type Data = Dashboardv20240509Api.GetBillingSchedules.BillingScheduleList | null

type LoadDataProps = {
  customerId?: string
  includeArchivedSchedules?: boolean
  after?: string
  before?: string
  limit?: number
  billingScheduleStatus?: string
  label?: string
}

type BillingSchedulesLoaderState = {
  data: Data
  loading: boolean
  error: boolean
}

export const useBillingSchedulesLoader = () => {
  const [state, setState] = useState<BillingSchedulesLoaderState>({
    data: null,
    loading: false,
    error: false
  })

  const loadData = useCallback(
    async ({
      customerId,
      includeArchivedSchedules,
      after,
      before,
      limit,
      billingScheduleStatus,
      label
    }: LoadDataProps = {}): Promise<Data> => {
      if (state.loading) {
        return null
      }

      setState(appState => ({ ...appState, loading: true }))

      const response = await dashboardv20240509Client.getBillingSchedules({
        customerId,
        includeArchivedSchedules,
        after,
        before,
        limit,
        billingScheduleStatus,
        label
      })

      if (response.error) {
        throw new Error('Could not load quotes')
      }

      setState({
        data: response.data,
        loading: false,
        error: response.error
      })

      return response.data
    },
    [state.loading]
  )

  const magicTableAdapter = useCallback(() => {
    return [
      (params: LoadDataProps = {}) =>
        loadData(params).then(res => ({
          data: {
            value: () => res
          }
        })),
      {
        data: {
          value: () => state?.data
        },
        isFetching: state.loading
      }
    ]
  }, [loadData, state])

  return {
    reload: loadData,
    magicTableAdapter
  }
}
