import { ServerAsset } from '@sequencehq/api/dashboard/v20240730'
import { useCubeContext } from 'Cube/communication/internal/cube.domain.context'
import { CubeStatus } from '../cube.domain.types'

export const useSelectQuoteAttachments = (): ServerAsset[] => {
  const { queries } = useCubeContext()
  return queries.rawData.data.quote.attachmentAssets
}

export const useStoreAttachments = () => {
  const cubeContext = useCubeContext()
  return (assets: ServerAsset[]) => {
    cubeContext.mutators.updateData({ quote: { attachmentAssets: assets } })
  }
}

const useQuoteStatus = () => {
  const { queries } = useCubeContext()
  return queries.rawData.data.common.status
}

export const useShouldDisableModifyAttachments = () => {
  const status = useQuoteStatus()

  if ([CubeStatus.QuoteAccepted, CubeStatus.QuoteExecuted].includes(status)) {
    const humanReadableStatus =
      status === CubeStatus.QuoteAccepted ? 'accepted' : 'executed'
    return {
      reason: `Cannot modify attachments for an ${humanReadableStatus} quote`
    }
  }

  return false
}
