import {
  createContext,
  FC,
  PropsWithChildren,
  Reducer,
  useCallback,
  useContext,
  useReducer,
  useState
} from 'react'
import {
  AddProductFormActions,
  AddProductFormActionTypes,
  AddProductFormMode,
  AddProductFormState
} from 'Products/drawers/AddProduct/domain/addProduct.type'
import {
  addProductFormReducer,
  INITIAL_ADD_PRODUCT_STATE
} from 'Products/drawers/AddProduct/domain/reducers/addProductReducer'
import { RecursivePartial } from 'Integrations/domain'
import { useAddProductForm } from 'Products/drawers/AddProduct/components/AddProductForm/useAddProductForm'
import { FormFields } from '@sequencehq/utils'
import { Product } from 'Products/types'
import { useListPriceEditorContext } from 'Products/drawers/ListPriceEditor/useListPriceEditorContext'
import { useProductRoot } from 'Products/hooks/useProductRoot'
import { internalPriceToApiPrice } from 'Products/drawers/ListPriceEditor/adapters/listPriceEditor.adapters'
import { useProductContext } from 'Products/hooks/useProductContext'
import { useNavigate } from 'react-router-dom'

type ProductFormFields = FormFields<
  Pick<Product, 'name' | 'label' | 'taxCategoryId'>
>
type AddProductFormContext = {
  actions: {
    updateFormMode: (mode: AddProductFormMode) => void
    updateFormData: (
      data: RecursivePartial<AddProductFormState['formData']>
    ) => void
    submitProductForm: VoidFunction
    submitPriceForm: VoidFunction
    submitForm: () => Promise<void>
  }
  data: AddProductFormState
  fieldsConfig: ProductFormFields & {
    taxCategoryId: ProductFormFields['taxCategoryId'] & { hidden: boolean }
  }
  isSubmitting?: boolean
}

export const AddProductFormContext = createContext({} as AddProductFormContext)

export const useAddProductFormContext = () => useContext(AddProductFormContext)

export const AddProductFormProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { fieldsConfig, onSubmit: onProductSubmit } = useAddProductForm()

  const navigate = useNavigate()

  const {
    functions: { createProduct }
  } = useProductRoot()

  const {
    functions: { createListPrice }
  } = useProductContext()

  const {
    functions: { updateEditor },
    derived: { queries }
  } = useListPriceEditorContext()

  const [state, dispatch] = useReducer<
    Reducer<AddProductFormState, AddProductFormActions>
  >(addProductFormReducer, INITIAL_ADD_PRODUCT_STATE)

  const updateFormMode = (mode: AddProductFormMode) => {
    dispatch({
      type: AddProductFormActionTypes.UPDATE_FORM_MODE,
      payload: mode
    })
  }

  const updateFormData = (
    data: RecursivePartial<AddProductFormState['formData']>
  ) => {
    dispatch({
      type: AddProductFormActionTypes.UPDATE_FORM_DATA,
      payload: data
    })
  }

  const onProductFormSubmit = () => {
    const product = onProductSubmit()

    if (product) {
      updateFormData({ product })
      updateFormMode(AddProductFormMode.ADD_PRICE)
    }
  }

  const onPriceFormSubmit = useCallback(() => {
    if (!queries.canSave) {
      updateEditor({ showValidationErrors: true })
      return
    }

    updateFormData({ price: queries.selectedPrice! })
    updateFormMode(AddProductFormMode.REVIEW)
  }, [queries.canSave, queries.selectedPrice, updateEditor])

  const onFormSubmit = async () => {
    setIsSubmitting(true)

    if (!state.formData.product || !state.formData.price) {
      return
    }

    const product = await createProduct(state.formData.product)

    if (product) {
      const apiPrice = internalPriceToApiPrice(product.id)(state.formData.price)

      await createListPrice(apiPrice)
      navigate('..')
    }

    setIsSubmitting(false)
  }

  return (
    <AddProductFormContext.Provider
      value={{
        actions: {
          updateFormMode,
          updateFormData,
          submitProductForm: onProductFormSubmit,
          submitPriceForm: onPriceFormSubmit,
          submitForm: onFormSubmit
        },
        data: state,
        isSubmitting,
        fieldsConfig
      }}
    >
      {children}
    </AddProductFormContext.Provider>
  )
}
