import { FC } from 'react'
import {
  UsageMetricModel,
  emptyPaginatedResponseNew
} from '@sequencehq/core-models'
import { required } from '@sequencehq/validation'
import { useGetUsageMetricsQuery } from 'features/api'
import { selectPlaceholder } from 'lib/form'
import {
  InfoPopoverProps,
  CustomSelectNew,
  CompoundInputFieldNew,
  CustomSelectNewProps
} from '@sequencehq/forms'
import { useNavigate } from 'react-router-dom'
import { useUsageMetric } from 'components/CreateUsageMetric'

type UsageMetricInputProps = {
  fieldName: string
  infoPopover?: InfoPopoverProps
  disabled?: boolean
}

export const UsageMetricInput: FC<UsageMetricInputProps> = ({
  disabled,
  ...rest
}) => {
  const navigate = useNavigate()
  const { state, dispatch } = useUsageMetric()

  const usageMetricsResult = useGetUsageMetricsQuery({
    limit: 1000
  })

  const { usageMetricId, usageMetric } = state
  const usageMetrics =
    usageMetricsResult.data?.value() ?? emptyPaginatedResponseNew

  return (
    <CompoundInputFieldNew<CustomSelectNewProps<UsageMetricModel>>
      fieldLabel="Usage metric"
      items={usageMetrics.items}
      itemToString={item =>
        item ? `${item.name} ${item.aggregationType}` : ''
      }
      itemToContent={item => ({
        title: `${item.name} ${item.aggregationType}`
      })}
      validate={required}
      placeholder={selectPlaceholder({
        result: usageMetricsResult,
        successPrompt: 'Choose usage metric'
      })}
      disabled={disabled || !usageMetricsResult.isSuccess}
      Component={CustomSelectNew}
      addNewAction={() => navigate('metrics/new')}
      borderRadius="6px"
      modelId={usageMetricId}
      model={usageMetric ?? null}
      setModelId={id => {
        dispatch({ type: 'setUsageMetricId', usageMetricId: id })
      }}
      {...rest}
    />
  )
}
