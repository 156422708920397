import { Box, Flex, Text } from '@chakra-ui/react'
import {
  GreyGrey40,
  GreyGrey60,
  GreyGrey90,
  IndigoIndigo50,
  Lato13Bold,
  Lato13Regular,
  Lato20Bold
} from '@sequencehq/design-tokens'
import { XYChart } from '@sequencehq/shared-components'
import { ChartTooltip } from 'components/InsightsV2/common/ChartTooltip'
import { FilterSelectInput } from 'components/InsightsV2/common/FilterSelectInput/FilterSelectInput'
import { useCallback } from 'react'
import { useEventTypesWidget } from 'components/InsightsV2/widgets/EventTypesWidget/useEventTypesWidget'
import { WidgetLoading } from 'components/InsightsV2/common/WidgetLoading/WidgetLoading'
import { StringDate, formatStringDate, toStringDate } from '@sequencehq/utils'

interface EventTypesWidgetProps {
  dateRange: {
    start: StringDate
    end: StringDate
  }
}

export const EventTypesWidget: React.FC<EventTypesWidgetProps> = ({
  dateRange
}) => {
  const { data, isFetching, total, unit, eventTypeField, unitTickValues } =
    useEventTypesWidget(dateRange)
  const tooltipFormat = useCallback(
    (value: number) => `${value.toLocaleString('en-US')} events`,
    []
  )

  if (isFetching || !data) {
    return <WidgetLoading />
  }

  return (
    <Box
      width="390px"
      height="291px"
      boxShadow="0 2px 4px 0 rgba(20, 23, 28, 0.08)"
      border={`1px solid ${GreyGrey40}`}
      borderRadius="lg"
      p={4}
    >
      <Flex gap={2}>
        <Flex flexGrow="1" flexDirection="column" gap={2}>
          <Text as={Flex} {...Lato13Bold} color={GreyGrey60} gap={1}>
            Total Events
          </Text>
          <Text {...Lato20Bold} color={GreyGrey90}>
            {total} events
          </Text>
          <Text {...Lato13Regular} color={GreyGrey60}>
            {formatStringDate(dateRange.start, 'd MMM yyyy')} -{' '}
            {formatStringDate(dateRange.end, 'd MMM yyyy')}
          </Text>
        </Flex>
        <Flex width="120px" alignSelf="baseline">
          <FilterSelectInput
            placeholder="Event type"
            value={eventTypeField.value}
            options={eventTypeField.options}
            onChange={eventTypeField.onChange}
          />
        </Flex>
      </Flex>
      <Box mt={4} height="170px" width="100%">
        <XYChart
          variant="line"
          dateFrom={dateRange.start}
          dateTo={dateRange.end}
          data={data}
          unitTickValues={unitTickValues}
          unit={unit}
          renderTooltip={({ date, value }: { date: string; value: number }) => (
            <ChartTooltip>
              <Text {...Lato13Bold} color="gray.70">
                {formatStringDate(toStringDate(date), 'EEE MMM d, yyyy')}
              </Text>
              <>
                <Text
                  as={Flex}
                  {...Lato13Regular}
                  color="gray.70"
                  alignItems="center"
                  gap={2}
                >
                  <Flex h={2} w={2} bg={IndigoIndigo50} borderRadius="full" />
                  {formatStringDate(toStringDate(date), 'EEE MMM d, yyyy')}
                </Text>
                <Text {...Lato13Bold} color="gray.80">
                  {tooltipFormat(value)}
                </Text>
              </>
            </ChartTooltip>
          )}
        />
      </Box>
    </Box>
  )
}
