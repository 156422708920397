import { Box } from '@chakra-ui/react'
import { GreyWhite } from '@sequencehq/design-tokens'
import { TitleEditor } from './TitleEditor'
import { ContentEditor } from './ContentEditor'
import { ConfigPills } from './ConfigPills'
import { QuoteByline } from 'modules/Cube/view/layouts/quote/QuoteEditorContent/QuoteByline/QuoteByline'
import { ValidationBanner } from './ValidationBanner/ValidationBanner'
import { CoverImageEditor } from './CoverImageEditor'
import { useFlags } from 'launchdarkly-react-client-sdk'

export const QuoteEditorContent = () => {
  const flags = useFlags()

  return (
    <Box background={GreyWhite} width="100%">
      <Box role="group">
        <ValidationBanner />
        {flags.useQuoteCoverImages && <CoverImageEditor />}
        <Box
          px="40px"
          pt={flags.useQuoteCoverImages ? '16px' : '64px'}
          w="100%"
        >
          <TitleEditor />
          <QuoteByline />
          <ConfigPills />
        </Box>
      </Box>
      <Box px="40px" pb="64px" minWidth="932px">
        <ContentEditor />
      </Box>
    </Box>
  )
}
