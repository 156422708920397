import { IntegrationService } from '@sequencehq/core-models'
import exhaustiveGuard from 'lib/exhaustiveGuard/exhaustiveGuard'
import { AmazonRedshiftIcon } from 'components/icons/AmazonRedshiftIcon'
import { GoogleBigQueryIcon } from 'components/icons/GoogleBigQueryIcon'
import { GoogleSheetsIcon } from 'components/icons/GoogleSheetsIcon'
import { HubspotIcon } from 'components/icons/HubspotIcon'
import { NetsuiteIcon } from 'components/icons/NetsuiteIcon'
import { QuickbooksIcon } from 'components/icons/QuickbooksIcon'
import { SalesforceIcon } from 'components/icons/SalesforceIcon'
import { SlackIcon } from 'components/icons/SlackIcon'
import { SnowflakeIcon } from 'components/icons/SnowflakeIcon'
import { StripeIcon } from 'components/icons/StripeIcon'
import { XeroIcon } from 'components/icons/XeroIcon'
import { ReactElement } from 'react'

export function getLogo(
  integrationService: IntegrationService,
  size = 32
): ReactElement {
  const props = { width: size, height: size }

  switch (integrationService) {
    case 'Stripe':
      return <StripeIcon {...props} />
    case 'Xero':
      return <XeroIcon {...props}></XeroIcon>
    case 'QuickBooks_Online':
      return <QuickbooksIcon {...props}></QuickbooksIcon>
    case 'NetSuite':
      return <NetsuiteIcon {...props}></NetsuiteIcon>
    case 'Salesforce':
      return <SalesforceIcon {...props}></SalesforceIcon>
    case 'HubSpot':
      return <HubspotIcon {...props}></HubspotIcon>
    case 'Slack':
      return <SlackIcon {...props}></SlackIcon>
    case 'Google_BigQuery':
      return <GoogleBigQueryIcon {...props}></GoogleBigQueryIcon>
    case 'Google_Sheets':
      return <GoogleSheetsIcon {...props}></GoogleSheetsIcon>
    case 'Snowflake':
      return <SnowflakeIcon {...props}></SnowflakeIcon>
    case 'Amazon_Redshift':
      return <AmazonRedshiftIcon {...props}></AmazonRedshiftIcon>
    default:
      return exhaustiveGuard(integrationService)
  }
}

export function addPaddingToIntegrationLogo(
  integrationService: IntegrationService
): boolean {
  switch (integrationService) {
    case 'Stripe':
    case 'Salesforce':
    case 'Amazon_Redshift':
      return true
    default:
      return false
  }
}
