import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

export const useEditCustomerOutletConnector = () => {
  const navigate = useNavigate()

  const onSuccess = useCallback(() => {
    navigate('..')
  }, [navigate])

  const onCancel = useCallback(() => {
    navigate('..')
  }, [navigate])

  return {
    onSuccess,
    onCancel
  }
}
