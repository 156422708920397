import { TextAreaField, TextField } from '@sequencehq/core-components'
import { Flex } from '@chakra-ui/react'
import { useAddProductFormContext } from 'Products/drawers/AddProduct/hooks/useAddProductFormContext'
import { TaxCategoryField } from 'common/drawers/PricingEditor/view/layout/listPrice/ProductForm/TaxCategoryField'

export const AddProductForm = () => {
  const { fieldsConfig } = useAddProductFormContext()

  return (
    <Flex gap="24px" padding="24px" direction="column">
      <Flex direction="column" data-testid="addProduct">
        <TextField
          data-testid="addProduct.name"
          label="Product name"
          value={fieldsConfig.name.value}
          onChange={fieldsConfig.name.onChange}
          validationErrors={fieldsConfig.name.validationErrors}
          isDisabled={fieldsConfig.name.disabled}
          placeholder="Enter a product name"
          autoFocus
        />
        <TextAreaField
          data-testid="addProduct.description"
          label="Description"
          value={fieldsConfig.label.value}
          onChange={fieldsConfig.label.onChange}
          validationErrors={fieldsConfig.label.validationErrors}
          isDisabled={fieldsConfig.label.disabled}
          placeholder="Enter a product description"
        />

        <TaxCategoryField
          data-testid="addProduct.taxCategoryId"
          field={fieldsConfig.taxCategoryId}
          formChangeset={{}}
        />
      </Flex>
    </Flex>
  )
}
