import { Box, Center, Flex } from '@chakra-ui/react'
import EllipsisHorizontalIcon from '@heroicons/react/24/outline/EllipsisHorizontalIcon'
import {
  KebabMenu,
  MenuItem,
  HorizontalSelectorField,
  SelectFieldWithActions
} from '@sequencehq/core-components'
import {
  GreyWhite,
  IndigoIndigo50,
  Lato14Bold
} from '@sequencehq/design-tokens'
import { useVolumePriceForm } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/forms/VolumePrice/useVolumePriceForm'
import { FixedTierTableInput } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/forms/inputs/FixedTierTableInput/FixedTierTableInput'
import { PercentageTierTableInput } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/forms/inputs/PercentageTierTableInput/PercentageTierTableInput'
import { PercentageTierWithLimitsTableInput } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/forms/inputs/PercentageTierWithLimitsTableInput/PercentageTierWithLimitsTableInput'
import { UsageMetricParameterFields } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/utils/UsageMetricParameterFields'
import {
  AvailableStandardFrequency,
  UsageTierType
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.types'

export const VolumePriceForm = () => {
  const { fieldsConfig, currency, fieldsAreHidden } = useVolumePriceForm()

  const kebabMenuItems = () => {
    const text = fieldsConfig.includePercentageLimits.value
      ? 'Remove fee thresholds'
      : 'Add fee thresholds'

    return [
      {
        status: 'LIVE',
        label: text,
        action: () => {
          fieldsConfig.includePercentageLimits.onChange(
            !fieldsConfig.includePercentageLimits.value
          )
        },
        styles: {
          padding: '8px',
          borderRadius: '8px',
          _hover: {
            backgroundColor: IndigoIndigo50,
            color: GreyWhite
          },
          cursor: 'pointer'
        }
      } as MenuItem
    ]
  }

  return (
    <Box paddingBottom={fieldsAreHidden ? '0px' : '8px'}>
      {!fieldsConfig.billingFrequency.hidden && (
        <HorizontalSelectorField
          label="Frequency"
          data-testid="common-field-billingFrequency"
          value={fieldsConfig.billingFrequency.value}
          options={fieldsConfig.billingFrequency.options}
          onChange={newValue =>
            fieldsConfig.billingFrequency.onChange(
              newValue as AvailableStandardFrequency
            )
          }
          validationErrors={fieldsConfig.billingFrequency.validationErrors}
          isDisabled={fieldsConfig.billingFrequency.disabled}
        />
      )}
      {!fieldsConfig.usageMetricId.hidden && (
        <>
          <SelectFieldWithActions
            label="Usage Metric"
            options={fieldsConfig.usageMetricId.options}
            validationErrors={fieldsConfig.usageMetricId.validationErrors}
            value={fieldsConfig.usageMetricId.value}
            onChange={fieldsConfig.usageMetricId.onChange}
            isDisabled={fieldsConfig.usageMetricId.disabled}
            placeholder="Select usage metric"
            onAddNew={fieldsConfig.usageMetricId.onAddNew}
          />
          <UsageMetricParameterFields
            fieldsConfig={fieldsConfig}
            usageMetricId={fieldsConfig.usageMetricId.value}
          ></UsageMetricParameterFields>
        </>
      )}
      <HorizontalSelectorField
        label="Type"
        value={fieldsConfig.usageTierType.value}
        options={fieldsConfig.usageTierType.options}
        onChange={newValue =>
          fieldsConfig.usageTierType.onChange(newValue as UsageTierType)
        }
        validationErrors={fieldsConfig.usageTierType.validationErrors}
        isDisabled={fieldsConfig.usageTierType.disabled}
      />
      <Box
        paddingTop="8px"
        marginBottom={fieldsAreHidden ? '0px' : '16px'}
        paddingBottom={fieldsAreHidden ? '0px' : '8px'}
        width="100%"
      >
        <Flex flexDirection="row">
          <Box {...Lato14Bold} marginBottom="8px">
            Tiers
          </Box>
          <Flex grow="1"></Flex>
          {fieldsConfig.usageTierType.value === 'PERCENTAGE' && (
            <Center height="14px" paddingBottom="8px">
              <KebabMenu
                kebabIcon={<EllipsisHorizontalIcon height="20px" />}
                menuItems={kebabMenuItems()}
                renderListInPortal={true}
              />
            </Center>
          )}
        </Flex>
        {fieldsConfig.usageTierType.value === 'FIXED' ? (
          <FixedTierTableInput
            value={fieldsConfig.tiers.value}
            onChange={fieldsConfig.tiers.onChange}
            isDisabled={fieldsConfig.tiers.disabled}
            currency={currency}
            validationErrors={fieldsConfig.tiers.validationErrors}
          />
        ) : fieldsConfig.includePercentageLimits.value ? (
          <PercentageTierWithLimitsTableInput
            value={fieldsConfig.percentageTiers.value}
            onChange={fieldsConfig.percentageTiers.onChange}
            isDisabled={fieldsConfig.percentageTiers.disabled}
            currency={currency}
            validationErrors={fieldsConfig.percentageTiers.validationErrors}
          />
        ) : (
          <PercentageTierTableInput
            value={fieldsConfig.percentageTiers.value}
            onChange={fieldsConfig.percentageTiers.onChange}
            isDisabled={fieldsConfig.percentageTiers.disabled}
            currency={currency}
            validationErrors={fieldsConfig.percentageTiers.validationErrors}
          />
        )}
      </Box>
    </Box>
  )
}

export const HiddenFieldsForm = () => {
  const { fieldsConfig } = useVolumePriceForm()

  return (
    <>
      <HorizontalSelectorField
        label="Frequency"
        data-testid="common-field-billingFrequency"
        value={fieldsConfig.billingFrequency.value}
        options={fieldsConfig.billingFrequency.options}
        onChange={newValue =>
          fieldsConfig.billingFrequency.onChange(
            newValue as AvailableStandardFrequency
          )
        }
        validationErrors={fieldsConfig.billingFrequency.validationErrors}
        isDisabled={fieldsConfig.billingFrequency.disabled}
      />
      <SelectFieldWithActions
        label="Usage Metric"
        options={fieldsConfig.usageMetricId.options}
        validationErrors={fieldsConfig.usageMetricId.validationErrors}
        value={fieldsConfig.usageMetricId.value}
        onChange={fieldsConfig.usageMetricId.onChange}
        isDisabled={fieldsConfig.usageMetricId.disabled}
        placeholder="Select usage metric"
        onAddNew={fieldsConfig.usageMetricId.onAddNew}
      />
      <UsageMetricParameterFields
        fieldsConfig={fieldsConfig}
        usageMetricId={fieldsConfig.usageMetricId.value}
      ></UsageMetricParameterFields>
    </>
  )
}
