import {
  Dashboardv20240509Api,
  dashboardv20240509Client
} from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { useCallback, useState } from 'react'

type Data = Dashboardv20240509Api.GetInvoices.InvoicesList | null

export const useInvoicesMagicTableLoader = () => {
  const [state, setState] = useState<{
    data: Data
    loading: boolean
    error: boolean
  }>({
    data: null,
    loading: false,
    error: false
  })

  const loadData = useCallback(
    async (
      props: Dashboardv20240509Api.GetInvoices.Params
    ): Promise<Dashboardv20240509Api.GetInvoices.InvoicesList | null> => {
      if (state.loading) {
        return null
      }
      setState(ps => ({ ...ps, loading: true }))
      const response = await dashboardv20240509Client.getInvoices(props)
      if (response.error) {
        throw new Error('Could not load invoices')
      }
      setState({
        data: response.data,
        loading: false,
        error: response.error
      })
      return response.data
    },
    [state.loading]
  )

  /*
       This adapter is the key part - it provides an interface that matches up to the lazy
       rtk-query hook, and it's this adapter that we pass into MagicTable as the `useLazyQuery`
       prop.
     */
  const magicTableLoader = useCallback(() => {
    return [
      (
        params: {
          customerId?: string
          includeArchived?: boolean
          after?: string
          before?: string
          limit?: number
        } = {}
      ) =>
        loadData(params).then(res => ({
          data: {
            value: () => res
          }
        })),
      {
        data: {
          value: () => state?.data
        },
        isFetching: state.loading
      }
    ]
  }, [loadData, state])

  return {
    loadData,
    magicTableLoader
  }
}
