import { pastRelativeToDate } from '@sequencehq/formatters'
import { FC, memo } from 'react'
import { EmptyStateContent } from 'components/Loading/EmptyState'
import { getToday } from 'components/FormInputs/Dates/getToday'
import { Flex } from '@chakra-ui/react'
import Page from 'components/Page'
import { UsageEventsMagicTable } from 'components/UsageEvents/UsageEventsMagicTable'
import { Outlet } from 'react-router-dom'
import { usePersistMagicTableQueryParams } from '@sequencehq/tables'

export type UsageEventsSharedTableProps = {
  emptyContent: EmptyStateContent
}

export const timestampFilterOptions = [
  {
    label: '1 week ago',
    value: pastRelativeToDate(getToday(), { weeks: 1 })
  },
  {
    label: '1 month ago',
    value: pastRelativeToDate(getToday(), { months: 1 })
  },
  {
    label: '3 months ago',
    value: pastRelativeToDate(getToday(), { months: 3 })
  },
  {
    label: '6 months ago',
    value: pastRelativeToDate(getToday(), { months: 6 })
  }
]

const UsageEvents: FC = memo(() => {
  const { persistMagicTableQueryParams } = usePersistMagicTableQueryParams()

  return (
    <Flex flexDirection="column" flex={1}>
      <Flex position="sticky" top={0} height="100vh">
        <Page
          title="Events"
          addNewConfig={{
            buttonText: 'New usage event',
            href: persistMagicTableQueryParams('/usage-events/new')
          }}
          paddingBottom={0}
        >
          <UsageEventsMagicTable emptyContent={emptyContent} />
        </Page>
      </Flex>
      <Outlet />
    </Flex>
  )
})

export default UsageEvents

const emptyContent: EmptyStateContent = {
  title: 'Start ingesting usage events',
  description: 'Integrate with the Usage API or submit event data via CSV.',
  linkCopy: 'View documentation.',
  docLink: 'https://docs.sequencehq.com/metrics-events/usage-events',
  route: '/usage-events/new',
  buttonCopy: 'Submit test event'
}
