import { Global, css } from '@emotion/react'
import { FC } from 'react'

type GlobalStylesProps = {
  path?: string
}

const GlobalStyles: FC<GlobalStylesProps> = () => {
  return (
    <Global
      styles={css`
        body {
          margin: 0;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }

        @font-face {
          font-family: 'Lato';
          src: url('/fonts/Lato-Regular.woff2') format('woff2'),
            url('/fonts/Lato-Regular.woff') format('woff'),
            url('/fonts/Lato-Regular.ttf') format('truetype');
          font-weight: normal;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: 'Lato';
          src: url('/fonts/Lato-Bold.woff2') format('woff2'),
            url('/fonts/Lato-Bold.woff') format('woff'),
            url('/fonts/Lato-Bold.ttf') format('truetype');
          font-weight: bold;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: 'Inconsolata';
          src: url('/fonts/Inconsolata-Regular.woff2') format('woff2'),
            url('/fonts/Inconsolata-Regular.woff') format('woff'),
            url('/fonts/Inconsolata-Regular.ttf') format('truetype');
          font-weight: normal;
          font-style: normal;
          font-display: swap;
        }
      `}
    />
  )
}

export default GlobalStyles
