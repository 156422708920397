import { UTCDate } from '@date-fns/utc'
import { addAndAccountForDST } from '@sequencehq/utils'
import { isEqual } from 'date-fns'
import isBefore from 'date-fns/isBefore'
import { getLatestRecurrenceDate } from 'modules/Cube/domain/postActionStages/utils/getLatestRecurrenceDate'
import * as Sentry from '@sentry/react'

const getBillingDatesForFrequency =
  (ctx: { intervalInMonths: number; recurrenceDayOfMonth: number }) =>
  (
    { start, end }: { start: UTCDate; end: UTCDate },
    /**
     * A max depth of 10 years of a monthly frequency was added in response to
     * a date-fns driven incident (Slack: #incident-billing-schedule-failing-for-cake-capital)
     * where the 'next billing date' was in the past, and thus lead to this recursion spinning endlessly.
     *
     * There was a change to some of the clauses to also account for this. However,
     * considering that this feature powers validation and helpful checks, it exploding
     * is an outsized impact on the user experience. Thus, just in case
     * date-fns decided to screw with us again in another niche scenario the max depth
     * was added to force this recursion to eventual exit, and to ping us a Sentry error
     * when it does.
     */
    maxDepth = 120
  ): UTCDate[] => {
    if (maxDepth <= 0) {
      Sentry.captureException(new Error('Max depth of billing dates reached'))
      return []
    }

    const nextDate = addAndAccountForDST({
      months: ctx.intervalInMonths
    })(start)
    const latestBillingDateForNextDate = getLatestRecurrenceDate(
      ctx.recurrenceDayOfMonth
    )(nextDate)

    if (!latestBillingDateForNextDate) {
      return []
    }

    if (isBefore(end, latestBillingDateForNextDate)) {
      return [latestBillingDateForNextDate]
    }

    if (
      isEqual(start, latestBillingDateForNextDate) ||
      isBefore(latestBillingDateForNextDate, start)
    ) {
      return [
        ...getBillingDatesForFrequency(ctx)(
          { start: nextDate, end },
          maxDepth - 1
        )
      ]
    }

    return [
      latestBillingDateForNextDate,
      ...getBillingDatesForFrequency(ctx)(
        { start: latestBillingDateForNextDate, end },
        maxDepth - 1
      )
    ]
  }

export const getBillingDates = ({
  recurrenceDayOfMonth,
  phaseDates
}: {
  recurrenceDayOfMonth: number
  phaseDates: { start: UTCDate; end: UTCDate }
}) => ({
  MONTHLY: getBillingDatesForFrequency({
    intervalInMonths: 1,
    recurrenceDayOfMonth
  })({
    start: phaseDates.start,
    end: phaseDates.end
  }),
  QUARTERLY: getBillingDatesForFrequency({
    intervalInMonths: 3,
    recurrenceDayOfMonth
  })({
    start: phaseDates.start,
    end: phaseDates.end
  }),
  YEARLY: getBillingDatesForFrequency({
    intervalInMonths: 12,
    recurrenceDayOfMonth
  })({
    start: phaseDates.start,
    end: phaseDates.end
  }),
  ONE_TIME: []
})
