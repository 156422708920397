import { Grid, GridItem, ResponsiveValue } from '@chakra-ui/react'
import { GreyGrey30 } from '@sequencehq/design-tokens'
import SpinnerContainer from 'components/Loading/SpinnerContainer'
import { QuoteEditorContent } from 'modules/Cube/view/layouts/quote/QuoteEditorContent/QuoteEditorContent'
import { QuoteEditorHeader } from 'modules/Cube/view/layouts/quote/QuoteEditorHeader/QuoteEditorHeader'
import { QuoteEditorSidebar } from 'Cube/view/layouts/quote/QuoteEditorSidebar/QuoteEditorSidebar'
import { useQuoteEditorLayout } from 'modules/Cube/view/layouts/quote/useQuoteEditorLayout'
import { QuoteEditorInspector } from './QuoteEditorInspector/QuoteEditorInspector'

const styles = {
  container: {
    width: '100%',
    minWidth: '1218px',
    height: '100vh',
    overflow: 'hidden'
  },
  content: {
    overflowY: 'auto' as const,
    position: 'relative' as ResponsiveValue<'relative'>
  },
  sidebar: {
    position: 'sticky' as ResponsiveValue<'sticky'>,
    height: '100%',
    right: 0,
    top: 0,
    borderLeft: `1px solid ${GreyGrey30}`
  }
}

export const QuoteEditorLayout = () => {
  const { ready, useInspector } = useQuoteEditorLayout()

  if (!ready) {
    return <SpinnerContainer />
  }

  return (
    <Grid
      data-testid="quote.layout"
      templateAreas={`
    "header"
    "content"
    `}
      templateRows={`auto 1fr`}
      columnGap="20px"
      width="100%"
      height="100vh"
      overflow="auto"
    >
      <GridItem gridArea="header">
        <QuoteEditorHeader />
      </GridItem>
      <Grid
        templateColumns={`1fr auto`}
        templateAreas={`"main sidebar"`}
        gridArea="content"
        height="100%"
        overflow="hidden"
        minWidth={!useInspector ? '1218px' : undefined}
      >
        <GridItem gridArea="main" width="100%" overflow={'auto'}>
          <Grid rowGap="20px" justifyItems="center">
            <QuoteEditorContent />
          </Grid>
        </GridItem>

        {useInspector ? (
          <GridItem gridArea="sidebar" zIndex="10">
            <QuoteEditorInspector />
          </GridItem>
        ) : (
          <GridItem
            gridArea="sidebar"
            zIndex="10"
            width="328px"
            {...styles.sidebar}
          >
            <QuoteEditorSidebar />
          </GridItem>
        )}
      </Grid>
    </Grid>
  )
}
