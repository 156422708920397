import { InputSelectControl } from '@sequencehq/core-components'
import { emptyPaginatedResponseNew } from '@sequencehq/core-models'
import { InfoPopoverProps } from '@sequencehq/forms'
import { useSeatType } from 'components/SeatType'
import { useGetSeatTypesQuery } from 'features/api'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

type SeatTypeInputProps = {
  fieldName: string
  infoPopover?: InfoPopoverProps
  disabled?: boolean
}

export const SeatTypeInput: FC<SeatTypeInputProps> = ({ disabled }) => {
  const navigate = useNavigate()
  const { dispatch } = useSeatType()

  const seatTypesResult = useGetSeatTypesQuery({
    limit: 1000
  })

  const seatTypes = seatTypesResult.data?.value() ?? emptyPaginatedResponseNew

  return (
    <InputSelectControl
      triggerStyle={{ paddingLeft: 16, userSelect: 'none' }}
      onChange={value => {
        dispatch({ type: 'setSeatTypeId', seatTypeId: value })
      }}
      options={seatTypes.items.map(item => ({
        value: item.id,
        label: item.label
      }))}
      disabled={disabled || !seatTypesResult.isSuccess}
      onCreate={() => navigate('seats/new')}
      placeholder="Select seat metric"
      createLabel="Create new"
    />
  )
}
