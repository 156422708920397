import { CreateNotificationPolicyForm } from 'components/CreateNotificationPolicy/CreateNotificationPolicyForm'
import { createFormModal } from 'components/Form/createFormModal'
import { Toast } from '@sequencehq/core-components'
import { usePostApiNotificationsPoliciesMutation } from 'features/api'
import { ExtractMutationParams } from 'lib/types'

export type CreateNotificationPolicyFormMutation = ExtractMutationParams<
  typeof usePostApiNotificationsPoliciesMutation
>

export const CreateNotificationPolicyModal = createFormModal<
  CreateNotificationPolicyFormMutation,
  Record<string, never>
>({
  FormComponent: CreateNotificationPolicyForm,
  useMutation: usePostApiNotificationsPoliciesMutation,
  modalTitleFromProps: () => 'Create notification policy',
  successMessage: 'Notification policy created',
  submitTitle: 'Create',
  formId: 'createNotificationPolicyForm',
  successToast: response => (
    <Toast
      title="Notification policy created"
      type="success"
      description={`Notification policy ${response.name} created`}
      isClosable={true}
    ></Toast>
  )
})
