import { Flex } from '@chakra-ui/react'
import ArrowTrendingUpIcon from '@heroicons/react/24/outline/ArrowTrendingUpIcon'
import { Badge } from '@sequencehq/core-components'
import { SeatSnapshotModel } from '@sequencehq/core-models'
import { RedRed20, RedRed50 } from '@sequencehq/design-tokens'
import {
  MagicTable,
  MagicTableCell,
  MagicTableCellEmpty,
  MagicTableCellLink,
  MagicTableEmptyStateProps
} from '@sequencehq/tables'
import MagicTableAutoLoader from 'components/AutoLoader/MagicTableAutoLoader'
import { CurrentUserId } from 'components/CurrentUserId/CurrentUserId'
import { SeatBalancesViewSelector } from 'components/SeatBalances/SeatBalancesViewSelector'
import { SeatSnapshotsView } from 'components/SeatBalances/types'
import {
  TagTypes,
  useGetCustomersByAliasByAliasQuery,
  useLazyGetSeatSnapshotsQuery
} from 'features/api'
import { useSelector } from 'features/store'
import { ExtractQueryParams } from 'lib/types'
import { FC } from 'react'

type SeatSnapshotsMagicTableProps = {
  emptyState: (props: MagicTableEmptyStateProps) => React.ReactNode
}

export const SeatSnapshotsMagicTable: FC<SeatSnapshotsMagicTableProps> = ({
  emptyState
}) => {
  const resetKey = useSelector(state => state.apiCaching.tags['SeatSnapshots'])

  return (
    <CurrentUserId>
      {userId => (
        <MagicTable<SeatSnapshotsView, TagTypes>
          entityNamePlural="seat events"
          entityIcon={props => <ArrowTrendingUpIcon {...props} />}
          sequenceUserId={userId}
          resetKey={resetKey}
          useLazyQuery={useLazyGetSeatSnapshotsQuery}
          rowPath={(row: SeatSnapshotsView['model']) =>
            `/seat-events?snapshotId=${row.id}`
          }
          columns={[
            {
              id: 'total',
              accessorKey: 'total',
              header: 'Total Seats',
              cell: value => {
                const total = value.getValue<SeatSnapshotModel['total']>()
                return <MagicTableCell text={total} />
              }
            },
            {
              id: 'customer',
              accessorKey: 'customerAlias',
              header: 'Customer',
              cell: value => (
                <MagicTableAutoLoader<
                  ExtractQueryParams<typeof useGetCustomersByAliasByAliasQuery>,
                  { customerId?: string; customerName?: string }
                >
                  queryParams={{
                    alias: value.getValue<SeatSnapshotModel['customerAlias']>()
                  }}
                  useQuery={useGetCustomersByAliasByAliasQuery}
                  extract={customer => ({
                    customerId: customer?.id,
                    customerName: customer?.legalName
                  })}
                >
                  {({ customerId, customerName }) => {
                    if (!customerId || !customerName) {
                      return (
                        <MagicTableCell
                          text="Alias not mapped to Customer"
                          borderColor={RedRed20}
                          textColor={RedRed50}
                        />
                      )
                    }

                    return (
                      <MagicTableCellLink
                        to={`/customers/${customerId}`}
                        label={customerName}
                      />
                    )
                  }}
                </MagicTableAutoLoader>
              )
            },
            {
              id: 'seatType',
              accessorKey: 'seatType',
              header: 'Seat type',
              cell: value => (
                <MagicTableCellEmpty>
                  <Flex overflow="hidden">
                    <Badge sentiment="neutral">
                      {value.getValue<SeatSnapshotModel['seatType']>()}
                    </Badge>
                  </Flex>
                </MagicTableCellEmpty>
              )
            },
            {
              id: 'id',
              accessorKey: 'id',
              header: 'Event ID',
              cell: value => (
                <MagicTableCell
                  text={value.getValue<SeatSnapshotModel['id']>()}
                />
              )
            },
            {
              id: 'snapshotTimestamp',
              accessorKey: 'snapshotTimestamp',
              header: 'Timestamp',
              cell: value => (
                <MagicTableCell
                  text={value.getValue<
                    SeatSnapshotModel['snapshotTimestamp']
                  >()}
                />
              ),
              enableSorting: true
            }
          ]}
          emptyState={emptyState}
          additionalToolbarComponents={
            <SeatBalancesViewSelector active="snapshots" />
          }
        />
      )}
    </CurrentUserId>
  )
}
