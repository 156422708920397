import { useModalContext } from '@sequencehq/core-components'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useNotifications } from 'lib/hooks/useNotifications'
import { useIntegrationsDomainContext } from 'modules/Integrations/communication'
import { ResolvedIntegration } from 'modules/Integrations/domain'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import invariant from 'tiny-invariant'

type UseIntegrationDetailHeader = (props: {
  integration: ResolvedIntegration
}) => {
  features: {
    manage: {
      label: string
      visible: boolean
      disabled: boolean
      onClick: () => void
      onAuxClick: () => void
    }
    connect: {
      label: string
      visible: boolean
      disabled: boolean
      showSpinner: boolean
      onClick: () => void
    }
    documentation: {
      onClick: () => void
      disabled: boolean
    }
  }
  comingSoon: boolean
}

export const useIntegrationDetailHeader: UseIntegrationDetailHeader = props => {
  const ctx = useIntegrationsDomainContext()
  const navigate = useNavigate()
  const modalCtx = useModalContext()
  const { displayNotification } = useNotifications()
  const flags = useFlags()

  const integration = useMemo(() => {
    return ctx.queries.resolvedIntegrations[props.integration.id]
  }, [ctx.queries.resolvedIntegrations, props.integration.id])

  const integrationFeatures = useMemo(() => {
    const features =
      ctx.queries.availableFeatures.integrations[props.integration.id]

    if (props.integration.id !== 'Salesforce' || flags.useInspector) {
      return features
    }

    return {
      ...features,
      canManage: {
        available: {
          visible: false,
          enabled: false
        }
      }
    }
  }, [
    ctx.queries.availableFeatures.integrations,
    props.integration.id,
    flags.useInspector
  ])

  invariant(integration, 'Integration not found')
  invariant(integrationFeatures, 'Integration features not found')

  const features = useMemo(() => {
    return {
      manage: {
        label: 'Manage integration',
        visible: integrationFeatures.canManage.available.visible,
        disabled: !integrationFeatures.canManage.available.enabled,
        onAuxClick: () => {
          window.open(
            `/integration-management/${props.integration.id}`,
            '_blank'
          )
        },
        onClick: () => {
          navigate(`/integration-management/${props.integration.id}`)
          modalCtx.setIsOpen(false)
        }
      },
      connect: {
        label: ctx.queries.rawData.editor.connecting
          ? `Connecting...`
          : `Connect to ${props.integration.title}`,
        showSpinner: ctx.queries.rawData.editor.connecting,
        visible: integrationFeatures.canConnect?.available.visible ?? false,
        disabled: !integrationFeatures.canConnect?.available.enabled ?? true,
        onClick: async () => {
          const res = await ctx.mutators.external.out.connect(
            props.integration.id
          )
          if (res.success) {
            displayNotification(
              `Connected to ${props.integration.title} successfully`,
              {
                type: 'success'
              }
            )
            navigate(`/integration-management/${props.integration.id}`)
            modalCtx.setIsOpen(false)
            return
          }

          displayNotification(
            `Could not connect to ${props.integration.title}`,
            {
              type: 'error'
            }
          )
        }
      },
      documentation: {
        onClick: () => {
          window.open(props.integration.documentationUrl, '_blank')
        },
        disabled: !props.integration.documentationUrl
      }
    }
  }, [
    ctx.queries.rawData.editor.connecting,
    props.integration.id,
    props.integration.title,
    modalCtx,
    ctx.mutators,
    navigate,
    props.integration.documentationUrl,
    integrationFeatures.canConnect?.available,
    integrationFeatures.canManage.available,
    displayNotification
  ])

  return {
    features,
    comingSoon:
      integration.isComingSoon ||
      (props.integration.id === 'Salesforce' && !flags.useInspector)
  }
}
