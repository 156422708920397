import { type ConfigurationManagementProps } from 'Integrations/domain'
import SpinnerContainer from 'components/Loading/SpinnerContainer'
import { Box, Flex } from '@chakra-ui/react'
import { Card, InputSelectControl } from '@sequencehq/core-components'
import { Lato13Regular, Lato14Bold } from '@sequencehq/design-tokens'
import { useState } from 'react'
import AvalaraTaxCodesCard from './cards/AvalaraTaxCodesCard'
import AvalaraAuthenticationCard from './cards/AvalaraAuthenticationCard'

// Type for a view model that is a mapping between:
// 1. our internal Tax category
// 2. Avalara tax code and tax name (if available)
export type AvalaraTaxCodeMapping = {
  taxCategoryId: string
  taxCategoryName: string
  isDefaultTaxCategory?: boolean
  avalaraTaxCode?: string
  taxName?: string
  pending?: boolean
}

const NEW_AVALARA_MAPPING_PATTERN = 'new-avalara-mapping::'

export type AvalaraTaxCodeMappingByTaxCategoryId = {
  [taxCategoryId: string]: AvalaraTaxCodeMapping
}

export const AvalaraConfigurationManagement = (
  props: ConfigurationManagementProps
) => {
  const loading = false

  const [avalaraCompany, setAvalaraCompany] = useState<string | undefined>()

  const persistedMappings: AvalaraTaxCodeMappingByTaxCategoryId = (
    (props.integration.configuration.avalaraTaxCodeMappings as
      | AvalaraTaxCodeMapping[]
      | undefined) || []
  ).reduce(
    (acc, mapping) => ({
      ...acc,
      [mapping.taxCategoryId]: mapping
    }),
    {}
  )

  const [avalaraTaxCodeMappings, setAvalaraTaxCodeMappings] =
    useState<AvalaraTaxCodeMappingByTaxCategoryId>(persistedMappings)

  if (loading) {
    return <SpinnerContainer />
  }

  const isIntegrationDisabled = !props.integration.enabled

  const updateMappings = (mappings: AvalaraTaxCodeMappingByTaxCategoryId) => {
    // Update local state with new mappings to show changes in the UI
    setAvalaraTaxCodeMappings(mappings)

    // Update to-be-saved configuration with new mappings
    props.updateConfiguration({
      avalaraTaxCodeMappings: Object.values(mappings)
    })
  }

  const createTaxCategoryWithMapping = ({
    taxCategoryName,
    taxName,
    avalaraTaxCode
  }: {
    taxCategoryName: string
    taxName: string
    avalaraTaxCode: string
  }) => {
    const newTaxCategoryId = `${NEW_AVALARA_MAPPING_PATTERN}${crypto.randomUUID()}`

    const updatedMappings = {
      ...avalaraTaxCodeMappings,
      [newTaxCategoryId]: {
        pending: true,
        taxCategoryId: newTaxCategoryId,
        taxCategoryName,
        taxName,
        avalaraTaxCode
      }
    }

    updateMappings(updatedMappings)
  }

  const updateTaxCategoryMapping = ({
    taxCategoryId,
    taxName,
    avalaraTaxCode
  }: {
    taxCategoryId: string
    taxName: string
    avalaraTaxCode: string
  }) => {
    const updatedMappings = {
      ...avalaraTaxCodeMappings,
      [taxCategoryId]: {
        ...avalaraTaxCodeMappings[taxCategoryId],
        taxCategoryId,
        avalaraTaxCode,
        taxName
      }
    }

    updateMappings(updatedMappings)
  }

  const removeAvalaraTaxCodeMapping = (taxCategoryId: string) => {
    const updatedMappings = {
      ...avalaraTaxCodeMappings,
      [taxCategoryId]: {
        ...avalaraTaxCodeMappings[taxCategoryId],
        avalaraTaxCode: undefined,
        taxName: undefined
      }
    }

    updateMappings(updatedMappings)
  }

  return (
    <Flex grow={1} width="100%">
      <Flex flexDirection="column" gap="20px" grow={1} padding="16px">
        <AvalaraAuthenticationCard
          onChange={props.updateConfiguration}
          initialValues={{
            username: props.integration.configuration.username as
              | string
              | undefined,
            licenseKey: props.integration.configuration.licenseKey as
              | string
              | undefined
          }}
        />

        <Card>
          <Box>
            <Box {...Lato14Bold} mb={2}>
              Avalara company
            </Box>
            <Box {...Lato13Regular} mb={2}>
              Link to the corresponding company in Avalara for this merchant.
            </Box>
            <Flex>
              <InputSelectControl
                options={[]}
                initialValue={avalaraCompany}
                onChange={value => {
                  setAvalaraCompany(value)
                }}
                disabled={isIntegrationDisabled}
                placeholder="Select a company..."
              />
            </Flex>
          </Box>
        </Card>

        <AvalaraTaxCodesCard
          disabled={isIntegrationDisabled}
          avalaraTaxCodeMappings={avalaraTaxCodeMappings}
          onCreateTaxCategory={createTaxCategoryWithMapping}
          onUpdateTaxCategory={updateTaxCategoryMapping}
          onRemoveAvalaraTaxCode={removeAvalaraTaxCodeMapping}
        />
      </Flex>
    </Flex>
  )
}
