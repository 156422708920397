import { CreateSeatTypeForm } from 'components/SeatType/CreateSeatTypeForm'
import Sidebar from 'components/Sidebar'
import { useNavigate } from 'react-router-dom'
import { useSeatType } from 'components/SeatType'
import { SeatTypeModel } from '@sequencehq/core-models'
import { useCallback } from 'react'

export const CreateSeatTypeFormDrawer = () => {
  const navigate = useNavigate()
  const { dispatch } = useSeatType()

  const onSuccess = useCallback(
    (seatType: SeatTypeModel) => {
      dispatch({
        type: 'setSeatTypeId',
        seatTypeId: seatType.id
      })
      navigate('..')
    },
    [dispatch, navigate]
  )

  const handleCancel = useCallback(() => {
    navigate('..')
  }, [navigate])

  return (
    <Sidebar>
      <CreateSeatTypeForm onSuccess={onSuccess} handleCancel={handleCancel} />
    </Sidebar>
  )
}
