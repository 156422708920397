import { useForm } from '@sequencehq/utils'
import { required } from '@sequencehq/validation'
import { useProductContext } from 'modules/Products/hooks/useProductContext'
import { useProductRoot } from 'modules/Products/hooks/useProductRoot'
import { useCallback, useMemo, useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { dequal } from 'dequal'
import { useQuery } from 'lib/hooks/useQuery'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'

type Product = {
  name: string
  label?: string
  taxCategoryId?: string
}

export const useEditProduct = () => {
  const flags = useFlags()

  const {
    functions: { editProduct }
  } = useProductRoot()
  const {
    data: { product: productDetails }
  } = useProductContext()
  const { data: taxCategoriesData } = useQuery(
    dashboard20240730Client.getTaxCategories
  )

  const [product, setProduct] = useState<Product>()
  const [showValidationErrors, setShowValidationErrors] = useState(false)

  const taxCategories = useMemo(
    () => taxCategoriesData?.data?.items ?? [],
    [taxCategoriesData?.data?.items]
  )

  const defaultTaxCategory = useMemo(
    () => taxCategories.find(category => category.isDefault),
    [taxCategories]
  )

  const { fields, queries } = useForm<Product>({
    value: {
      label: productDetails.label,
      name: productDetails.name,
      taxCategoryId: productDetails.taxCategoryId ?? defaultTaxCategory?.id
    },
    showValidationErrors,
    fieldConfiguration: [
      {
        property: 'name',
        validation: [required]
      },
      {
        property: 'label',
        validation: []
      },
      {
        property: 'taxCategoryId',
        validation: flags.showNewTaxRatesSettings ? [required] : []
      }
    ],
    onChange: (newData: Product) => {
      setShowValidationErrors(false)
      setProduct(newData)
    }
  })

  const canUpdate = useMemo(() => {
    return product !== undefined && queries.isValid
  }, [product, queries.isValid])

  const onEditProduct = useCallback(() => {
    if (!queries.isValid) {
      setShowValidationErrors(true)
      return
    }

    if (!product) {
      return
    }

    return editProduct(productDetails.id, product)
  }, [editProduct, product, queries.isValid, productDetails.id])

  const enhancedFields = useMemo(() => {
    return {
      ...fields,
      taxCategoryId: {
        ...fields.taxCategoryId,
        hidden: !flags.showNewTaxRatesSettings || taxCategories.length === 0,
        options: taxCategories.map(category => ({
          value: category.id,
          label: category.name
        }))
      }
    }
  }, [fields, flags.showNewTaxRatesSettings, taxCategories])

  /**
   * This is a bit 'more' than we need just to check if the tax category has changed,
   * but I wanted to write out a changeset util and toy with the idea of adding it to `useForm`.
   *
   * Given the form hook takes in the original values and spits out the field configuration (which tracks changes to those values),
   * I feel like it could make sense as something to expose on its `queries` object similarly to validation results.
   */
  const changeset:
    | Record<
        keyof Product,
        {
          oldValue: unknown
          newValue: unknown
        }
      >
    | Record<string, never> = useMemo(() => {
    if (dequal(queries.formData, productDetails)) {
      return {}
    }

    const productKeys = Object.keys(productDetails)

    return Object.entries(queries.formData)
      .filter(
        ([fieldKey, fieldValue]) =>
          productKeys.includes(fieldKey) &&
          fieldValue !== productDetails[fieldKey as keyof Product]
      )
      .reduce((acc, [fieldKey, fieldValue]) => {
        return {
          ...acc,
          [fieldKey]: {
            newValue: fieldValue,
            oldValue: productDetails[fieldKey as keyof Product]
          }
        }
      }, {})
  }, [productDetails, queries.formData])

  return {
    canUpdate,
    changeset,
    fieldsConfig: enhancedFields,
    functions: {
      editProduct: onEditProduct
    }
  }
}
