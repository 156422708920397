import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { BuildingOfficeIcon } from '@heroicons/react/16/solid'
import { Currency } from '@sequencehq/api/utils/commonEnums'
import {
  GreyGrey10,
  GreyGrey20,
  GreyGrey30,
  GreyGrey70,
  GreyGrey80,
  GreyGrey90,
  Lato12Bold,
  Lato13Bold
} from '@sequencehq/design-tokens'
import { useState } from 'react'
import { formatTotal } from 'RevenueRecognition/view/utils/formatTotal'
import { useJournalReportDetail } from './useJournalReportDetail'
import { useSelectedDates } from './selectorHooks'
import Loading from 'components/Loading'
import PageError from 'components/ErrorPages/PageError'
import { useSelectedCurrency } from 'RevenueRecognition/view/utils/useSelectedCurrency'

const HeaderRow = () => (
  /**
   * We use a box shadow here, instead of a border, because the border
   * will 'follow' the original position of the sticky row when scrolling.
   * An outline doesn't, but an outline can only cover all sides at once.
   * That leaves us with a box shadow to save the day.
   */
  <Tr boxShadow={`0 1px 0 ${GreyGrey30}`} borderBottom="none !important">
    <Th width="100%" borderRight={`1px solid ${GreyGrey30}`}>
      Customer
    </Th>
    <Th width="100%" borderRight={`1px solid ${GreyGrey30}`}>
      Product
    </Th>
    <Th width="100%" borderRight={`1px solid ${GreyGrey30}`}>
      Ledger account
    </Th>
    <Th width="153px" borderRight={`1px solid ${GreyGrey30}`}>
      Debit
    </Th>
    <Th width="153px" borderRight={`1px solid ${GreyGrey30}`}>
      Credit
    </Th>
  </Tr>
)

const TableRowGroup = (groupData: {
  currency: Currency
  customer: string
  product: string
  ledgerAccounts: Array<{
    name: string
    debit?: number
    credit?: number
  }>
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const totalRows = groupData.ledgerAccounts.length

  return groupData.ledgerAccounts.map((ledgerAccount, index) => (
    <Tr
      key={index}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        backgroundColor: isHovered ? GreyGrey10 : 'transparent'
      }}
    >
      {index === 0 ? (
        <>
          <Td
            width="100%"
            borderRight={`1px solid ${GreyGrey30}`}
            rowSpan={totalRows}
            verticalAlign="top"
            cursor="default"
          >
            <Flex color={GreyGrey80} {...Lato13Bold} gap="6px">
              <BuildingOfficeIcon width="16px" /> {groupData.customer}
            </Flex>
          </Td>
          <Td
            width="153px"
            color={GreyGrey90}
            borderRight={`1px solid ${GreyGrey30}`}
            rowSpan={totalRows}
            verticalAlign="top"
            cursor="default"
          >
            {groupData.product}
          </Td>
        </>
      ) : null}
      <Td width="100%" borderRight={`1px solid ${GreyGrey30}`} cursor="default">
        <Flex
          height="24px"
          alignItems="center"
          borderRadius="6px"
          backgroundColor={GreyGrey20}
          padding="0 6px"
          {...Lato12Bold}
          color={GreyGrey70}
          width="fit-content"
        >
          {ledgerAccount.name}
        </Flex>
      </Td>
      <Td
        width="153px"
        color={GreyGrey90}
        borderRight={`1px solid ${GreyGrey30}`}
        cursor="default"
      >
        {ledgerAccount.debit
          ? formatTotal({
              currency: groupData.currency,
              value: ledgerAccount.debit
            })
          : null}
      </Td>
      <Td
        width="153px"
        color={GreyGrey90}
        borderRight={`1px solid ${GreyGrey30}`}
        cursor="default"
      >
        {ledgerAccount.credit
          ? formatTotal({
              currency: groupData.currency,
              value: ledgerAccount.credit
            })
          : null}
      </Td>
    </Tr>
  ))
}

const TotalRow = (totals: {
  currency: Currency
  debit: number
  credit: number
  totalRecords: number
}) => (
  /**
   * We use a box shadow here, instead of a border, because the border
   * will 'follow' the original position of the sticky row when scrolling.
   * An outline doesn't, but an outline can only cover all sides at once.
   * That leaves us with a box shadow to save the day.
   */
  <Tr boxShadow={`0 -1px 0 ${GreyGrey30}`} borderTop="none !important">
    <Td width="100%" {...Lato13Bold} color={GreyGrey90} cursor="default">
      {totals.totalRecords !== 1
        ? `${totals.totalRecords} records`
        : '1 record'}
    </Td>
    <Td cursor="default" />
    <Td borderRight={`1px solid ${GreyGrey30}`} cursor="default" />
    <Td width="153px" borderRight={`1px solid ${GreyGrey30}`} cursor="default">
      <Text {...Lato13Bold} color={GreyGrey90}>
        {formatTotal({ currency: totals.currency, value: totals.debit })}
      </Text>
    </Td>
    <Td
      width="153px"
      {...Lato13Bold}
      color={GreyGrey90}
      borderRight={`1px solid ${GreyGrey30}`}
      cursor="default"
    >
      <Text {...Lato13Bold} color={GreyGrey90}>
        {formatTotal({ currency: totals.currency, value: totals.credit })}
      </Text>
    </Td>
  </Tr>
)

export const ExpandedTable = () => {
  const { currency } = useSelectedCurrency()
  const dates = useSelectedDates()
  const { detail, isLoading, error } = useJournalReportDetail({
    currency,
    dates
  })

  if (error) {
    return <PageError />
  }

  if (isLoading || !detail) {
    return <Loading />
  }

  return (
    <TableContainer
      overflowX="auto"
      overflowY="auto"
      /**
       * We need to set a max height, rather than height, to prevent the
       * footer extending all of the way down the page when the table is
       * short!
       */
      maxHeight="100%"
      borderRadius="lg"
      border={`1px solid ${GreyGrey30}`}
    >
      <Table variant="v2" width="100%">
        <Thead position="sticky" insetBlockStart={0}>
          <HeaderRow />
        </Thead>
        <Tbody>
          {detail.groups.map((group, index) => (
            <TableRowGroup
              key={index}
              currency={detail.currency}
              customer={group.customer.name}
              product={group.product.name}
              ledgerAccounts={group.ledgerAccounts}
            />
          ))}
        </Tbody>
        <Tfoot position="sticky" insetBlockEnd={0}>
          <TotalRow
            currency={detail.currency}
            debit={detail.totals.debit}
            credit={detail.totals.credit}
            totalRecords={detail.groups.length}
          />
        </Tfoot>
      </Table>
    </TableContainer>
  )
}
