import { useCallback } from 'react'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Product } from 'Products/types'

type UpdatedProduct = Omit<Product, 'createdAt' | 'updatedAt' | 'id'>

type UseUpdateProduct = () => {
  editProduct: (
    id: string,
    updatedProduct: UpdatedProduct
  ) => Promise<Product | null>
}

export const useUpdateProduct: UseUpdateProduct = () => {
  const flags = useFlags()
  const editProduct = useCallback(
    async (id: string, updatedProduct: UpdatedProduct) => {
      const baseRequest = {
        name: updatedProduct.name,
        label: updatedProduct.label
      }

      const requestBody = flags.showNewTaxRatesSettings
        ? {
            ...baseRequest,
            taxCategoryId: updatedProduct.taxCategoryId
          }
        : baseRequest

      const response = await dashboardv99990101Client.putProduct({
        id,
        body: requestBody
      })

      if (response.error || !response.data) {
        return null
      }

      return response.data
    },
    [flags.showNewTaxRatesSettings]
  )

  return {
    editProduct
  }
}
