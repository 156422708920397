import * as Sentry from '@sentry/react'
import { Currency } from '@sequencehq/core-models'
import formatInTimeZone from 'date-fns-tz/formatInTimeZone'
import { baseApi } from 'features/api/baseApi'
import { useNotifications } from 'lib/hooks/useNotifications'
import { useCallback } from 'react'

type UseInvoicesGridCsvExport = () => {
  download: {
    available: boolean
    disabled: boolean
    onClick: (
      currency: Currency,
      startDate: Date | undefined,
      endDate: Date | undefined
    ) => void
  }
}

export const useInvoicesGridCsvExport: UseInvoicesGridCsvExport = () => {
  const notifications = useNotifications()

  const [getDownload] =
    baseApi.endpoints.getInvoiceGridCsvExport.useLazyQuerySubscription()

  /**
   * Toasts
   */
  const showToastPreparing = useCallback(() => {
    notifications.displayNotification('Preparing download', {
      id: 'download-csv',
      type: 'neutral',
      isClosable: false
    })
  }, [notifications])

  const showToastSuccess = useCallback(() => {
    notifications.displayNotification('Download ready', {
      id: 'download-csv-success',
      type: 'success',
      isClosable: false,
      duration: 3000
    })
  }, [notifications])

  const showToastError = useCallback(() => {
    notifications.displayNotification('CSV download failed', {
      id: 'download-csv-error',
      type: 'error',
      isClosable: false,
      duration: 3000
    })
  }, [notifications])

  /**
   * Helpers
   */
  const downloadBlob = useCallback(
    ({ blob, filename }: { blob: Blob; filename: string }) => {
      const blobUrl = URL.createObjectURL(blob)

      const a = document.createElement('a')
      a.download = filename
      a.href = blobUrl

      // For Firefox https://stackoverflow.com/a/32226068
      document.body.appendChild(a)

      a.click()
      a.remove()
    },
    []
  )

  function formatDate(
    date: Date | undefined,
    format: string
  ): string | undefined {
    try {
      return date
        ? formatInTimeZone(
            Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
            'UTC',
            format
          )
        : undefined
    } catch (e) {
      return undefined
    }
  }

  const downloadCsvFile = useCallback(
    async ({
      currency,
      startDate,
      endDate
    }: {
      currency: Currency
      startDate: Date | undefined
      endDate: Date | undefined
    }) => {
      try {
        const fulfilled = await getDownload({
          currency: currency,
          startDate: formatDate(startDate, 'yyyy-MM-dd'),
          endDate: formatDate(endDate, 'yyyy-MM-dd')
        }).unwrap()

        downloadBlob({
          blob: new Blob([fulfilled], { type: 'text/csv' }),
          filename: 'invoice-totals-export.csv'
        })

        showToastSuccess()
      } catch (e) {
        showToastError()
        Sentry.captureException(e)
      }
    },
    [downloadBlob, getDownload, showToastError, showToastSuccess]
  )

  const downloadCsv = useCallback(
    ({
      currency,
      startDate,
      endDate
    }: {
      currency: Currency
      startDate: Date | undefined
      endDate: Date | undefined
    }) => {
      showToastPreparing()

      void downloadCsvFile({ currency, startDate, endDate })
    },
    [showToastPreparing]
  )

  return {
    download: {
      available: true,
      disabled: false,
      onClick: (currency, startDate, endDate) =>
        downloadCsv({ currency, startDate, endDate })
    }
  }
}
