import { IntegrationServices } from '@sequencehq/api/dist/utils/commonEnums'
import { LinkSalesforceCustomerModal } from './integrationsConfig/salesforce/InspectorItem/LinkSalesforceCustomerModal/LinkSalesforceCustomerModal'
import { FC } from 'react'
export { IntegrationsRoot } from './view/integrations.root'

/**
 * All of these components may be used anywhere in the wider application,
 * but have a dependency on the Root having been mounted, to provide the
 * appropriate context. Integrations is a globally impactful feature, so we
 * expect to add more exportable components to this module over time.
 *
 * We should, when the pattern falls into place, make sure these components are
 * more flexible by allowing for adapter to be swapped out rather than a 'hard'
 * dependency on the above module root. But for now they'll be 'drop in and go' regardless.
 */
export { IntegrationsNavigation } from './view/integrationsNavigation/IntegrationsNavigation'
export { IntegrationManagement } from './view/integrationManagement/IntegrationManagement'
export { IntegrationsBrowserModal } from './view/integrationsBrowserModal/IntegrationsBrowserModal'

/**
 * These components are common 'inspector widgets' associated to the integrations that
 * may be used in various contexts.
 */
export * from './integrationsConfig/stripe/InspectorItem'
export * from './integrationsConfig/xero/InspectorItem'
export * from './integrationsConfig/hubSpot/InspectorItem'
export * from './integrationsConfig/quickBooks/InspectorItem'
export * from './integrationsConfig/salesforce/InspectorItem'

/**
 * These components are the Link/Unlink modals for the relevant integrations. Most of the
 * time, these would be accessed via the inspector item, but we also use them in the
 * customer list view.
 */
export { LinkCustomerModal } from './integrationsConfig/common/LinkEntities/LinkCustomerToService/LinkCustomerModal'
export { UnlinkCustomerModal } from './integrationsConfig/common/LinkEntities/LinkCustomerToService/UnlinkCustomerModal'

export const customCustomerLinkModals: Partial<
  Record<
    IntegrationServices,
    FC<{
      customerId: string
      onLink?: () => void
      onClose?: () => void
    }>
  >
> = {
  Salesforce: LinkSalesforceCustomerModal
}
