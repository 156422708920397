import { FC, ReactElement } from 'react'
import { Box, Flex } from '@chakra-ui/react'
import Spinner from 'components/Loading/Spinner'
import { GreyGrey10 } from '@sequencehq/design-tokens'
import { ExistingEntityLink } from './ExistingEntityLink'
import { LinkEntity } from './LinkEntity'

export const LinkEntityWidget: FC<{
  'data-testid'?: string
  externalEntityLabel: string
  sequenceEntityLabel: string
  serviceName: string
  isLoaded: boolean
  canLink?: boolean
  noLinkFoundText?: string
  linkedAccount: {
    href: string | undefined
    label: string
    canUnlink: boolean
    onUnlink: () => void
  } | null
  linkModal?: ReactElement
  unlinkModal?: ReactElement
}> = props => {
  const LinkModal = props.linkModal
  const UnlinkModal = props.unlinkModal

  return (
    <Box data-testid={props['data-testid']}>
      {props.isLoaded ? (
        <>
          {props.linkedAccount && (
            <ExistingEntityLink
              serviceName={props.serviceName}
              externalEntityLabel={props.externalEntityLabel}
              linkedAccount={{
                href: props.linkedAccount.href,
                canUnlink: props.linkedAccount.canUnlink,
                label: props.linkedAccount.label
              }}
              unlinkModal={UnlinkModal}
            />
          )}
          {!props.linkedAccount && (
            <LinkEntity
              canLink={props.canLink}
              serviceName={props.serviceName}
              externalEntityLabel={props.externalEntityLabel}
              sequenceEntityLabel={props.sequenceEntityLabel}
              linkModal={LinkModal}
              text={props.noLinkFoundText}
            />
          )}
        </>
      ) : (
        <Flex
          alignItems="center"
          justifyContent="center"
          backgroundColor={GreyGrey10}
          borderRadius="8px"
          height="104px"
        >
          <Spinner />
        </Flex>
      )}
    </Box>
  )
}
