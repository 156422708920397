import { XYChartItem } from '@sequencehq/shared-components'
import { useGetInsightsInvoicedRevenueQuery } from 'features/api'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Currency, toCurrencySymbol } from '@sequencehq/core-models'
import {
  useDefaultCurrency,
  useEnabledCurrencies
} from 'components/CurrencySettings/useCurrencies'
import { StringDate } from '@sequencehq/utils'

export type InvoicedRevenueData = XYChartItem & {
  //TODO: apply when api data is available
  breakdown?: {
    current: number
    overdue: number
    paid: number
  }
}
interface DateRange {
  start: StringDate
  end: StringDate
}

export const useInvoicedRevenueWidget = (dateRange: DateRange) => {
  const res = useEnabledCurrencies()
  const enabledCurrencies = useMemo(() => {
    if (res.status === 'SUCCESS') {
      return res.enabledCurrencies
    }
    return []
  }, [res])
  const currencyOptions = useMemo(
    () =>
      enabledCurrencies.map(currency => ({
        value: currency,
        label: currency
      })),
    [enabledCurrencies]
  )

  const [currency, setCurrency] = useState(useDefaultCurrency() || 'GBP')

  const { data, refetch, isFetching } = useGetInsightsInvoicedRevenueQuery({
    currency,
    dateFrom: dateRange.start,
    dateTo: dateRange.end
  })

  const insightsInvoicedRevenue = data?.value()

  const transformedData = useMemo(() => {
    if (!insightsInvoicedRevenue) {
      return []
    }
    return insightsInvoicedRevenue.invoicedRevenueByMonth.map(
      ({ amount, periodStart }) => ({
        value: amount,
        date: periodStart
      })
    )
  }, [insightsInvoicedRevenue])

  const tickValues = useMemo(() => {
    if (transformedData.length === 0) {
      return [0, 0]
    }
    const max = Math.max(...transformedData.map(d => d.value))
    return [0, max / 2, max]
  }, [transformedData])

  const currencyField = {
    value: currency,
    label: currency,
    onChange: (newCurrency: string) => setCurrency(newCurrency as Currency),
    options: currencyOptions
  }

  const tooltipFormat = useCallback(
    (value: number) =>
      `${toCurrencySymbol(currencyField.value)}${Intl.NumberFormat('en-GB', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      }).format(value)}`,
    [currencyField.value]
  )

  useEffect(() => {
    void refetch()
  }, [dateRange, refetch, currency])

  return {
    data: transformedData,
    total: insightsInvoicedRevenue?.totalAmount || 0,
    tickValues,
    tooltipFormat,
    isFetching,
    currencyField
  }
}
