import { UTCDate } from '@date-fns/utc'
import { addAndAccountForDST } from '@sequencehq/utils'
import intervalToDuration from 'date-fns/intervalToDuration'
//eslint-disable-next-line no-restricted-imports
import type { Duration } from 'date-fns'
import formatInTimeZone from 'date-fns-tz/formatInTimeZone'
import { pick } from 'lodash/fp'

export const durationsToDateAdapter = {
  in:
    (baseDate: UTCDate | null | undefined) =>
    (duration: Duration): UTCDate | null | undefined => {
      if (!baseDate) {
        return
      }

      return addAndAccountForDST(duration)(baseDate)
    },
  out:
    (baseDate: UTCDate | null | undefined) =>
    (date: UTCDate | null | undefined): Duration | undefined => {
      if (!baseDate || !date) {
        return
      }

      const basicInterval = intervalToDuration({
        start: new UTCDate(formatInTimeZone(baseDate, 'UTC', 'yyyy-MM-dd')),
        end: new UTCDate(formatInTimeZone(date, 'UTC', 'yyyy-MM-dd'))
      })
      /*       
        Our concept of a 'month' is not _really_ a month - it's the period of time that ends
        at 11:59:59.999 on the previous day. This means our actual 'monthly' durations are
        instead the number of months - 1 day. This is awkward since the number of days is
        different every month, so the basic interval calculation will produce a different duration
        for each start/end month. 

        To accomodate for this, we check to see if the same date is produced by the +1 month -1 day
        equivalent and, if so, return that duration. 
      */

      const sequenceMonthInterval = addAndAccountForDST({
        years: basicInterval.years ?? 0,
        months: (basicInterval.months ?? 0) + 1,
        days: -1
      })(new UTCDate(formatInTimeZone(baseDate, 'UTC', 'yyyy-MM-dd')))

      if (
        intervalToDuration({
          start: sequenceMonthInterval,
          end: addAndAccountForDST(basicInterval)(
            new UTCDate(formatInTimeZone(baseDate, 'UTC', 'yyyy-MM-dd'))
          )
        })?.days === 0
      ) {
        if ((basicInterval.months ?? 0) + 1 === 12) {
          return {
            years: (basicInterval.years ?? 0) + 1,
            months: 0,
            days: -1
          }
        }

        return {
          years: basicInterval.years ?? 0,
          months: (basicInterval.months ?? 0) + 1,
          days: -1
        }
      }

      return pick(['years', 'months', 'days'])(basicInterval)
    }
}
