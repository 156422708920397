import { Box, Flex, Grid, GridItem } from '@chakra-ui/react'
import { IntegrationServices } from '@sequencehq/api/utils/commonEnums'
import {
  Button,
  Modal,
  SelectField,
  useModalContext
} from '@sequencehq/core-components'
import { FC } from 'react'
import {
  BorderRadius6,
  GreyGrey10,
  GreyGrey60,
  GreyGrey70,
  GreyGrey80,
  GreyGrey90,
  IndigoIndigo50,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import Spinner from 'components/Loading/Spinner'
import { useLinkCustomerModal } from './useLinkCustomerModal'

const LinkCustomerModalContent: FC<{
  onLink?: () => void
  onClose?: () => void
  customerId: string
  service: IntegrationServices
}> = props => {
  const hook = useLinkCustomerModal({
    onConfirm: props.onLink,
    onClose: props.onClose,
    customerId: props.customerId,
    service: props.service
  })

  if (!hook.loaded) {
    return (
      <Flex
        height="210px"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Spinner color={GreyGrey70} />
      </Flex>
    )
  }

  return (
    <Grid
      gridTemplateAreas={`
        "header"
        "content"
        "footer"
        `}
      gridTemplateRows="auto 1fr auto"
      overflow="hidden"
      height="100%"
      width="100%"
      data-testid="modal.linkCustomer"
    >
      <GridItem gridArea="header">
        <Modal.Header showCloseButton withBorder>
          Link {hook.customerName} to {hook.serviceName} customer
        </Modal.Header>
      </GridItem>
      <GridItem gridArea="content" overflow="auto">
        <Flex
          padding="20px"
          color={GreyGrey80}
          {...Lato13Regular}
          flexDirection="column"
          gap="16px"
        >
          <SelectField
            {...hook.fields.linkToAccount}
            data-testid="linkToAccount"
            label={`${hook.serviceName} customer`}
            placeholder={`Select ${hook.serviceName} customer`}
            styles={{
              wrapper: { marginBottom: '0' }
            }}
            isDisabled={hook.fields.linkToAccount.options.length === 0}
          />
          {hook.externalAccountDetails && (
            <Grid
              gridTemplateColumns="1fr auto"
              gridColumnGap="8px"
              backgroundColor={GreyGrey10}
              borderRadius={BorderRadius6}
              padding="12px"
            >
              <GridItem {...Lato13Bold} color={GreyGrey90}>
                {hook.externalAccountDetails.name}
              </GridItem>
              <GridItem {...Lato13Bold} color={IndigoIndigo50}>
                <a
                  href={hook.externalAccountDetails.externalUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  View in {hook.serviceName}
                </a>
              </GridItem>
            </Grid>
          )}
        </Flex>
      </GridItem>
      <GridItem gridArea="footer">
        <Modal.Footer withBorder>
          <Box flex={1}></Box>
          <Modal.CloseTrigger>
            <Button variant="secondary">Cancel</Button>
          </Modal.CloseTrigger>
          <Button
            variant="primary"
            onClick={() => {
              void hook.submit.onClick()
            }}
            disabled={hook.submit.disabled}
          >
            {hook.submit.linking ? (
              <Flex gap="8px">
                <Spinner height="16px" width="16px" color={GreyGrey60} />
                Creating Link...
              </Flex>
            ) : (
              'Create link'
            )}
          </Button>
        </Modal.Footer>
      </GridItem>
    </Grid>
  )
}

export const LinkCustomerModal: FC<{
  onLink?: () => void
  onClose?: () => void
  customerId: string
  service: IntegrationServices
}> = props => {
  const modalContext = useModalContext()

  return (
    <Modal unmountOnClose>
      {modalContext.isOpen && <LinkCustomerModalContent {...props} />}
    </Modal>
  )
}
