const settingsPages = [
  { label: 'Users', path: '/settings' },
  { label: 'Team', path: '/settings/team' },
  { label: 'Currencies', path: '/settings/currencies' },
  { label: 'API keys', path: '/settings/api-keys' },
  { label: 'Notifications', path: '/settings/notifications' },
  { label: 'Merchant', path: '/settings/merchant' },
  { label: 'Bank details', path: '/settings/bank-details' },
  { label: 'Tax rates', path: '/settings/tax-rates' },
  { label: 'Invoices', path: '/settings/invoices' }
]

export default settingsPages
