import { useMemo } from 'react'

import { PopoverCustomerInterface } from '@sequencehq/quote-content'

import { useCubeContext } from 'modules/Cube/communication/internal/cube.domain.context'
import { formatApiAddress } from 'modules/Cube/view/layouts/quote/QuoteEditorPreview/previewRenderers.adapters'

type UsePopoverCustomer = () =>
  | (PopoverCustomerInterface & {
      isVisible: true
    })
  | { isVisible: false }

export const usePopoverCustomer: UsePopoverCustomer = () => {
  const context = useCubeContext()

  const customer = useMemo(() => {
    const customerId = context.queries.rawData.data.common.customerId
    if (
      !customerId ||
      !context.queries.rawData.data.quote.customerLegalName ||
      !context.queries.rawData.data.quote.customerAddress
    ) {
      return undefined
    }

    return {
      id: customerId,
      legalName: context.queries.rawData.data.quote.customerLegalName,
      address: context.queries.rawData.data.quote.customerAddress
    }
  }, [
    context.queries.rawData.data.common.customerId,
    context.queries.rawData.data.quote.customerLegalName,
    context.queries.rawData.data.quote.customerAddress
  ])

  if (!customer) {
    return {
      isVisible: false
    }
  }

  return {
    isVisible: true,
    legalName: customer.legalName,
    address: formatApiAddress(customer.address),
    linkGoToCustomer: `/customers/${customer.id}`,
    linkEditCustomer: `customers/${customer.id}/edit`
  }
}
