import { FC, useEffect, useReducer, useRef } from 'react'
import { AttachmentsEmptyState } from './AttachmentsEmptyState'
import {
  ATTACHMENT_ERROR,
  attachmentReducer,
  isServerAsset,
  MAX_FILE_COUNT
} from './attachmentReducer'
import { GreyGrey80, Lato12Regular } from '@sequencehq/design-tokens'
import { Text } from '@chakra-ui/react'
import { ServerAsset } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { isEqual } from 'lodash'
import { AttachmentList } from './AttachmentList'

type Props = {
  initialAttachments?: ServerAsset[]
  onChange: (assets: ServerAsset[]) => void // called each time an upload completes,
  disableModifyAttachments?: false | { reason: string }
}

export const Attachments: FC<Props> = ({
  initialAttachments = null,
  onChange,
  disableModifyAttachments = false
}) => {
  const [state, dispatch] = useReducer(attachmentReducer, {
    error: null,
    attachments: initialAttachments
  })

  const fileInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const serverAssets = state.attachments?.filter(isServerAsset) ?? []
    if (!isEqual(serverAssets, initialAttachments)) {
      onChange(serverAssets)
    }
  }, [state.attachments, initialAttachments, onChange])

  const triggerFileInput = () => {
    fileInputRef.current?.click()
  }

  const handleFilesPicked = () => {
    const files = fileInputRef.current?.files
    if (!files) {
      return
    }
    dispatch({ type: 'USER_PICKED_FILES', files: Array.from(files) })
    fileInputRef.current.value = ''
  }

  return (
    <>
      {!state.attachments?.length ? (
        <AttachmentsEmptyState
          onClickAddAttachment={triggerFileInput}
          disableModifyAttachments={disableModifyAttachments}
        />
      ) : (
        <AttachmentList
          state={state}
          dispatch={dispatch}
          onClickAddAttachment={triggerFileInput}
          disableModifyAttachments={disableModifyAttachments}
        />
      )}

      {state.error && (
        <Text align="center" color={GreyGrey80} {...Lato12Regular}>
          {toUserFriendlyError(state.error)}
        </Text>
      )}
      <input
        type="file"
        style={{ display: 'none' }}
        ref={fileInputRef}
        accept="application/pdf"
        multiple
        onChange={handleFilesPicked}
      />
    </>
  )
}

function toUserFriendlyError(error: ATTACHMENT_ERROR) {
  switch (error) {
    case 'MAX_FILE_COUNT_EXCEEDED':
      return `You can only add up to ${MAX_FILE_COUNT} files`
    default:
      return 'An error occurred'
  }
}
