import { Grid, GridItem } from '@chakra-ui/react'
import { ResolvedIntegration } from 'modules/Integrations/domain'
import { IntegrationDetailHeader } from './IntegrationDetailHeader'

type IntegrationDetailProps = {
  integration: ResolvedIntegration
}

export const IntegrationDetail = (props: IntegrationDetailProps) => {
  const ContentComponent = props.integration.components.detail

  return (
    <Grid gridTemplateRows={'auto 1fr'}>
      <GridItem>
        <IntegrationDetailHeader integration={props.integration} />
      </GridItem>
      <GridItem padding="20px">
        <ContentComponent />
      </GridItem>
    </Grid>
  )
}
