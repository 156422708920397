import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import {
  GreyGrey30,
  GreyGrey50,
  IndigoIndigo50,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { Badge, Button, Pill } from '@sequencehq/core-components'
import AddAvalaraTaxCodeToTaxCategoryModal from 'Integrations/integrationsConfig/avalara/AvalaraConfigurationManagement/modals/AddAvalaraTaxCodeToTaxCategoryModal'
import { PlusIcon } from '@heroicons/react/16/solid'
import { type AvalaraTaxCodeMappingByTaxCategoryId } from 'Integrations/integrationsConfig/avalara/AvalaraConfigurationManagement/AvalaraConfigurationManagement'

interface Props {
  disabled?: boolean
  avalaraTaxCategoryMappings: AvalaraTaxCodeMappingByTaxCategoryId[keyof AvalaraTaxCodeMappingByTaxCategoryId][]
  onUpdateTaxCategory: ({
    taxCategoryId,
    avalaraTaxCode,
    taxName
  }: {
    taxCategoryId: string
    avalaraTaxCode: string
    taxName: string
  }) => void
  onRemoveAvalaraTaxCode: (taxCategoryId: string) => void
}

const AvalaraTaxCodeMappingTable = ({
  disabled,
  avalaraTaxCategoryMappings,
  onUpdateTaxCategory,
  onRemoveAvalaraTaxCode
}: Props) => {
  return (
    <TableContainer
      borderRadius="lg"
      overflow="hidden"
      style={{
        opacity: disabled ? 0.5 : 1,
        pointerEvents: disabled ? 'none' : 'auto'
      }}
      border={`1px solid ${GreyGrey30}`}
      mt={4}
      width="100%"
    >
      <Table
        variant="v2"
        width="100%"
        // tableLayount: 'fixed' which was set by default
        // would cause the inspector to not show up on the page
        style={{
          tableLayout: 'initial'
        }}
      >
        <Thead>
          <Tr>
            <Th width="30%" borderRight={`1px solid ${GreyGrey30}`}>
              Tax category
            </Th>
            <Th width="20%" borderRight={`1px solid ${GreyGrey30}`}>
              Avalara tax code
            </Th>
            <Th>Tax name</Th>
          </Tr>
        </Thead>
        <Tbody>
          {avalaraTaxCategoryMappings.map(mapping => (
            <Tr key={mapping.taxCategoryId}>
              <Td borderRight={`1px solid ${GreyGrey30}`}>
                <Flex justifyContent="space-between" align="center">
                  <Text {...Lato13Bold}>{mapping.taxCategoryName}</Text>{' '}
                  {mapping.isDefaultTaxCategory ? (
                    <Badge sentiment="neutral" size="sm">
                      Default
                    </Badge>
                  ) : null}
                </Flex>
              </Td>
              <Td borderRight={`1px solid ${GreyGrey30}`}>
                {mapping.avalaraTaxCode ? (
                  <Pill
                    interactive
                    onClear={() =>
                      onRemoveAvalaraTaxCode(mapping.taxCategoryId)
                    }
                  >
                    {mapping.avalaraTaxCode}
                  </Pill>
                ) : (
                  <AddAvalaraTaxCodeToTaxCategoryModal
                    onAddTaxCode={({ avalaraTaxCode, taxName }) =>
                      onUpdateTaxCategory({
                        taxCategoryId: mapping.taxCategoryId,
                        avalaraTaxCode,
                        taxName
                      })
                    }
                    trigger={
                      <Button
                        disabled={disabled}
                        variant="unstyled"
                        style={{
                          color: IndigoIndigo50
                        }}
                        leadingIcon={<PlusIcon height="16px" width="16px" />}
                      >
                        Add
                      </Button>
                    }
                  />
                )}
              </Td>
              <Td>
                {!mapping.taxName ? (
                  <Text {...Lato13Regular} color={GreyGrey50}>
                    No tax code selected yet
                  </Text>
                ) : (
                  mapping.taxName
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export default AvalaraTaxCodeMappingTable
