import { useCallback } from 'react'
import { usePostCreditNotesMutation } from 'features/api'
import { CreditNoteModel, Currency } from '@sequencehq/core-models'
import { dashboardv20240509Client } from '@sequencehq/api/dashboard/v20240509'

export const useSaveCreditNote = () => {
  const [postCreditNoteMutator] = usePostCreditNotesMutation()

  const createFromInvoice = useCallback(
    (invoiceId: string) =>
      async (
        currency: Currency,
        customerId: string
      ): Promise<{
        creditNote: CreditNoteModel | null
        success: boolean
      }> => {
        const response = await postCreditNoteMutator({
          createCreditNoteEndpointCreateCreditNoteRequestModel: {
            currency,
            customerId,
            invoiceId
          }
        })

        if ('error' in response) {
          return { creditNote: null, success: false }
        }

        return { creditNote: response.data.value() ?? null, success: true }
      },
    [postCreditNoteMutator]
  )

  const createLineItems = useCallback(
    (creditNoteId: string) =>
      async (
        lineItemIds: string[]
      ): Promise<{
        creditNote: { id: string } | null
        success: boolean
      }> => {
        const response = await dashboardv20240509Client.postCreditNoteLineItems(
          {
            id: creditNoteId,
            body: {
              lineItemIds
            }
          }
        )

        if (response.error) {
          return { creditNote: null, success: false }
        }

        // Request doesn't return a response body
        return { creditNote: { id: creditNoteId }, success: true }
      },
    [dashboardv20240509Client]
  )

  return {
    createFromInvoice,
    createLineItems
  }
}
