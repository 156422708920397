import { Box, Flex, Text } from '@chakra-ui/react'
import { QuestionMarkCircleIcon } from '@heroicons/react/16/solid'
import InformationCircleIcon from '@heroicons/react/16/solid/InformationCircleIcon'
import { InputSelectControl, Tooltip } from '@sequencehq/core-components'
import {
  GreyGrey20,
  GreyGrey70,
  GreyGrey80,
  Lato13Regular,
  Lato14Bold,
  RedRed60
} from '@sequencehq/design-tokens'
import { FormFields } from '@sequencehq/utils'
import { Product } from 'common/drawers/PricingEditor/domain'

type ProductFormFields = FormFields<Product>

type TaxCategoryFieldProps = {
  'data-testid'?: string
  field: ProductFormFields['taxCategoryId'] & { hidden: boolean }
  formChangeset:
    | Record<keyof ProductFormFields, { oldValue: unknown; newValue: unknown }>
    | Record<string, unknown>
}

export const TaxCategoryField = (props: TaxCategoryFieldProps) => {
  return (
    !props.field.hidden && (
      <>
        <Box marginBottom="16px" width="100%">
          {/* Note that we're not using the `InputSelectControlField` component here due to `withFormField` not supporting the newest tooltip designs. */}
          {/* When that's updated, we should switch to that instead so we don't have to manage the label and validation error display here */}
          <Flex
            alignItems="center"
            color={GreyGrey70}
            {...Lato14Bold}
            height="16px"
            marginBottom="8px"
            gap="4px"
          >
            Tax category
            <Tooltip label="The correct tax rate will be applied based on your customer's shipping address. If no tax rate is setup, the price will appear as tax inclusive on invoices.">
              <QuestionMarkCircleIcon height="16px" width="16px" />
            </Tooltip>
          </Flex>

          <InputSelectControl
            data-testid={props['data-testid'] ?? 'taxCategoryField'}
            initialValue={props.field.value ?? ''}
            options={props.field.options}
            onChange={props.field.onChange}
            disabled={props.field.disabled}
            placeholder="Select tax category"
          />

          {Boolean(props.field.validationErrors.length) && (
            <Box
              marginTop="8px"
              key={props.field.validationErrors[0].message}
              {...Lato13Regular}
              color={RedRed60}
            >
              {props.field.validationErrors[0].message}
            </Box>
          )}
        </Box>

        {'taxCategoryId' in props.formChangeset && (
          <Flex
            borderRadius="8px"
            backgroundColor={GreyGrey20}
            padding="12px 16px"
            gap="8px"
          >
            <InformationCircleIcon
              color={GreyGrey80}
              height="16px"
              width="16px"
            />
            <Text color={GreyGrey80}>
              Recalculate draft invoices to apply the latest changes.
            </Text>
          </Flex>
        )}
      </>
    )
  )
}
