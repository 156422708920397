import {
  countries,
  Country,
  State,
  states,
  statesForCountry,
  taxStatusMap
} from '@sequencehq/core-models'
import { useForm } from '@sequencehq/utils'
import { isEmail, required } from '@sequencehq/validation'
import { useCustomersRoot } from 'components/Customers/hooks/useCustomersRoot'
import { Address, CreateCustomer, TaxStatus } from 'components/Customers/types'
import { dequal } from 'dequal'
import { useCallback, useMemo, useState } from 'react'
import { useFetchCustomers } from 'components/Customers/components/drawers/CreateCustomer/hooks/useFetchCustomers'
import { useCustomersContext } from 'components/Customers/hooks/useCustomersContext'
import { useFetchMerchantBySequenceAccountId } from 'components/MerchantSettings/hooks/useFetchMerchantBySequenceAccountId'
import { CustomerContactBillingPreference } from 'CustomerContacts/domain/CustomerContacts.domain.types'

export const useCreateCustomer = () => {
  const { refetchCustomers } = useCustomersContext()
  const {
    functions: { createCustomer }
  } = useCustomersRoot({ refetchCustomers })
  const { customers } = useFetchCustomers()
  const { merchant } = useFetchMerchantBySequenceAccountId()

  const [isValid, setIsValid] = useState<boolean>(true)
  const [customer, setCustomer] = useState<CreateCustomer>()
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [showValidationErrors, setShowValidationErrors] =
    useState<boolean>(false)

  const initialState = useMemo(
    () => ({
      legalName: '',
      email: '',
      taxStatus: 'TAXED' as TaxStatus,
      label: '',
      parentId: '',
      customerAliases: [],
      address: {
        line1: '',
        town: '',
        postcode: '',
        country: merchant?.address.country,
        state: ''
      } as Address,
      addTaxRegistration: false,
      taxCountry: 'GB' as Country,
      contacts: [],
      contactName: ''
    }),
    [merchant]
  )

  const stateOptions: { label: string; value: string }[] = useMemo(() => {
    if (
      !customer?.address ||
      statesForCountry(customer.address.country).length === 0
    ) {
      return []
    }

    return Object.entries(states)
      .filter(([value]) => {
        return statesForCountry(customer.address.country).includes(
          value as State
        )
      })
      .map(([value, label]) => ({
        value,
        label
      }))
  }, [customer])

  const taxStateOptions: { label: string; value: string }[] = useMemo(() => {
    if (!customer || statesForCountry(customer.taxCountry).length === 0) {
      return []
    }

    return Object.entries(states)
      .filter(([value]) => {
        return statesForCountry(customer.taxCountry).includes(value as State)
      })
      .map(([value, label]) => ({
        value,
        label
      }))
  }, [customer])

  const parentCustomerOptions = useMemo(() => {
    if (!customers) {
      return [{ value: '', label: 'Choose a parent account' }]
    }

    const options = customers.filter(item => {
      return !item.organizations.some(org =>
        org.members.some(member => member.id === item.id)
      )
    })

    const formattedOptions = options.map(({ id, legalName }) => ({
      value: id,
      label: legalName
    }))

    return [
      { value: '', label: 'Choose a parent account' },
      ...formattedOptions
    ]
  }, [customers])

  const { fields } = useForm<
    CreateCustomer & { contactName: string; email: string }
  >({
    value: initialState,
    showValidationErrors,
    fieldConfiguration: [
      {
        property: 'legalName',
        validation: [required]
      },
      {
        property: 'email',
        validation: [required, isEmail]
      },
      {
        property: 'label'
      },
      {
        property: 'address.line1',
        validation: [required]
      },
      {
        property: 'address.line2'
      },
      {
        property: 'address.town',
        validation: [required]
      },
      {
        property: 'address.state',
        options: stateOptions,
        validation: stateOptions.length > 0 ? [required] : []
      },
      {
        property: 'address.country',
        options: Object.entries(countries).map(([value, label]) => ({
          value,
          label
        })),
        validation: [required]
      },
      {
        property: 'address.postcode',
        validation: [required]
      },
      {
        property: 'taxStatus',
        options: Object.entries(taxStatusMap).map(([value, label]) => ({
          value,
          label
        })),
        validation: [required]
      },
      {
        property: 'customerAliases'
      },
      {
        property: 'addTaxRegistration'
      },
      {
        property: 'taxCountry',
        options: Object.entries(countries).map(([value, label]) => ({
          value,
          label
        }))
      },
      {
        property: 'taxState',
        options: taxStateOptions,
        validation: taxStateOptions.length > 0 ? [required] : []
      },
      {
        property: 'taxIdentifier'
      },
      {
        property: 'parentId',
        options: parentCustomerOptions
      },
      {
        property: 'contactName',
        validation: [required]
      }
    ],
    onChange: newData => {
      setCustomer({
        ...newData,
        contacts: [contact(newData.contactName, newData.email)]
      })
    },
    onValidationStateChange: setIsValid
  })

  const processedFields = useMemo(() => {
    return {
      ...fields,
      'address.country': {
        ...fields['address.country'],
        showAddressFields: fields['address.country'].value
      },
      'address.state': {
        ...fields['address.state'],
        visible: statesForCountry(fields['address.country'].value).length > 0
      },
      taxState: {
        ...fields['taxState'],
        visible: statesForCountry(fields['taxCountry'].value).length > 0
      },
      customerAliases: {
        ...fields['customerAliases'],
        onAddNew: () =>
          fields.customerAliases.onChange([
            ...fields.customerAliases.value,
            ''
          ]),
        onRemove: (index: number) => {
          const aliasesBefore =
            index !== 0 ? fields.customerAliases.value.slice(0, index) : []
          const aliasesAfter =
            fields.customerAliases.value.slice(index + 1) ?? []
          fields.customerAliases.onChange([...aliasesBefore, ...aliasesAfter])
        }
      },
      addTaxRegistration: {
        ...fields.addTaxRegistration,
        visible: true,
        showTaxRegistrationFields: fields.addTaxRegistration.value !== false
      }
    }
  }, [fields])

  const canCreate = useMemo(() => {
    return customer !== undefined && !dequal(customer, initialState)
  }, [customer, initialState, isValid])

  const onCreateCustomer = useCallback(() => {
    if (!canCreate || !customer) {
      return
    }

    if (!isValid) {
      setShowValidationErrors(true)
      setIsSaving(false)
    } else {
      return createCustomer(customer)
    }
  }, [canCreate, customer, isValid, createCustomer, setShowValidationErrors])

  return {
    fieldsConfig: processedFields,
    functions: {
      createCustomer: onCreateCustomer
    },
    canCreate,
    isSaving,
    setIsSaving
  }
}

function contact(name: string, email: string) {
  return {
    name,
    email,
    billingPreference: 'PRIMARY' as CustomerContactBillingPreference
  }
}
