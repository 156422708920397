import { Currency } from '@sequencehq/api/commonEnums'
import { v1ApiQuote } from 'modules/Cube/communication/external/quotes.api.v1/ports/entityLoaders/useLoadQuote'
import {
  QuoteMutationInterface,
  v1ApiUpdateQuote
} from 'modules/Cube/communication/external/quotes.api.v1/ports/entitySaving/useSaveQuote'
import {
  NEW_DISCOUNT_PREFIX,
  NEW_MINIMUM_PREFIX,
  NEW_PRICE_PREFIX,
  NEW_PRODUCT_PATTERN
} from 'modules/Cube/domain/cube.constants'
import { CubeDomainInterface } from 'modules/Cube/domain/cube.domain'
import {
  CubeReducerState,
  Product,
  QuoteContact
} from 'modules/Cube/domain/cube.domain.types'
import { quoteStatusAdapter } from 'modules/Cube/utils/status.adapter'

const commonFieldsToQuoteRequest = (
  data: CubeReducerState['data']['common']
): Pick<
  v1ApiQuote,
  | 'id'
  | 'title'
  | 'customerId'
  | 'currency'
  | 'quoteNumber'
  | 'status'
  | 'preliminaryBillingStartDate'
> => {
  return {
    id: data.id,
    title: data.title,
    customerId: data.customerId ? data.customerId : undefined,
    currency: data.currency as Currency,
    quoteNumber: data.alias,
    status: quoteStatusAdapter.out(data.status) as v1ApiQuote['status'],
    preliminaryBillingStartDate: data.startDate?.toISOString()
  }
}

export const fieldsToQuoteRequest = (
  data: CubeReducerState['data']['quote']
): Pick<
  QuoteMutationInterface,
  | 'dealType'
  | 'expiryDays'
  | 'contactIds'
  | 'salesforceOpportunityId'
  | 'attachmentAssetIds'
> => {
  return {
    dealType: data.dealType,
    expiryDays: data.expiresIn?.days,
    contactIds: data.contacts.map(contact => contact.contactId),
    salesforceOpportunityId: data.salesforceOpportunityId ?? null,
    attachmentAssetIds: data.attachmentAssets.map(asset => asset.id)
  }
}

export const phasesToQuotePhases = (
  data: CubeReducerState['data']
): v1ApiUpdateQuote['phases'] => {
  return Object.values(data.phases).map(phase => ({
    id: phase.id,
    prices: phase.priceIds.map(priceId => {
      const price = data.prices[priceId]

      const { id, ...rest } = {
        ...price,
        structure: price.structure
      }

      return {
        temporaryId: id.split(NEW_PRICE_PREFIX)[1] ?? id,
        ...rest
      }
    }),
    discounts: Object.values(phase.discountIds).map(discountId => {
      const discount = data.discounts[discountId]

      return {
        id: discountId.split(NEW_DISCOUNT_PREFIX)[1] ?? discountId,
        restrictToPrices: discount.priceIds.map(
          priceId => priceId.split(NEW_PRICE_PREFIX)[1] ?? priceId
        ),
        type: discount.discountCalculationType,
        amount: discount.amount.toString(),
        message: discount.message,
        separateLineItem: true
      }
    }),
    minimums: Object.values(phase.minimumIds).map(minimumId => {
      const minimum = data.minimums[minimumId]
      return {
        id: minimumId.split(NEW_MINIMUM_PREFIX)[1] ?? minimumId,
        restrictToPrices: minimum.scope.priceIds.map(
          priceId => priceId.split(NEW_PRICE_PREFIX)[1] ?? priceId
        ),
        amount: minimum.value
      }
    }),
    duration: phase.duration
      ? {
          years: phase.duration?.years ?? 0,
          months: phase.duration?.months ?? 0
        }
      : undefined
  }))
}

export const extractNewProducts = (
  products: CubeReducerState['data']['products']
): Product[] =>
  Object.values(products).filter(product =>
    product.id.match(NEW_PRODUCT_PATTERN)
  )

export const domainToApi = (
  data: CubeDomainInterface['queries']['rawData']['data']
): QuoteMutationInterface => {
  return {
    ...commonFieldsToQuoteRequest(data.common),
    ...fieldsToQuoteRequest(data.quote),
    phases: phasesToQuotePhases(data),
    presentation: data.presentation,
    products: {
      new: extractNewProducts(data.products)
    }
  }
}

export const quoteContactToContactOption: (contact: QuoteContact) => {
  name: string
  email: string
} = contact => {
  return {
    name: contact.name ? contact.name : contact.email,
    email: contact.name ? contact.email : ''
  }
}
