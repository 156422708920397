import { useMemo } from 'react'
import { FormFieldConfig, useForm } from '@sequencehq/utils'
import { required } from '@sequencehq/validation'
import { usePricingEditorDomainContext } from 'common/drawers/PricingEditor/communication'
import { PricingEditorReducerState } from 'common/drawers/PricingEditor/domain'

type UseExternalLedgerForm = () => {
  fieldsConfig: {
    integrations: (FormFieldConfig<Record<string, string>> & {
      id: string
      label: string
      options: { value: string; label: string }[]
    })[]
  }
}

export const useExternalLedgerForm: UseExternalLedgerForm = () => {
  const pricingEditorContext = usePricingEditorDomainContext()

  const { fields } = useForm<
    PricingEditorReducerState['data']['pricingEditorData']['common']['integrationIds']
  >({
    value:
      pricingEditorContext.queries.rawData.data.pricingEditorData.common
        .integrationIds,
    showValidationErrors:
      pricingEditorContext.queries.rawData.editor.showValidationErrors,
    fieldConfiguration: Object.values(
      pricingEditorContext.queries.rawData.data.integrations
    ).map(integration => ({
      property: integration.id,
      options: integration.options.map(option => ({
        value: option.code,
        label: `${option.name} (${option.code})`
      })),
      validation: [required]
    })),
    disabled:
      !pricingEditorContext.queries.availableFeatures.common.form.available
        .enabled,
    /**
     * Sync the data back to the domain
     */
    onValidationStateChange: isValid => {
      pricingEditorContext.mutators.common.updateEditor({
        valid: isValid
      })
    },
    onChange: newData => {
      pricingEditorContext.mutators.common.updatePricingEditorData({
        common: {
          integrationIds: newData
        }
      })
    }
  })

  const enhancedFieldsConfig = useMemo(() => {
    return Object.entries(fields).map(([integrationId, fieldConfig]) => ({
      ...fieldConfig,
      id: integrationId,
      label: `${integrationId} ledger account`
    }))
  }, [fields])

  return {
    fieldsConfig: {
      integrations: enhancedFieldsConfig
    }
  }
}
