import { Flex, Text } from '@chakra-ui/react'
import { GreyGrey80, Lato14Bold } from '@sequencehq/design-tokens'
import TaxCategoriesTable from 'Settings/view/taxRatesSettings/components/TaxCategoriesTable'
import { Button } from '@sequencehq/core-components'
import { PlusIcon } from '@heroicons/react/16/solid'
import TaxCategoryModal from 'Settings/view/taxRatesSettings/modals/TaxCategoryModal'
import { useCreateTaxCategory } from 'Settings/view/taxRatesSettings/hooks/useCreateTaxCategory'
import { useUpdateTaxCategory } from 'Settings/view/taxRatesSettings/hooks/useUpdateTaxCategory'
import type { TaxCategory } from 'Settings/domain/taxRates.types'
import { useMarkTaxCategoryAsDefault } from 'Settings/view/taxRatesSettings/hooks/useMarkTaxCategoryAsDefault'

interface Props {
  isLoadingTaxCategories: boolean
  taxCategories: TaxCategory[]
}

const TaxCategoriesSection = ({
  taxCategories,
  isLoadingTaxCategories
}: Props) => {
  const createTaxCategory = useCreateTaxCategory()
  const editTaxCategory = useUpdateTaxCategory()
  const markTaxCategoryAsDefault = useMarkTaxCategoryAsDefault()

  return (
    <Flex flexDirection="column" p={4} width="100%">
      <Flex justifyContent="space-between" align="center">
        <Text {...Lato14Bold} color={GreyGrey80}>
          Tax categories
        </Text>
        <TaxCategoryModal
          onSubmit={createTaxCategory}
          trigger={
            <Button
              variant="ghost"
              leadingIcon={
                <PlusIcon width="16px" height="16px" color="inherit" />
              }
            >
              Add tax category
            </Button>
          }
        />
      </Flex>

      <TaxCategoriesTable
        taxCategories={taxCategories}
        isLoading={isLoadingTaxCategories}
        onCreateNewCategory={createTaxCategory}
        onEditCategory={editTaxCategory}
        onMarkAsDefault={taxCategory =>
          void markTaxCategoryAsDefault(taxCategory.id)
        }
      />
    </Flex>
  )
}

export default TaxCategoriesSection
