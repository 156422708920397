import * as Sentry from '@sentry/react'
import { baseApi } from 'features/api/baseApi'
import { useNotifications } from 'lib/hooks/useNotifications'
import { useCallback } from 'react'

type UseInvoicesCsvExport = () => {
  download: {
    available: boolean
    disabled: boolean
    onClick: (queryParams: string) => void
  }
}

export const useInvoicesCsvExport: UseInvoicesCsvExport = () => {
  const notifications = useNotifications()

  const [getDownload] =
    baseApi.endpoints.getInvoiceCsvExport.useLazyQuerySubscription()

  /**
   * Toasts
   */
  const showToastPreparing = useCallback(() => {
    notifications.displayNotification('Preparing download', {
      id: 'download-csv',
      type: 'neutral',
      isClosable: false
    })
  }, [notifications])

  const showToastSuccess = useCallback(() => {
    notifications.displayNotification('Download ready', {
      id: 'download-csv-success',
      type: 'success',
      isClosable: false,
      duration: 3000
    })
  }, [notifications])

  const showToastError = useCallback(() => {
    notifications.displayNotification('CSV download failed', {
      id: 'download-csv-error',
      type: 'error',
      isClosable: false,
      duration: 3000
    })
  }, [notifications])

  /**
   * Helpers
   */
  const downloadBlob = useCallback(
    ({ blob, filename }: { blob: Blob; filename: string }) => {
      const blobUrl = URL.createObjectURL(blob)

      const a = document.createElement('a')
      a.download = filename
      a.href = blobUrl

      // For Firefox https://stackoverflow.com/a/32226068
      document.body.appendChild(a)

      a.click()
      a.remove()
    },
    []
  )

  const downloadCsvFile = useCallback(
    async (queryParams: string) => {
      try {
        const fulfilled = await getDownload(queryParams).unwrap()

        downloadBlob({
          blob: new Blob([fulfilled], { type: 'text/csv' }),
          filename: 'invoice-export.csv'
        })

        showToastSuccess()
      } catch (e) {
        showToastError()
        Sentry.captureException(e)
      }
    },
    [downloadBlob, getDownload, showToastError, showToastSuccess]
  )

  const downloadCsv = useCallback(
    (queryParams: string) => {
      showToastPreparing()

      void downloadCsvFile(queryParams)
    },
    [showToastPreparing]
  )

  return {
    download: {
      available: true,
      disabled: false,
      onClick: queryParams => downloadCsv(queryParams)
    }
  }
}
