import {
  InvoiceModel,
  LineItemGroupModel,
  LineItemModel
} from '@sequencehq/core-models'
import {
  useLazyGetInvoicesByIdLineItemGroupsAllQuery,
  useLazyGetInvoicesByIdLineItemsAllQuery
} from 'features/api'
import { useCallback, useEffect, useMemo, useState } from 'react'
import * as Sentry from '@sentry/react'
import { LoaderError } from 'InvoiceEditor/domainManagement/invoiceEditor.types'

type UseLoadLineItemsAndGroupsData = {
  lineItems: LineItemModel[]
  lineItemGroups: LineItemGroupModel[]
}

type UseLoadLineItemsAndGroups = (props: {
  invoiceId: InvoiceModel['id'] | undefined
  invoice?: InvoiceModel
  async?: boolean
}) => {
  loading: boolean
  data?: UseLoadLineItemsAndGroupsData
  lineItemsError?: LoaderError
  lineItemGroupsError?: LoaderError
  loadLineItemsAndGroups: (props: {
    invoiceId: InvoiceModel['id']
  }) => Promise<{ error?: boolean; data: UseLoadLineItemsAndGroupsData }>
}

export const useLoadLineItemsAndGroups: UseLoadLineItemsAndGroups = ({
  invoiceId,
  invoice,
  async = false
}) => {
  const [
    fetchLineItems,
    { isLoading: isLoadingLineItems, error: lineItemsFetchingError }
  ] = useLazyGetInvoicesByIdLineItemsAllQuery()
  const [
    fetchLineItemGroups,
    { isLoading: isLoadingLineItemGroups, error: lineItemGroupsFetchingError }
  ] = useLazyGetInvoicesByIdLineItemGroupsAllQuery()

  const [lineItems, setLineItems] = useState<LineItemModel[]>([])
  const [lineItemGroups, setLineItemGroups] = useState<LineItemGroupModel[]>([])

  const getLineItems = useCallback(
    async (args: { invoiceId: string }) =>
      fetchLineItems({ id: args.invoiceId }).then(
        response => response.data?.value()?.items ?? []
      ),
    [fetchLineItems]
  )

  const getLineItemGroups = useCallback(
    async (args: { invoiceId: string }) =>
      fetchLineItemGroups({ id: args.invoiceId }).then(
        response => response.data?.value()?.items ?? []
      ),
    [fetchLineItemGroups]
  )

  useEffect(() => {
    if (!invoiceId || async || !invoice) {
      return
    }

    const fetchLineItemAndLineItemGroups = async () => {
      const loadedLineItems = await getLineItems({
        invoiceId
      })

      const loadedLineItemGroups = await getLineItemGroups({
        invoiceId
      })

      setLineItems(loadedLineItems)
      setLineItemGroups(loadedLineItemGroups)
    }

    try {
      void fetchLineItemAndLineItemGroups()
    } catch (err) {
      Sentry.captureException(err)
    }
  }, [getLineItemGroups, getLineItems, invoiceId, async, invoice])

  const data = useMemo(() => {
    if (!lineItems || !lineItemGroups || async) {
      return
    }

    return {
      lineItems,
      lineItemGroups
    }
  }, [lineItems, lineItemGroups, async])

  const loadLineItemsAndGroups = useCallback(
    async (props: {
      invoiceId: InvoiceModel['id']
    }): Promise<{ error?: boolean; data: UseLoadLineItemsAndGroupsData }> => {
      try {
        const loadedLineItems = await getLineItems({
          invoiceId: props.invoiceId
        })

        const loadedLineItemGroups = await getLineItemGroups({
          invoiceId: props.invoiceId
        })

        return {
          data: {
            lineItems: loadedLineItems,
            lineItemGroups: loadedLineItemGroups
          }
        }
      } catch (e) {
        return {
          error: true,
          data: {
            lineItems: [],
            lineItemGroups: []
          }
        }
      }
    },
    [getLineItemGroups, getLineItems]
  )

  return {
    loading: [isLoadingLineItems, isLoadingLineItemGroups].some(Boolean),
    data,
    loadLineItemsAndGroups,
    lineItemGroupsError: lineItemGroupsFetchingError,
    lineItemsError: lineItemsFetchingError
  }
}
