import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { Currency } from '@sequencehq/api/utils/commonEnums'
import {
  GreyGrey20,
  GreyGrey30,
  GreyGrey70,
  GreyGrey90,
  Lato12Bold,
  Lato13Bold
} from '@sequencehq/design-tokens'
import { formatTotal } from 'RevenueRecognition/view/utils/formatTotal'
import { useJournalReportSummary } from './useJournalReportSummary'
import { useSelectedDates } from './selectorHooks'
import Loading from 'components/Loading'
import PageError from 'components/ErrorPages/PageError'
import { useSelectedCurrency } from 'RevenueRecognition/view/utils/useSelectedCurrency'

const HeaderRow = () => (
  <Tr>
    <Th width="100%" borderRight={`1px solid ${GreyGrey30}`}>
      Ledger account
    </Th>
    <Th width="153px" borderRight={`1px solid ${GreyGrey30}`}>
      Debit
    </Th>
    <Th width="153px" borderRight={`1px solid ${GreyGrey30}`}>
      Credit
    </Th>
  </Tr>
)

const TableRow = (rowData: {
  currency: Currency
  ledgerAccount: string
  debit?: number
  credit?: number
}) => (
  <Tr>
    <Td width="100%" borderRight={`1px solid ${GreyGrey30}`} cursor="default">
      <Flex
        height="24px"
        alignItems="center"
        borderRadius="6px"
        backgroundColor={GreyGrey20}
        padding="0 6px"
        {...Lato12Bold}
        color={GreyGrey70}
        width="fit-content"
      >
        {rowData.ledgerAccount}
      </Flex>
    </Td>
    <Td
      width="153px"
      color={GreyGrey90}
      borderRight={`1px solid ${GreyGrey30}`}
      cursor="default"
    >
      {rowData.debit
        ? formatTotal({ currency: rowData.currency, value: rowData.debit })
        : null}
    </Td>
    <Td
      width="153px"
      color={GreyGrey90}
      borderRight={`1px solid ${GreyGrey30}`}
      cursor="default"
    >
      {rowData.credit
        ? formatTotal({ currency: rowData.currency, value: rowData.credit })
        : null}
    </Td>
  </Tr>
)

const TotalRow = (totals: {
  currency: Currency
  debit: number
  credit: number
  totalRecords: number
}) => (
  <Tr>
    <Td
      width="100%"
      {...Lato13Bold}
      color={GreyGrey90}
      borderRight={`1px solid ${GreyGrey30}`}
      cursor="default"
    >
      {totals.totalRecords !== 1
        ? `${totals.totalRecords} records`
        : '1 record'}
    </Td>
    <Td width="153px" borderRight={`1px solid ${GreyGrey30}`} cursor="default">
      <Text {...Lato13Bold} color={GreyGrey90}>
        {formatTotal({ currency: totals.currency, value: totals.debit })}
      </Text>
    </Td>
    <Td
      width="153px"
      {...Lato13Bold}
      color={GreyGrey90}
      borderRight={`1px solid ${GreyGrey30}`}
      cursor="default"
    >
      <Text {...Lato13Bold} color={GreyGrey90}>
        {formatTotal({ currency: totals.currency, value: totals.credit })}
      </Text>
    </Td>
  </Tr>
)

export const CollapsedTable = () => {
  const { currency } = useSelectedCurrency()
  const dates = useSelectedDates()
  const { summary, isLoading, error } = useJournalReportSummary({
    currency,
    dates
  })

  if (error) {
    return <PageError />
  }

  if (!summary || isLoading) {
    return <Loading />
  }

  return (
    <TableContainer
      borderRadius="lg"
      overflow="hidden"
      border={`1px solid ${GreyGrey30}`}
      width="100%"
    >
      <Table variant="v2" width="100%">
        <Thead>
          <HeaderRow />
        </Thead>
        <Tbody>
          {summary.ledgerAccounts.map((ledgerAccount, idx) => (
            <TableRow
              key={idx}
              currency={summary.currency}
              ledgerAccount={ledgerAccount.name}
              debit={ledgerAccount.debit}
              credit={ledgerAccount.credit}
            />
          ))}
          <TotalRow
            currency={summary.currency}
            debit={summary.totals.debit}
            credit={summary.totals.credit}
            totalRecords={summary.ledgerAccounts.length}
          />
        </Tbody>
      </Table>
    </TableContainer>
  )
}
