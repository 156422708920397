import {
  BriefcaseIcon,
  BuildingOfficeIcon,
  CalendarIcon,
  ClockIcon,
  CurrencyPoundIcon,
  PlayCircleIcon,
  PlusCircleIcon,
  UserCircleIcon
} from '@heroicons/react/16/solid'
import {
  ControlContextProvider,
  DateControl,
  MultiSelectControl,
  Pill,
  PillSelectControl,
  PopoverWrapper
} from '@sequencehq/core-components'
import { ValidationResult } from 'modules/Cube/domain/cube.domain.types'
import { FC, ReactNode, useState } from 'react'
import { Currency, Option } from '@sequencehq/core-models'
import { match } from 'ts-pattern'
import { SidebarSelectControl } from 'Cube/view/layouts/quote/QuoteEditorSidebar/widgets/SidebarSelectControl'
import { DashboardApi20240730 } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import {
  durationsMatch as durationsMatchUtil,
  normalizeDuration
} from '@sequencehq/utils'
import { UTCDate } from '@date-fns/utc'
import { formatInTimeZone } from 'date-fns-tz'
import { SidebarSelectControlTrigger } from 'Cube/view/layouts/quote/QuoteEditorSidebar/widgets/SidebarSelectControlTrigger'
import { PopoverCustomer } from 'modules/Cube/view/layouts/quote/QuoteEditorControls/PopoverCustomerCard/PopoverCustomerCard'
import { PopoverRecipients } from './PopoverRecipients/PopoverRecipients'

type CustomerEditorControlType = 'sidebar' | 'pill'

export const CustomerEditorControl: FC<{
  type: CustomerEditorControlType
  options: Option[]
  value: string | undefined
  onChange: (newValue: string) => void
  onCreate: () => void
  disabled: boolean
  validationErrors: ValidationResult[]
}> = ({
  options,
  value,
  onChange,
  onCreate,
  disabled,
  validationErrors,
  type
}) => {
  const props = {
    placeholder: 'Customers',
    createLabel: 'Add Customer',
    initialValue: value,
    icon: <BuildingOfficeIcon width={16} height={16} color="inherit" />,
    popover: <PopoverCustomer />,
    options,
    onChange,
    onCreate,
    disabled
  }

  return match(type)
    .with('pill', () => (
      <PillSelectControl
        {...props}
        labelWhenNoSelection="Customer"
        errorMessage={validationErrors.length ? 'Customer' : undefined}
      />
    ))
    .with('sidebar', () => (
      <SidebarSelectControl
        {...props}
        labelWhenNoSelection="No customer"
        errorMessage={validationErrors.length ? 'No customer' : undefined}
      />
    ))
    .exhaustive()
}

export const RecipientsEditorControl: FC<{
  type: CustomerEditorControlType
  options: Option[]
  value: string[] | undefined
  onChange: (newValue: string[]) => void
  onCreate: () => void
  validationErrors: ValidationResult[]
  disabled: boolean
}> = ({
  type,
  options,
  onChange,
  value,
  onCreate,
  validationErrors,
  disabled
}) => {
  const props = {
    type,
    options,
    onChange,
    disabled,
    validationErrors,
    placeholder: 'Recipients',
    icon: <UserCircleIcon width={16} height={16} color="inherit" />,
    values: value || [],
    onCreate,
    popover: <PopoverRecipients />
  }

  return <RecipientsMultiSelectControl {...props} />
}

export const RecipientsMultiSelectControl: FC<{
  type: CustomerEditorControlType
  onChange: (newValue: string[]) => void
  disabled: boolean
  options: Option[]
  validationErrors: ValidationResult[]
  placeholder: string
  icon: ReactNode
  values: string[]
  onCreate: () => void
  popover?: ReactNode
}> = ({
  type,
  onChange,
  disabled,
  options,
  validationErrors,
  placeholder,
  icon,
  values,
  onCreate,
  popover
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const errorMessage =
    validationErrors && validationErrors.length > 0
      ? 'Select a recipient'
      : undefined

  const selectedLabel = (() => {
    if (values.length === 0) {
      return undefined
    }

    return options.find(option => option.value === values[0])?.label ?? ''
  })()

  const more = values.length < 2 ? undefined : values.length - 1

  const trigger = match(type)
    .with('pill', () => (
      <Pill
        interactive
        disabled={disabled}
        icon={icon}
        error={!!errorMessage}
        more={more}
      >
        {values.length === 0 ? placeholder : selectedLabel}
      </Pill>
    ))
    .with('sidebar', () => (
      <SidebarSelectControlTrigger
        isMenuOpen={isOpen}
        icon={icon}
        labelSelectedOption={values.length === 0 ? undefined : selectedLabel}
        labelWhenNoSelection="No recipients"
        disabled={disabled}
        more={more}
        errorMessage={errorMessage}
      />
    ))
    .exhaustive()

  const placement = match(type)
    .with('pill', () => 'bottom-start' as const)
    .with('sidebar', () => 'bottom-end' as const)
    .exhaustive()

  return (
    <ControlContextProvider
      placement={placement}
      matchWidth={false}
      maxWidth={300}
    >
      <MultiSelectControl
        placeholder="Recipients"
        options={options}
        onChange={onChange}
        trigger={
          popover && !isOpen ? (
            <PopoverWrapper popover={popover}>{trigger}</PopoverWrapper>
          ) : (
            trigger
          )
        }
        onToggle={setIsOpen}
        initialValues={values}
        createLabel="New recipient"
        onCreate={() => {
          onCreate()
          setIsOpen(false)
        }}
        disabled={disabled}
      />
    </ControlContextProvider>
  )
}

export const CurrencyEditorControl: FC<{
  type: CustomerEditorControlType
  options: Option[]
  value: string | undefined
  onChange: (newValue: Currency) => void
  disabled: boolean
  validationErrors: ValidationResult[]
}> = ({ options, value, onChange, disabled, validationErrors, type }) => {
  const props = {
    placeholder: 'Currency',
    initialValue: value,
    onChange: (val: string) => onChange(val as Currency),
    icon: <CurrencyPoundIcon width={16} height={16} color="inherit" />,
    labelWhenNoSelection: 'Currency',
    errorMessage: validationErrors.length ? 'Select a currency' : undefined,
    options,
    disabled
  }

  return match(type)
    .with('pill', () => <PillSelectControl {...props} />)
    .with('sidebar', () => <SidebarSelectControl {...props} />)
    .exhaustive()
}

export const DealTypeEditorControl: FC<{
  type: CustomerEditorControlType
  options: Option[]
  value: string | undefined
  onChange: (newValue: DashboardApi20240730.GetQuote.QuoteDealType) => void
  disabled: boolean
  validationErrors: ValidationResult[]
}> = ({ options, value, onChange, disabled, validationErrors, type }) => {
  const props = {
    placeholder: 'Deal type',
    initialValue: value,
    onChange: (val: string) =>
      onChange(val as DashboardApi20240730.GetQuote.QuoteDealType),
    icon: <BriefcaseIcon width={16} height={16} color="inherit" />,
    labelWhenNoSelection: 'Deal type',
    errorMessage: validationErrors.length ? 'Select a deal type' : undefined,
    disabled,
    options
  }

  return match(type)
    .with('pill', () => <PillSelectControl {...props} />)
    .with('sidebar', () => <SidebarSelectControl {...props} />)
    .exhaustive()
}

export const ExpirySelectControl: FC<{
  type: CustomerEditorControlType
  options: (Option & { diff: Duration })[]
  duration: Duration | undefined
  onChange: (newValue: Duration) => void
  disabled: boolean
  validationErrors: ValidationResult[]
  labelOverride?: string
}> = ({
  duration,
  options,
  onChange,
  disabled,
  validationErrors,
  type,
  labelOverride
}) => {
  const props = {
    label: 'Valid for',
    onChange: (val: Duration | undefined) => onChange(val as Duration),
    icon: <ClockIcon width={16} height={16} color="inherit" />,
    errorMessage: validationErrors.length ? 'Select validity' : undefined,
    type,
    duration,
    options,
    disabled,
    labelOverride
  }

  return <DurationSelectControl {...props} />
}

export const ContractDurationSelectControl: FC<{
  type: CustomerEditorControlType
  options: (Option & { diff: Duration | 'FOREVER' })[]
  duration: Duration | 'FOREVER' | undefined
  onChange: (newValue: Duration | undefined) => void
  disabled: boolean
  validationErrors: ValidationResult[]
}> = ({ type, options, duration, onChange, disabled, validationErrors }) => {
  const props = {
    label: 'Contract Duration',
    icon: <CalendarIcon width={16} height={16} color="inherit" />,
    errorMessage: validationErrors.length ? 'Select a duration' : undefined,
    type,
    onChange,
    duration,
    options,
    disabled
  }

  return <DurationSelectControl {...props} />
}

export const StartDateSelectControl: FC<{
  type: CustomerEditorControlType
  value: UTCDate | undefined
  onChange: (newValue: UTCDate | undefined) => void
  disabled: boolean
  validationErrors: ValidationResult[]
}> = ({ type, value, onChange, disabled, validationErrors }) => {
  const [isOpen, setIsOpen] = useState(false)
  const handleClear = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    onChange(undefined)
  }

  const trigger = match(type)
    .with('pill', () => {
      const errorMessage =
        validationErrors.length > 0 ? 'Start date' : undefined

      return (
        <Pill
          optional={!value}
          interactive
          disabled={disabled}
          icon={
            value ? (
              <PlayCircleIcon width={16} height={16} color="inherit" />
            ) : (
              <PlusCircleIcon width={16} height={16} color="inherit" />
            )
          }
          onClear={value && !disabled ? handleClear : undefined}
          error={!!errorMessage}
        >
          {errorMessage ??
            (value
              ? formatInTimeZone(value, 'UTC', 'd MMMM yyyy')
              : 'Start date')}
        </Pill>
      )
    })
    .with('sidebar', () => (
      <SidebarSelectControlTrigger
        isMenuOpen={isOpen}
        icon={<PlayCircleIcon width={16} height={16} color="inherit" />}
        labelSelectedOption={
          value ? formatInTimeZone(value, 'UTC', 'd MMMM yyyy') : undefined
        }
        labelWhenNoSelection="No start date"
        handleClear={value ? handleClear : undefined}
        disabled={disabled}
        errorMessage={validationErrors.length > 0 ? 'No start date' : undefined}
      />
    ))
    .exhaustive()

  return (
    <DateControl
      onToggle={setIsOpen}
      onChange={onChange}
      initialValue={value}
      trigger={trigger}
      disabled={disabled}
    />
  )
}

/**
 * Duration components
 */

const durationsMatch = (
  a: Duration | 'FOREVER',
  b: Duration | 'FOREVER'
): boolean => {
  if (a === 'FOREVER' && b === 'FOREVER') {
    return true
  }

  if (a === 'FOREVER' || b === 'FOREVER') {
    return false
  }

  return durationsMatchUtil(a, b)
}

const DurationSelectControl: FC<{
  type: CustomerEditorControlType
  options: (Option & { diff: Duration | 'FOREVER' })[]
  duration: Duration | 'FOREVER' | undefined
  onChange: (newValue: Duration | undefined) => void
  label: string
  icon: React.ReactNode
  disabled: boolean
  errorMessage?: string
  labelOverride?: string
}> = ({
  type,
  options,
  duration,
  onChange,
  label,
  icon,
  disabled,
  errorMessage,
  labelOverride
}) => {
  let value: string | undefined = undefined

  if (duration) {
    value = options.find(opt => durationsMatch(opt.diff, duration))?.value
  }

  const finalProps = {
    placeholder: label,
    initialValue: value ?? 'FOREVER',
    options: options,
    onChange: (val: string) => {
      const dur = options.find(opt => opt.value === val)?.diff
      if (!dur) {
        return
      }
      onChange(dur === 'FOREVER' ? undefined : normalizeDuration(dur))
    },
    icon: icon,
    labelWhenNoSelection: label,
    disabled: disabled,
    errorMessage: errorMessage,
    labelOverride
  }

  return match(type)
    .with('pill', () => <PillSelectControl {...finalProps} />)
    .with('sidebar', () => <SidebarSelectControl {...finalProps} />)
    .exhaustive()
}
