import { Box } from '@chakra-ui/react'
import { PillRow } from '@sequencehq/core-components'
import {
  ContractDurationSelectControl,
  CurrencyEditorControl,
  CustomerEditorControl,
  DealTypeEditorControl,
  ExpirySelectControl,
  RecipientsEditorControl,
  StartDateSelectControl
} from 'modules/Cube/view/layouts/quote/QuoteEditorControls/QuoteEditorControls'
import { useQuoteEditorSidebar } from 'Cube/view/layouts/quote/QuoteEditorSidebar/useQuoteEditorSidebar'
import { ModalCreateRecipient } from '../../QuoteEditorControls/ModalCreateRecipient/ModalCreateRecipient'

export const ConfigPills = () => {
  const hook = useQuoteEditorSidebar()

  return (
    <Box
      paddingLeft="55px"
      marginBottom="18px"
      zIndex={100}
      position="relative"
    >
      <PillRow>
        <CustomerEditorControl
          type="pill"
          options={hook.fields.customer.options}
          value={hook.fields.customer.value}
          onChange={hook.fields.customer.onChange}
          onCreate={hook.fields.customer.onAddNew}
          disabled={hook.fields.customer.isDisabled}
          validationErrors={hook.fields.customer.validationErrors}
        />
        {hook.fields.recipients.visible && (
          <RecipientsEditorControl
            type="pill"
            options={hook.fields.recipients.options}
            value={hook.fields.recipients.value}
            onChange={hook.fields.recipients.onChange}
            onCreate={hook.features.createRecipient.onClick}
            validationErrors={hook.fields.recipients.validationErrors}
            disabled={hook.fields.recipients.isDisabled}
          />
        )}
        <CurrencyEditorControl
          type="pill"
          options={hook.fields.currency.options}
          value={hook.fields.currency.value}
          onChange={hook.fields.currency.onChange}
          disabled={hook.fields.currency.isDisabled}
          validationErrors={hook.fields.currency.validationErrors}
        />
        <DealTypeEditorControl
          type="pill"
          options={hook.fields.quoteDealType.options}
          value={hook.fields.quoteDealType.value}
          onChange={hook.fields.quoteDealType.onChange}
          disabled={hook.fields.quoteDealType.isDisabled}
          validationErrors={hook.fields.quoteDealType.validationErrors}
        />
        <ExpirySelectControl
          type="pill"
          options={hook.fields.validFor.durations}
          duration={hook.fields.validFor.value}
          onChange={hook.fields.validFor.onChange}
          disabled={hook.fields.validFor.isDisabled}
          validationErrors={hook.fields.validFor.validationErrors}
          labelOverride={hook.fields.validFor.labelOverride}
        />
        <ContractDurationSelectControl
          type="pill"
          options={hook.fields.contractLength.durations}
          duration={hook.fields.contractLength.value}
          onChange={hook.fields.contractLength.onChange}
          disabled={hook.fields.contractLength.isDisabled}
          validationErrors={hook.fields.contractLength.validationErrors}
        />
        <StartDateSelectControl
          type="pill"
          value={hook.fields.startDate.value}
          onChange={hook.fields.startDate.onChange}
          disabled={hook.fields.startDate.isDisabled}
          validationErrors={hook.fields.startDate.validationErrors}
        />
      </PillRow>

      {hook.modals.createRecipient.active && (
        <ModalCreateRecipient onClose={hook.modals.createRecipient.onClose} />
      )}
    </Box>
  )
}
