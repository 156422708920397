import { CustomerModel } from '@sequencehq/core-models'
import { closeOverlay } from 'features/overlay'
import { useDispatch } from 'features/store'
import { FC, useCallback } from 'react'
import { CustomerContactsRoot } from 'CustomerContacts/index'
import { CustomerContextProvider } from 'Customer/hooks/useCustomerContext'
import { EditCustomerForm } from 'Customer/drawers/EditCustomer/EditCustomerForm'

type EditCustomerModalFormProps = {
  customer: CustomerModel
}

export const EditCustomerModalForm: FC<EditCustomerModalFormProps> = ({
  customer
}) => {
  const dispatch = useDispatch()

  const close = useCallback(() => {
    dispatch(closeOverlay())
  }, [dispatch])

  return (
    <CustomerContextProvider customerId={customer.id}>
      <CustomerContactsRoot customerId={customer.id}>
        <EditCustomerForm onSuccess={close} onClose={close} />
      </CustomerContactsRoot>
    </CustomerContextProvider>
  )
}
