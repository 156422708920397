import { DocumentIcon } from '@heroicons/react/16/solid'
import { Inspector } from '@sequencehq/core-components'
import { GreyGrey70 } from '@sequencehq/design-tokens'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { FC } from 'react'
import { QuoteAttachments } from './QuoteAttachments'

export const AttachmentsInspectorItem: FC = () => {
  const flags = useFlags()

  if (!flags.useAttachments) {
    return null
  }

  return (
    <Inspector.IconItem
      tooltip="Attachments"
      content={
        <Inspector.Content>
          <QuoteAttachments />
        </Inspector.Content>
      }
      icon={<DocumentIcon height="16px" width="16px" color={GreyGrey70} />}
    />
  )
}
