import {
  Button,
  Card,
  TextInputField,
  Banner
} from '@sequencehq/core-components'
import { Box, Flex } from '@chakra-ui/react'
import { GreyWhite, Lato14Bold } from '@sequencehq/design-tokens'
import { Label } from '@sequencehq/forms'
import { useForm } from '@sequencehq/utils'
import { required } from '@sequencehq/validation'
import Spinner from 'components/Loading/Spinner'
import { useState } from 'react'
import { isEmpty } from 'lodash'

type FormValues = {
  username: string | undefined
  licenseKey: string | undefined
}

interface Props {
  initialValues: FormValues
  onChange: ({ username, licenseKey }: FormValues) => void
}

const mockTestConnectionMutation = (
  username?: string,
  licenseKey?: string
): Promise<{ success: boolean; username?: string; licenseKey?: string }> =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve({
        success: true,
        username,
        licenseKey
      })
    }, 2000)
  })

// Mock implementation to be replaced by actual backend impl when ready
// Extracted into a separate hook so it can be taken out later and tested more easily
function useTestAvalaraConnection() {
  const [isTestingConnection, setIsTestingConnection] = useState(false)
  const [testResult, setTestResult] = useState<{ success: boolean } | null>(
    null
  )

  const testConnection = async ({ username, licenseKey }: FormValues) => {
    setIsTestingConnection(true)
    const result = await mockTestConnectionMutation(username, licenseKey)
    setIsTestingConnection(false)

    setTestResult(result)
  }

  return {
    testConnection,
    isTestingConnection,
    testResult
  }
}

const AvalaraAuthenticationCard = ({ initialValues, onChange }: Props) => {
  const { testConnection, isTestingConnection, testResult } =
    useTestAvalaraConnection()

  const { fields } = useForm({
    value: initialValues,
    fieldConfiguration: [
      {
        property: 'username',
        validation: [required]
      },
      {
        property: 'licenseKey',
        validation: [required]
      }
    ],
    onChange
  })

  const fieldsEmpty =
    isEmpty(fields.username.value) || isEmpty(fields.licenseKey.value)

  return (
    <Card>
      <Flex flexDirection="column" gap={4}>
        <Box {...Lato14Bold}>Avalara authentication</Box>
        <Flex gap={2}>
          <Box>
            <Label>Username</Label>
            <TextInputField
              data-1p-ignore
              value={fields.username.value}
              onChange={event => {
                fields.username.onChange(event?.target?.value)
              }}
              validationErrors={fields.username.validationErrors}
              placeholder="bob@example.com"
              styles={{
                wrapper: {
                  marginBottom: 0
                }
              }}
              width="256px"
            />
          </Box>
          <Box>
            <Label>License key</Label>
            <Flex gap={2}>
              <TextInputField
                value={fields.licenseKey.value}
                onChange={event => {
                  fields.licenseKey.onChange(event?.target?.value)
                }}
                validationErrors={fields.licenseKey.validationErrors}
                placeholder="XXXXXX-XXXXXX-XXXXXX-XXXXXX"
                styles={{
                  wrapper: {
                    marginBottom: 0
                  }
                }}
                width="278px"
              />
              <Button
                onClick={() => {
                  void testConnection({
                    username: fields.username.value,
                    licenseKey: fields.licenseKey.value
                  })
                }}
                disabled={isTestingConnection || fieldsEmpty}
                leadingIcon={
                  isTestingConnection ? (
                    <Spinner color={GreyWhite} height="16px" width="16px" />
                  ) : undefined
                }
              >
                {isTestingConnection ? 'Checking...' : 'Test connection'}
              </Button>
            </Flex>
          </Box>
        </Flex>
        {testResult?.success && (
          <Banner variant="success">Connection successful</Banner>
        )}

        {testResult?.success === false && (
          <Banner variant="error">Connection failed</Banner>
        )}
      </Flex>
    </Card>
  )
}

export default AvalaraAuthenticationCard
