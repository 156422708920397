import { useCallback, useMemo } from 'react'
import { useLineItemEditorContext } from 'InvoiceEditor/components/LineItems/drawer/LineItemEditor/domainManagement/useLineItemEditorContext.ts'
import { LineItemEditorLineItem } from 'InvoiceEditor/components/LineItems/drawer/LineItemEditor/domainManagement/useLineItemEditor.ts'
import { Currency, usageBasedPricingTypes } from '@sequencehq/core-models'
import { FormFields, useForm } from '@sequencehq/utils'
import {
  greaterThanEqualTo,
  isNumber,
  lessThanEqualTo,
  required
} from '@sequencehq/validation'

type FormDataType = Omit<LineItemEditorLineItem, 'id'>
type UseLineItemEditorForm = () => {
  data: {
    currency: Currency
  }
  fieldsConfig: FormFields<FormDataType> & {
    quantity: FormFields<FormDataType>['quantity'] & {
      description: string
    }
  }
}

export const useLineItemEditorForm: UseLineItemEditorForm = () => {
  const lineItemEditorContext = useLineItemEditorContext()

  const updateFields = useCallback(
    (updatedFields: Partial<LineItemEditorLineItem>) => {
      lineItemEditorContext.functions.updateLineItem(updatedFields)
    },
    [lineItemEditorContext.functions]
  )

  const { fields } = useForm<FormDataType>({
    value: lineItemEditorContext.data.lineItem,
    showValidationErrors: lineItemEditorContext.editor.showValidationErrors,
    fieldConfiguration: [
      {
        property: 'itemType',
        options: [
          {
            value: 'PRODUCT' as LineItemEditorLineItem['itemType'],
            label: 'Fee'
          },
          {
            value: 'DISCOUNT' as LineItemEditorLineItem['itemType'],
            label: 'Discount'
          }
        ]
      },
      {
        property: 'rateType',
        options: [
          {
            value: 'FIXED' as LineItemEditorLineItem['rateType'],
            label: 'Fixed'
          },
          {
            value: 'PERCENTAGE' as LineItemEditorLineItem['rateType'],
            label: 'Percentage'
          }
        ]
      },
      {
        property: 'title',
        validation: [required]
      },
      {
        property: 'description',
        validation: []
      },
      {
        property: 'quantity',
        validation: [isNumber],
        disabled: ctx => {
          return !!(
            ctx.formData.priceId &&
            usageBasedPricingTypes.includes(
              lineItemEditorContext.data.prices[ctx.formData.priceId]
                ?.pricingType
            )
          )
        }
      },
      {
        property: 'rate',
        validation:
          lineItemEditorContext.data.lineItem.rateType === 'FIXED'
            ? [isNumber]
            : [greaterThanEqualTo(0), lessThanEqualTo(100)]
      },
      {
        property: 'taxRate',
        validation: [isNumber, greaterThanEqualTo(0), lessThanEqualTo(100)]
      }
    ],
    onChange: newData => updateFields(newData),
    onValidationStateChange: lineItemEditorContext.functions.onValidationChange
  })

  const enhancedFields = useMemo(() => {
    return {
      ...fields,
      quantity: {
        ...fields.quantity,
        description: "Usage can't be edited manually"
      }
    }
  }, [fields])

  const currency = useMemo(() => {
    return lineItemEditorContext.data.currency
  }, [lineItemEditorContext.data.currency])

  return {
    data: {
      currency
    },
    fieldsConfig: enhancedFields
  }
}
