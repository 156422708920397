import {
  Dashboardv20240509Api,
  dashboardv20240509Client
} from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { useCallback } from 'react'

export type v1ApiListPrice = Dashboardv20240509Api.GetListPrice.ListPrice

type UseLoadListPrices = () => (props?: { productId?: string }) => Promise<{
  listPrices: v1ApiListPrice[]
}>

export const useLoadListPrices: UseLoadListPrices = () => {
  const load = useCallback(
    async ({ productId }: { productId?: string } = {}) => {
      const listPricesResponse = await dashboardv20240509Client.getListPrices({
        productId,
        limit: 1000
      })

      return {
        listPrices: listPricesResponse.data?.items ?? []
      }
    },
    []
  )

  return load
}
