import { Text } from '@chakra-ui/react'
import { HashtagIcon } from '@heroicons/react/16/solid'
import { SeatBalanceModel, SeatTypeBalanceModel } from '@sequencehq/core-models'
import { GreyGrey60 } from '@sequencehq/design-tokens'
import { dateTimeWithFormat } from '@sequencehq/formatters'
import {
  MagicTable,
  MagicTableCell,
  MagicTableCellLink
} from '@sequencehq/tables'
import MagicTableAutoLoader from 'components/AutoLoader/MagicTableAutoLoader'
import { CurrentUserId } from 'components/CurrentUserId/CurrentUserId'
import EmptyState, { EmptyStateContent } from 'components/Loading/EmptyState'
import { SeatBalancesViewSelector } from 'components/SeatBalances/SeatBalancesViewSelector'
import { useSeatBalanceContext } from 'components/SeatBalances/hooks/useSeatBalanceContext'
import { SeatBalancesView } from 'components/SeatBalances/types.ts'
import {
  TagTypes,
  useGetCustomersByAliasByAliasQuery,
  useLazyGetSeatBalancesQuery
} from 'features/api'
import { useGetMagicTableFilterOptions } from 'lib/magicTableSupport/useGetMagicTableFilterOptions'
import { ExtractQueryParams } from 'lib/types'
import { useMemo } from 'react'
type SeatBalancesMagicTableProps = {
  emptyContent: EmptyStateContent
}

export const SeatBalancesMagicTable = ({
  emptyContent
}: SeatBalancesMagicTableProps) => {
  const seatBalanceContext = useSeatBalanceContext()

  const { fetchCustomerAliasOptions } = useGetMagicTableFilterOptions()

  const resetKey = useMemo(() => {
    return seatBalanceContext.data.resetKey
  }, [seatBalanceContext.data.resetKey])

  return (
    <CurrentUserId>
      {userId => (
        <MagicTable<SeatBalancesView, TagTypes>
          entityNamePlural="seats"
          sequenceUserId={userId}
          useLazyQuery={useLazyGetSeatBalancesQuery}
          resetKey={resetKey}
          getSubRows={row => {
            return row.balances.map(balance => {
              return {
                ...balance,
                id: '',
                sequenceAccountId: '',
                legalName: '',
                customerAliases: [],
                balances: []
              }
            })
          }}
          columns={[
            {
              id: 'customerAliases',
              accessorKey: 'customerAliases',
              header: 'Customer',
              cell: value => {
                return (
                  <MagicTableAutoLoader<
                    ExtractQueryParams<
                      typeof useGetCustomersByAliasByAliasQuery
                    >,
                    { customerId?: string; customerName?: string }
                  >
                    queryParams={{
                      alias:
                        value.getValue<SeatBalanceModel['customerAliases']>()[0]
                    }}
                    useQuery={useGetCustomersByAliasByAliasQuery}
                    extract={customer => ({
                      customerId: customer?.id,
                      customerName: customer?.legalName
                    })}
                  >
                    {({ customerId }) => {
                      if (customerId) {
                        return (
                          <MagicTableCellLink
                            to={`/customers/${customerId}`}
                            label={value.row.original.legalName}
                          />
                        )
                      }

                      return (
                        <MagicTableCell text={value.row.original.legalName} />
                      )
                    }}
                  </MagicTableAutoLoader>
                )
              }
            },
            {
              id: 'seatType',
              accessorKey: 'seatType',
              header: 'Seat metric',
              cell: value => {
                const isSubRow = value.row.depth > 0
                const totalSubRows = value.row.subRows.length
                const hasSubRows = totalSubRows > 0

                if (!hasSubRows && !isSubRow) {
                  return (
                    <MagicTableCell
                      text={value.getValue<SeatTypeBalanceModel['seatType']>()}
                    />
                  )
                }

                if (hasSubRows && !isSubRow) {
                  return (
                    <MagicTableCell
                      text={`${totalSubRows} seat ${
                        totalSubRows > 1 ? 'metrics' : 'metric'
                      }`}
                      textColor={GreyGrey60}
                    />
                  )
                }
                return (
                  <MagicTableCell
                    text={value.getValue<SeatTypeBalanceModel['seatType']>()}
                  />
                )
              }
            },
            {
              id: 'total',
              accessorKey: 'total',
              header: 'Balance',
              cell: value => (
                <MagicTableCell
                  text={String(value.getValue<SeatBalanceModel['total']>())}
                />
              )
            },
            {
              id: 'changeTimestamp',
              accessorKey: 'changeTimestamp',
              header: 'Last Updated',
              cell: value => {
                const changeTimestamp =
                  value.getValue<SeatBalanceModel['changeTimestamp']>()

                return (
                  <MagicTableCell
                    text={dateTimeWithFormat(
                      changeTimestamp,
                      'dd-MM-yyyy ∙ HH:mm:ss'
                    )}
                  />
                )
              }
            }
          ]}
          filters={[
            {
              type: 'multiSelect',
              paramName: 'customerAliases',
              label: 'Customer alias',
              icon: HashtagIcon,
              options: [],
              optionsFunc: fetchCustomerAliasOptions,
              format: (value: string) => <Text>{value}</Text>
            }
          ]}
          emptyState={props => (
            <EmptyState emptyContent={emptyContent} {...props} />
          )}
          additionalToolbarComponents={
            <SeatBalancesViewSelector active="balances" />
          }
        />
      )}
    </CurrentUserId>
  )
}
