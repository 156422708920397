import { FC, useCallback } from 'react'
import {
  Asset,
  attachmentReducer,
  AttachmentReducerAction,
  isUserAddedAttachment,
  isServerAsset,
  MAX_FILE_COUNT
} from './attachmentReducer'
import { GreyGrey40, GreyGrey80, Lato13Bold } from '@sequencehq/design-tokens'
import { Text, Box } from '@chakra-ui/react'
import { match } from 'ts-pattern'
import { Button, Tooltip } from '@sequencehq/core-components'
import { NewAttachment } from './Attachment/NewAttachment'
import { ExistingAttachment } from './Attachment/ExistingAttachment'
import { PlusIcon } from '@heroicons/react/16/solid'

export const AttachmentList: FC<{
  state: ReturnType<typeof attachmentReducer>
  dispatch: React.Dispatch<AttachmentReducerAction>
  onClickAddAttachment: () => void
  disableModifyAttachments?: false | { reason: string }
}> = ({
  state,
  dispatch,
  onClickAddAttachment,
  disableModifyAttachments = false
}) => {
  const maxFileCountReached =
    (state.attachments?.length ?? 0) === MAX_FILE_COUNT
  const addDisabled = maxFileCountReached || !!disableModifyAttachments

  const handleUploadSuccess = useCallback((asset: Asset, clientId: string) => {
    dispatch({
      type: 'UPLOAD_SUCCESS',
      clientId,
      asset
    })
  }, [])

  return (
    <div>
      <Box mb={2} position="relative">
        <Text {...Lato13Bold} color={GreyGrey80}>
          Attachments
        </Text>
        <Box position="absolute" right={0} top={-1.5}>
          <Tooltip
            isDisabled={!addDisabled}
            label={
              disableModifyAttachments
                ? disableModifyAttachments?.reason
                : 'Maximum number of attachments reached'
            }
            placement="top"
          >
            <Button
              variant="ghost"
              disabled={addDisabled}
              icon={
                <PlusIcon
                  width={16}
                  height={16}
                  color={addDisabled ? GreyGrey40 : 'black'}
                />
              }
              aria-label="add attachment"
              size="24px"
              onClick={onClickAddAttachment}
            />
          </Tooltip>
        </Box>
      </Box>
      {state.attachments?.map(attachment =>
        match(attachment)
          .when(isUserAddedAttachment, userAddedAttachment => (
            <NewAttachment
              key={userAddedAttachment.clientId}
              clientId={userAddedAttachment.clientId}
              file={userAddedAttachment.file}
              onUploadSuccess={handleUploadSuccess}
              onClickDelete={() => {
                dispatch({
                  type: 'DELETE_ATTACHMENT',
                  attachment: userAddedAttachment
                })
              }}
            />
          ))
          .when(isServerAsset, serverAsset => (
            <ExistingAttachment
              key={serverAsset.id}
              attachment={serverAsset}
              disableModifyAttachments={disableModifyAttachments}
              onClickDelete={() =>
                dispatch({
                  type: 'DELETE_ATTACHMENT',
                  attachment: serverAsset
                })
              }
            />
          ))
          .exhaustive()
      )}
    </div>
  )
}
