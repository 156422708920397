import { Box } from '@chakra-ui/react'
import { GreyWhite, IndigoIndigo50 } from '@sequencehq/design-tokens'
import { MagicTable, MagicTableCell } from '@sequencehq/tables'
import { UTCDate } from '@date-fns/utc'
import {
  AccountingPeriod,
  useJournalReportTable
} from './useJournalReportTable'

import formatInTimeZone from 'date-fns-tz/formatInTimeZone'
import EmptyState from 'components/Loading/EmptyState'

export const JournalReportTable = () => {
  const { magicTableLoader } = useJournalReportTable()

  return (
    <Box backgroundColor={GreyWhite} height="100%">
      <MagicTable<
        {
          model: AccountingPeriod
          controlFilters: undefined
        },
        string
      >
        entityNamePlural="Journal Entries"
        //@ts-expect-error - This is a highly coupled and weird interface, we just gotta duck type and hope
        useLazyQuery={magicTableLoader}
        rowPath={accountingPeriod =>
          `/revenue-recognition/report-detail?startDate=${accountingPeriod.dates.start}&endDate=${accountingPeriod.dates.endInclusive}`
        }
        emptyState={() => (
          <EmptyState
            emptyContent={{
              title: 'No accounting periods found',
              description:
                'Start using Sequence to see accounting periods listed here',
              docLink:
                'https://docs.sequencehq.com/revenue-recognition/recognising-pre-paid-revenue',
              linkCopy: 'Learn more'
            }}
          />
        )}
        columns={[
          {
            id: 'label',
            accessorFn: ({ dates }) =>
              formatInTimeZone(new UTCDate(dates.start), 'UTC', 'MMMM yyyy'),
            header: 'Journal report',
            cell: cellValue => {
              const formattedDate = cellValue.getValue<string>()
              return (
                <MagicTableCell
                  textColor={IndigoIndigo50}
                  text={formattedDate}
                />
              )
            }
          },
          {
            id: 'period',
            accessorFn: ({ dates }) =>
              `${formatInTimeZone(
                new UTCDate(dates.start),
                'UTC',
                'd MMM'
              )} to ${formatInTimeZone(
                new UTCDate(dates.endInclusive),
                'UTC',
                'd MMM'
              )}`,
            header: 'Period',
            cell: cellValue => {
              const formattedDate = cellValue.getValue<string>()
              return <MagicTableCell text={formattedDate} />
            }
          }
        ]}
      />
    </Box>
  )
}
