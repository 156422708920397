import ArrowTrendingUpIcon from '@heroicons/react/24/outline/ArrowTrendingUpIcon'
import { Badge } from '@sequencehq/core-components'
import { SeatTypeModel } from '@sequencehq/core-models'
import { dateTimeWithFormat } from '@sequencehq/formatters'
import {
  MagicTable,
  MagicTableCell,
  MagicTableCellEmpty
} from '@sequencehq/tables'
import { CurrentUserId } from 'components/CurrentUserId/CurrentUserId'
import { EmptyStateContent } from 'components/Loading/EmptyState'
import SeatTableEmptyState from 'components/SeatBalances/common/SeatTableEmptyState'
import { SeatTypesView } from 'components/SeatTypes/types'
import { TagTypes, useLazyGetSeatTypesQuery } from 'features/api'
import { useSelector } from 'features/store'
import { FC } from 'react'

type SeatTypesMagicTableProps = {
  emptyContent: EmptyStateContent
}

export const SeatTypesMagicTable: FC<SeatTypesMagicTableProps> = ({
  emptyContent
}) => {
  const resetKey = useSelector(state => state.apiCaching.tags['SeatTypes'])

  return (
    <CurrentUserId>
      {userId => (
        <MagicTable<SeatTypesView, TagTypes>
          entityNamePlural="seat metrics"
          entityIcon={props => <ArrowTrendingUpIcon {...props} />}
          sequenceUserId={userId}
          resetKey={resetKey}
          useLazyQuery={useLazyGetSeatTypesQuery}
          columns={[
            {
              id: 'label',
              accessorKey: 'label',
              header: 'Name',
              cell: value => (
                <MagicTableCell
                  text={value.getValue<SeatTypeModel['label']>()}
                />
              )
            },
            {
              id: 'key',
              accessorKey: 'key',
              header: 'Seat type',
              cell: value => (
                <MagicTableCellEmpty>
                  <Badge sentiment="neutral" size="sm">
                    {value.getValue<SeatTypeModel['key']>()}
                  </Badge>
                </MagicTableCellEmpty>
              )
            },
            {
              id: 'createdAt',
              accessorKey: 'createdAt',
              header: 'Created At',
              cell: value => {
                const createdAt = value.getValue<SeatTypeModel['createdAt']>()

                return (
                  <MagicTableCell
                    text={dateTimeWithFormat(
                      createdAt,
                      'dd-MM-yyyy ∙ HH:mm:ss'
                    )}
                  />
                )
              }
            }
          ]}
          emptyState={props => (
            <SeatTableEmptyState emptyContent={emptyContent} {...props} />
          )}
        />
      )}
    </CurrentUserId>
  )
}
