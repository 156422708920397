import { CustomerModel } from '@sequencehq/core-models'
import { LoaderError } from 'InvoiceEditor/domainManagement/invoiceEditor.types'
import { useLazyGetCustomersByIdQuery } from 'features/api'
import { useCallback, useEffect, useMemo, useState } from 'react'

type UseLoadCustomer = (customerId: string | undefined) => {
  loading: boolean
  error?: LoaderError
  data?: {
    customer: CustomerModel
  }
}

export const useLoadCustomer: UseLoadCustomer = customerId => {
  const [customer, setCustomer] = useState<CustomerModel | undefined>()
  const [fetchCustomer, { isLoading, error }] = useLazyGetCustomersByIdQuery()

  const loadCustomer = useCallback(
    async (id: string) =>
      fetchCustomer({ id }).then(response => response.data?.value()),
    [fetchCustomer]
  )

  useEffect(() => {
    if (customerId) {
      loadCustomer(customerId)
        .then(loadedCustomer => {
          setCustomer(loadedCustomer)
        })
        .catch(e => {
          console.log(e)
        })
    }
  }, [loadCustomer, customerId])

  const data = useMemo(() => {
    if (isLoading || !customer) {
      return
    }

    return {
      customer
    }
  }, [isLoading, customer])

  return {
    loading: isLoading,
    error,
    data
  }
}
