import EditTimeTravellerForm from 'components/EditTimeTraveller/EditTimeTravellerForm'
import {
  EditTimeTravellerFormMutation,
  EditTimeTravellerFormProps
} from 'components/EditTimeTraveller/types'
import { createFormModal } from 'components/Form/createFormModal'
import { usePutTimeTravellersByIdMutation } from 'features/api'

const EditTimeTravellerModal = createFormModal<
  EditTimeTravellerFormMutation,
  EditTimeTravellerFormProps
>({
  FormComponent: EditTimeTravellerForm,
  useMutation: usePutTimeTravellersByIdMutation,
  modalTitle: 'Travel forwards to',
  successMessage: 'Time travel has begun',
  navigateTo: () => `/billing-schedules`,
  submitTitle: 'Begin time travel 🛸',
  formId: 'editTimeTravellerForm',
  overrideSubmitButton: true
})

export default EditTimeTravellerModal
