import { SeatTypeModel } from '@sequencehq/core-models'
import { usePricingEditorContext } from 'modules/Cube/view/common/drawers/priceEditor/drawer/hooks/usePricingEditorContext'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

export const useCreateSeatTypeOutletConnector = () => {
  const navigate = useNavigate()
  const pricingEditorContext = usePricingEditorContext()

  const handleSucess = useCallback(
    (seatType: SeatTypeModel) => {
      pricingEditorContext.functions.updateFormData({
        SEAT_BASED_LINEAR: {
          seatTypeId: seatType.id
        }
      })
      navigate('..')
    },
    [navigate, pricingEditorContext.functions]
  )

  const handleCancel = useCallback(() => {
    navigate('..')
  }, [navigate])

  return {
    onSuccess: handleSucess,
    onCancel: handleCancel
  }
}
