import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { useModalContext } from '@sequencehq/core-components'
import { FormFields, useForm } from '@sequencehq/utils'
import { required } from '@sequencehq/validation'
import { getIntegrationName } from 'Integrations/utils/getIntegrationName'
import { useNotifications } from 'lib/hooks/useNotifications'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

interface LoadedData {
  customer: DashboardApi20240730.GetCustomer.Customer | null
}

enum Status {
  UNINITIALIZED = 'UNINITIALIZED',
  LOADING = 'LOADING',
  LINKING = 'LINKING',
  LOADED = 'LOADED',
  VALIDATING = 'VALIDATING'
}

type FormData = {
  linkToAccount: string
}

type UseLinkSalesforceCustomerModal = (props: {
  onConfirm?: () => void
  onClose?: () => void
  customerId: string
}) => {
  fields: FormFields<FormData>
  customerName: string
  serviceName: string
  loaded: boolean
  externalDetails: {
    fetching: boolean
    show: boolean
    valid: boolean
    name?: string
  }
  submit: {
    disabled: boolean
    linking: boolean
    onClick: () => void
  }
}

const salesforceUrlRegexp = /r\/Account\/(.*)\/view$/

export const useLinkSalesforceCustomerModal: UseLinkSalesforceCustomerModal =
  props => {
    const [status, setStatus] = useState<Status>(Status.UNINITIALIZED)
    const modalContext = useModalContext()
    const [checkResult, setCheckResult] = useState<{
      value: string
      isValid: boolean
      name?: string
    } | null>(null)
    const { displayNotification } = useNotifications()
    const [loadedData, setLoadedData] = useState<LoadedData>({
      customer: null
    })

    /**
     * Grab the current data, for this service, from the customer object.
     */
    const selectedOption = useMemo(() => {
      return (loadedData.customer?.integrationIds ?? []).find(
        int => int.service === 'Salesforce'
      )
    }, [loadedData])

    const serviceName = useMemo(() => {
      return getIntegrationName('Salesforce')
    }, [])

    const isValidId = useCallback(
      (value: unknown) => {
        if (typeof value !== 'string') {
          return 'Format does not match a Salesforce ID or URL'
        }

        if (checkResult?.isValid) {
          return undefined
        }

        return 'Failed to load account'
      },
      [checkResult]
    )

    const defaultFormValues = useMemo(() => {
      return {
        linkToAccount: selectedOption?.id ?? ''
      }
    }, [selectedOption?.id])

    const form = useForm<FormData>({
      value: defaultFormValues,
      showValidationErrors: Boolean(checkResult),
      fieldConfiguration: [
        {
          property: 'linkToAccount',
          validation: [required, isValidId]
        }
      ]
    })

    /**
     * Parse the ID from the URL, if it is a URL.
     */
    const parsedId = useMemo(() => {
      return (
        form.fields.linkToAccount.value.match(salesforceUrlRegexp)?.[1] ??
        form.fields.linkToAccount.value
      )
    }, [form.fields.linkToAccount.value])

    /**
     * API interactions
     */

    /**
     * A simple load function which will grab the customer and the integration
     * customers for the given service.
     */
    const load = useCallback(async ({ customerId }: { customerId: string }) => {
      setStatus(Status.LOADING)

      const customer = await dashboard20240730Client.getCustomer({
        id: customerId
      })

      setLoadedData({
        customer: customer.data ?? null
      })

      setStatus(Status.LOADED)
    }, [])

    /**
     * Link the customer to an account on the third party service.
     */
    const linkAccount = useCallback(async (): Promise<boolean> => {
      if (status === Status.LINKING || !loadedData.customer) {
        return false
      }

      setStatus(Status.LINKING)
      const res = await dashboard20240730Client.putCustomer({
        id: props.customerId,
        body: {
          ...loadedData.customer,
          contacts: loadedData.customer.contacts.map(contact => ({
            email: contact.email,
            name: contact.name ?? '',
            billingPreference: contact.billingPreference
          })),
          integrationIds: [
            ...(loadedData.customer?.integrationIds ?? []).filter(
              int => int.service !== 'Salesforce'
            ),
            {
              service: 'Salesforce',
              id: parsedId
            }
          ]
        }
      })

      if (res.error) {
        displayNotification(`Could not link customer to ${serviceName}`, {
          type: 'error'
        })
        setStatus(Status.LOADED)
        return false
      }

      displayNotification(`Customer linked to ${serviceName}`, {
        type: 'success'
      })
      setStatus(Status.LOADED)
      return true
    }, [
      loadedData,
      props.customerId,
      status,
      displayNotification,
      serviceName,
      parsedId
    ])

    const validateId = useDebouncedCallback(
      async (externalId: string): Promise<boolean> => {
        setCheckResult(null)
        setStatus(Status.VALIDATING)

        const res = await dashboard20240730Client.postValidateExternalEntity({
          id: externalId,
          entityType: 'ACCOUNT',
          service: 'Salesforce'
        })

        setStatus(Status.LOADED)
        setCheckResult({
          isValid: res.data?.isValid ?? false,
          value: externalId,
          name: res.data?.name
        })

        return !res.error
      },
      500
    )

    const submit = useMemo(() => {
      return {
        disabled: !form.queries.isValid || status === Status.LINKING,
        linking: status === Status.LINKING,
        onClick: async () => {
          const success = await linkAccount()
          if (success) {
            modalContext.setIsOpen(false)
            props.onConfirm?.()
          }
        }
      }
    }, [form.queries.isValid, linkAccount, modalContext, props, status])

    /**
     * Side effects
     */
    useEffect(() => {
      void load({ customerId: props.customerId })
    }, [props.customerId, load])

    useEffect(() => {
      /**
       * This hook is designed to manage the validation of our current ID. We store
       * the last result from our validation in the `checkResult` state. If the value of
       * the field changes, we want to clear that state and prepare a for a new validation
       * call.
       */
      if (parsedId === checkResult?.value || !parsedId) {
        return
      }

      if (parsedId !== checkResult?.value) {
        setCheckResult(null)
      }

      void validateId(parsedId)
    }, [parsedId, validateId, checkResult?.isValid, checkResult?.value])

    const externalDetails = useMemo(() => {
      const fetching = status === Status.VALIDATING

      return {
        fetching: status === Status.VALIDATING,
        show: (fetching || checkResult?.isValid) ?? false,
        valid: checkResult?.isValid ?? false,
        name: checkResult?.name ?? ''
      }
    }, [checkResult, status])

    return {
      loaded: ![Status.LOADING, Status.LOADING, Status.UNINITIALIZED].includes(
        status
      ),
      fields: form.fields,
      externalDetails,
      serviceName,
      customerName: loadedData.customer?.legalName ?? '',
      submit
    }
  }
