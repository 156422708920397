import type {
  Calendar,
  GetBackForwardPropsOptions,
  GetDatePropsOptions
} from 'dayzed'
import { FC, MutableRefObject } from 'react'
import { CalendarHeader } from 'components/FormInputs/Dates/CalendarHeader'
import { monthNamesShort, weekdayNamesShort } from '@sequencehq/core-models'
import { Box, Flex, SimpleGrid, Text } from '@chakra-ui/react'
import { DateButton } from 'components/FormInputs/Dates/DateButton'
import format from 'date-fns-tz/format'
import { match } from 'ts-pattern'
import { isValidDate } from '@sequencehq/validation'
import { getToday } from 'components/FormInputs/Dates/getToday'
import add from 'date-fns/add'

type CalendarViewProps = {
  calendar: Calendar
  getDateProps: (data: GetDatePropsOptions) => Record<string, unknown>
  getBackProps: (data: GetBackForwardPropsOptions) => Record<string, unknown>
  getForwardProps: (data: GetBackForwardPropsOptions) => Record<string, unknown>
  initialRef?: MutableRefObject<HTMLButtonElement | null>
} & RangeProps

type RangeProps =
  | {
      type: 'single'
      selectedDate: Date | undefined
    }
  | {
      type: 'range'
      selectedDates: [Date | undefined, Date | undefined]
      focusedInput: 'start' | 'end'
    }

export const CalendarView: FC<CalendarViewProps> = ({
  calendar,
  getDateProps,
  getBackProps,
  getForwardProps,
  initialRef,
  ...rest
}) => (
  <Flex flexDirection="column" width="max-content" height="320px">
    <CalendarHeader
      getForwardProps={getForwardProps}
      getBackProps={getBackProps}
      calendar={calendar}
      monthLabel={`${monthNamesShort[calendar.month]} ${calendar.year}`}
    />
    <SimpleGrid columns={7} spacingY="1px" textAlign="center">
      {weekdayNamesShort.map(weekday => (
        <Text
          key={weekday}
          color="gray.60"
          fontSize="12px"
          lineHeight="16px"
          py={4}
        >
          {weekday}
        </Text>
      ))}
      {calendar.weeks.map(week =>
        week.map((dateObj, index) => {
          if (typeof dateObj === 'string') {
            return <Box key={index} outline="none" />
          }

          const { date, selectable, selected, today } = dateObj

          return (
            <DateButton
              key={format(date, 'yyyy-MM-dd')}
              initialRef={initialRef}
              selected={selected}
              selectableDate={selectable}
              today={today}
              label={dateObj.date.getDate()}
              dateProps={{
                ...getDateProps({
                  dateObj
                })
              }}
              {...getRangeProps({
                ...rest,
                date,
                selected,
                today
              })}
            />
          )
        })
      )}
    </SimpleGrid>
  </Flex>
)

type GetRangeProps = {
  selected: boolean
  date: Date
  today: boolean
} & RangeProps

const getRangeProps = ({ selected, date, today, ...rest }: GetRangeProps) => {
  if (rest.type === 'single') {
    return
  }

  const { selectedDates, focusedInput } = rest

  return {
    position: getPosition({ selected, date, selectedDates }),
    hasInitialFocus: hasInitialFocus({
      today,
      date,
      selectedDates,
      focusedInput
    }),
    inRange: Boolean(isInRange({ date, selectedDates })),
    focusedInput
  }
}

type GetPositionArgs = {
  selected: boolean
  date: Date
  selectedDates: [Date | undefined, Date | undefined]
}

const getPosition = ({
  selected,
  date,
  selectedDates
}: GetPositionArgs): 'start' | 'end' | undefined => {
  if (!selected) {
    return undefined
  }

  if (
    isValidDate(selectedDates[0]) &&
    isValidDate(selectedDates[1]) &&
    date.getTime() === selectedDates[0].getTime()
  ) {
    return 'start'
  }

  if (
    isValidDate(selectedDates[0]) &&
    isValidDate(selectedDates[1]) &&
    date.getTime() === selectedDates[1].getTime()
  ) {
    return 'end'
  }
}

type IsInRangeArgs = {
  date: Date
  selectedDates: [Date | undefined, Date | undefined]
}

const isInRange = ({ date, selectedDates }: IsInRangeArgs) => {
  if (!isValidDate(selectedDates[0]) || !isValidDate(selectedDates[1])) {
    return false
  }

  const firstSelected = selectedDates[0].getTime()
  const secondSelected = selectedDates[1].getTime()

  return firstSelected < date.getTime() && secondSelected > date.getTime()
}

type HasInitialFocusArgs = {
  today: boolean
  date: Date
  selectedDates: [Date | undefined, Date | undefined]
  focusedInput?: 'start' | 'end' | undefined
}

const hasInitialFocus = ({
  today,
  date,
  selectedDates,
  focusedInput
}: HasInitialFocusArgs) => {
  return match(focusedInput)
    .with('start', () => {
      if (isValidDate(selectedDates[0])) {
        return date.getTime() === selectedDates[0].getTime()
      } else if (isValidDate(selectedDates[1])) {
        return date.getTime() === add(selectedDates[1], { days: -1 }).getTime()
      } else {
        return today
      }
    })
    .with('end', () => {
      if (isValidDate(selectedDates[1])) {
        return date.getTime() === selectedDates[1].getTime()
      } else if (isValidDate(selectedDates[0])) {
        return date.getTime() === add(selectedDates[0], { days: 0 }).getTime()
      } else {
        return date.getTime() === getToday().getTime()
      }
    })
    .otherwise(() => false)
}
