import { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useInvoiceEditorOutletContext } from 'InvoiceEditor/InvoiceEditorOutletProvider.tsx'
import { lineItemEditorAdapter } from 'InvoiceEditor/components/LineItems/drawer/LineItemEditor/domainManagement/lineItemEditorAdapter.ts'
import { LineItemEditorLineItem } from 'InvoiceEditor/components/LineItems/drawer/LineItemEditor/domainManagement/useLineItemEditor.ts'
import { LineItemEditorRootProps } from 'InvoiceEditor/components/LineItems/drawer/LineItemEditor/components/LineItemEditorRoot.tsx'

type UseLineItemEditorOutletConnector = () => LineItemEditorRootProps

export const useLineItemEditorOutletConnector: UseLineItemEditorOutletConnector =
  () => {
    const { lineItemGroupId, lineItemId } = useParams<{
      lineItemGroupId: string
      lineItemId: string
    }>()
    const navigate = useNavigate()

    if (!lineItemGroupId) {
      navigate('..')
    }

    const invoiceEditorContext = useInvoiceEditorOutletContext()

    const lineItemGroup = useMemo(() => {
      if (!lineItemGroupId) {
        return
      }

      const existingGroup =
        invoiceEditorContext.data.lineItemGroups[lineItemGroupId]

      if (!existingGroup) {
        return
      }

      return lineItemEditorAdapter.in.lineItemGroup(existingGroup)
    }, [lineItemGroupId, invoiceEditorContext.data.lineItemGroups])

    const existingLineItem = useMemo(() => {
      if (!lineItemId) {
        return
      }

      const lineItem = invoiceEditorContext.data.lineItems[lineItemId]

      if (!lineItem) {
        return
      }

      return lineItemEditorAdapter.in.lineItem(lineItem)
    }, [lineItemId, invoiceEditorContext.data.lineItems])

    const mode = useMemo(() => {
      if (existingLineItem) {
        return 'EDIT'
      }

      return 'CREATE'
    }, [existingLineItem])

    const currency = useMemo(() => {
      return invoiceEditorContext.data.invoice.currency ?? 'GBP'
    }, [invoiceEditorContext.data.invoice.currency])

    const customer = useMemo(() => {
      return invoiceEditorContext.data.customer
    }, [invoiceEditorContext.data.customer])

    const onClose = useCallback(() => {
      navigate('..')
    }, [navigate])

    const onCreate = useCallback(
      (lineItem: LineItemEditorLineItem) => {
        if (!lineItemGroupId) {
          return
        }

        const apiLineItem = lineItemEditorAdapter.out(lineItem)

        invoiceEditorContext.functions.createLineItem(
          lineItemGroupId,
          lineItem.id,
          {
            ...invoiceEditorContext.data,
            lineItems: {
              ...invoiceEditorContext.data.lineItems,
              [apiLineItem.id]: apiLineItem
            }
          }
        )
        navigate('..')
      },
      [
        lineItemGroupId,
        invoiceEditorContext.data,
        invoiceEditorContext.functions,
        navigate
      ]
    )

    const onUpdate = useCallback(
      (lineItem: LineItemEditorLineItem) => {
        if (!lineItemGroupId || !lineItemId) {
          return
        }

        const apiLineItem = lineItemEditorAdapter.out(lineItem)
        const updatedLineItems = {
          ...invoiceEditorContext.data.lineItems,
          [apiLineItem.id]: apiLineItem
        }

        invoiceEditorContext.functions.updateLineItem(
          lineItemGroupId,
          lineItemId,
          {
            ...invoiceEditorContext.data,
            lineItems: updatedLineItems
          }
        )
        navigate('..')
      },
      [
        lineItemGroupId,
        lineItemId,
        invoiceEditorContext.data,
        invoiceEditorContext.functions,
        navigate
      ]
    )

    const onDelete = useCallback(() => {
      if (!lineItemGroupId || !lineItemId) {
        return
      }

      const { [lineItemId]: deletedLineItem, ...remainingLineItems } =
        invoiceEditorContext.data.lineItems

      invoiceEditorContext.functions.updateData({
        lineItems: remainingLineItems
      })

      invoiceEditorContext.functions.deleteLineItem(
        lineItemGroupId,
        lineItemId,
        {
          ...invoiceEditorContext.data,
          lineItems: remainingLineItems
        }
      )
    }, [
      lineItemGroupId,
      lineItemId,
      invoiceEditorContext.data,
      invoiceEditorContext.functions
    ])

    return {
      lineItemGroup,
      existingLineItem,
      currency,
      customer,
      mode,
      onClose,
      onDelete,
      onCreate,
      onUpdate
    }
  }
