import { Box } from '@chakra-ui/react'
import { TextField } from '@sequencehq/core-components'
import { GreyGrey50, GreyGrey80, Lato28Bold } from '@sequencehq/design-tokens'
import { useTitleEditor } from 'modules/Cube/view/layouts/quote/QuoteEditorContent/TitleEditor/useTitleEditor'

export const TitleEditor = () => {
  const hook = useTitleEditor()

  return (
    <Box marginBottom="4px" paddingX="40px">
      <TextField
        border="none"
        boxShadow="none"
        {...Lato28Bold}
        _focus={{
          border: 'none',
          boxShadow: 'none'
        }}
        _focusVisible={{
          border: 'none',
          boxShadow: 'none'
        }}
        _active={{
          border: 'none',
          boxShadow: 'none'
        }}
        _hover={{ boxShadow: 'none' }}
        _placeholder={{ color: GreyGrey50 }}
        color={!hook.title.value ? GreyGrey50 : GreyGrey80}
        width="100%"
        value={hook.title.value}
        onChange={value => hook.title.onChange(value)}
        placeholder="Untitled"
        validationErrors={hook.validationErrors}
        isDisabled={hook.title.disabled}
        styles={{ wrapper: { marginBottom: '0px' } }}
        autoFocus={true}
        onKeyDown={hook.title.onKeyDown}
        showValidationMessage={false}
      />
    </Box>
  )
}
