import { Box, Flex } from '@chakra-ui/react'
import { Label } from '@sequencehq/forms'
import AvalaraTaxCodesSearchLink from './AvalaraTaxCodesSearchLink'
import { Banner, TextInputField } from '@sequencehq/core-components'
import { useDebouncedCallback } from 'use-debounce'
import { useState } from 'react'

interface Props {
  value: string
  onChange: (avalaraTaxCode: string) => void
  onSuccess?: (avalaraTaxCodeInfo: string) => void
}

type AvalaraTaxCodeInfoState = {
  loading: boolean
  info: string | undefined
  error: boolean
}

const AvalaraTaxCodeInput = ({ value, onChange, onSuccess }: Props) => {
  const mockValidTaxCode = 'SC130601'
  const mockInvalidTaxDode = 'ER0000'

  const [avalaraTaxCodeInfoState, setAvalaraTaxCodeInfoState] =
    useState<AvalaraTaxCodeInfoState>({
      loading: false,
      info: undefined,
      error: false
    })

  const getAvalaraTaxCodeInfo = useDebouncedCallback(
    async (avalaraTaxCode: string): Promise<void> => {
      if (avalaraTaxCode.length === 0) {
        setAvalaraTaxCodeInfoState({
          loading: false,
          info: undefined,
          error: false
        })
        return
      }
      if (avalaraTaxCode === mockValidTaxCode) {
        const res = await Promise.resolve({
          data: { avalaraTaxCode, info: 'Tax code info' }
        })

        const avalaraTaxCodeInfo = res.data.info

        onSuccess?.(avalaraTaxCodeInfo)

        setAvalaraTaxCodeInfoState({
          loading: false,
          info: avalaraTaxCodeInfo,
          error: false
        })
      }

      if (avalaraTaxCode === mockInvalidTaxDode) {
        setAvalaraTaxCodeInfoState({
          loading: false,
          info: undefined,
          error: true
        })
      }
    },
    500
  )

  return (
    <Box>
      <Flex justifyContent="space-between" alignContent="center">
        <Label size="sm">Avalara tax code</Label>

        <AvalaraTaxCodesSearchLink pb={2} />
      </Flex>
      <Flex flexDirection="column">
        <TextInputField
          value={value}
          validationErrors={
            avalaraTaxCodeInfoState.error ? [{ message: '' }] : undefined
          }
          onChange={e => {
            setAvalaraTaxCodeInfoState({
              loading: true,
              info: undefined,
              error: false
            })
            const taxCode = e.target.value
            onChange(taxCode)
            void getAvalaraTaxCodeInfo(taxCode)
          }}
          placeholder="XX00000"
        />

        <Flex mt="-8px" mb="17px">
          {avalaraTaxCodeInfoState.loading && (
            <Banner variant="loading">Fetching tax code information...</Banner>
          )}

          {avalaraTaxCodeInfoState.error && (
            <Banner variant="error">
              Failed to fetch tax code information
            </Banner>
          )}

          {Boolean(avalaraTaxCodeInfoState.info) && (
            <Banner withIcon={false} variant="neutral">
              {avalaraTaxCodeInfoState.info}
            </Banner>
          )}
        </Flex>
      </Flex>
    </Box>
  )
}

export default AvalaraTaxCodeInput
