import { Currency } from '@sequencehq/core-models'
import { StringDate } from '@sequencehq/utils'

const formatInvoicedRevenueData = (
  invoicedRevenuePerMonth:
    | Array<{ amount: number; periodStart: string }>
    | undefined
): Array<{
  value: number
  subValues: {
    billed: number
    unbilled: number
  }
  date: string
}> => {
  if (!invoicedRevenuePerMonth?.length) {
    return []
  }

  return invoicedRevenuePerMonth.map(({ amount, periodStart }) => ({
    value: amount,
    subValues: {
      billed: amount,
      unbilled: 0
    },
    date: periodStart
  }))
}

interface UseInvoicedRevenueDataProps {
  dateRange: {
    start: StringDate
    end: StringDate
  }
  currency: Currency
}

//Props will actually get used when this is a real hook.
//eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useInvoicedRevenueData = (props: UseInvoicedRevenueDataProps) => {
  /**
   * TODO: replace with API package call
   */
  const totalInvoicedRevenue = 1500
  const invoicedRevenueByMonth = [
    {
      amount: 100,
      periodStart: '2023-09-01'
    },
    {
      amount: 200,
      periodStart: '2023-10-01'
    },
    {
      amount: 400,
      periodStart: '2023-11-01'
    },
    {
      amount: 800,
      periodStart: '2024-01-01'
    }
  ]

  const transformedData = formatInvoicedRevenueData(invoicedRevenueByMonth)

  return {
    data: transformedData,
    total: totalInvoicedRevenue || 0,
    isFetching: false
  }
}
