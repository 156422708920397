import { Font, pdf } from '@react-pdf/renderer'
import * as Sentry from '@sentry/react'
import { InvoiceModel } from '@sequencehq/core-models'
import { ReactPdfInvoice } from '@sequencehq/invoice-content'
import { getPortalDomain } from '@sequencehq/utils'
import { useFetchDataForLocalPdfInvoice } from 'InvoiceEditor/components/Header/useFetchDataForLocalPdfInvoice'
import { useInvoicePdfDownload } from 'InvoiceEditor/components/Header/useInvoicePdfDownload'
import { createSubAccountUsageBreakdownFromApiResponse } from 'InvoiceEditor/domainManagement/invoiceEditorAdapter'
import { useInvoiceEditorContext } from 'InvoiceEditor/hooks/useInvoiceEditorContext'
import { baseApi } from 'features/api/baseApi'
import { getEnvironment, isProductionEnv } from 'lib/environment/environment'
import exhaustiveGuard from 'lib/exhaustiveGuard/exhaustiveGuard'
import { useCallback, useEffect, useState } from 'react'

type UseInvoicePdfPreview = (props: { isOpen: boolean }) => {
  previewDataUrl: string | undefined
  download: {
    available: boolean
    disabled: boolean
    onClick: () => void
  }
}

Font.register({
  family: 'Lato',
  fonts: [
    {
      src: 'https://dashboard.seqhq.io/fonts/Lato-Regular.ttf',
      fontStyle: 'normal',
      fontWeight: 'normal'
    },
    {
      src: 'https://dashboard.seqhq.io/fonts/Lato-Bold.ttf',
      fontStyle: 'normal',
      fontWeight: 'bold'
    }
  ]
})

export const useInvoicePdfPreview: UseInvoicePdfPreview = props => {
  const { downloadPdf } = useInvoicePdfDownload()
  const { data, derived } = useInvoiceEditorContext()
  const { fetchData } = useFetchDataForLocalPdfInvoice()

  const [getInvoiceDownload] =
    baseApi.endpoints.getInvoiceDownload.useLazyQuerySubscription()

  const portalDomain = getPortalDomain(getEnvironment())

  const [previewDataUrl, setPreviewDataUrl] = useState<string | undefined>()

  /**
   * Main functions
   */

  /**
   * Safaris' protections prevents the iframe from loading, so we convert
   * the blob to a base64 URL and pass that as the `src` param of the iframe
   */
  const convertBlobToBase64 = useCallback(
    (blob: Blob, caller: string) =>
      new Promise((resolve: (value: string) => void, reject) => {
        const reader = new FileReader()

        reader.onload = event => {
          if (!event.target) {
            return reject(`${caller}: event.target is null`)
          }

          const dataUrl = event.target.result

          if (typeof dataUrl !== 'string') {
            return reject(`${caller}: dataUrl is not string`)
          }

          return resolve(dataUrl)
        }

        reader.readAsDataURL(blob)
      }),
    []
  )

  const localPreviewPdf = useCallback(
    async ({
      customerId,
      invoiceId
    }: {
      customerId: InvoiceModel['customerId']
      invoiceId: InvoiceModel['id']
    }) => {
      try {
        const pdfInvoiceData = await fetchData({ invoiceId, customerId })

        const invoiceProps = {
          invoice: {
            ...pdfInvoiceData.invoice,
            customerPortalEnabled:
              pdfInvoiceData.merchant.customerPortalEnabled,
            creditBalances: pdfInvoiceData.creditBalances
          },
          lineItems: pdfInvoiceData.lineItems,
          lineItemGroups: pdfInvoiceData.lineItemGroups,
          merchantDetails: pdfInvoiceData.merchantDetails,
          creditBalances: pdfInvoiceData.creditBalances,
          showNonProdWatermark: !isProductionEnv(),
          urlRootApi: import.meta.env.VITE_API_ORIGIN,
          urlRootCustomerPortal: portalDomain || '',
          defaultDueDateDays: pdfInvoiceData.merchant.defaultDueDateDays,
          isCustomerPortalEnabled:
            pdfInvoiceData.merchant.customerPortalEnabled,
          isRenderedInBrowser: true,
          subAccountUsageBreakdown:
            createSubAccountUsageBreakdownFromApiResponse(
              pdfInvoiceData.subAccountUsageBreakdown
            )
        }

        /**
         * NOTE: see apps/dashboard-web/src/components/InvoiceEditor/components/Header/useInvoicePdfDownload.tsx
         */
        let paymentDetailsPageNumber: number | undefined = undefined

        const setPaymentDetailsPageNumber = (pageNumber: number) => {
          paymentDetailsPageNumber = pageNumber
        }

        await pdf(
          <ReactPdfInvoice
            {...invoiceProps}
            setExternalPaymentDetailsPageNumber={setPaymentDetailsPageNumber}
          />
        ).toBlob()

        const blob = await pdf(
          <ReactPdfInvoice
            {...invoiceProps}
            externalPaymentDetailsPageNumber={paymentDetailsPageNumber}
          />
        ).toBlob()

        const dataUrl = await convertBlobToBase64(blob, 'localPreviewPdf')

        setPreviewDataUrl(dataUrl)
      } catch (e) {
        Sentry.captureException(e)
      }
    },
    [convertBlobToBase64, fetchData, portalDomain]
  )

  const remotePreviewPdf = useCallback(
    async ({ invoiceId }: { invoiceId: InvoiceModel['id'] }) => {
      try {
        const fulfilled = await getInvoiceDownload({ id: invoiceId }).unwrap()

        const blob = new Blob([fulfilled], { type: 'application/pdf' })

        const dataUrl = await convertBlobToBase64(blob, 'remotePreviewPdf')

        setPreviewDataUrl(dataUrl)
      } catch (e) {
        Sentry.captureException(e)
      }
    },
    [convertBlobToBase64, getInvoiceDownload]
  )

  /**
   * On load
   */
  useEffect(() => {
    if (!props.isOpen || !data.invoice.id || !data.invoice.customerId) {
      return
    }

    switch (data.invoice.status) {
      case 'DRAFT':
      case 'IN_PROGRESS':
      case 'FINAL':
        void localPreviewPdf({
          invoiceId: data.invoice.id,
          customerId: data.invoice.customerId
        })
        break
      case 'SENT':
      case 'VOIDED':
        void remotePreviewPdf({ invoiceId: data.invoice.id })
        break
      default:
        return exhaustiveGuard(data.invoice.status)
    }
  }, [
    data.invoice.customerId,
    data.invoice.id,
    data.invoice.status,
    localPreviewPdf,
    props.isOpen,
    remotePreviewPdf
  ])

  return {
    previewDataUrl,
    download: {
      available: derived.queries.availableFeatures.canDownloadInvoice,
      disabled: false,
      onClick: () =>
        downloadPdf({
          invoiceId: data.invoice.id,
          invoiceNumber: data.invoice.invoiceNumber,
          invoiceStatus: data.invoice.status,
          customerId: data.invoice.customerId
        })
    }
  }
}
