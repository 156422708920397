import { SwitchLineInput } from '@sequencehq/core-components'
import { Box, Flex } from '@chakra-ui/react'
import { FC } from 'react'

import {
  BorderRadius6,
  GreyGrey10,
  GreyGrey70,
  IndigoIndigo50,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { BookOpenIcon } from '@heroicons/react/16/solid'
import {
  StripeInspectorItemData,
  useStripePaymentWidget
} from './useStripePaymentWidget'

interface StripePaymentInspectorItemProps {
  fieldData: {
    acceptPaymentsViaStripe: {
      value: boolean
      disabled?: boolean
    }
  }
  onChange: (newData: StripeInspectorItemData) => void
  disabled?: boolean
}

export const StripePaymentWidget: FC<
  StripePaymentInspectorItemProps
> = props => {
  const { fields } = useStripePaymentWidget({
    dataFromInterface: {
      acceptPaymentsViaStripe: props.fieldData.acceptPaymentsViaStripe
    },
    onChange: props.onChange,
    disabled: props.disabled
  })

  return (
    <Flex
      flexDirection="column"
      gap="8px"
      borderRadius={BorderRadius6}
      backgroundColor={GreyGrey10}
      padding="12px"
    >
      <SwitchLineInput
        isDisabled={fields.acceptPaymentsViaStripe.isDisabled}
        text="Accept payments via Stripe"
        value={fields.acceptPaymentsViaStripe.value}
        onChange={fields.acceptPaymentsViaStripe.onChange}
        styles={{
          label: Lato13Bold
        }}
        data-testid="stripe.inspector.content.acceptPayments"
      />
      <Box {...Lato13Regular} color={GreyGrey70}>
        When this is enabled, a Stripe payment link will be added to invoices.
        Once a payment method is on file for a customer, Sequence will
        auto-charge the customer when you send subsequent invoices.
      </Box>
      <a
        href="https://docs.sequencehq.com/integrations/stripe"
        target="_blank"
        rel="noreferrer"
      >
        <Flex gap="8px">
          <BookOpenIcon height="16px" width="16px" color={IndigoIndigo50} />
          <Box {...Lato13Regular} color={IndigoIndigo50}>
            Learn more
          </Box>
        </Flex>
      </a>
    </Flex>
  )
}
