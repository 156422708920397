import { Flex } from '@chakra-ui/react'
import { Inspector } from '@sequencehq/core-components'
import { FC, ReactNode } from 'react'
import { quickBooksIntegrationConfig } from '../quickbooks.integration.config'
import { LinkCustomerToServiceWidget } from '../../common/LinkEntities/LinkCustomerToService'
import { useIntegrationActive } from 'Integrations/utils/useIntegrationActive'
import { SmallIcon } from 'Integrations/integrationsConfig/common/SmallIcon'

export interface InspectorItemProps {
  customerId: string
  canUnlink?: boolean
}

export const LinkQuickbooksCustomerWidget: FC<{
  customerId: string
  canUnlink?: boolean
}> = props => (
  <LinkCustomerToServiceWidget
    customerId={props.customerId}
    sequenceEntityLabel="customer"
    externalEntityLabel="customer"
    service="QuickBooks_Online"
    canUnlink={props.canUnlink}
  />
)

export const QuickBooksInspectorItem: FC<{
  children: ReactNode
}> = props => {
  const integrationActive = useIntegrationActive('QuickBooks_Online')

  if (!integrationActive) {
    return null
  }

  return (
    <Inspector.IconItem
      data-testid="quickbooks.inspector.item"
      content={
        <Inspector.Content
          data-testid="quickbooks.inspector.content"
          title="QuickBooks"
          icon={<SmallIcon url={quickBooksIntegrationConfig.smallLogoUrl} />}
        >
          <Flex flexDirection="column" gap="16px">
            {props.children}
          </Flex>
        </Inspector.Content>
      }
      tooltip="QuickBooks"
      icon={<SmallIcon url={quickBooksIntegrationConfig.smallLogoUrl} />}
    />
  )
}
