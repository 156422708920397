import { useLoadInvoice } from 'InvoiceEditor/hooks/useLoadInvoice.ts'
import {
  InvoiceMerchantDetailsModel,
  InvoiceModel,
  LineItemGroupModel,
  LineItemModel,
  MerchantModel,
  CustomerModel,
  UsageDataResponseModel,
  InvoiceUsageItemGroup
} from '@sequencehq/core-models'
import { useMemo } from 'react'
import { useLoadLineItemsAndGroups } from 'InvoiceEditor/hooks/useLoadLineItemsAndGroups.ts'
import { useLoadMerchantDetails } from 'InvoiceEditor/hooks/useLoadMerchantDetails.ts'
import { useLoadMerchant } from 'InvoiceEditor/hooks/useLoadAccountMerchant'
import { useLoadCustomer } from 'InvoiceEditor/hooks/useLoadCustomer'
import { useLoadSubAccountUsage } from 'InvoiceEditor/hooks/useLoadSubAccountUsage'
import { useLoadUsageData } from 'InvoiceEditor/hooks/useLoadUsageData'
import { LoaderError } from 'InvoiceEditor/domainManagement/invoiceEditor.types'
import {
  Price,
  useLoadPrices
} from 'InvoiceEditor/components/LineItems/drawer/LineItemEditor/hooks/useLoadPrices.ts'

type UseLoadInvoiceEditor = ({ invoiceId }: { invoiceId: string }) => {
  loading: boolean
  errors: {
    invoice?: LoaderError
    merchant?: LoaderError
    customer?: LoaderError
    lineItems?: LoaderError
    lineItemGroups?: LoaderError
  }
  data?: {
    invoice: InvoiceModel
    invoiceMerchantDetails?: InvoiceMerchantDetailsModel
    merchant?: MerchantModel
    lineItemsAndGroups?: {
      lineItems: LineItemModel[]
      lineItemGroups: LineItemGroupModel[]
      lineItemGroupUsage: (UsageDataResponseModel & {
        lineItemGroupId: string
      })[]
    }
    customer?: CustomerModel
    subAccountUsageBreakdown: InvoiceUsageItemGroup[]
    prices?: Price[]
  }
  reloadInvoice: () => void
}

export const useLoadInvoiceEditor: UseLoadInvoiceEditor = ({ invoiceId }) => {
  const {
    data: invoiceData,
    loading: invoiceLoading,
    error: invoiceError,
    reloadInvoice
  } = useLoadInvoice({
    invoiceId
  })
  const invoice = invoiceData?.invoice

  const {
    data: merchantData,
    loading: merchantLoading,
    error: merchantError
  } = useLoadMerchant()
  const merchant = merchantData?.merchant

  const {
    data: customerData,
    loading: customerLoading,
    error: customerError
  } = useLoadCustomer(invoice?.customerId)
  const { data: subAccountUsageBreakdown, loading: subAccountUsageLoading } =
    useLoadSubAccountUsage({ invoiceId, async: false })
  const customer = customerData?.customer

  const merchantDetailProps = useMemo(() => {
    if (!invoice) {
      return {}
    }

    return {
      currency: invoice.currency,
      country: invoice.customerBillingAddress.country,
      state: invoice.customerBillingAddress.state,
      invoiceMerchantDetails: invoice.merchantDetails
    }
  }, [invoice])
  const { loading: loadingMerchantDetails, data: invoiceMerchantData } =
    useLoadMerchantDetails(merchantDetailProps)

  const lineItemProps = useMemo(() => {
    return {
      invoiceId,
      invoice: invoiceData?.invoice
    }
  }, [invoiceId, invoiceData])
  const {
    data: lineItemData,
    loading: loadingLineItemData,
    lineItemGroupsError,
    lineItemsError
  } = useLoadLineItemsAndGroups(lineItemProps)

  const lineItemGroupUsageProps = useMemo(() => {
    return {
      invoiceId,
      lineItemGroups: lineItemData?.lineItemGroups ?? []
    }
  }, [invoiceId, lineItemData])
  const { data: lineItemGroupUsageData } = useLoadUsageData(
    lineItemGroupUsageProps
  )

  const priceProps = useMemo(() => {
    return {
      currency: invoice?.currency
    }
  }, [invoice])

  const { data: priceData, loading: loadingPrices } = useLoadPrices(priceProps)

  const loading = useMemo(() => {
    return [
      invoiceLoading,
      loadingLineItemData,
      loadingMerchantDetails,
      merchantLoading,
      customerLoading,
      subAccountUsageLoading,
      loadingPrices
    ].some(Boolean)
  }, [
    invoiceLoading,
    loadingLineItemData,
    loadingMerchantDetails,
    merchantLoading,
    customerLoading,
    subAccountUsageLoading,
    loadingPrices
  ])

  const data = useMemo(() => {
    if (loading || !invoice || !customer) {
      return
    }

    const lineItemsAndGroups =
      lineItemData && lineItemGroupUsageData
        ? {
            ...lineItemData,
            lineItemGroupUsage: lineItemGroupUsageData.lineItemGroupUsage
          }
        : undefined

    return {
      invoice,
      invoiceMerchantDetails: invoiceMerchantData?.merchantDetails,
      lineItemsAndGroups,
      merchant,
      customer,
      subAccountUsageBreakdown:
        subAccountUsageBreakdown?.subAccountUsageBreakdown ?? [],
      prices: priceData?.prices ?? []
    }
  }, [
    loading,
    invoice,
    customer,
    invoiceMerchantData?.merchantDetails,
    lineItemData,
    lineItemGroupUsageData,
    merchant,
    subAccountUsageBreakdown?.subAccountUsageBreakdown,
    priceData?.prices
  ])

  const errors = useMemo(() => {
    return {
      invoice: invoiceError,
      merchant: merchantError,
      customer: customerError,
      lineItems: lineItemsError,
      lineItemGroups: lineItemGroupsError
    }
  }, [
    invoiceError,
    merchantError,
    customerError,
    lineItemsError,
    lineItemGroupsError
  ])

  return {
    data,
    loading,
    errors,
    reloadInvoice
  }
}
