import { Center, Flex, Grid, GridItem, Spinner } from '@chakra-ui/react'
import {
  EllipsisHorizontalIcon,
  MinusCircleIcon
} from '@heroicons/react/16/solid'
import { Button, SequenceMenu } from '@sequencehq/core-components'
import {
  GreyGrey30,
  GreyWhite,
  RedRed60,
  GreyGrey60
} from '@sequencehq/design-tokens'
import { Blocker } from 'common/components/Blocker'
import { RemoveIntegrationModal } from 'modules/Integrations/view/integrationManagement/IntegrationManagementActions/RemoveIntegrationModal'
import { useIntegrationManagementActions } from 'modules/Integrations/view/integrationManagement/IntegrationManagementActions/useIntegrationManagementActions'

export const IntegrationManagementActions = () => {
  const hook = useIntegrationManagementActions()

  return (
    <>
      <Grid
        gridTemplateColumns="1fr auto"
        padding="8px 12px 8px 20px"
        backgroundColor={GreyWhite}
        borderBottom={`1px solid ${GreyGrey30}`}
        backdropFilter="blur(10px)"
      >
        <GridItem></GridItem>
        <GridItem display="flex" gap="8px" alignItems="center">
          {!hook.actions.save.hidden && (
            <Button
              variant="primary"
              onClick={() => {
                void hook.actions.save.onClick()
              }}
              disabled={hook.actions.save.disabled}
            >
              <Flex gap="8px" alignItems="center">
                {hook.actions.save.saving && (
                  <Spinner height="16px" width="16px" color={GreyWhite} />
                )}{' '}
                {hook.actions.save.label}
              </Flex>
            </Button>
          )}
          <SequenceMenu
            alignment="bottom-right"
            menuButton={
              <Button
                data-testid="integrationManagement.kebab"
                variant="ghost"
                size="24px"
              >
                <Center flexShrink="0">
                  <EllipsisHorizontalIcon
                    width="16px"
                    height="16px"
                    color={GreyGrey60}
                  />
                </Center>
              </Button>
            }
            items={[
              {
                label: 'Remove integration',
                hidden: hook.actions.remove.disabled,
                onClick: hook.actions.remove.onClick,
                style: {
                  color: RedRed60
                },
                iconLeft: (
                  <MinusCircleIcon
                    width="16px"
                    height="16px"
                    color={RedRed60}
                  />
                )
              }
            ]}
          />
        </GridItem>
      </Grid>
      {hook.modals.remove.active && (
        <RemoveIntegrationModal
          onClose={hook.modals.remove.onClose}
          onSuccess={hook.modals.remove.onSuccess}
          service={hook.modals.remove.service}
        />
      )}

      <Blocker
        name={hook.blocker.label}
        dirty={hook.blocker.enabled}
        submitting={hook.blocker.submitting}
      />
    </>
  )
}
