import {
  toBillingScheduleResponse,
  toBillingScheduleUpdateResponse,
  toCreateOAuthClientResponse,
  toCreditBalances,
  toCreditGrant,
  toCreditGrantsResponse,
  toCreditNote,
  toCreditNotesResponse,
  toCreditNoteTotals,
  toCustomer,
  toCustomerAlias,
  toCustomerAliasesResponsePaginated,
  toCustomerAliasesResponseUnpaginated,
  toCustomerCreditBalancesResponseUnpaginated,
  toCustomersResponse,
  toImportIntegrationCustomersProgressResponse,
  toImportIntegrationCustomersResponse,
  toInsightsInvoicedRevenueResponse,
  toInsightsOutstandingInvoicesResponse,
  toInsightsUsageEventsResponse,
  toInsightsUsageMetricResponse,
  toIntegrationCustomersForServiceResponse,
  toIntegrationCustomersResponse,
  toInvoice,
  toInvoiceMerchantDetails,
  toInvoiceSettings,
  toInvoicesGrid,
  toInvoicesResponse,
  toInvoiceTotals,
  toInvoiceUsageItemGroupResponse,
  toLineItem,
  toLineItemCreditNote,
  toLineItemGroup,
  toLineItemGroupCreditNote,
  toLinkedEntityModel,
  toMerchant,
  toNotificationPoliciesResponse,
  toNotificationPolicy,
  toOAuthClient,
  toOAuthClientsResponse,
  toPlan,
  toPrice,
  toPriceMetadata,
  toPricesResponse,
  toProduct,
  toSeatBalancesResponse,
  toSeatSnapshot,
  toSeatSnapshotsResponse,
  toSeatTotalsResponse,
  toSeatType,
  toSeatTypesResponse,
  toSelf,
  toSequenceAccount,
  toSequenceAccountId,
  toSequenceAccountSettings,
  toTaxRate,
  toTaxRatesResponse,
  toTaxRegistration,
  toTaxRegistrationsResponse,
  toTimeTraveller,
  toTotalCustomersResponse,
  toUniqueUsageEventProperties,
  toUniqueUsageEventTypes,
  toUnpaginatedLineItemGroupsCreditNoteResponse,
  toUnpaginatedLineItemGroupsResponse,
  toUnpaginatedLineItemsCreditNoteResponse,
  toUnpaginatedLineItemsResponse,
  toUsageDataDataResponse,
  toUsageEvent,
  toUsageMetric,
  toUsageMetricCalculation,
  toUsageMetricsResponse,
  toUser,
  toUsers
} from '@sequencehq/core-models'

import { integratedApi } from 'features/api/integratedApi.generated'

export const tagTypes = [
  'AccountSettings',
  'BillingSchedules',
  'BillingScheduleSettings',
  'CreditBalances',
  'CreditGrants',
  'CreditNote',
  'CreditNoteLineItemGroups',
  'CreditNoteLineItems',
  'CreditNotes',
  'CustomerAliases',
  'Customer',
  'Customers',
  'Discounts',
  'Integration',
  'IntegrationCustomers',
  'IntegrationCustomersForService',
  'IntegrationLedgerAccounts',
  'Integrations',
  'Invoice',
  'InvoiceAccountingDateSettings',
  'Invoices',
  'Insights',
  'InvoicesGrid',
  'LineItemGroups',
  'LineItems',
  'Merchant',
  'NotificationPolicies',
  'OAuthClients',
  'Plan',
  'Plans',
  'Prices',
  'Products',
  'Quote',
  'QuoteLineItem',
  'Quotes',
  'SeatSnapshots',
  'SeatTotals',
  'SeatBalances',
  'SeatTypes',
  'SeatBalances',
  'SequenceAccount',
  'TaxRate',
  'TaxRates',
  'TaxRegistrations',
  'UsageEvent',
  'UsageEvents',
  'UsageMetric',
  'UsageMetrics',
  'Users'
] as const

export type TagTypes = (typeof tagTypes)[number]

export const integratedApiWithTags = integratedApi.enhanceEndpoints({
  addTagTypes: tagTypes,
  endpoints: {
    getCustomers: {
      transformResponse: toCustomersResponse,
      providesTags: ['Customers']
    },
    getInvoices: {
      providesTags: ['Invoices'],
      transformResponse: toInvoicesResponse
    },
    getUsageMetrics: {
      transformResponse: toUsageMetricsResponse,
      providesTags: ['UsageMetrics']
    },
    getUsageMetricsId: {
      providesTags: ['UsageMetric'],
      transformResponse: toUsageMetric
    },
    getUsageMetricsByUsageMetricIdCalculate: {
      transformResponse: toUsageMetricCalculation
    },
    getApiUsageEventsId: {
      transformResponse: toUsageEvent,
      providesTags: ['UsageEvent']
    },
    getCreditNotes: {
      providesTags: ['CreditNotes'],
      transformResponse: toCreditNotesResponse
    },
    postApiUsageEvents: {
      invalidatesTags: ['UsageEvents'],
      transformResponse: toUsageEvent
    },
    putBillingSchedulesBillingScheduleId: {
      invalidatesTags: ['BillingSchedules', 'Plans', 'Plan', 'Discounts'],
      transformResponse: toBillingScheduleUpdateResponse
    },
    putBillingSchedulesBillingScheduleIdActivate: {
      invalidatesTags: ['BillingSchedules', 'Plans', 'Plan'],
      transformResponse: toBillingScheduleUpdateResponse
    },
    putBillingSchedulesBillingScheduleIdArchive: {
      invalidatesTags: ['BillingSchedules'],
      transformResponse: toBillingScheduleUpdateResponse
    },
    postTimeTravellers: {
      transformResponse: toTimeTraveller
    },
    putTimeTravellersById: {
      transformResponse: toTimeTraveller
    },
    getTimeTravellersById: {
      transformResponse: toTimeTraveller
    },
    postInvoicesByInvoiceLineItems: {
      invalidatesTags: ['LineItems'],
      transformResponse: toLineItem
    },
    postInvoicesByInvoiceLineItemGroups: {
      invalidatesTags: ['LineItemGroups'],
      transformResponse: toLineItemGroup
    },
    deleteInvoicesByInvoiceLineItemsById: {
      invalidatesTags: ['LineItems'],
      transformResponse: toLineItem
    },
    deleteInvoicesByInvoiceLineItemGroupsById: {
      invalidatesTags: ['LineItemGroups', 'LineItems'],
      transformResponse: toLineItemGroup
    },
    putInvoicesByInvoiceLineItemsById: {
      invalidatesTags: ['LineItems'],
      transformResponse: toLineItem
    },
    putInvoicesByInvoiceLineItemGroupsById: {
      invalidatesTags: ['LineItemGroups'],
      transformResponse: toLineItemGroup
    },
    getInvoicesByIdLineItemsAll: {
      providesTags: ['LineItems'],
      transformResponse: toUnpaginatedLineItemsResponse
    },
    getInvoicesByIdLineItemGroupsAll: {
      providesTags: ['LineItemGroups'],
      transformResponse: toUnpaginatedLineItemGroupsResponse
    },
    postInvoicesTotals: {
      transformResponse: toInvoiceTotals
    },
    postInvoicesByIdVoid: {
      invalidatesTags: ['Invoices', 'Invoice'],
      transformResponse: toInvoice
    },
    postInvoicesVoid: {
      invalidatesTags: ['Invoices', 'Invoice']
    },
    postInvoicesByIdRecalculate: {
      transformResponse: toInvoice
    },
    postInvoicesRecalculate: {
      invalidatesTags: ['Invoices', 'Invoice', 'LineItems', 'LineItemGroups']
    },
    putInvoicesByIdPaymentStatus: {
      invalidatesTags: ['Invoices', 'Invoice'],
      transformResponse: toInvoice
    },
    putInvoicesPaymentStatus: {
      invalidatesTags: ['Invoices', 'Invoice']
    },
    postInvoices: {
      invalidatesTags: ['Invoices'],
      transformResponse: toInvoice
    },
    putInvoicesById: {
      invalidatesTags: ['Invoices', 'Invoice'],
      transformResponse: toInvoice
    },
    getInvoicesById: {
      providesTags: ['Invoice'],
      transformResponse: toInvoice
    },
    postInvoicesByIdFinalizeAndSend: {
      invalidatesTags: ['Invoices', 'Invoice'],
      transformResponse: toInvoice
    },
    postInvoicesFinalizeAndSend: {
      invalidatesTags: ['Invoices', 'Invoice']
    },
    postInvoicesByIdFinalize: {
      invalidatesTags: ['Invoices', 'Invoice'],
      transformResponse: toInvoice
    },
    postInvoicesFinalize: {
      invalidatesTags: ['Invoices', 'Invoice']
    },
    postInvoicesByIdSend: {
      invalidatesTags: ['Invoices', 'Invoice'],
      transformResponse: toInvoice
    },
    postInvoicesByIdSendByTestEmail: {
      transformResponse: toInvoice
    },
    postInvoicesSend: {
      invalidatesTags: ['Invoices', 'Invoice']
    },
    getCustomersById: {
      providesTags: ['Customer'],
      transformResponse: toCustomer
    },
    putCustomersByIdArchive: {
      invalidatesTags: ['Customer', 'Customers'],
      transformResponse: toCustomer
    },
    getMerchantForSequenceAccount: {
      providesTags: ['Merchant'],
      transformResponse: toMerchant
    },
    getBillingScheduleBillingScheduleId: {
      providesTags: ['BillingSchedules'],
      transformResponse: toBillingScheduleResponse
    },
    getApiPlansPlanId: {
      transformResponse: toPlan,
      providesTags: ['Plan']
    },
    getCustomersByAliasByAlias: {
      providesTags: ['CustomerAliases'],
      transformResponse: toCustomer
    },
    deleteCreditNotesByCreditnoteLineItemsById: {
      invalidatesTags: ['CreditNoteLineItems'],
      transformResponse: toLineItemCreditNote
    },
    deleteCreditNotesByCreditnoteLineItemGroupsById: {
      invalidatesTags: ['CreditNoteLineItemGroups', 'CreditNoteLineItems'],
      transformResponse: toLineItemGroupCreditNote
    },
    postCreditNotesByIdFinalizeAndSend: {
      invalidatesTags: ['CreditNote', 'CreditNotes'],
      transformResponse: toCreditNote
    },
    postCreditNotesByIdFinalize: {
      invalidatesTags: ['CreditNote', 'CreditNotes'],
      transformResponse: toCreditNote
    },
    postCreditNotesByIdSend: {
      invalidatesTags: ['CreditNote', 'CreditNotes'],
      transformResponse: toCreditNote
    },
    postCreditNotesByIdSendByTestEmail: {
      transformResponse: toCreditNote
    },
    postCreditNotesByIdVoid: {
      invalidatesTags: ['CreditNote', 'CreditNotes'],
      transformResponse: toCreditNote
    },
    getPrices: {
      providesTags: ['Prices'],
      transformResponse: toPricesResponse
    },
    getInsightsCustomerTotals: {
      providesTags: ['Insights'],
      transformResponse: toTotalCustomersResponse
    },
    getInsightsUsageEvents: {
      providesTags: ['Insights'],
      transformResponse: toInsightsUsageEventsResponse
    },
    getInsightsInvoicedRevenue: {
      providesTags: ['Insights'],
      transformResponse: toInsightsInvoicedRevenueResponse
    },
    getInsightsOutstandingInvoices: {
      providesTags: ['Insights'],
      transformResponse: toInsightsOutstandingInvoicesResponse
    },
    getInsightsUsageMetricsByUsageMetricIdUsageData: {
      providesTags: ['Insights'],
      transformResponse: toInsightsUsageMetricResponse
    },
    postProducts: {
      invalidatesTags: ['Products'],
      transformResponse: toProduct
    },
    postCreditNotes: {
      transformResponse: toCreditNote,
      invalidatesTags: ['CreditNote']
    },
    putCreditNotesByCreditnoteLineItemsById: {
      transformResponse: toLineItemCreditNote,
      invalidatesTags: ['CreditNoteLineItems']
    },
    postCreditNotesByCreditnoteLineItemGroups: {
      transformResponse: toLineItemGroupCreditNote,
      invalidatesTags: ['CreditNoteLineItemGroups']
    },
    putCreditNotesByCreditnoteLineItemGroupsById: {
      transformResponse: toLineItemGroupCreditNote,
      invalidatesTags: ['CreditNoteLineItemGroups']
    },
    postCreditNotesByCreditnoteLineItems: {
      transformResponse: toLineItemCreditNote,
      invalidatesTags: ['CreditNoteLineItems']
    },
    getCreditNotesById: {
      transformResponse: toCreditNote,
      providesTags: ['CreditNote']
    },
    putCreditNotesById: {
      transformResponse: toCreditNote,
      invalidatesTags: ['CreditNote', 'CreditNotes']
    },
    postCreditNotesTotals: {
      transformResponse: toCreditNoteTotals
    },
    getCreditNotesByIdLineItemGroupsAll: {
      transformResponse: toUnpaginatedLineItemGroupsCreditNoteResponse,
      providesTags: ['CreditNoteLineItemGroups']
    },
    getCreditNotesByIdLineItemsAll: {
      transformResponse: toUnpaginatedLineItemsCreditNoteResponse,
      providesTags: ['CreditNoteLineItems']
    },
    getTaxRates: {
      transformResponse: toTaxRatesResponse,
      providesTags: ['TaxRates']
    },
    putTaxRatesByIdArchive: {
      transformResponse: toTaxRate,
      invalidatesTags: ['TaxRates']
    },
    postTaxRates: {
      transformResponse: toTaxRate,
      invalidatesTags: ['TaxRates']
    },
    getCustomersByIdAliases: {
      providesTags: ['CustomerAliases'],
      transformResponse: toCustomerAliasesResponseUnpaginated
    },
    putCustomersById: {
      transformResponse: toCustomer,
      invalidatesTags: [
        'Customer',
        'Customers',
        'CustomerAliases',
        'IntegrationCustomersForService',
        'IntegrationCustomers'
      ]
    },
    postTaxRegistrations: {
      transformResponse: toTaxRegistration,
      invalidatesTags: ['TaxRegistrations']
    },
    getTaxRegistrationsForCustomerById: {
      transformResponse: toTaxRegistrationsResponse,
      providesTags: ['TaxRegistrations']
    },
    postCreditNotesByIdLineItemsFromInvoice: {
      invalidatesTags: ['CreditNoteLineItemGroups', 'CreditNoteLineItems']
    },
    getPricesById: {
      transformResponse: toPrice
    },
    postUsageMetrics: {
      transformResponse: toUsageMetric,
      invalidatesTags: ['UsageMetrics']
    },
    putUsageMetricsById: {
      transformResponse: toUsageMetric,
      invalidatesTags: ['UsageMetrics', 'UsageMetric']
    },
    postApiIntegrationsServiceCustomers: {
      invalidatesTags: ['Customers', 'IntegrationCustomers'],
      transformResponse: toImportIntegrationCustomersResponse
    },
    postApiIntegrationsServiceCustomersProgress: {
      invalidatesTags: ['Customers', 'IntegrationCustomers'],
      transformResponse: toImportIntegrationCustomersProgressResponse
    },
    getApiSelf: {
      transformResponse: toSelf
    },
    getApiIntegrationsCustomers: {
      providesTags: ['IntegrationCustomers'],
      transformResponse: toIntegrationCustomersResponse
    },
    getApiIntegrationsCustomersService: {
      providesTags: ['IntegrationCustomersForService'],
      transformResponse: toIntegrationCustomersForServiceResponse
    },
    getCreditsFiltered: {
      providesTags: ['CreditGrants'],
      transformResponse: toCreditGrantsResponse
    },
    postCredits: {
      invalidatesTags: ['CreditGrants', 'CreditBalances'],
      transformResponse: toCreditGrant
    },
    getCreditsByCustomerIdGrants: {
      providesTags: ['CreditBalances'],
      transformResponse: toCreditBalances
    },
    getInvoiceMerchantDetails: {
      transformResponse: toInvoiceMerchantDetails
    },
    getUsers: {
      providesTags: ['Users'],
      transformResponse: toUsers
    },
    getUsersById: {
      transformResponse: toUser
    },
    deleteUsersById: {
      invalidatesTags: ['Users'],
      transformResponse: toUser
    },
    getSequenceAccountId: {
      transformResponse: toSequenceAccountId
    },
    getApiNotificationsPolicies: {
      providesTags: ['NotificationPolicies'],
      transformResponse: toNotificationPoliciesResponse
    },
    postApiNotificationsPolicies: {
      invalidatesTags: ['NotificationPolicies'],
      transformResponse: toNotificationPolicy
    },
    deleteApiNotificationsPoliciesId: {
      invalidatesTags: ['NotificationPolicies'],
      transformResponse: toNotificationPolicy
    },
    getOauthClients: {
      providesTags: ['OAuthClients'],
      transformResponse: toOAuthClientsResponse
    },
    deleteOauthClientsById: {
      invalidatesTags: ['OAuthClients'],
      transformResponse: toOAuthClient
    },
    postOauthClients: {
      invalidatesTags: ['OAuthClients'],
      transformResponse: toCreateOAuthClientResponse
    },
    getCustomerAliases: {
      providesTags: ['CustomerAliases'],
      transformResponse: toCustomerAliasesResponsePaginated
    },
    postCustomerAliases: {
      invalidatesTags: ['Customers', 'CustomerAliases', 'UsageEvents'],
      transformResponse: toCustomerAlias
    },
    postApiIntegrationsGoogleSheetsExport: {
      invalidatesTags: []
    },
    getSequenceAccountSettings: {
      providesTags: ['AccountSettings'],
      transformResponse: toSequenceAccountSettings
    },
    putSequenceAccountSettings: {
      invalidatesTags: ['AccountSettings'],
      transformResponse: toSequenceAccountSettings
    },
    postInvoicesByIdSendPaymentReminder: {
      transformResponse: toInvoice
    },
    getUniqueUsageEventTypes: {
      transformResponse: toUniqueUsageEventTypes
    },
    getUniqueUsageEventProperties: {
      transformResponse: toUniqueUsageEventProperties
    },
    getSeatTypes: {
      transformResponse: toSeatTypesResponse,
      providesTags: ['SeatTypes']
    },
    getSeatTypesById: {
      transformResponse: toSeatType
    },
    postSeatTypes: {
      transformResponse: toSeatType,
      invalidatesTags: ['SeatTypes']
    },
    getSeatSnapshots: {
      transformResponse: toSeatSnapshotsResponse,
      providesTags: ['SeatSnapshots']
    },
    postSeatSnapshots: {
      transformResponse: toSeatSnapshot,
      invalidatesTags: ['SeatSnapshots', 'SeatTotals', 'SeatBalances']
    },
    getLatestSeatTotals: {
      transformResponse: toSeatTotalsResponse,
      providesTags: ['SeatTotals']
    },
    getSeatBalances: {
      transformResponse: toSeatBalancesResponse,
      providesTags: ['SeatBalances']
    },
    getProductsById: {
      providesTags: ['Products'],
      transformResponse: toProduct
    },
    getInvoicesInvoicesGrid: {
      providesTags: ['InvoicesGrid'],
      transformResponse: toInvoicesGrid
    },
    getInvoicesByInvoiceIdLineItemGroupsByLineItemGroupIdUsageData: {
      transformResponse: toUsageDataDataResponse
    },
    putSequenceAccountsById: {
      invalidatesTags: ['SequenceAccount'],
      transformResponse: toSequenceAccount
    },
    getSequenceAccountsById: {
      providesTags: ['SequenceAccount'],
      transformResponse: toSequenceAccount
    },
    postApiIntegrationsServiceSyncInvoiceInvoiceId: {
      invalidatesTags: ['Invoice', 'Invoices']
    },
    deleteLinkedEntitiesByTypeByIdByService: {
      invalidatesTags: ['Customers', 'Customer', 'IntegrationCustomers'],
      transformResponse: toLinkedEntityModel
    },
    getCreditsByCustomerIdBalances: {
      transformResponse: toCustomerCreditBalancesResponseUnpaginated
    },
    putTaxRegistrationsById: {
      transformResponse: toTaxRegistration,
      invalidatesTags: ['TaxRegistrations']
    },
    getInvoicesByInvoiceIdUsageItemGroups: {
      transformResponse: toInvoiceUsageItemGroupResponse
    },
    getPricesByIdMetadata: {
      transformResponse: toPriceMetadata
    },
    putProductsById: {
      transformResponse: toProduct,
      invalidatesTags: ['Products']
    },
    postInvoiceSettings: {
      transformResponse: toInvoiceSettings
    }
  }
})

export const {
  useDeleteCreditNotesByCreditnoteLineItemGroupsByIdMutation,
  useDeleteInvoicesByInvoiceLineItemGroupsByIdMutation,
  useDeleteInvoicesByInvoiceLineItemsByIdMutation,
  useDeleteApiNotificationsPoliciesIdMutation,
  useDeleteOauthClientsByIdMutation,
  useDeleteUsersByIdMutation,
  useGetCreditNotesByIdLineItemGroupsAllQuery,
  useLazyGetCreditNotesByIdLineItemGroupsAllQuery,
  useGetCreditNotesByIdLineItemsAllQuery,
  useLazyGetCreditNotesByIdLineItemsAllQuery,
  useGetCreditNotesByIdQuery,
  useLazyGetCreditNotesByIdQuery,
  useGetCreditNotesQuery,
  useGetCreditsByCustomerIdGrantsQuery,
  useGetCreditsFilteredQuery,
  useGetCustomerAliasesQuery,
  useGetCustomersByAliasByAliasQuery,
  useGetCustomersByIdQuery,
  useGetCustomersQuery,
  useGetApiIntegrationsCustomersQuery,
  useGetInvoicesByIdQuery,
  useGetInvoicesInvoicesGridQuery,
  useGetInvoicesQuery,
  useGetMerchantForSequenceAccountQuery,
  useGetApiPlansPlanIdQuery,
  useGetPricesByIdQuery,
  useGetProductsByIdQuery,
  useGetSeatSnapshotsQuery,
  useGetInsightsCustomerTotalsQuery,
  useGetInsightsUsageEventsQuery,
  useGetInsightsInvoicedRevenueQuery,
  useGetInsightsOutstandingInvoicesQuery,
  useGetSeatTypesQuery,
  useGetApiSelfQuery,
  useGetSequenceAccountIdQuery,
  useGetSequenceAccountSettingsQuery,
  useGetTaxRatesQuery,
  useGetTaxRegistrationsForCustomerByIdQuery,
  useGetTimeTravellersByIdQuery,
  useGetUniqueUsageEventPropertiesQuery,
  useGetUniqueUsageEventTypesQuery,
  useGetApiUsageEventsIdQuery,
  useGetUsageMetricsIdQuery,
  useGetUsageMetricsQuery,
  useGetInsightsUsageMetricsByUsageMetricIdUsageDataQuery,
  useGetUsersByIdQuery,
  useLazyGetBillingScheduleBillingScheduleIdQuery,
  useLazyGetCreditNotesQuery,
  useLazyGetCustomersByAliasByAliasQuery,
  useLazyGetCustomersByIdAliasesQuery,
  useLazyGetCustomersByIdQuery,
  useLazyGetCustomersQuery,
  useLazyGetApiIntegrationsCustomersServiceQuery,
  useLazyGetInvoiceMerchantDetailsQuery,
  useLazyGetInvoicesByIdLineItemGroupsAllQuery,
  useLazyGetInvoicesByIdLineItemsAllQuery,
  useLazyGetInvoicesByIdQuery,
  useLazyGetInvoicesByInvoiceIdLineItemGroupsByLineItemGroupIdUsageDataQuery,
  useLazyGetLatestSeatTotalsQuery,
  useLazyGetSeatBalancesQuery,
  useLazyGetMerchantForSequenceAccountQuery,
  useLazyGetApiNotificationsPoliciesQuery,
  useLazyGetOauthClientsQuery,
  useLazyGetApiPlansPlanIdQuery,
  useLazyGetSeatSnapshotsQuery,
  useLazyGetSeatTypesByIdQuery,
  useLazyGetSeatTypesQuery,
  useLazyGetTaxRatesQuery,
  useLazyGetTaxRegistrationsForCustomerByIdQuery,
  useLazyGetUsageMetricsIdQuery,
  useLazyGetUsageMetricsQuery,
  useLazyGetUsageMetricsByUsageMetricIdCalculateQuery,
  useLazyGetUsersQuery,
  usePostCreditNotesByCreditnoteLineItemGroupsMutation,
  usePostCreditNotesByCreditnoteLineItemsMutation,
  usePostCreditNotesByIdFinalizeAndSendMutation,
  usePostCreditNotesByIdFinalizeMutation,
  usePostCreditNotesByIdSendMutation,
  usePostCreditNotesByIdSendByTestEmailMutation,
  usePostCreditNotesByIdVoidMutation,
  usePostCreditNotesMutation,
  usePostCreditNotesTotalsMutation,
  usePostCreditsMutation,
  usePostApiIntegrationsGoogleSheetsExportMutation,
  usePostApiIntegrationsServiceCustomersMutation,
  usePostApiIntegrationsServiceCustomersProgressMutation,
  usePostInvoicesFinalizeAndSendMutation,
  usePostInvoicesFinalizeMutation,
  usePostInvoicesByIdFinalizeAndSendMutation,
  usePostInvoicesByIdFinalizeMutation,
  usePostInvoicesByIdRecalculateMutation,
  usePostInvoicesByIdSendMutation,
  usePostInvoicesByIdSendPaymentReminderMutation,
  usePostInvoicesByIdSendByTestEmailMutation,
  usePostInvoicesByIdVoidMutation,
  usePostInvoicesByInvoiceLineItemGroupsMutation,
  usePostInvoicesByInvoiceLineItemsMutation,
  usePostInvoicesMutation,
  usePostInvoicesRecalculateMutation,
  usePostInvoicesSendMutation,
  usePostInvoicesTotalsMutation,
  usePostInvoicesVoidMutation,
  usePostApiNotificationsPoliciesMutation,
  usePostOauthClientsMutation,
  usePostProductsMutation,
  usePostSeatSnapshotsMutation,
  usePostSeatTypesMutation,
  usePostTaxRatesMutation,
  usePostTaxRegistrationsMutation,
  usePostTimeTravellersMutation,
  usePostApiUsageEventsMutation,
  usePostUsageMetricsMutation,
  usePutUsageMetricsByIdMutation,
  usePutBillingSchedulesBillingScheduleIdActivateMutation,
  usePutBillingSchedulesBillingScheduleIdArchiveMutation,
  usePutBillingSchedulesBillingScheduleIdMutation,
  usePutCreditNotesByCreditnoteLineItemGroupsByIdMutation,
  usePutCreditNotesByCreditnoteLineItemsByIdMutation,
  usePutCreditNotesByIdMutation,
  usePutCustomersByIdMutation,
  usePutInvoicesByIdMutation,
  usePutInvoicesByIdPaymentStatusMutation,
  usePutInvoicesByInvoiceLineItemGroupsByIdMutation,
  usePutInvoicesByInvoiceLineItemsByIdMutation,
  usePutInvoicesPaymentStatusMutation,
  usePutSequenceAccountSettingsMutation,
  usePutTaxRatesByIdArchiveMutation,
  usePutTaxRegistrationsByIdMutation,
  usePutTimeTravellersByIdMutation,
  usePutSequenceAccountsByIdMutation,
  useGetSequenceAccountsByIdQuery,
  useLazyGetCustomerAliasesQuery,
  useLazyGetCreditsByCustomerIdBalancesQuery,
  useLazyGetInvoicesByInvoiceIdUsageItemGroupsQuery,
  useGetPricesByIdMetadataQuery,
  usePutProductsByIdMutation,
  usePostInvoiceSettingsMutation
} = integratedApiWithTags
