import { Flex, UnorderedList, ListItem, Text } from '@chakra-ui/react'

import { Banner } from '@sequencehq/core-components'
import { Lato13Bold } from '@sequencehq/design-tokens'
import { useValidationBanner } from './useValidationBanner'

export const ValidationBanner = () => {
  const { isVisible, validationMessages, nextStatusLabel } =
    useValidationBanner()

  if (!isVisible) {
    return null
  }

  return (
    <Flex marginX="55px" marginBottom="16px">
      <Banner variant="error">
        <Text {...Lato13Bold} color="inherit" marginBottom="4px">
          Before {nextStatusLabel}
        </Text>

        <UnorderedList>
          {validationMessages.map(message => (
            <ListItem key={message}>{message}</ListItem>
          ))}
        </UnorderedList>
      </Banner>
    </Flex>
  )
}
