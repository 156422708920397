import {
  DashboardApi20240730,
  dashboard20240730Client
} from '@sequencehq/api/dashboard/v20240730'
import { useCallback } from 'react'
import { CreateCustomerBody } from 'components/Customers/types'
import { ApiError } from 'features/api/integratedApi.generated.ts'

type UseAddCustomer = () => {
  addCustomer: (
    addCustomerBody: CreateCustomerBody
  ) => Promise<DashboardApi20240730.PostCustomer.CreateCustomerResponse | null>
}

export const useAddCustomer: UseAddCustomer = () => {
  const addCustomer = useCallback(
    async (addCustomerBody: CreateCustomerBody) => {
      const response = await dashboard20240730Client
        .postCustomer({
          body: addCustomerBody
        })
        .then(async result => {
          if (result?.error) {
            const errorResponse = (await result.res.json()) as ApiError

            throw new Error(
              errorResponse?.error?.errors?.[0]?.message ??
                'Something went wrong, please try again.'
            )
          }

          return result?.data
        })

      return response
    },
    []
  )
  return {
    addCustomer
  }
}
