import { Card, Button } from '@sequencehq/core-components'
import { Box } from '@chakra-ui/react'
import { Lato13Regular, Lato14Bold } from '@sequencehq/design-tokens'
import { Cog6ToothIcon } from '@heroicons/react/16/solid'
import { useQuery } from '@sequencehq/api/utils'
import { dashboardv99990101Client } from '@sequencehq/api/dashboard/v99990101'
import AvalaraTaxCodesSearchLink from 'Integrations/integrationsConfig/avalara/AvalaraConfigurationManagement/common/AvalaraTaxCodesSearchLink'
import AddTaxCategoryForAvalaraModal from 'Integrations/integrationsConfig/avalara/AvalaraConfigurationManagement/modals/AddTaxCategoryForAvalaraModal'
import {
  AvalaraTaxCodeMapping,
  AvalaraTaxCodeMappingByTaxCategoryId
} from 'Integrations/integrationsConfig/avalara/AvalaraConfigurationManagement/AvalaraConfigurationManagement'
import AvalaraTaxCodeMappingTable from 'Integrations/integrationsConfig/avalara/AvalaraConfigurationManagement/common/AvalaraTaxCodeMappingTable'

interface Props {
  disabled: boolean
  avalaraTaxCodeMappings: AvalaraTaxCodeMappingByTaxCategoryId
  onCreateTaxCategory: ({
    taxCategoryName,
    avalaraTaxCode,
    taxName
  }: {
    taxCategoryName: string
    avalaraTaxCode: string
    taxName: string
  }) => void
  onUpdateTaxCategory: ({
    taxCategoryId,
    avalaraTaxCode,
    taxName
  }: {
    taxCategoryId: string
    avalaraTaxCode: string
    taxName: string
  }) => void
  onRemoveAvalaraTaxCode: (taxCategoryId: string) => void
}

const AvalaraTaxCodesCard = ({
  disabled,
  avalaraTaxCodeMappings,
  onCreateTaxCategory,
  onUpdateTaxCategory,
  onRemoveAvalaraTaxCode
}: Props) => {
  const result = useQuery(dashboardv99990101Client.getTaxCategories)

  const taxCategories = result.data?.items || []

  const currentTaxCategoriesWithMappings: AvalaraTaxCodeMapping[] =
    taxCategories.map(category => {
      const mappingForCategory = avalaraTaxCodeMappings[category.id]

      return {
        taxCategoryId: category.id,
        taxCategoryName: category.name,
        isDefaultTaxCategory: category.isDefault,
        avalaraTaxCode: mappingForCategory?.avalaraTaxCode,
        taxName: mappingForCategory?.taxName
      }
    })

  const pendingTaxCategoriesWithMappings: AvalaraTaxCodeMapping[] =
    Object.values(avalaraTaxCodeMappings)
      .filter(mapping => mapping.pending)
      .map(mapping => ({
        taxCategoryId: mapping.taxCategoryId,
        taxCategoryName: mapping.taxCategoryName,
        avalaraTaxCode: mapping.avalaraTaxCode,
        taxName: mapping.taxName
      }))

  const allTaxCategoryMappings = [
    ...currentTaxCategoriesWithMappings,
    ...pendingTaxCategoriesWithMappings
  ]

  return (
    <Card>
      <Box>
        <Box {...Lato14Bold} mb={2}>
          Avalara tax codes
        </Box>
        <Box {...Lato13Regular} mb={2}>
          Tax is calculated based on the category of each product. Add an
          Avalara tax code to each tax category to automatically calculate sales
          tax.
        </Box>

        <AvalaraTaxCodesSearchLink />

        <AvalaraTaxCodeMappingTable
          disabled={disabled}
          avalaraTaxCategoryMappings={allTaxCategoryMappings}
          onUpdateTaxCategory={onUpdateTaxCategory}
          onRemoveAvalaraTaxCode={onRemoveAvalaraTaxCode}
        />

        <Box mt={2} mb={-2} ml={-2}>
          <AddTaxCategoryForAvalaraModal
            onCreate={onCreateTaxCategory}
            trigger={
              <Button
                disabled={disabled}
                variant="ghost"
                leadingIcon={<Cog6ToothIcon height="16px" width="16px" />}
              >
                Manage tax categories
              </Button>
            }
          />
        </Box>
      </Box>
    </Card>
  )
}

export default AvalaraTaxCodesCard
