import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  XCircleIcon
} from '@heroicons/react/16/solid'
import { useIntegrationsDomainContext } from 'modules/Integrations/communication'
import { ResolvedIntegration } from 'modules/Integrations/domain'
import { FunctionComponent, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { match } from 'ts-pattern'

export interface HealthStatusConfig {
  label: string
  icon: FunctionComponent<{
    height: string | number | undefined
    width: string | number | undefined
  }>
  sentiment: 'success' | 'highlight' | 'neutral'
}

type UseStatusWidget = () => {
  health: HealthStatusConfig
}

export const integrationToHealth = (
  integration: ResolvedIntegration
): HealthStatusConfig => {
  return match(integration)
    .with({ authFailure: true }, () => {
      return {
        label: 'Auth failure',
        icon: ExclamationTriangleIcon,
        sentiment: 'highlight' as const
      }
    })
    .with({ enabled: false }, () => {
      return {
        label: 'Inactive',
        icon: XCircleIcon,
        sentiment: 'neutral' as const
      }
    })
    .with({ state: 'CONNECTED' }, () => {
      return {
        label: 'Active',
        icon: CheckCircleIcon,
        sentiment: 'success' as const
      }
    })
    .otherwise(() => {
      return {
        label: 'Inactive',
        icon: XCircleIcon,
        sentiment: 'neutral' as const
      }
    })
}

export const useStatusWidget: UseStatusWidget = () => {
  const { integrationId } = useParams<{ integrationId: string }>()
  const ctx = useIntegrationsDomainContext()

  invariant(integrationId, 'Integration ID is required')

  const integration: ResolvedIntegration | undefined = useMemo(() => {
    return ctx.queries.resolvedIntegrations[
      integrationId as ResolvedIntegration['id']
    ]
  }, [ctx.queries.resolvedIntegrations, integrationId])

  invariant(integration, 'Integration not found')

  const health = useMemo(() => {
    return integrationToHealth(integration)
  }, [integration])

  return {
    health
  }
}
