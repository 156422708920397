import { enGB } from 'date-fns/locale'

/**
 * This file is a custom locale for date-fns that allows us to use the `formatDistanceToNow` function with custom
 * prefixes as per the design of the 'last recalculated' text in the invoice editor header.
 *
 * The types and interfaces below are copied from date-fns, as they don't seem to be exported by the library.
 *
 * This custom locale is based off the en-US locale (the en-GB locale mostly imports from the en-US locale,
 * with some minor changes). The original file is available here:
 * https://github.com/date-fns/date-fns/blob/main/src/locale/en-US/_lib/formatDistance/index.ts
 */

type FormatDistanceLocale<Template> = {
  [Token in FormatDistanceToken]: Template
}

type FormatDistanceToken =
  | 'lessThanXSeconds'
  | 'xSeconds'
  | 'halfAMinute'
  | 'lessThanXMinutes'
  | 'xMinutes'
  | 'aboutXHours'
  | 'xHours'
  | 'xDays'
  | 'aboutXWeeks'
  | 'xWeeks'
  | 'aboutXMonths'
  | 'xMonths'
  | 'aboutXYears'
  | 'xYears'
  | 'overXYears'
  | 'almostXYears'

interface FormatDistanceFnOptions {
  addSuffix?: boolean
  comparison?: -1 | 0 | 1
}

type FormatDistanceFn = (
  token: FormatDistanceToken,
  count: number,
  options?: FormatDistanceFnOptions
) => string

type FormatDistanceTokenValue =
  | string
  | {
      one: string
      other: string
    }

const formatDistanceLocale: FormatDistanceLocale<FormatDistanceTokenValue> = {
  lessThanXSeconds: {
    one: '1s',
    other: '1s'
  },

  xSeconds: {
    one: '1s',
    other: '{{count}}s'
  },

  halfAMinute: '30s',

  lessThanXMinutes: {
    one: 'just now',
    other: '{{count}}m'
  },

  xMinutes: {
    one: '1m',
    other: '{{count}}m'
  },

  aboutXHours: {
    one: '1h',
    other: '{{count}}h'
  },

  xHours: {
    one: '1h',
    other: '{{count}}h'
  },

  xDays: {
    one: '1d',
    other: '{{count}}d'
  },

  aboutXWeeks: {
    one: '1w',
    other: '{{count}}w'
  },

  xWeeks: {
    one: '1w',
    other: '{{count}}w'
  },

  aboutXMonths: {
    one: '1mth',
    other: '{{count}}mo'
  },

  xMonths: {
    one: '1mth',
    other: '{{count}}mo'
  },

  aboutXYears: {
    one: '1y',
    other: '{{count}}y'
  },

  xYears: {
    one: '1y',
    other: '{{count}}y'
  },

  overXYears: {
    one: '1y',
    other: '{{count}}y'
  },

  almostXYears: {
    one: '1y',
    other: '{{count}}y'
  }
}

export const formatDistance: FormatDistanceFn = (token, count, options) => {
  let result

  const tokenValue = formatDistanceLocale[token]
  if (typeof tokenValue === 'string') {
    result = tokenValue
  } else if (count === 1) {
    result = tokenValue.one
  } else {
    result = tokenValue.other.replace('{{count}}', count.toString())
  }

  if (options?.addSuffix) {
    if (options.comparison && options.comparison > 0) {
      return 'in ' + result
    } else {
      if (token === 'lessThanXMinutes' && count === 1) {
        return result
      }

      return result + ' ago'
    }
  }

  return result
}

export const enGBCustom: Locale = {
  ...enGB,
  code: 'en-GB-Custom',
  formatDistance
}
