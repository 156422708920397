import { Flex } from '@chakra-ui/react'
import { Inspector } from '@sequencehq/core-components'
import { FC, ReactNode } from 'react'
import { salesforceIntegrationConfig } from '../salesforce.integration.config'
import { useIntegrationActive } from 'Integrations/utils/useIntegrationActive'
import { SmallIcon } from 'Integrations/integrationsConfig/common/SmallIcon'

export const SalesforceInspectorItem: FC<{
  children: ReactNode
}> = props => {
  const integrationActive = useIntegrationActive('Salesforce')

  if (!integrationActive) {
    return null
  }

  return (
    <Inspector.IconItem
      data-testid="salesforce.inspector.item"
      content={
        <Inspector.Content
          data-testid="salesforce.inspector.content"
          title="Salesforce"
          icon={<SmallIcon url={salesforceIntegrationConfig.smallLogoUrl} />}
        >
          <Flex flexDirection="column" gap="16px">
            {props.children}
          </Flex>
        </Inspector.Content>
      }
      tooltip="Salesforce"
      icon={<SmallIcon url={salesforceIntegrationConfig.smallLogoUrl} />}
    />
  )
}
