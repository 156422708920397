import { Flex, Text } from '@chakra-ui/react'
import {
  MagicTable,
  MagicTableCell,
  MagicTableCellProductStatus
} from '@sequencehq/tables'
import { CurrentUserId } from 'components/CurrentUserId/CurrentUserId'
import Page from 'components/Page'
import { TagTypes } from 'features/api'
import { Outlet } from 'react-router-dom'
import { useSelector } from 'features/store'
import { Product, ProductsView } from 'modules/Products/types'
import { dateTimeWithFormat } from '@sequencehq/formatters'
import {
  BillingProductModel,
  PricingStructureModel
} from '@sequencehq/core-models'
import CubeIcon from '@heroicons/react/24/outline/CubeIcon'
import { isProductPriceGuard } from 'modules/Products/guards'
import EmptyState from 'components/Loading/EmptyState'
import { ProductsGraphs } from 'modules/Products/ProductGraphs/ProductsGraphs'
import { isDemoEnv } from 'lib/environment/environment'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { IndigoIndigo50, RedRed50, RedRed60 } from '@sequencehq/design-tokens'
import {
  KebabMenu,
  MenuItemBuilder,
  ModalContextProvider,
  Modal
} from '@sequencehq/core-components'
import { ArchiveBoxIcon } from '@heroicons/react/16/solid'
import { ArchiveProductModal } from 'Products/modals/ArchiveProduct.tsx'
import { useBillingProductsLoader } from 'Products/ProductGraphs/useBillingProductsLoader.ts'

export const deriveIsPricePercentage = (
  structure?: PricingStructureModel
): boolean => {
  if (!structure) {
    return false
  }

  if (structure.pricingType === 'VOLUME') {
    return structure.tiers[0].isPricePercentage
  }

  if (structure.pricingType === 'GRADUATED') {
    return structure.tiers[0].isPricePercentage
  }

  if (structure.pricingType === 'LINEAR') {
    return structure.isPricePercentage
  }

  return false
}

const priceStructureToUrlChunk = (structure: PricingStructureModel) => {
  switch (structure.pricingType) {
    case 'FIXED':
      return 'standard'
    case 'ONE_TIME':
      return 'standard'
    case 'LINEAR':
      return 'linear'
    case 'PACKAGE':
      return 'packaged'
    case 'VOLUME':
      return `volume${deriveIsPricePercentage(structure) ? '-percentage' : ''}`
    case 'GRADUATED':
      return `graduated${
        deriveIsPricePercentage(structure) ? '-percentage' : ''
      }`
    case 'SEAT_BASED':
      return 'seat'
  }
}

export const archiveProductBuilder: MenuItemBuilder<Product> = (
  product: Product,
  options = {}
) => {
  return {
    status: 'LIVE',
    action: () => {},
    label: (
      <ModalContextProvider>
        <Modal.Trigger>
          {triggerModal => {
            return (
              <Flex
                flexDirection="row"
                align="center"
                gap={2}
                width="100%"
                onClick={triggerModal}
                data-testid="kebab-menu-archive-button"
              >
                <ArchiveBoxIcon color={RedRed50} height={16} width={16} />
                <Text color={RedRed60}>Archive product</Text>
              </Flex>
            )
          }}
        </Modal.Trigger>
        <ArchiveProductModal product={product} onSuccess={options.onSuccess} />
      </ModalContextProvider>
    )
  }
}

export const Products = () => {
  const flags = useFlags()

  const { magicTableAdapter, reload } = useBillingProductsLoader()

  const resetKey = useSelector(state => state.apiCaching.tags['Products'])

  const emptyContent = {
    title: 'No products found',
    description: 'Create a product to get started.',
    docLink:
      'https://docs.sequencehq.com/plans-products/creating-plans-products',
    linkCopy: 'Learn more about products'
  }

  return (
    <Flex flexDirection="column" flex={1}>
      <Flex position="sticky" top={0} height="100vh">
        <Page
          title="Products"
          addNewConfig={{
            href: flags.useUnifiedPriceEditor
              ? '/products/new'
              : '/products/create'
          }}
          paddingBottom={0}
        >
          <CurrentUserId>
            {userId => (
              <MagicTable<ProductsView, TagTypes>
                entityNamePlural="products"
                entityIcon={props => <CubeIcon {...props} />}
                resetKey={resetKey}
                //@ts-expect-error - TODO: fix this once magic table is refactored not to be tightly coupled with rtk-query
                useLazyQuery={magicTableAdapter}
                sequenceUserId={userId}
                rowPath={(row: BillingProductModel, isSubRow: boolean) => {
                  if (isSubRow && isProductPriceGuard(row)) {
                    return `/products/${
                      row.productId
                    }/price/${priceStructureToUrlChunk(row.structure)}/${
                      row.id
                    }`
                  } else {
                    return `/products/${row.id}`
                  }
                }}
                columns={[
                  {
                    id: 'name',
                    header: 'Product',
                    accessorKey: 'name',
                    cell: row => {
                      return (
                        <MagicTableCell
                          text={row.getValue<string>()}
                          textColor={IndigoIndigo50}
                        />
                      )
                    }
                  },
                  {
                    id: 'status',
                    header: 'Status',
                    cell: () => {
                      //TODO: get the product status from the row
                      return (
                        <MagicTableCellProductStatus productStatus="ACTIVE" />
                      )
                    }
                  },
                  {
                    id: 'priceVariants',
                    header: 'Price Variants',
                    accessorKey: 'prices',
                    cell: row => {
                      const value =
                        row.getValue<BillingProductModel['prices']>()
                      return <MagicTableCell text={value.length || '—'} />
                    }
                  },
                  {
                    id: 'createdAt',
                    header: 'Created',
                    accessorKey: 'createdAt',
                    cell: row => (
                      <div data-testid="price-list-created-at-row">
                        <MagicTableCell
                          text={dateTimeWithFormat(
                            row.getValue<BillingProductModel['createdAt']>(),
                            'd MMM, yyyy'
                          )}
                        />
                      </div>
                    )
                  },
                  {
                    id: 'updatedAt',
                    header: 'Updated',
                    accessorKey: 'updatedAt',
                    cell: row => (
                      <div data-testid="price-list-updated-at-row">
                        <MagicTableCell
                          text={dateTimeWithFormat(
                            row.getValue<BillingProductModel['updatedAt']>(),
                            'd MMM, HH:mm'
                          )}
                        />
                      </div>
                    )
                  }
                ]}
                emptyState={props => (
                  <EmptyState emptyContent={emptyContent} {...props} />
                )}
                topContent={isDemoEnv() ? <ProductsGraphs /> : undefined}
                kebabMenu={(model: Product, props) => (
                  <div data-testid="price-list-kebab-menu">
                    <KebabMenu
                      menuItems={[archiveProductBuilder].map(builder =>
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        builder(model, { onSuccess: reload })
                      )}
                      renderListInPortal
                      flags={flags}
                      {...props}
                    />
                  </div>
                )}
              />
            )}
          </CurrentUserId>
          <Outlet />
        </Page>
      </Flex>
    </Flex>
  )
}
