import {
  CubeReducerState,
  ValidationResult
} from 'modules/Cube/domain/cube.domain.types'

export const taxRateRequired = (
  prevState: CubeReducerState
): ValidationResult[] | null => {
  if (prevState.configuration.features.newTaxRateSettings) {
    return null
  }

  const customer = prevState.data.customers[prevState.data.common.customerId]

  if (!customer) {
    return null
  }

  if (customer.taxStatus !== 'TAXED') {
    return null
  }

  if (prevState.data.schedule.taxRateId) {
    return null
  }

  return [
    {
      message: 'Tax rate is required for this customer',
      metadata: {
        customer
      }
    }
  ]
}
