import { useForm } from '@sequencehq/utils'
import { required } from '@sequencehq/validation'
import { usePricingEditorDomainContext } from 'common/drawers/PricingEditor/communication'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useMemo } from 'react'
import { dashboard20240730Client } from '@sequencehq/api/dashboard/v20240730'
import { useQuery } from 'lib/hooks/useQuery'

export const useProductForm = () => {
  const flags = useFlags()
  const pricingEditorContext = usePricingEditorDomainContext()

  const { data, loadingState: taxCategoriesLoadingState } = useQuery(
    dashboard20240730Client.getTaxCategories
  )

  const taxCategories = data?.data?.items ?? []

  const { fields } = useForm({
    value: pricingEditorContext.queries.rawData.data.product,
    fieldConfiguration: [
      {
        property: 'name',
        validation: [required]
      },
      {
        property: 'label'
      },
      {
        property: 'taxCategoryId',
        validation: flags.showNewTaxRatesSettings ? [required] : [],
        options: taxCategories.map(category => ({
          value: category.id,
          label: category.name
        }))
      }
    ],
    showValidationErrors:
      pricingEditorContext.queries.rawData.editor.showValidationErrors,
    onValidationStateChange: isValid =>
      pricingEditorContext.mutators.common.updateEditor({
        valid: isValid
      }),
    onChange: newData =>
      pricingEditorContext.mutators.common.updateProduct({
        name: newData.name,
        label: newData.label,
        taxCategoryId: newData.taxCategoryId
      })
  })

  const enhancedFields = useMemo(
    () => ({
      ...fields,
      taxCategoryId: {
        ...fields.taxCategoryId,
        hidden:
          !flags.showNewTaxRatesSettings ||
          (taxCategoriesLoadingState === 'READY' && taxCategories.length === 0)
      }
    }),
    [
      fields,
      flags.showNewTaxRatesSettings,
      taxCategories.length,
      taxCategoriesLoadingState
    ]
  )

  return {
    fieldsConfig: enhancedFields
  }
}
