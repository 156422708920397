import { Inspector } from '@sequencehq/core-components'
import { stripeIntegrationConfig } from '../stripe.integration.config'
import { Flex } from '@chakra-ui/react'
import { FC, ReactNode } from 'react'
import { useIntegrationActive } from 'Integrations/utils/useIntegrationActive'
import { SmallIcon } from 'Integrations/integrationsConfig/common/SmallIcon'

export const StripeInspectorItem: FC<{
  alwaysShow?: boolean
  children: ReactNode
}> = props => {
  const integrationActive = useIntegrationActive('Stripe')

  if (!integrationActive && !props.alwaysShow) {
    return null
  }

  return (
    <Inspector.IconItem
      data-testid="stripe.inspector.item"
      tooltip="Stripe"
      icon={<SmallIcon url={stripeIntegrationConfig.smallLogoUrl} />}
      content={
        <Inspector.Content
          data-testid="stripe.inspector.content"
          title="Stripe"
          icon={<SmallIcon url={stripeIntegrationConfig.smallLogoUrl} />}
        >
          <Flex flexDirection="column" gap="16px">
            {props.children}
          </Flex>
        </Inspector.Content>
      }
    />
  )
}
