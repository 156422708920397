import { IntegrationConfig } from 'modules/Integrations/domain'
import logo from './avalaraLogo.svg'
import { AvalaraIntegrationDetail } from './AvalaraIntegrationDetail'
import { AvalaraConfigurationManagement } from './AvalaraConfigurationManagement/AvalaraConfigurationManagement'
import { AvalaraIntegrationManagementInspector } from 'Integrations/integrationsConfig/avalara/AvalaraConfigurationManagement/AvalaraIntegrationManagementInspector.tsx'

export const avalaraIntegrationConfig: IntegrationConfig = {
  logoUrl: logo,
  smallLogoUrl: logo,
  documentationUrl: '',
  title: 'Avalara',
  features: {
    canDeleteLinks: false,
    canUninstall: false,
    isDocumentationOnly: false
  },
  description: 'Automatically calculate sales tax for invoices.',
  components: {
    detail: AvalaraIntegrationDetail,
    management: AvalaraConfigurationManagement,
    inspector: AvalaraIntegrationManagementInspector
  }
}
