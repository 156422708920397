import {
  Dashboardv20240509Api,
  dashboardv20240509Client
} from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { IntegrationServices } from '@sequencehq/api/dist/utils/commonEnums'
import { INTEGRATIONS_CONFIG } from 'modules/Integrations/integrationsConfig/integrations.config'

export type v1ApiLoadedData = {
  integrations: Array<
    Dashboardv20240509Api.GetIntegrations.Integration & {
      configuration: Record<string, unknown>
    }
  >
  featuredIntegrations: Array<IntegrationServices>
}

/**
 * A collection of integrations that do not exist on the service (or in general
 * yet). These are layered onto the response from the API, but should be cleaned
 * away should these be added to the service proper.
 */
const COMING_SOON_INTEGRATIONS: v1ApiLoadedData['integrations'] = [
  {
    //@ts-expect-error this integration isn't real (for now)
    service: 'Attio',
    type: 'CRM',
    state: 'COMING_SOON',
    enabled: false,
    configuration: {}
  },
  {
    //@ts-expect-error this integration isn't real (for now)
    service: 'Anrok',
    //@ts-expect-error this also isn't a real type (for now)
    type: 'Tax',
    state: 'COMING_SOON',
    enabled: false,
    configuration: {}
  },
  {
    //@ts-expect-error this integration isn't real (for now)
    service: 'Avalara',
    //@ts-expect-error this also isn't a real type (for now)
    type: 'Tax',
    state: 'COMING_SOON',
    enabled: false,
    configuration: {}
  },
  {
    //@ts-expect-error this integration isn't real (for now)
    service: 'Go_Cardless',
    type: 'Payments',
    state: 'COMING_SOON',
    enabled: false,
    configuration: {}
  },
  {
    //@ts-expect-error this integration isn't real (for now)
    service: 'Dropbox_Sign',
    //@ts-expect-error this integration type isn't real (for now)
    type: 'E-signatures',
    state: 'AVAILABLE',
    enabled: true,
    configuration: {}
  }
]

export const loadData = async (): Promise<v1ApiLoadedData> => {
  const response = await dashboardv20240509Client.getIntegrations()

  if (response.error) {
    throw new Error('Failed to load integrations')
  }

  const integrations = response.data?.items ?? []

  const resolvedIntegrations = await Promise.all(
    [...COMING_SOON_INTEGRATIONS, ...integrations].map(async int => {
      const config = INTEGRATIONS_CONFIG[int.service]
      if (!config?.communication?.configuration?.loader) {
        return {
          ...int,
          configuration: {}
        }
      }

      return {
        ...int,
        configuration:
          (await config.communication.configuration.loader())?.data ?? {}
      }
    })
  )

  return {
    integrations: resolvedIntegrations,
    featuredIntegrations: ['Xero', 'Stripe', 'HubSpot']
  }
}
