import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex
} from '@chakra-ui/react'
import CheckCircleIcon from '@heroicons/react/16/solid/CheckCircleIcon'
import { XCircleIcon } from '@heroicons/react/16/solid'

import { Badge } from '@sequencehq/core-components'
import { GreenGreen60, GreyGrey30, GreyGrey50 } from '@sequencehq/design-tokens'

import { useContactTable } from './useContactTable'
import { CustomerContact } from 'modules/CustomerContacts/domain/CustomerContacts.domain.types'
import { EmptyContent } from './EmptyContent'
import { KebabMenu } from './KebabMenu'
import { EditContactModal } from 'CustomerContacts/view/modals/EditContactModal.tsx'

function receivesInvoices(contact: CustomerContact) {
  return ['PRIMARY', 'STANDARD'].includes(contact.billingPreference)
}

export const ContactTable = () => {
  const hook = useContactTable()

  const { contacts } = hook

  return (
    <TableContainer
      minWidth="1024px"
      borderRadius="lg"
      overflow="hidden"
      border={`1px solid ${GreyGrey30}`}
      mt={2}
      width="100%"
      boxShadow="0px 0px 2px 0px rgba(0, 0, 0, 0.02), 0px 1px 1px 0px rgba(0, 0, 0, 0.05)"
    >
      <Table variant="v2" width="100%">
        <Thead>
          <Tr>
            <Th>Email</Th>
            <Th>Name</Th>
            <Th>Receives invoices</Th>
            <Th></Th>
          </Tr>
        </Thead>

        <Tbody>
          {contacts.length === 0 && <EmptyContent />}

          {contacts.map(contact => (
            <EditContactModal
              key={`${contact.id}-edit-contact-modal-table-row`}
              contactId={contact.id}
              trigger={openModal => {
                return (
                  <Tr
                    onClick={() => {
                      hook.editContact.editContactById(contact.id)
                      openModal()
                    }}
                  >
                    <Td>{contact.email}</Td>
                    <Td isTruncated>{contact.name}</Td>
                    <Td style={{ width: '100%' }}>
                      {receivesInvoices(contact) ? (
                        <CheckCircleIcon
                          width="16px"
                          height="16px"
                          color={GreenGreen60}
                        />
                      ) : (
                        <XCircleIcon
                          width="16px"
                          height="16px"
                          color={GreyGrey50}
                        />
                      )}
                    </Td>
                    <Td padding="0 8px !important">
                      <Flex
                        alignItems="center"
                        justifyContent="flex-end"
                        gap="8px"
                      >
                        {contact.billingPreference === 'PRIMARY' && (
                          <Badge>Primary</Badge>
                        )}
                        <span onClick={event => event.stopPropagation()}>
                          <KebabMenu contact={contact} hook={hook} />
                        </span>
                      </Flex>
                    </Td>
                  </Tr>
                )
              }}
            />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
