import { UTCDate } from '@date-fns/utc'
import {
  AvailableFeaturesForPhase,
  AvailableProductFeatures,
  CubeReducerState,
  Phase,
  Price,
  Product,
  DisabledReason,
  DisabledReasonType,
  VisibleEnabledFeatureAvailable,
  CubeStatus,
  ResolvedPhase
} from 'modules/Cube/domain/cube.domain.types'
import isAfter from 'date-fns/isAfter'
import { compose } from 'lodash/fp'
import { getEditQuoteAvailable } from 'modules/Cube/domain/postActionStages/queries/availableFeatures/quote/getEditQuoteAvailable'
import { quoteStatusAdapter } from 'modules/Cube/utils/status.adapter'

export const getCanAddProduct = (prevState: CubeReducerState) => {
  return ![
    CubeStatus.ScheduleCompleted,
    CubeStatus.ScheduleCancelled,
    CubeStatus.ScheduleSuspended
  ].includes(prevState.data.common.status)
}

export type ProductLevelCheckContext = {
  prevState: CubeReducerState
  resolvedPhase: ResolvedPhase
  orderedPhases: ResolvedPhase[]
  allResolvedPhases: Record<Phase['id'], ResolvedPhase>
  product: Product
  price?: Price
  today: UTCDate
}
type ProductLevelAvailabilityCheck = (
  ctx: ProductLevelCheckContext
) => (prevCheckResults: Array<DisabledReason>) => Array<DisabledReason>

export type PhaseLevelCheckContext = {
  prevState: CubeReducerState
  resolvedPhase: ResolvedPhase
  orderedPhases: ResolvedPhase[]
  allResolvedPhases: Record<Phase['id'], ResolvedPhase>
  today: UTCDate
}
type PhaseLevelAvailabilityCheck = (
  ctx: PhaseLevelCheckContext
) => (prevCheckResults: Array<DisabledReason>) => Array<DisabledReason>

export const evaluateChecksForProduct =
  (config: { visibleIfDisabled: boolean }) =>
  (
    ...checks: Array<
      ProductLevelAvailabilityCheck | PhaseLevelAvailabilityCheck
    >
  ) =>
  (ctx: ProductLevelCheckContext): VisibleEnabledFeatureAvailable => {
    const result = compose(...checks.map(check => check(ctx)))(
      []
    ) as Array<DisabledReason>

    return {
      available: {
        visible: config.visibleIfDisabled ? true : !result.length,
        enabled: !result.length
      },
      reasons: result.filter(Boolean)
    }
  }

export const evaluateChecksForPhase =
  (config: { visibleIfDisabled: boolean }) =>
  (...checks: Array<PhaseLevelAvailabilityCheck>) =>
  (ctx: PhaseLevelCheckContext): VisibleEnabledFeatureAvailable => {
    const result = compose(...checks.map(check => check(ctx)))(
      []
    ) as Array<DisabledReason>

    return {
      available: {
        visible: config.visibleIfDisabled ? true : !result.length,
        enabled: !result.length
      },
      reasons: result.filter(Boolean)
    }
  }

/** Product level checks */
export const checkValidStatusForMinimumEditing: PhaseLevelAvailabilityCheck =
  ctx => prevCheckResults => {
    if (
      [
        CubeStatus.ScheduleCompleted,
        CubeStatus.ScheduleCancelled,
        CubeStatus.ScheduleSuspended
      ].includes(ctx.prevState.data.common.status)
    ) {
      return [
        ...prevCheckResults,
        {
          reasonType: DisabledReasonType.InvalidStatus,
          metadata: {
            statusValue: ctx.prevState.data.common.status
          }
        }
      ]
    }

    return prevCheckResults
  }
export const checkValidStatusForPricingChanges: PhaseLevelAvailabilityCheck =
  ctx => prevCheckResults => {
    const editQuoteAvailable = getEditQuoteAvailable(ctx.prevState)
    if (!editQuoteAvailable.available.enabled) {
      return [...prevCheckResults, ...editQuoteAvailable.reasons]
    }

    if (
      [CubeStatus.ScheduleCompleted, CubeStatus.ScheduleCancelled].includes(
        ctx.prevState.data.common.status
      )
    ) {
      return [
        ...prevCheckResults,
        {
          reasonType: DisabledReasonType.CompletedSchedule,
          metadata: {
            statusValue: ctx.prevState.data.common.status
          }
        }
      ]
    }

    return prevCheckResults
  }

export const checkIfMinimumAlreadyExists: PhaseLevelAvailabilityCheck =
  ctx => prevCheckResults => {
    const minimumExists = ctx.resolvedPhase.minimums.length > 0

    if (minimumExists) {
      return [
        ...prevCheckResults,
        {
          reasonType: DisabledReasonType.MaxMinimums,
          metadata: {
            minimums: ctx.resolvedPhase.minimums.length
          }
        }
      ]
    }

    return prevCheckResults
  }

export const checkIfAnyApplicablePricesForMinimum: PhaseLevelAvailabilityCheck =
  ctx => prevCheckResults => {
    const applicablePrices = ctx.resolvedPhase.prices
      .filter(Boolean)
      .find(price => 'usageMetricId' in price.structure)

    if (!applicablePrices) {
      return [
        ...prevCheckResults,
        {
          reasonType: DisabledReasonType.NoValidPrices,
          metadata: {
            priceIds: ctx.resolvedPhase.prices.map(({ id }) => id)
          }
        }
      ]
    }

    return prevCheckResults
  }

/**
 * For this check we are only interested in the active, persisted, start date for the phase.
 * @param ctx
 * @returns
 */
export const checkIfPhaseCompletedAndActive: PhaseLevelAvailabilityCheck =
  ctx => prevCheckResults => {
    const persistedPhaseEndDate =
      ctx.prevState.initialQueries.resolvedPhases[ctx.resolvedPhase.id]?.dates
        .absolute.end

    if (
      [
        CubeStatus.ScheduleActive,
        CubeStatus.ScheduleCompleted,
        CubeStatus.ScheduleCancelled
      ].includes(ctx.prevState.data.common.status) &&
      persistedPhaseEndDate &&
      ctx.today &&
      isAfter(ctx.today, persistedPhaseEndDate)
    ) {
      return [
        ...prevCheckResults,
        {
          reasonType: DisabledReasonType.CompletedPhase,
          metadata: {
            phaseEndDate: persistedPhaseEndDate
          }
        }
      ]
    }

    return prevCheckResults
  }

/**
 * Check to see if the price is an in advance price, and the phase in which it is contained
 * has begun.
 * @param ctx
 * @returns
 */
export const checkIfInAdvanceAndPhaseStarted: ProductLevelAvailabilityCheck =
  ctx => prevCheckResults => {
    if (
      ctx.prevState.data.common.status === CubeStatus.ScheduleActive &&
      ctx.resolvedPhase.dates.absolute.start &&
      isAfter(ctx.today, ctx.resolvedPhase.dates.absolute.start) &&
      ctx.price?.billingType === 'IN_ADVANCE'
    ) {
      return [
        ...prevCheckResults,
        {
          reasonType: DisabledReasonType.InvalidBillingType,
          metadata: {
            phaseStartDate: ctx.resolvedPhase.dates.absolute.start
          }
        }
      ]
    }

    return prevCheckResults
  }

/**
 * For this check we are only interested in the active, persisted, start date for the phase.
 * @param ctx
 * @returns
 */
export const checkIfPhaseStartedAndActive: PhaseLevelAvailabilityCheck =
  ctx => prevCheckResults => {
    const persistedPhaseStartDate =
      ctx.prevState.initialQueries.resolvedPhases[ctx.resolvedPhase.id]?.dates
        .absolute.start

    if (
      [
        CubeStatus.ScheduleActive,
        CubeStatus.ScheduleCompleted,
        CubeStatus.ScheduleCancelled
      ].includes(ctx.prevState.data.common.status) &&
      persistedPhaseStartDate &&
      ctx.today &&
      isAfter(ctx.today, persistedPhaseStartDate)
    ) {
      return [
        ...prevCheckResults,
        {
          reasonType: DisabledReasonType.CompletedPhase,
          metadata: {
            phaseStartDate: persistedPhaseStartDate
          }
        }
      ]
    }

    return prevCheckResults
  }

export const checkIfGlobalDiscountAlreadyExists: PhaseLevelAvailabilityCheck =
  ctx => prevCheckResults => {
    if (
      ctx.resolvedPhase.discounts.find(discount => discount.applyToAllPrices)
    ) {
      return [
        ...prevCheckResults,
        {
          reasonType: DisabledReasonType.GlobalDiscountExists,
          metadata: {
            discountIds: ctx.resolvedPhase.discounts.map(({ id }) => id)
          }
        }
      ]
    }

    return prevCheckResults
  }

export const checkIfPhaseFirstPhase: PhaseLevelAvailabilityCheck =
  ctx => prevCheckResults => {
    if (ctx.orderedPhases[0].id !== ctx.resolvedPhase.id) {
      return [
        ...prevCheckResults,
        {
          reasonType: DisabledReasonType.NotFirstPhase,
          metadata: {}
        }
      ]
    }

    return prevCheckResults
  }

export const checkIfAnyInvalidProration: PhaseLevelAvailabilityCheck =
  ctx => prevCheckResults => {
    const phaseIndex = ctx.orderedPhases.findIndex(
      p => p.id === ctx.resolvedPhase.id
    )
    const nextPhase = ctx.orderedPhases[phaseIndex + 1]

    if (!nextPhase) {
      return [
        ...prevCheckResults,
        {
          reasonType: DisabledReasonType.NoProration,
          metadata: {}
        }
      ]
    }

    if (
      !Object.values(nextPhase.analysis?.proration ?? {}).some(
        arr => arr.length
      )
    ) {
      return [
        ...prevCheckResults,
        {
          reasonType: DisabledReasonType.NoProration,
          metadata: {}
        }
      ]
    }

    return prevCheckResults
  }
/**
 * As these are a composition, the highest priority message should go at the end
 */
export const getPriceDeletionAvailableForProductInPhase =
  evaluateChecksForProduct({ visibleIfDisabled: true })(
    checkIfPhaseCompletedAndActive,
    checkIfInAdvanceAndPhaseStarted,
    checkValidStatusForPricingChanges
  )

export const getPriceEditingAvailableForProductInPhase =
  evaluateChecksForProduct({ visibleIfDisabled: true })(
    checkIfPhaseCompletedAndActive,
    checkValidStatusForPricingChanges
  )

export const getDiscountEditingAvailableForProductInPhase =
  evaluateChecksForProduct({ visibleIfDisabled: true })(
    checkIfPhaseCompletedAndActive,
    checkValidStatusForPricingChanges
  )

export const getDiscountDeletingAvailableForProductInPhase =
  evaluateChecksForProduct({ visibleIfDisabled: true })(
    checkIfPhaseCompletedAndActive,
    checkValidStatusForPricingChanges
  )

export const checkCanEditPricingModelInQuote: ProductLevelAvailabilityCheck =
  ctx => prevCheckResults => {
    const isQuote = Boolean(
      quoteStatusAdapter.out(ctx.prevState.data.common.status)
    )

    const productIdsInPhases = Object.entries(ctx.allResolvedPhases).flatMap(
      ([, phase]) => phase.products.map(product => product.id)
    )

    const isProductIdAlreadySelected: boolean = productIdsInPhases.includes(
      ctx.product.id
    )

    if (isQuote && isProductIdAlreadySelected) {
      return [
        ...prevCheckResults,
        {
          reasonType: DisabledReasonType.CantEditPricingModelOnQuotePrice,
          metadata: {}
        }
      ]
    }

    return prevCheckResults
  }

export const getCanEditPricingModelForProductInPhase = evaluateChecksForProduct(
  { visibleIfDisabled: true }
)(checkCanEditPricingModelInQuote)

export const getProductFeatureAvailabilityForPhase = (
  ctx: ProductLevelCheckContext
): AvailableProductFeatures => {
  return {
    edit: getPriceEditingAvailableForProductInPhase(ctx),
    delete: getPriceDeletionAvailableForProductInPhase(ctx),
    editDiscount: getDiscountEditingAvailableForProductInPhase(ctx),
    deleteDiscount: getDiscountEditingAvailableForProductInPhase(ctx),
    canEditPricingModel: getCanEditPricingModelForProductInPhase(ctx)
  }
}

export const getEditMinimumAvailable = evaluateChecksForPhase({
  visibleIfDisabled: true
})(checkIfPhaseCompletedAndActive, checkValidStatusForMinimumEditing)

export const getAlignDatesAvailable = evaluateChecksForPhase({
  visibleIfDisabled: false
})(checkIfAnyInvalidProration, checkValidStatusForPricingChanges)

export const getDeleteMinimumAvailable = evaluateChecksForPhase({
  visibleIfDisabled: true
})(checkIfPhaseCompletedAndActive, checkValidStatusForMinimumEditing)

export const getAddMinimumAvailable = evaluateChecksForPhase({
  visibleIfDisabled: true
})(
  checkIfMinimumAlreadyExists,
  checkIfAnyApplicablePricesForMinimum,
  checkIfPhaseCompletedAndActive,
  checkValidStatusForMinimumEditing
)

export const getAddGlobalDiscountAvailable = evaluateChecksForPhase({
  visibleIfDisabled: true
})(
  checkIfGlobalDiscountAlreadyExists,
  checkIfPhaseCompletedAndActive,
  checkValidStatusForPricingChanges
)

export const getAddProductAvailable = evaluateChecksForPhase({
  visibleIfDisabled: true
})(checkIfPhaseCompletedAndActive, checkValidStatusForPricingChanges)

export const getStartDateEditingAvailable = evaluateChecksForPhase({
  visibleIfDisabled: true
})(
  checkIfPhaseStartedAndActive,
  checkValidStatusForPricingChanges,
  checkIfPhaseFirstPhase
)

export const getDurationEditingAvailable = evaluateChecksForPhase({
  visibleIfDisabled: true
})(checkIfPhaseCompletedAndActive, checkValidStatusForPricingChanges)

export const getAvailableFeaturesForPhases = (ctx: {
  resolvedPhases: Record<Phase['id'], ResolvedPhase>
  orderedPhases: ResolvedPhase[]
  prevState: CubeReducerState
}): Record<Phase['id'], AvailableFeaturesForPhase> => {
  const today = new UTCDate()

  return Object.values(ctx.resolvedPhases).reduce(
    (
      phaseFeaturesAcc,
      resolvedPhase
    ): Record<Phase['id'], AvailableFeaturesForPhase> => {
      const phaseLevelCheckContext = {
        prevState: ctx.prevState,
        resolvedPhase,
        allResolvedPhases: ctx.resolvedPhases,
        orderedPhases: ctx.orderedPhases,
        today
      }

      const productLevelFeaturesForThisPhase: Record<
        Product['id'],
        AvailableProductFeatures
      > = Object.values(ctx.prevState.data.products).reduce(
        (productFeaturesAcc, product: Product) => ({
          ...productFeaturesAcc,
          [product.id]: getProductFeatureAvailabilityForPhase({
            ...phaseLevelCheckContext,
            product,
            price: resolvedPhase.prices.find(
              price => price.productId === product.id
            )
          })
        }),
        {}
      )

      const allProductDiscountUnavailableReasons = resolvedPhase.prices
        .flatMap(price => {
          const productId = price.productId
          return productLevelFeaturesForThisPhase[productId].editDiscount
            .reasons
        })
        .filter(Boolean)

      const globalDiscountEditingAvailable = {
        available: {
          visible: true,
          enabled: !allProductDiscountUnavailableReasons.length
        },
        reasons: allProductDiscountUnavailableReasons
      }

      const featuresForPhase: AvailableFeaturesForPhase = {
        products: productLevelFeaturesForThisPhase,
        phase: {
          discount: {
            edit: globalDiscountEditingAvailable,
            add: getAddGlobalDiscountAvailable(phaseLevelCheckContext),
            delete: globalDiscountEditingAvailable
          },
          product: {
            add: getAddProductAvailable(phaseLevelCheckContext)
          },
          minimum: {
            add: getAddMinimumAvailable(phaseLevelCheckContext),
            delete: getDeleteMinimumAvailable(phaseLevelCheckContext),
            edit: getEditMinimumAvailable(phaseLevelCheckContext)
          },
          dates: {
            alignDates: getAlignDatesAvailable(phaseLevelCheckContext),
            editStart: getStartDateEditingAvailable(phaseLevelCheckContext),
            editDuration: getDurationEditingAvailable(phaseLevelCheckContext)
          }
        }
      }
      return {
        ...phaseFeaturesAcc,
        [resolvedPhase.id]: featuresForPhase
      }
    },
    {}
  )
}
