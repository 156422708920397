import { useCallback } from 'react'
import { useQuery } from '@sequencehq/api/utils'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'

export type AccountingPeriod = {
  id: string
  dates: {
    start: string
    endInclusive: string
  }
}

type MagicTableLoader = () => [
  () => Promise<{
    items: AccountingPeriod[] | null | undefined
    pagination: {
      totalResultSize: number | undefined
    }
  }>,
  {
    data: {
      value: () => {
        items: AccountingPeriod[] | null | undefined
        pagination: {
          totalResultSize: number | undefined
        }
      } | null
    }
    isFetching: boolean
  }
]

type UseJournalReportTable = () => {
  magicTableLoader: MagicTableLoader
}

export const useJournalReportTable: UseJournalReportTable = () => {
  /**
   * Lookup for grabbing data for the table will go here.
   */
  const accountingPeriodQuery = useQuery(
    dashboardv99990101Client.getAccountingPeriods,
    undefined,
    {
      select: data => {
        return (data?.items ?? []).map(item => ({
          id: item.id,
          dates: item.accountingPeriod
        }))
      }
    }
  )

  /**
   * We need to include the totalResultSize in the pagination object,
   * otherwise magic table will explode.
   */
  const magicTableLoader = useCallback((): ReturnType<MagicTableLoader> => {
    return [
      async () => {
        const res = await accountingPeriodQuery.refetch()
        return {
          items: res.data,
          pagination: {
            totalResultSize: res.data?.length
          }
        }
      },
      {
        data: {
          value: () =>
            accountingPeriodQuery.data
              ? {
                  items: accountingPeriodQuery.data,
                  pagination: {
                    totalResultSize: accountingPeriodQuery.data?.length
                  }
                }
              : null
        },
        isFetching: accountingPeriodQuery.isFetching
      }
    ]
  }, [accountingPeriodQuery])

  return {
    magicTableLoader
  }
}
