import { TaxCategory, TaxRate } from 'Settings/domain/taxRates.types'
import * as Sentry from '@sentry/react'
import { pick } from 'lodash'
import { dequal } from 'dequal'
import {
  dashboardv99990101Client,
  type Dashboardv99990101Api
} from '@sequencehq/api/dashboard/v99990101'
import { useMutation } from '@sequencehq/api/utils'
import { useNotifications } from 'lib/hooks/useNotifications'

type CreateTaxRateBody = Dashboardv99990101Api.PostTaxRate.CreateTaxRateBody
type CreateNewTaxRateVersionBody =
  Dashboardv99990101Api.PostTaxRate.CreateNewTaxRateVersionBody

export type UpdateTaxCategoryInput = Pick<
  TaxCategory,
  'name' | 'description'
> & {
  countries: Partial<Record<TaxRate['country'], TaxRate['amount']>> | undefined
}

export function useUpdateTaxCategory() {
  const notifications = useNotifications()

  const mutationOptions = {
    onError: (err: any) => {
      Sentry.captureException(err)
    }
  }

  const { mutateAsync: postNewTaxCategoryVersion } = useMutation(
    dashboardv99990101Client.postNewTaxCategoryVersion
  )
  const { mutateAsync: postTaxRate, isError: postTaxRateError } = useMutation(
    dashboardv99990101Client.postTaxRate
  )
  const {
    mutateAsync: postNewTaxRateVersion,
    isError: postNewTaxRateVersionError
  } = useMutation(dashboardv99990101Client.postNewTaxRateVersion)

  const updateTaxCategory = async (
    taxCategory: TaxCategory,
    taxCategoryInput: UpdateTaxCategoryInput
  ): Promise<TaxCategory | null | undefined> => {
    return await postNewTaxCategoryVersion(
      {
        id: taxCategory.id,
        body: {
          version: taxCategory.version + 1,
          name: taxCategoryInput.name,
          description: taxCategoryInput.description,
          isPublished: true
        }
      },
      mutationOptions
    )
  }

  const updateTaxRatesForCategory = async (
    taxCategory: TaxCategory,
    countries:
      | Partial<Record<TaxRate['country'], TaxRate['amount']>>
      | undefined
  ) => {
    if (!countries) {
      return
    }

    const taxCategoryId = taxCategory.id

    const existingTaxRatesToUpdate: {
      id: string
      body: CreateNewTaxRateVersionBody
    }[] = []
    const newTaxRatesToCreate: { body: CreateTaxRateBody }[] = []

    Object.entries(countries).forEach(([country, amount]) => {
      const existingTaxRateForCountry = taxCategory.taxRates.find(
        rate => rate.country === country
      )

      if (existingTaxRateForCountry) {
        if (existingTaxRateForCountry.amount !== amount) {
          existingTaxRatesToUpdate.push({
            id: existingTaxRateForCountry.id,
            body: {
              amount,
              country: country as TaxRate['country'],
              name: existingTaxRateForCountry.name,
              invoiceName: existingTaxRateForCountry.invoiceName,
              invoiceShortName: existingTaxRateForCountry.invoiceShortName,
              isPublished: true,
              taxCategoryId
            }
          })
        }
      } else {
        newTaxRatesToCreate.push({
          body: {
            amount,
            country: country as TaxRate['country'],
            name: `${country} ${taxCategory.name}`,
            invoiceName: `${country} ${taxCategory.name}`,
            invoiceShortName: `${country} ${taxCategory.name}`,
            isPublished: true,
            taxCategoryId
          }
        })
      }
    })

    return await Promise.all([
      ...existingTaxRatesToUpdate.map(taxRateToUpdate => {
        return postNewTaxRateVersion(taxRateToUpdate, mutationOptions)
      }),
      ...newTaxRatesToCreate.map(newTaxRate => {
        return postTaxRate(newTaxRate, mutationOptions)
      })
    ])
  }

  return async (
    initialTaxCategory: TaxCategory,
    taxCategoryInput: UpdateTaxCategoryInput
  ) => {
    const wasTaxCategoryModified = !dequal(
      pick(initialTaxCategory, ['name', 'description']),
      pick(taxCategoryInput, ['name', 'description'])
    )

    let taxCategory: TaxCategory | null | undefined = initialTaxCategory

    if (wasTaxCategoryModified) {
      taxCategory = await updateTaxCategory(taxCategory, taxCategoryInput)
    }

    // If tax category is undefined, updateTaxCategory failed and we should not proceed
    if (!taxCategory) {
      notifications.displayNotification('Failed to update tax category', {
        type: 'error'
      })

      return
    }

    await updateTaxRatesForCategory(taxCategory, taxCategoryInput.countries)

    // See if there was any error in the tax rates update results
    if (postTaxRateError || postNewTaxRateVersionError) {
      // If so, show a warning that some of the tax rates failed to save and return
      notifications.displayNotification(
        'Tax category updated but some tax rates may not have been saved',
        {
          type: 'warning'
        }
      )

      return
    }

    notifications.displayNotification('Tax category updated', {
      type: 'success'
    })
  }
}
