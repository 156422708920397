import { useCallback, useEffect, useState } from 'react'
import { dashboard20240730Client } from '@sequencehq/api/dashboard/v20240730'
import { Customer } from 'components/Customers/types'

export const useFetchCustomers = () => {
  const [customers, setCustomers] = useState<
    Omit<Customer, 'contacts'>[] | null
  >(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)

  const fetchCustomers = useCallback(async () => {
    setLoading(true)
    setError(null)
    try {
      const customersData = await dashboard20240730Client.getCustomers({
        limit: 1000
      })

      if (customersData.data?.items) {
        setCustomers(customersData.data.items)
      }
    } catch (e) {
      setError(e as Error)
      console.error(e)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    void fetchCustomers()
  }, [fetchCustomers])

  return { customers, loading, error, refetch: fetchCustomers }
}
