import { useCubeContext } from 'modules/Cube/communication/internal/cube.domain.context'
import { useCallback, useEffect, useMemo, useState } from 'react'
import add from 'date-fns/add'
import formatInTimeZone from 'date-fns-tz/formatInTimeZone'
import uniqBy from 'lodash/fp/uniqBy'
import isAfter from 'date-fns/isAfter'
import { UTCDate } from '@date-fns/utc'
import {
  SchedulePreviewArguments,
  SchedulePreviewResponse
} from 'modules/Cube/domain/cube.domain.types'

type UseTimelineOverviewWidget = () => {
  loading: boolean
  showAllInvoices: boolean
  updateShowAllInvoices: (showAllInvoices: boolean) => void
  data?: {
    billingStartDate: string
    billingEndDate: string
    invoiceDates: string[]
    finalInvoiceAfterEndDate: boolean
  }
}

export const useTimelineOverviewWidget: UseTimelineOverviewWidget = () => {
  const [showAllInvoices, setShowAllInvoices] = useState(false)
  const cubeContext = useCubeContext()
  const [loading, setLoading] = useState(false)
  const [preview, setPreview] = useState<SchedulePreviewResponse | null>(null)

  const scheduleDates = useMemo(() => {
    return {
      start: cubeContext.queries.pricingDates.start,
      end: cubeContext.queries.pricingDates.end
    }
  }, [cubeContext])

  const formattedDates = useMemo(() => {
    return {
      startDate:
        scheduleDates.start && !isNaN(scheduleDates.start.getTime())
          ? formatInTimeZone(
              scheduleDates.start,
              'UTC',
              "yyyy-MM-dd'T'HH:mm:ss'Z'"
            )
          : '',
      endDate:
        scheduleDates.end && !isNaN(scheduleDates.end.getTime())
          ? formatInTimeZone(
              scheduleDates.end,
              'UTC',
              "yyyy-MM-dd'T'HH:mm:ss'Z'"
            )
          : ''
    }
  }, [scheduleDates.start, scheduleDates.end])

  /**
   * We stringify these arguments to reduce the sensitivity of the useEffect,
   * since it will otherwise be easily triggered by, say, changes to the prices
   * datastructure even if the changes are not relevant to the call.
   *
   * Caching means that this doesn't impact the networking side of things, but it
   * is annoying to manage the loading states and such otherwise.
   */
  const allSchedulePrices = useMemo(() => {
    return cubeContext.queries.orderedPhases.flatMap(phase => phase.prices)
  }, [cubeContext])

  const stringifiedLoadPreviewArguments = useMemo(() => {
    return JSON.stringify({
      ...formattedDates,
      prices: allSchedulePrices,
      recurrenceDayOfMonth:
        cubeContext.queries.rawData.data.schedule.recurrenceDayOfMonth
    })
  }, [
    formattedDates,
    cubeContext.queries.rawData.data.schedule.recurrenceDayOfMonth,
    allSchedulePrices
  ])

  const skipTimelineFetch = useMemo(() => {
    if (!scheduleDates.start || !scheduleDates.end) {
      return true
    }
    return false
  }, [scheduleDates])

  useEffect(() => {
    if (skipTimelineFetch) {
      return
    }

    const parsedArguments = JSON.parse(
      stringifiedLoadPreviewArguments
    ) as SchedulePreviewArguments

    setLoading(true)
    void cubeContext.mutators.external.in.schedule
      .preview(parsedArguments)
      .then(res => setPreview(res))
      .finally(() => setLoading(false))
  }, [
    stringifiedLoadPreviewArguments,
    skipTimelineFetch,
    cubeContext.mutators.external.in.schedule
  ])

  const hasInAdvancePrices = useMemo(() => {
    return allSchedulePrices.some(price => {
      return price?.billingType === 'IN_ADVANCE'
    })
  }, [allSchedulePrices])

  const hasInArrearsPrices = useMemo(() => {
    return allSchedulePrices.some(price => {
      return price?.billingType === 'IN_ARREARS'
    })
  }, [allSchedulePrices])

  const data = useMemo(() => {
    if (!scheduleDates.start || !scheduleDates.end || !preview) {
      return
    }

    const inArrearsInvoiceDates = preview.map(billingPeriod =>
      add(new UTCDate(billingPeriod.end), {
        days: 1
      })
    )

    const inAdvanceInvoiceDates = preview.map(
      billingPeriod => new UTCDate(billingPeriod.start)
    )

    const uniqueSortedInvoiceDates = uniqBy<Date>(date =>
      formatInTimeZone(date, 'UTC', 'd MMM yyyy')
    )([
      ...(hasInArrearsPrices ? inArrearsInvoiceDates : []),
      ...(hasInAdvancePrices ? inAdvanceInvoiceDates : [])
    ]).sort((a, b) =>
      formatInTimeZone(a, 'UTC', 'yyyyMMdd') >
      formatInTimeZone(b, 'UTC', 'yyyyMMdd')
        ? 1
        : -1
    )

    const finalInvoiceDate =
      uniqueSortedInvoiceDates[uniqueSortedInvoiceDates.length - 1]

    return {
      billingStartDate: formatInTimeZone(
        scheduleDates.start,
        'UTC',
        'd MMM yyyy'
      ),
      billingEndDate: formatInTimeZone(scheduleDates.end, 'UTC', 'd MMM yyyy'),
      invoiceDates: uniqueSortedInvoiceDates.map(date =>
        formatInTimeZone(date, 'UTC', 'd MMM yyyy')
      ),
      finalInvoiceAfterEndDate: isAfter(finalInvoiceDate, scheduleDates.end)
    }
  }, [hasInArrearsPrices, hasInAdvancePrices, scheduleDates, preview])

  const updateShowAllInvoices = useCallback((newValue: boolean) => {
    setShowAllInvoices(newValue)
  }, [])

  return {
    loading: loading && !data,
    data,
    showAllInvoices: showAllInvoices || (data?.invoiceDates?.length ?? 0) < 3,
    updateShowAllInvoices
  }
}
