import { UTCDate } from '@date-fns/utc'
import { addAndAccountForDST } from '@sequencehq/utils'
import isAfter from 'date-fns/isAfter'
import {
  CubeReducerState,
  CubeStatus,
  ResolvedPhase
} from 'modules/Cube/domain/cube.domain.types'
import { getPhaseAnalysis } from 'modules/Cube/domain/postActionStages/queries/getPhaseAnalysis/getPhaseAnalysis'

/**
 * The rawData for phases has references to other ids, as well as other
 * information such as relative dates which may need some processing to
 * make use of. This query handles all that processing up front, allowing
 * for easy access!
 * @param prevState
 */
export const getResolvedPhaseData = (
  prevState: CubeReducerState
): Record<ResolvedPhase['id'], ResolvedPhase> => {
  const orderedPhases = prevState.data.common.phaseIds
    .map(phaseId => prevState.data.phases[phaseId])
    .filter(Boolean)

  /**
   * Phase analysis relies on the analysis results from the previous phase. Therefore, we
   * need to build up the resolved phased data in a reduce, rather than a simple
   * map.
   */

  return orderedPhases.reduce((acc, phase, idx) => {
    const phaseDiscounts = phase.discountIds.map(
      discountId => prevState.data.discounts[discountId]
    )
    const previousPhaseEndDate =
      acc[orderedPhases[idx - 1]?.id]?.dates?.absolute.end
    const previousPhaseEndDatePlusDay = previousPhaseEndDate
      ? addAndAccountForDST({ days: 1 })(previousPhaseEndDate)
      : undefined

    const absolutePhaseStartDate =
      idx === 0 ? prevState.data.common.startDate : previousPhaseEndDatePlusDay

    const prices = phase.priceIds
      .map(priceId => prevState.data.prices[priceId])
      .filter(Boolean)

    const partialResolvedPhase: Omit<ResolvedPhase, 'analysis'> = {
      id: phase.id,
      minimums: phase.minimumIds
        .map(minimumId => prevState.data.minimums[minimumId])
        .filter(Boolean),
      discounts: phase.discountIds
        .map(discountId => prevState.data.discounts[discountId])
        .filter(Boolean),
      prices,
      globalDiscount: phaseDiscounts.find(
        discount => discount.applyToAllPrices
      ),
      products: prices
        .map(price => prevState.data.products[price.productId])
        .filter(Boolean),
      dates: {
        duration: phase.duration,
        absolute: {
          start: absolutePhaseStartDate,
          end:
            phase.duration && absolutePhaseStartDate
              ? addAndAccountForDST(phase.duration)(absolutePhaseStartDate)
              : undefined
        }
      },
      phaseHasStarted: Boolean(
        [CubeStatus.ScheduleCompleted, CubeStatus.ScheduleActive].includes(
          prevState.data.common.status
        ) &&
          absolutePhaseStartDate &&
          isAfter(new UTCDate(), absolutePhaseStartDate)
      )
    }

    return {
      ...acc,
      [phase.id]: {
        ...partialResolvedPhase,
        analysis: getPhaseAnalysis({
          reducerState: prevState,
          previousPhase: acc[orderedPhases[idx - 1]?.id]
        })(partialResolvedPhase)
      }
    }
  }, {} as Record<ResolvedPhase['id'], ResolvedPhase>)
}
