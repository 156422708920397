import { FC, useState, useRef, useEffect } from 'react'
import { useDayzed, DateObj } from 'dayzed'
import {
  Box,
  useOutsideClick,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal
} from '@chakra-ui/react'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import { isValidDate } from '@sequencehq/validation'
import { Input } from '@sequencehq/forms'
import { CalendarView } from 'components/FormInputs/Dates/CalendarView'
import { Calendar } from 'react-feather'

type DateInputProps = {
  value?: Date
  name: string
  isDisabled?: boolean
  onChange: (date: Date | undefined) => void
  tabIndex?: number
  isInvalid?: boolean
  errorBorderColor?: string
  isSelectable: (date: Date) => boolean
  minDate?: Date
  maxDate?: Date
  rightAddon?: any
  leftAddon?: any
  placeholder?: string
}

export const DateInput: FC<DateInputProps> = ({
  value,
  onChange,
  minDate,
  maxDate,
  isSelectable,
  ...props
}) => {
  const popoverContentRef = useRef<HTMLElement>(null)
  const initialFocusRef = useRef<HTMLInputElement>(null)
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [offset, setOffset] = useState(0)
  const [currentDate, setCurrentDate] = useState('')

  useEffect(() => {
    if (value && value.toString() !== 'Invalid Date') {
      const theValue: Date =
        value instanceof Date ? value : parseISO(value as string)
      const formattedDate = format(theValue, 'yyyy-MM-dd')
      setCurrentDate(formattedDate)
    }
  }, [value])

  useOutsideClick({
    ref: popoverContentRef,
    handler: event => {
      const target = event.target instanceof Node ? event.target : null

      if (initialFocusRef.current?.contains(target)) {
        return
      }

      setPopoverOpen(false)
    }
  })

  const onDateSelected = ({ date: d }: DateObj) => {
    setCurrentDate(format(d, 'yyyy-MM-dd'))
    setOffset(0)
    setPopoverOpen(false)
    onChange(d)
  }

  const { calendars, getDateProps, getBackProps, getForwardProps } = useDayzed({
    selected: isValidDate(value) ? value : undefined,
    onDateSelected,
    minDate,
    maxDate,
    offset,
    date: isValidDate(value) ? value : undefined,
    onOffsetChanged: newOffset => setOffset(newOffset),
    monthsToDisplay: 1,
    firstDayOfWeek: 1
  })

  const handleOnBlur = () => {
    const date = currentDate === '' ? undefined : parseISO(currentDate)

    setPopoverOpen(false)
    onChange(date)
  }

  return (
    <Popover
      variant="dateInput"
      isOpen={popoverOpen}
      placement="bottom-start"
      onClose={() => setPopoverOpen(false)}
      initialFocusRef={initialFocusRef}
    >
      <PopoverTrigger>
        <Box>
          <Input
            autoComplete="off"
            inputRef={initialFocusRef}
            value={currentDate}
            onChange={e => setCurrentDate(e.target.value)}
            onFocus={() => setPopoverOpen(true)}
            onClick={event => event.stopPropagation()}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                handleOnBlur()
              }
            }}
            onBlur={event => {
              const nowFocused = event.relatedTarget

              if (!popoverContentRef.current?.contains(nowFocused)) {
                handleOnBlur()
              }
            }}
            rightAddon={<Calendar />}
            {...props}
          />
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent ref={popoverContentRef}>
          <PopoverBody>
            <CalendarView
              calendar={calendars[0]}
              getDateProps={getDateProps}
              getBackProps={getBackProps}
              getForwardProps={getForwardProps}
              selectedDate={value}
              type="single"
            />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}
