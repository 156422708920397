import {
  Dashboardv99990101Api,
  dashboardv99990101Client
} from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { PricingEditorPortErrors } from 'common/drawers/PricingEditor/domain'

export type v1ApiCreateProductData =
  Dashboardv99990101Api.PostProduct.ProductBody

export type v1ApiCreateProductResult =
  Dashboardv99990101Api.PostProduct.Response['data']

type CreateProduct = (data: v1ApiCreateProductData) => Promise<{
  data: v1ApiCreateProductResult
  success: boolean
  error: PricingEditorPortErrors | null
}>

export const createProduct: CreateProduct = async (
  data: v1ApiCreateProductData
) => {
  const res = await dashboardv99990101Client.postProduct({
    body: data
  })

  return {
    data: res.data,
    success: !res.error,
    error: res.error ? PricingEditorPortErrors.Other : null
  }
}
