import { useCallback } from 'react'

import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dashboard/v20240730'

type v20240730ApiCustomer = DashboardApi20240730.GetCustomer.Customer & {
  aliases: { value: string; id: string; label?: string }[]
}

export type v1ApiCustomer = Omit<v20240730ApiCustomer, 'contacts'>

type UseLoadCustomers = () => () => Promise<v1ApiCustomer[]>

export const useLoadAllCustomers: UseLoadCustomers = () => {
  const load = useCallback(async () => {
    const allCustomers =
      (await dashboard20240730Client.getCustomers({ limit: 1000 }))?.data
        ?.items ?? []

    return allCustomers.map(c => ({
      ...c,
      aliases: []
    }))
  }, [])

  return load
}
