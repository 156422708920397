import { useCallback, useState } from 'react'
import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dist/clients/dashboard/v20240730'

type Data = DashboardApi20240730.GetApiUsageEvents.ApiUsageEventList | null

type LoadDataProps = {
  after?: string
  before?: string
  limit?: number
  sortOrder?: string
  sortBy?: string
  customerAlias?: string
  eventType?: string
  eventId?: string
  eventTimestampAfter?: string
  eventTimestampBefore?: string
  unmappedAliasesOnly?: boolean
}

type LoaderState = {
  data: Data
  loading: boolean
  error: boolean
}

export const useApiUsageEventsLoader = () => {
  const [state, setState] = useState<LoaderState>({
    data: null,
    loading: false,
    error: false
  })

  const loadData = useCallback(
    async ({
      after,
      before,
      limit,
      sortOrder,
      sortBy,
      customerAlias,
      eventType,
      eventId,
      eventTimestampAfter,
      eventTimestampBefore,
      unmappedAliasesOnly
    }: LoadDataProps = {}): Promise<Data> => {
      if (state.loading) {
        return null
      }

      setState(appState => ({ ...appState, loading: true }))

      const response = await dashboard20240730Client.getApiUsageEvents({
        after,
        before,
        limit,
        sortOrder,
        sortBy,
        customerAlias,
        eventType,
        eventId,
        eventTimestampAfter,
        eventTimestampBefore,
        unmappedAliasesOnly
      })

      if (response.error) {
        throw new Error('Could not load api usage events')
      }

      setState({
        data: response.data,
        loading: false,
        error: response.error
      })

      return response.data
    },
    [state.loading]
  )

  const magicTableAdapter = useCallback(() => {
    return [
      (params: LoadDataProps = {}) =>
        loadData(params).then(res => ({
          data: {
            value: () => res
          }
        })),
      {
        data: {
          value: () => state?.data
        },
        isFetching: state.loading
      }
    ]
  }, [loadData, state])

  return {
    reload: loadData,
    magicTableAdapter
  }
}
