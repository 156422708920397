import { Grid, GridItem } from '@chakra-ui/react'
import { ReportSummary } from './ReportSummary'
import { ReportActions } from './ReportActions'
import { ReportDetail } from './ReportDetail'

export const RevRecReportDetailContent = () => {
  return (
    <Grid
      gridTemplateRows="auto auto 1fr"
      padding="16px"
      paddingBottom="0"
      rowGap="16px"
      height="100%"
      overflow="hidden"
    >
      <ReportSummary />
      <ReportActions />
      <GridItem overflow="auto" paddingBottom="16px">
        <ReportDetail />
      </GridItem>
    </Grid>
  )
}
