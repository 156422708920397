import { useReducer, Reducer, useCallback, useMemo } from 'react'

type SeatSnapshotsState = {
  resetKey: number
}

type SeatSnapshotsActions = {
  type: 'updateResetKey'
}

const reducer: Reducer<SeatSnapshotsState, SeatSnapshotsActions> = (
  state,
  action
) => {
  switch (action.type) {
    case 'updateResetKey':
      return {
        ...state,
        resetKey: state.resetKey + 1
      }
    default:
      return state
  }
}

const initialState = {
  resetKey: 0
}

export const useSeatSnapshots = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const updateResetKey = useCallback(() => {
    dispatch({ type: 'updateResetKey' })
  }, [])

  const data = useMemo(() => {
    return {
      resetKey: state.resetKey
    }
  }, [state.resetKey])

  return {
    data,
    functions: {
      updateResetKey
    }
  }
}
