import { Font, pdf } from '@react-pdf/renderer'
import * as Sentry from '@sentry/react'
import { CreditNoteModel } from '@sequencehq/core-models'
import { ReactPdfCreditNote } from '@sequencehq/invoice-content'
import { useFetchDataForLocalPdfCreditNote } from 'components/CreditNote/useFetchDataForLocalPdfCreditNote'
import { baseApi } from 'features/api/baseApi'
import { isProductionEnv } from 'lib/environment/environment'
import exhaustiveGuard from 'lib/exhaustiveGuard/exhaustiveGuard'
import { useNotifications } from 'lib/hooks/useNotifications'
import { useCallback } from 'react'

export type DownloadCreditNotePdfProps = {
  creditNoteId: CreditNoteModel['id']
  creditNoteNumber: CreditNoteModel['creditNoteNumber']
  creditNoteStatus: CreditNoteModel['status']
}

type UseCreditNotePdfDownload = () => {
  downloadPdf: (props: DownloadCreditNotePdfProps) => void
}

Font.register({
  family: 'Lato',
  fonts: [
    {
      src: 'https://dashboard.seqhq.io/fonts/Lato-Regular.ttf',
      fontStyle: 'normal',
      fontWeight: 'normal'
    },
    {
      src: 'https://dashboard.seqhq.io/fonts/Lato-Bold.ttf',
      fontStyle: 'normal',
      fontWeight: 'bold'
    }
  ]
})

export const useCreditNotePdfDownload: UseCreditNotePdfDownload = () => {
  const notifications = useNotifications()

  const [getCreditNoteDownload] =
    baseApi.endpoints.getCreditNoteDownload.useLazyQuerySubscription()

  const { fetchData } = useFetchDataForLocalPdfCreditNote()

  /**
   * Toasts
   */
  const showToastPreparing = useCallback(() => {
    notifications.displayNotification('Preparing download', {
      id: 'download-pdf',
      type: 'neutral',
      isClosable: false
    })
  }, [notifications])

  const showToastSuccess = useCallback(() => {
    notifications.displayNotification('Download ready', {
      id: 'download-pdf-success',
      type: 'success',
      isClosable: false,
      duration: 3000
    })
  }, [notifications])

  const showToastError = useCallback(() => {
    notifications.displayNotification('PDF download failed', {
      id: 'download-pdf-error',
      type: 'error',
      isClosable: false,
      duration: 3000
    })
  }, [notifications])

  /**
   * Helpers
   */
  const downloadBlob = useCallback(
    ({ blob, filename }: { blob: Blob; filename: string }) => {
      const blobUrl = URL.createObjectURL(blob)

      const a = document.createElement('a')
      a.download = filename
      a.href = blobUrl

      // For Firefox https://stackoverflow.com/a/32226068
      document.body.appendChild(a)

      a.click()
      a.remove()
    },
    []
  )

  /**
   * Main functions
   */
  const localGeneratePdf = useCallback(
    async ({
      creditNoteId,
      creditNoteNumber
    }: {
      creditNoteId: CreditNoteModel['id']
      creditNoteNumber: CreditNoteModel['creditNoteNumber']
    }) => {
      try {
        const pdfCreditNoteData = await fetchData({ creditNoteId })

        const blob = await pdf(
          <ReactPdfCreditNote
            creditNote={pdfCreditNoteData.creditNote}
            lineItems={pdfCreditNoteData.lineItems}
            lineItemGroups={pdfCreditNoteData.lineItemGroups}
            merchantDetails={pdfCreditNoteData.merchantDetails}
            showNonProdWatermark={!isProductionEnv()}
            isCustomerPortalEnabled={
              pdfCreditNoteData.merchant.customerPortalEnabled
            }
            isRenderedInBrowser={true}
          />
        ).toBlob()

        downloadBlob({
          blob,
          filename: creditNoteNumber
            ? `${creditNoteNumber}.pdf`
            : 'Credit Note.pdf'
        })

        showToastSuccess()
      } catch (e) {
        showToastError()
        Sentry.captureException(e)
      }
    },
    [downloadBlob, fetchData, showToastError, showToastSuccess]
  )

  const remoteGeneratePdf = useCallback(
    async ({
      creditNoteId,
      creditNoteNumber
    }: {
      creditNoteId: CreditNoteModel['id']
      creditNoteNumber: CreditNoteModel['creditNoteNumber']
    }) => {
      try {
        const fulfilled = await getCreditNoteDownload({
          id: creditNoteId
        }).unwrap()

        downloadBlob({
          blob: new Blob([fulfilled], { type: 'application/pdf' }),
          filename: creditNoteNumber
            ? `${creditNoteNumber}.pdf`
            : 'Credit Note.pdf'
        })

        showToastSuccess()
      } catch (e) {
        showToastError()
        Sentry.captureException(e)
      }
    },
    [downloadBlob, getCreditNoteDownload, showToastError, showToastSuccess]
  )

  const downloadPdf = useCallback(
    ({
      creditNoteId,
      creditNoteNumber,
      creditNoteStatus
    }: DownloadCreditNotePdfProps) => {
      showToastPreparing()

      switch (creditNoteStatus) {
        case 'DRAFT':
          void localGeneratePdf({ creditNoteId, creditNoteNumber })
          break
        case 'FINAL':
        case 'SENT':
        case 'VOIDED':
          void remoteGeneratePdf({ creditNoteId, creditNoteNumber })
          break
        default:
          return exhaustiveGuard(creditNoteStatus)
      }
    },
    [localGeneratePdf, remoteGeneratePdf, showToastPreparing]
  )

  return { downloadPdf }
}
