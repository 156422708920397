import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  BillingScheduleModel,
  BillingScheduleStatus,
  CustomerModel,
  IntegrationService,
  InvoiceModel,
  PlanModel
} from '@sequencehq/core-models'
import { ArchiveTaxRateFormProps } from 'components/ArchiveTaxRate/ArchiveTaxRateModal'
import { CreateCreditNoteFromInvoiceFormProps } from 'components/CreateCreditNoteFromInvoice/types'
import { DeleteApiKeyFormProps } from 'components/DeleteApiKey/DeleteApiKeyModal'
import { DeleteNotificationPolicyFormProps } from 'components/DeleteNotificationPolicy/DeleteNotificationPolicyModal'
import { DeleteUserProps } from 'components/DeleteUser/DeleteUserModal'
import { SelectImportCustomersProps } from 'components/ImportCustomers/SelectImportCustomersModal'
import { InsightsTemplateType } from 'components/InsightsV2/widgets/InsightsTemplates/CtaCard'
import { SendInvoicePaymentReminderFormProps } from 'components/SendInvoicePaymentReminder/types'
import { Customer } from 'components/Customers/types.ts'
import { IntegrationServices } from '@sequencehq/api/dist/utils/commonEnums'

type OverlayClosed = {
  open: false
  content: null
  data: null
  isCentered: boolean
  closeOnOverlayClick: boolean
  size?: 'xl' | 'exports'
  variant?: 'v2'
}

type OverlayOpen = {
  open: true
  data?: unknown
  isCentered: boolean
  closeOnOverlayClick: boolean
  size?: 'xl' | 'exports'
  variant?: 'v2'
}

export type OverlayCreateUsageMetricModal = {
  content: 'createUsageMetricModal'
}

export type OverlayCreateCustomerModel = {
  content: 'createCustomerModal'
  data: {
    alias?: string
  }
}

type OverlayEditCustomerModel = {
  content: 'editCustomerModal'
  data: CustomerModel
}

type OverlayFinalizeAndSendInvoiceModal = {
  content: 'finalizeAndSendInvoiceModal'
  data: {
    id: string
    customerName: string
    billingScheduleId: string | undefined
    customerEmails: string[]
  }
}

type OverlayLinkAliasToCustomerModal = {
  content: 'linkAliasToCustomerModal'
  data: {
    alias: string
  }
}

type OverlayFinalizeInvoiceModal = {
  content: 'finalizeInvoiceModal'
  data: {
    id: string
    customerName: string
  }
}

type OverlaySendInvoiceModal = {
  content: 'sendInvoiceModal'
  data: {
    id: string
    customerName: string
    billingScheduleId: string | undefined
    customerEmails: string[]
  }
}

type OverlaySendTestInvoiceEmailModal = {
  content: 'sendTestInvoiceEmailModal'
  data: {
    id: string
  }
}

type OverlaySendTestCreditNoteEmailModal = {
  content: 'sendTestCreditNoteEmailModal'
  data: {
    id: string
  }
}

export type OverlayCreateInvoiceModal = {
  content: 'createInvoiceModal'
  variant?: 'v2'
  data: {
    customer: CustomerModel | undefined
    onClose?: () => void
  }
}

export type OverlayCreateCreditNoteModal = {
  content: 'createCreditNoteModal'
  variant?: 'v2'
  data: {
    customer: CustomerModel | undefined
  }
}

type OverlayTimeTravelModal = {
  content: 'createTimeTravellerModal'
  data: BillingScheduleModel
}

type OverlayEditTimeTravelModal = {
  content: 'editTimeTravellerModal'
  data: BillingScheduleModel
}

type OverlayVoidInvoiceModal = {
  content: 'voidInvoiceModal'
  data: {
    id: string
    customerName: string
  }
}

type OverlayRecalculateInvoiceModal = {
  content: 'recalculateInvoiceModal'
  data: {
    id: string
  }
}

type OverlayPaidInvoiceModal = {
  content: 'paidInvoiceModal'
  data: InvoiceModel
}

type OverlayPartiallyPaidInvoiceModal = {
  content: 'partiallyPaidInvoiceModal'
  data: InvoiceModel
}

type OverlayUncollectibleInvoiceModal = {
  content: 'uncollectibleInvoiceModal'
  data: InvoiceModel
}

type OverlayUnpaidInvoiceModal = {
  content: 'unpaidInvoiceModal'
  data: InvoiceModel
}

type OverlayDeleteCreditNoteLineItemModal = {
  content: 'deleteCreditNoteLineItemModal'
  data: {
    creditNoteId: string
    id: string
    title: string
  }
}

type OverlayDeleteCreditNoteLineItemGroupModal = {
  content: 'deleteCreditNoteLineItemGroupModal'
  data: {
    creditNoteId: string
    id: string
    title: string
  }
}

type OverlayDeleteInvoiceLineItemModal = {
  content: 'deleteInvoiceLineItemModal'
  data: {
    invoiceId: string
    id: string
    title: string
  }
}

type OverlayDeleteInvoiceLineItemGroupModal = {
  content: 'deleteInvoiceLineItemGroupModal'
  data: {
    invoiceId: string
    id: string
    title: string
  }
}

type OverlayCancelChangesModal = {
  content: 'cancelChangesModal'
  data: {
    cancel: () => void
  }
}

type OverlayDiscardPricingPlanModal = {
  content: 'discardPricingPlanModal'
}

type OverlayDeletePlanProductModal = {
  content: 'deletePlanProductModal'
  data: string
}

type OverlayFinalizeAndSendCreditNoteModal = {
  content: 'finalizeAndSendCreditNoteModal'
  data: {
    id: string
    customerName: string
    customerEmails: string[]
  }
}

type OverlayFinalizeCreditNoteModal = {
  content: 'finalizeCreditNoteModal'
  data: {
    id: string
    customerName: string
  }
}

type OverlaySendCreditNoteModal = {
  content: 'sendCreditNoteModal'
  data: {
    id: string
    customerName: string
    customerEmails: string[]
  }
}

type OverlayVoidCreditNoteModal = {
  content: 'voidCreditNoteModal'
  data: {
    id: string
    customerName: string
  }
}

type OverlayConfigureStripeModal = {
  content: 'configureStripeModal'
}

type OverlayConnectToStripeModal = {
  content: 'connectToStripeModal'
}

type OverlayConfigureXeroModal = {
  content: 'configureXeroModal'
}

type OverlayConnectToXeroModal = {
  content: 'connectToXeroModal'
}

type OverlayConfigureGoogle_BigQueryModal = {
  content: 'configureGoogle_BigQueryModal'
}

type OverlayConnectToGoogle_BigQueryModal = {
  content: 'connectToGoogle_BigQueryModal'
}

type OverlayConfigureAmazon_RedshiftModal = {
  content: 'configureAmazon_RedshiftModal'
}

type OverlayConnectToAmazon_RedshiftModal = {
  content: 'connectToAmazon_RedshiftModal'
}

type OverlayConfigureGoogle_SheetsModal = {
  content: 'configureGoogle_SheetsModal'
}

type OverlayConnectToGoogle_SheetsModal = {
  content: 'connectToGoogle_SheetsModal'
}

type OverlayConfigureHubSpotModal = {
  content: 'configureHubSpotModal'
}

type OverlayConnectToHubSpotModal = {
  content: 'connectToHubSpotModal'
}

type OverlayConfigureNetSuiteModal = {
  content: 'configureNetSuiteModal'
}

type OverlayConnectToNetSuiteModal = {
  content: 'connectToNetSuiteModal'
}

type OverlayConfigureQuickBooks_OnlineModal = {
  content: 'configureQuickBooks_OnlineModal'
}

type OverlayConnectToQuickBooks_OnlineModal = {
  content: 'connectToQuickBooks_OnlineModal'
}

type OverlayConfigureSalesforceModal = {
  content: 'configureSalesforceModal'
}

type OverlayConnectToSalesforceModal = {
  content: 'connectToSalesforceModal'
}

type OverlayConfigureSlackModal = {
  content: 'configureSlackModal'
}

type OverlayConnectToSlackModal = {
  content: 'connectToSlackModal'
}

type OverlayConfigureSnowflakeModal = {
  content: 'configureSnowflakeModal'
}

type OverlayConnectToSnowflakeModal = {
  content: 'connectToSnowflakeModal'
}

type OverlayDeleteIntegrationLinksAndRevokeAccessModal = {
  content: 'deleteIntegrationLinksAndRevokeAccessModal'
  data: {
    service: IntegrationService
  }
}

type OverlayCustomerIntegrationLinkModal = {
  content: 'customerIntegrationLinkModal'
  data: {
    customer: Customer | Omit<Customer, 'contacts'>
    integrationService: IntegrationServices
  }
}

type OverlayImportCustomersModal = {
  content: 'importCustomersModal'
}

type OverlaySelectImportCustomersModal = {
  content: 'selectImportCustomersModal'
  data: SelectImportCustomersProps
}

type OverlayCreateCreditNoteFromInvoiceModal = {
  content: 'createCreditNoteFromInvoiceModal'
  data: CreateCreditNoteFromInvoiceFormProps
}

type OverlayDeleteUserModal = {
  content: 'deleteUserModal'
  data: DeleteUserProps
}

type OverlayCreateNotificationPolicyModal = {
  content: 'createNotificationPolicyModal'
}

type OverlayDeleteNotificationPolicyModal = {
  content: 'deleteNotificationPolicyModal'
  data: DeleteNotificationPolicyFormProps
}

type OverlayArchiveTaxRateModal = {
  content: 'archiveTaxRateModal'
  data: ArchiveTaxRateFormProps
}

type OverlaySendInvoicePaymentReminderModal = {
  content: 'sendInvoicePaymentReminderModal'
  data: SendInvoicePaymentReminderFormProps
}

type OverlayCreateApiKeyModal = {
  content: 'createApiKeyModal'
}

type OverlayDeleteApiKeyModal = {
  content: 'deleteApiKeyModal'
  data: DeleteApiKeyFormProps
}

type OverlayCreateTaxRateModal = {
  content: 'createTaxRateModal'
}

type OverlayProductSpotlightModal = {
  content: 'productSpotlightModal'
  data: {
    plan: PlanModel
  }

  isCentered: false
  closeOnOverlayClick: true
}

type OverlayDemoModeModal = {
  content: 'demoMode'
}

type AvailableExportData = {
  id: string
  title: string
  description: string
}

type OverlayCreateInsightExportTemplateModal = {
  content: 'createInsightExportTemplateModal'
  data: {
    template: InsightsTemplateType
    title: string
    description: string
    copyLink: string
    exampleLink?: string
    image: string
    imageAlt: string
    icon: string
    availableExportData: AvailableExportData[]
  }
  closeOnOverlayClick: boolean
  size: 'xl' | 'exports'
}

type OverlayStartBillingModal = {
  content: 'startBillingModal'
  data: {
    billingScheduleId: string
    onSuccess?: () => void
    onFail?: () => void
    onClose?: () => void
  }
}

type OverlayArchiveBillingScheduleModal = {
  content: 'archiveBillingScheduleModal'
  data: {
    billingScheduleId: string
    status: BillingScheduleStatus
    onSuccess?: () => void
    onFail?: () => void
    onClose?: () => void
  }
}

type OverlayArchiveCustomerModal = {
  content: 'archiveCustomerModal'
  data: {
    customer: Omit<Customer, 'contacts'>
    onSuccess?: () => void
    onFail?: () => void
    onClose?: () => void
  }
}

type OverlayCollectEmailModal = {
  content: 'collectEmailModal'
}

export type OverlayState =
  | OverlayClosed
  | (OverlayOpen & OverlayCreateCustomerModel)
  | (OverlayOpen & OverlayEditCustomerModel)
  | (OverlayOpen & OverlayFinalizeAndSendInvoiceModal)
  | (OverlayOpen & OverlayFinalizeInvoiceModal)
  | (OverlayOpen & OverlaySendInvoiceModal)
  | (OverlayOpen & OverlaySendTestInvoiceEmailModal)
  | (OverlayOpen & OverlaySendTestCreditNoteEmailModal)
  | (OverlayOpen & OverlayCreateInvoiceModal)
  | (OverlayOpen & OverlayCreateCreditNoteModal)
  | (OverlayOpen & OverlayTimeTravelModal)
  | (OverlayOpen & OverlayEditTimeTravelModal)
  | (OverlayOpen & OverlayVoidInvoiceModal)
  | (OverlayOpen & OverlayRecalculateInvoiceModal)
  | (OverlayOpen & OverlayPaidInvoiceModal)
  | (OverlayOpen & OverlayPartiallyPaidInvoiceModal)
  | (OverlayOpen & OverlayUncollectibleInvoiceModal)
  | (OverlayOpen & OverlayUnpaidInvoiceModal)
  | (OverlayOpen & OverlayDeleteCreditNoteLineItemModal)
  | (OverlayOpen & OverlayDeleteCreditNoteLineItemGroupModal)
  | (OverlayOpen & OverlayDeleteInvoiceLineItemModal)
  | (OverlayOpen & OverlayDeleteInvoiceLineItemGroupModal)
  | (OverlayOpen & OverlayCancelChangesModal)
  | (OverlayOpen & OverlayDiscardPricingPlanModal)
  | (OverlayOpen & OverlayDeletePlanProductModal)
  | (OverlayOpen & OverlayFinalizeAndSendCreditNoteModal)
  | (OverlayOpen & OverlayFinalizeCreditNoteModal)
  | (OverlayOpen & OverlaySendCreditNoteModal)
  | (OverlayOpen & OverlayVoidCreditNoteModal)
  | (OverlayOpen & OverlayConfigureStripeModal)
  | (OverlayOpen & OverlayConnectToStripeModal)
  | (OverlayOpen & OverlayConfigureXeroModal)
  | (OverlayOpen & OverlayConnectToXeroModal)
  | (OverlayOpen & OverlayCreateUsageMetricModal)
  | (OverlayOpen & OverlayCustomerIntegrationLinkModal)
  | (OverlayOpen & OverlayImportCustomersModal)
  | (OverlayOpen & OverlaySelectImportCustomersModal)
  | (OverlayOpen & OverlayCreateCreditNoteFromInvoiceModal)
  | (OverlayOpen & OverlayConfigureGoogle_BigQueryModal)
  | (OverlayOpen & OverlayConnectToGoogle_BigQueryModal)
  | (OverlayOpen & OverlayConfigureAmazon_RedshiftModal)
  | (OverlayOpen & OverlayConnectToAmazon_RedshiftModal)
  | (OverlayOpen & OverlayConfigureGoogle_SheetsModal)
  | (OverlayOpen & OverlayConnectToGoogle_SheetsModal)
  | (OverlayOpen & OverlayConfigureHubSpotModal)
  | (OverlayOpen & OverlayConnectToHubSpotModal)
  | (OverlayOpen & OverlayConfigureNetSuiteModal)
  | (OverlayOpen & OverlayConnectToNetSuiteModal)
  | (OverlayOpen & OverlayConfigureQuickBooks_OnlineModal)
  | (OverlayOpen & OverlayConnectToQuickBooks_OnlineModal)
  | (OverlayOpen & OverlayConfigureSalesforceModal)
  | (OverlayOpen & OverlayConnectToSalesforceModal)
  | (OverlayOpen & OverlayConfigureSlackModal)
  | (OverlayOpen & OverlayConnectToSlackModal)
  | (OverlayOpen & OverlayConfigureSnowflakeModal)
  | (OverlayOpen & OverlayConnectToSnowflakeModal)
  | (OverlayOpen & OverlayDeleteUserModal)
  | (OverlayOpen & OverlayCreateNotificationPolicyModal)
  | (OverlayOpen & OverlayDeleteNotificationPolicyModal)
  | (OverlayOpen & OverlayProductSpotlightModal)
  | (OverlayOpen & OverlayArchiveTaxRateModal)
  | (OverlayOpen & OverlayCreateTaxRateModal)
  | (OverlayOpen & OverlayDeleteApiKeyModal)
  | (OverlayOpen & OverlayCreateApiKeyModal)
  | (OverlayOpen & OverlayDeleteIntegrationLinksAndRevokeAccessModal)
  | (OverlayOpen & OverlayDemoModeModal)
  | (OverlayOpen & OverlayCreateInsightExportTemplateModal)
  | (OverlayOpen & OverlaySendInvoicePaymentReminderModal)
  | (OverlayOpen & OverlayStartBillingModal)
  | (OverlayOpen & OverlayArchiveBillingScheduleModal)
  | (OverlayOpen & OverlayCollectEmailModal)
  | (OverlayOpen & OverlayArchiveCustomerModal)
  | (OverlayOpen & OverlayLinkAliasToCustomerModal)

export type OverlayPayload =
  | OverlayCreateCustomerModel
  | OverlayEditCustomerModel
  | OverlayFinalizeAndSendInvoiceModal
  | OverlayFinalizeInvoiceModal
  | OverlaySendInvoiceModal
  | OverlaySendTestInvoiceEmailModal
  | OverlaySendTestCreditNoteEmailModal
  | OverlayCreateInvoiceModal
  | OverlayCreateCreditNoteModal
  | OverlayTimeTravelModal
  | OverlayEditTimeTravelModal
  | OverlayVoidInvoiceModal
  | OverlayRecalculateInvoiceModal
  | OverlayPaidInvoiceModal
  | OverlayUncollectibleInvoiceModal
  | OverlayUnpaidInvoiceModal
  | OverlayPartiallyPaidInvoiceModal
  | OverlayDeleteCreditNoteLineItemModal
  | OverlayDeleteCreditNoteLineItemGroupModal
  | OverlayDeleteInvoiceLineItemModal
  | OverlayDeleteInvoiceLineItemGroupModal
  | OverlayCancelChangesModal
  | OverlayDiscardPricingPlanModal
  | OverlayDeletePlanProductModal
  | OverlayFinalizeAndSendCreditNoteModal
  | OverlayFinalizeCreditNoteModal
  | OverlaySendCreditNoteModal
  | OverlayVoidCreditNoteModal
  | OverlayConfigureStripeModal
  | OverlayConnectToStripeModal
  | OverlayConfigureXeroModal
  | OverlayConnectToXeroModal
  | OverlayCreateUsageMetricModal
  | OverlayCustomerIntegrationLinkModal
  | OverlayImportCustomersModal
  | OverlaySelectImportCustomersModal
  | OverlayCreateCreditNoteFromInvoiceModal
  | OverlayConfigureGoogle_BigQueryModal
  | OverlayConnectToGoogle_BigQueryModal
  | OverlayConfigureAmazon_RedshiftModal
  | OverlayConnectToAmazon_RedshiftModal
  | OverlayConfigureGoogle_SheetsModal
  | OverlayConnectToGoogle_SheetsModal
  | OverlayConfigureHubSpotModal
  | OverlayConnectToHubSpotModal
  | OverlayConfigureNetSuiteModal
  | OverlayConnectToNetSuiteModal
  | OverlayConfigureQuickBooks_OnlineModal
  | OverlayConnectToQuickBooks_OnlineModal
  | OverlayConfigureSalesforceModal
  | OverlayConnectToSalesforceModal
  | OverlayConfigureSlackModal
  | OverlayConnectToSlackModal
  | OverlayConfigureSnowflakeModal
  | OverlayConnectToSnowflakeModal
  | OverlayDeleteUserModal
  | OverlayCreateNotificationPolicyModal
  | OverlayDeleteNotificationPolicyModal
  | OverlayProductSpotlightModal
  | OverlayArchiveTaxRateModal
  | OverlayCreateTaxRateModal
  | OverlayDeleteApiKeyModal
  | OverlayCreateApiKeyModal
  | OverlayDeleteIntegrationLinksAndRevokeAccessModal
  | OverlayDemoModeModal
  | OverlayCreateInsightExportTemplateModal
  | OverlaySendInvoicePaymentReminderModal
  | OverlayStartBillingModal
  | OverlayArchiveBillingScheduleModal
  | OverlayCollectEmailModal
  | OverlayArchiveCustomerModal
  | OverlayLinkAliasToCustomerModal

const initialState = {
  open: false,
  content: null,
  data: null,
  size: undefined,
  isCentered: true,
  closeOnOverlayClick: false,
  variant: undefined
} as OverlayState

const { reducer, actions } = createSlice({
  name: 'overlay',
  initialState,
  reducers: {
    openOverlay: (state, action: PayloadAction<OverlayPayload>) => {
      const { content } = action.payload
      state.open = true
      state.content = content
      state.size = 'size' in action.payload ? action.payload.size : undefined
      state.data = 'data' in action.payload ? action.payload.data : null
      state.variant =
        'variant' in action.payload ? action.payload.variant : undefined
      state.isCentered =
        'isCentered' in action.payload ? action.payload.isCentered : true
      state.closeOnOverlayClick =
        'closeOnOverlayClick' in action.payload
          ? action.payload.closeOnOverlayClick
          : false
    },
    closeOverlay: state => {
      state.open = false
      state.content = null
    }
  }
})

export const { openOverlay, closeOverlay } = actions
export default reducer
