import { useInvoiceEditorContext } from 'InvoiceEditor/hooks/useInvoiceEditorContext'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { LineItem, LineItemGroup } from '@sequencehq/invoice-content'
import { formatTotal } from 'InvoiceEditor/domainManagement/invoiceEditorAdapter'
import { dateTimeWithFormat, toPercentage } from '@sequencehq/formatters'
import {
  Currency,
  LineItemGroupModel,
  LineItemModel,
  PriceModel,
  UsageDataResponseModel,
  percentageFromDecimal,
  usageBasedPricingTypes
} from '@sequencehq/core-models'
import {
  InvoiceEditorSubAccountUsageBreakdown,
  LineItemSubAccountUsageBreakdown
} from 'InvoiceEditor/domainManagement/invoiceEditor.types'
import { Price } from 'InvoiceEditor/components/LineItems/drawer/LineItemEditor/hooks/useLoadPrices.ts'

type UseLineItems = () => {
  data: {
    groups: LineItemGroup[]
  }
  lineItemGroupEditor: {
    mode: 'CREATE' | 'EDIT' | 'VIEW'
  }
  lineItemGroupFieldsConfig: {
    description?: {
      value: string
      onChange: (value: string) => void
      isValid: boolean
    }
  }
  subAccountUsageBreakdown: InvoiceEditorSubAccountUsageBreakdown[]
  currentLineItemGroup: LineItemGroup | null
  canEditLineItems: boolean
  onAddLineItemGroup: () => void
  onAddLineItem: (lineItemGroupId: string) => void
  onEditLineItemGroup: (id: string) => void
  onCancelGroupChange: () => void
  updateLineItemGroupFields: (updatedFields: Partial<LineItemGroup>) => void
  onUpdateLineItemGroup: () => void
  onEditLineItem: (lineItemGroupId: string, lineItemId: string) => void
  onDeleteLineItemGroup: (id: string) => void
  onDeleteLineItem: (lineItemGroupId: string, lineItemId: string) => void
  onCreateLineItemGroup: () => void
  showSubAccountUsage: boolean
}

const formatLineItem =
  (currency: Currency, lineItemGroupId: string) =>
  (lineItem: LineItemModel): LineItem => {
    return {
      title: lineItem.title,
      description: lineItem.description,
      quantity:
        lineItem.rateDisplay === 'PERCENTAGE'
          ? formatTotal(currency, lineItem.quantity)
          : lineItem.quantity,
      rate:
        lineItem.rateDisplay === 'PERCENTAGE'
          ? toPercentage(percentageFromDecimal(lineItem.rate))
          : formatTotal(currency, lineItem.rate, 10),
      price: formatTotal(currency, lineItem.netTotal),
      rateType:
        lineItem.rateDisplay === 'ABSOLUTE'
          ? ('FIXED' as LineItem['rateType'])
          : ('PERCENTAGE' as LineItem['rateType']),
      itemType:
        parseFloat(lineItem.rate) < 0
          ? ('DISCOUNT' as LineItem['itemType'])
          : ('PRODUCT' as LineItem['itemType']),
      taxRate: 0,
      groupId: lineItemGroupId,
      id: lineItem.id
    }
  }

const createUngroupedLineItemsAdapterData = (
  currency: Currency,
  lineItems: LineItemModel[]
) =>
  lineItems
    .filter(lineItem => !lineItem.groupId)
    .map(lineItem => ({
      id: `ungrouped:${lineItem.id}`,
      description: lineItem.title,
      total: formatTotal(currency, lineItem.netTotal),
      lineItems: [
        formatLineItem(currency, `ungrouped:${lineItem.id}`)(lineItem)
      ],
      servicePeriodLabel: !lineItem.servicePeriod
        ? undefined
        : `${dateTimeWithFormat(
            lineItem.servicePeriod.startDate,
            'd MMM'
          )} to ${dateTimeWithFormat(
            lineItem.servicePeriod.endDate,
            'd MMM yyyy'
          )}`,
      containsUsage: false
    }))

const createLineItemGroupAdapterData = (
  currency: Currency,
  lineItems: LineItemModel[],
  lineItemGroups: LineItemGroupModel[],
  usageData: (UsageDataResponseModel & { lineItemGroupId: string })[],
  subAccountUsageBreakdown: Record<
    LineItemGroupModel['id'],
    LineItemSubAccountUsageBreakdown[]
  >,
  prices: Record<PriceModel['id'], Price>
): LineItemGroup[] =>
  lineItemGroups.map(lineItemGroup => {
    const lineItemsForThisGroup = lineItems.filter(
      lineItem => lineItem.groupId === lineItemGroup.id
    )

    /**
     * All of the line items within a group should share the same service start and end date
     * (outside of garbage data). Therefore, grab the start and end dates from the
     * line items within a group.
     */
    const servicePeriodStart = lineItemsForThisGroup.find(
      lineItem => lineItem.servicePeriod?.startDate
    )?.servicePeriod?.startDate
    const servicePeriodEnd = lineItemsForThisGroup.find(
      lineItem => lineItem.servicePeriod?.endDate
    )?.servicePeriod?.endDate

    const usageForThisGroup = usageData.find(
      ({ lineItemGroupId }) => lineItemGroupId === lineItemGroup.id
    )

    const pricesForThisGroup = lineItemsForThisGroup
      .filter(lineItem => Boolean(lineItem.priceId))
      .map(lineItem => prices[lineItem.priceId ?? ''])
      .filter(Boolean)

    return {
      id: lineItemGroup.id,
      description: lineItemGroup.title,
      total: formatTotal(currency, lineItemGroup.netTotal),
      lineItems: lineItemsForThisGroup.map(
        formatLineItem(currency, lineItemGroup.id)
      ),
      servicePeriodLabel:
        !servicePeriodStart || !servicePeriodEnd
          ? ''
          : `${dateTimeWithFormat(
              servicePeriodStart,
              'd MMM'
            )} to ${dateTimeWithFormat(servicePeriodEnd, 'd MMM yyyy')}`,
      usageData: usageForThisGroup,
      subAccountUsageBreakdown:
        subAccountUsageBreakdown[lineItemGroup.id] ?? [],
      containsUsage: pricesForThisGroup.some(
        price =>
          'structure' in price &&
          usageBasedPricingTypes.includes(price.structure.pricingType)
      )
    }
  })

const adapter = {
  in: {
    lineItemGroups: (
      apiLineItems: LineItemModel[],
      apiGroups: LineItemGroupModel[],
      apiUsage: (UsageDataResponseModel & { lineItemGroupId: string })[],
      currency: Currency,
      subAccountUsageBreakdown: Record<
        LineItemGroupModel['id'],
        LineItemSubAccountUsageBreakdown[]
      >,
      prices: Record<PriceModel['id'], Price>
    ) => {
      const ungroupedLineItems = createUngroupedLineItemsAdapterData(
        currency,
        apiLineItems
      )
      const groupedLineItems = createLineItemGroupAdapterData(
        currency,
        apiLineItems,
        apiGroups,
        apiUsage,
        subAccountUsageBreakdown,
        prices
      )

      return [...ungroupedLineItems, ...groupedLineItems]
    }
  },
  out: {
    lineItemGroup: (
      adapterLineItemGroup: LineItemGroup,
      existingGroup?: LineItemGroupModel
    ): LineItemGroupModel => {
      return {
        id: adapterLineItemGroup.id,
        title: adapterLineItemGroup.description,
        grossTotal: existingGroup?.grossTotal ?? '0',
        netTotal: existingGroup?.netTotal ?? '0',
        totalTax: existingGroup?.totalTax ?? '0',
        invoiceId: existingGroup?.invoiceId ?? '',
        index: existingGroup?.index ?? 0
      }
    }
  }
}

export const useLineItems: UseLineItems = () => {
  const invoiceEditorContext = useInvoiceEditorContext()
  const { data: contextData, derived } = invoiceEditorContext
  const navigate = useNavigate()

  const [lineItemGroupEditorState, setLineItemGroupEditorState] = useState<{
    mode: 'CREATE' | 'EDIT' | 'VIEW'
  }>({ mode: 'VIEW' })
  const [selectedLineItemGroup, setSelectedLineItemGroup] =
    useState<LineItemGroup | null>(null)

  const data = useMemo(() => {
    return {
      groups: adapter.in.lineItemGroups(
        Object.values(contextData.lineItems),
        Object.values(contextData.lineItemGroups),
        contextData.lineItemGroupUsage,
        contextData.invoice.currency,
        derived.queries.subAccountUsageBreakdownByLineItemGroup,
        contextData.prices
      ),
      currency: contextData.invoice.currency ?? 'GBP'
    }
  }, [contextData, derived])

  const onAddLineItemGroup = useCallback(() => {
    if (!selectedLineItemGroup) {
      return
    }

    const updatedLineItemGroups = {
      ...invoiceEditorContext.data.lineItemGroups,
      [selectedLineItemGroup.id]: adapter.out.lineItemGroup(
        selectedLineItemGroup
      )
    }

    invoiceEditorContext.functions.updateData({
      lineItemGroups: updatedLineItemGroups
    })

    invoiceEditorContext.functions.createLineItemGroup(
      selectedLineItemGroup.id,
      {
        ...invoiceEditorContext.data,
        lineItemGroups: updatedLineItemGroups
      }
    )
    setSelectedLineItemGroup(null)
    setLineItemGroupEditorState({ mode: 'VIEW' })
  }, [
    invoiceEditorContext.data,
    invoiceEditorContext.functions,
    selectedLineItemGroup
  ])

  const onUpdateLineItemGroup = useCallback(() => {
    if (!selectedLineItemGroup) {
      return
    }

    const updatedGroup = {
      ...invoiceEditorContext.data.lineItemGroups[selectedLineItemGroup.id],
      title: selectedLineItemGroup.description
    }

    const updatedGroups = {
      ...invoiceEditorContext.data.lineItemGroups,
      [selectedLineItemGroup.id]: updatedGroup
    }

    invoiceEditorContext.functions.updateData({
      lineItemGroups: updatedGroups
    })

    invoiceEditorContext.functions.updateLineItemGroup(
      selectedLineItemGroup.id,
      {
        ...invoiceEditorContext.data,
        lineItemGroups: updatedGroups
      }
    )
    setSelectedLineItemGroup(null)
    setLineItemGroupEditorState({ mode: 'VIEW' })
  }, [
    invoiceEditorContext.data,
    invoiceEditorContext.functions,
    selectedLineItemGroup
  ])

  const onCreateLineItemGroup = () => {
    setLineItemGroupEditorState({ mode: 'CREATE' })
    setSelectedLineItemGroup({
      id: `new-line-item-group::${crypto.randomUUID()}`,
      description: '',
      total: '',
      lineItems: []
    })
  }

  const onDeleteLineItemGroup = useCallback(
    (id: string) => {
      const { [id]: deletedGroup, ...remainingGroups } =
        invoiceEditorContext.data.lineItemGroups
      // TODO also any line items??
      const updatedReducerData = {
        lineItemGroups: remainingGroups
      }

      invoiceEditorContext.functions.updateData(updatedReducerData)

      invoiceEditorContext.functions.deleteLineItemGroup(id, {
        ...invoiceEditorContext.data,
        ...updatedReducerData
      })
    },
    [invoiceEditorContext.data, invoiceEditorContext.functions]
  )

  const onDeleteLineItem = useCallback(
    (lineItemGroupId: string, lineItemId: string) => {
      const { [lineItemId]: deletedLineItem, ...remainingLineItems } =
        invoiceEditorContext.data.lineItems

      invoiceEditorContext.functions.updateData({
        lineItems: remainingLineItems
      })

      invoiceEditorContext.functions.deleteLineItem(
        lineItemGroupId,
        lineItemId,
        {
          ...invoiceEditorContext.data,
          lineItems: remainingLineItems
        }
      )
    },
    [invoiceEditorContext.data, invoiceEditorContext.functions]
  )

  const onEditLineItemGroup = (id: string) => {
    setSelectedLineItemGroup(data.groups.find(group => group.id === id) ?? null)
  }

  const updateLineItemGroupFields = useCallback(
    (updatedFields: Partial<LineItemGroup>) => {
      setSelectedLineItemGroup(prev => {
        return <LineItemGroup>{
          ...prev,
          ...updatedFields
        }
      })
    },
    []
  )

  const lineItemGroupFieldsConfig = useMemo(() => {
    if (!selectedLineItemGroup) {
      return {}
    }

    return {
      description: {
        value: selectedLineItemGroup?.description ?? '',
        onChange: (value: string) =>
          updateLineItemGroupFields({ description: value }),
        isValid: selectedLineItemGroup?.description.trim().length > 0
      }
    }
  }, [selectedLineItemGroup, updateLineItemGroupFields])

  const onCancelGroupChange = useCallback(() => {
    setSelectedLineItemGroup(null)
    setLineItemGroupEditorState({ mode: 'VIEW' })
  }, [])

  const onAddLineItem = useCallback(
    (lineItemGroupId: string) =>
      navigate(
        `/invoices/${invoiceEditorContext.data.invoice.id}/${lineItemGroupId}/line-items/new`
      ),
    [invoiceEditorContext.data.invoice.id, navigate]
  )

  const onEditLineItem = useCallback(
    (lineItemGroupId: string, lineItemId: string) =>
      navigate(
        `/invoices/${invoiceEditorContext.data.invoice.id}/${lineItemGroupId}/line-items/${lineItemId}`
      ),
    [invoiceEditorContext.data.invoice.id, navigate]
  )

  const subAccountUsageBreakdown =
    invoiceEditorContext.data.subAccountUsageBreakdown ?? []

  return {
    data,
    lineItemGroupFieldsConfig,
    lineItemGroupEditor: lineItemGroupEditorState,
    currentLineItemGroup: selectedLineItemGroup,
    canEditLineItems: derived.queries.availableFeatures.canEditLineItems,
    onEditLineItemGroup,
    onAddLineItemGroup,
    onCancelGroupChange,
    updateLineItemGroupFields,
    editingLineItemGroupId: selectedLineItemGroup?.id,
    onDeleteLineItemGroup,
    onUpdateLineItemGroup,
    onCreateLineItemGroup,
    onAddLineItem,
    onEditLineItem,
    onDeleteLineItem,
    subAccountUsageBreakdown,
    showSubAccountUsage:
      invoiceEditorContext.derived.queries.availableFeatures
        .canViewSubAccountUsage
  }
}
