import { Flex } from '@chakra-ui/react'
import { StatusWidget } from './StatusWidget'

export const IntegrationManagementSidebar = () => {
  return (
    <Flex flexDirection="column">
      <StatusWidget />
    </Flex>
  )
}
