import { useQuery } from '@sequencehq/api/utils'
import { dashboardv20240509Client } from '@sequencehq/api/dist/clients/dashboard/v20240509'

export const useAvailableCurrencies = () => {
  const availableCurrenciesResponse = useQuery(
    dashboardv20240509Client.getSequenceAccountSettings,
    undefined,
    {
      select: res => {
        return res?.enabledCurrencies ?? []
      }
    }
  )

  return {
    isLoading: availableCurrenciesResponse.isLoading,
    availableCurrencies: availableCurrenciesResponse.data
  }
}
