import { useCallback, useMemo, useState } from 'react'
import * as Sentry from '@sentry/react'
import {
  InvoiceEditorReducerState,
  RecursivePartial
} from 'InvoiceEditor/domainManagement/invoiceEditor.types.ts'
import { Currency, IntegrationService } from '@sequencehq/core-models'
import { AdapterCustomerWithIntegrationsModel } from 'InvoiceEditor/domainManagement/invoiceEditorAdapter'
import { RecalculateInvoiceRefreshPayload } from 'InvoiceEditor/domainManagement/useInvoiceEditor'

type ConfirmationModalState = {
  active: boolean
  onClose?: () => void
  onConfirm?: () => Promise<void>
}

type SendTestInvoiceConfirmationModalState = {
  active: boolean
  onClose?: () => void
  onConfirm?: (email: string) => Promise<void>
}

type CustomerIntegrationLinkModalState = {
  active: boolean
  integrationService?: IntegrationService
  onClose?: () => void
  onConfirm?: (
    customer: AdapterCustomerWithIntegrationsModel,
    integrationService: IntegrationService,
    existingExternalLinks: InvoiceEditorReducerState['data']['customer']['externalIds']
  ) => Promise<void>
}

type CreditNoteLineItemsModalState = {
  active: boolean
  onClose?: () => void
  onConfirm?: (lineItemIds: string[]) => Promise<void>
}

type ConfirmationModal = {
  active: boolean
  onClose: () => void
  onConfirm: () => void
}

type FinaliseAndSendInvoiceConfirmationModal = {
  active: boolean
  onClose: () => void
  onConfirm: (shouldRefetch: boolean) => void
}

type FinaliseInvoiceConfirmationModal = {
  active: boolean
  onClose: () => void
  onConfirm: (shouldRefetch: boolean) => void
}
type SendTestInvoiceConfirmationModal = {
  active: boolean
  onClose: () => void
  onConfirm: (email: string) => void
}

type CustomerIntegrationLinkModal = {
  active: boolean
  integrationService?: IntegrationService
  onClose: () => void
  onConfirm: (
    customer: AdapterCustomerWithIntegrationsModel,
    integrationService: IntegrationService,
    existingExternalLinks: InvoiceEditorReducerState['data']['customer']['externalIds']
  ) => void
}

type CreditNoteLineItemsModal = {
  active: boolean
  onClose: () => void
  onConfirm: (lineItemIds: string[]) => void
}

type UseConfirmationModals = ({
  createCreditNoteFromInvoice,
  linkCustomerToIntegration,
  sendTestInvoiceToEmail,
  updateCreditNoteLineItems,
  updateInvoiceToSend,
  updateInvoiceToFinalise,
  updateInvoiceToRecalculate,
  updateInvoiceToVoid,
  updateInvoiceToSendAndFinalise,
  updateInvoiceToSendPaymentReminder,
  refetchInvoice
}: {
  createCreditNoteFromInvoice: (
    currency: Currency,
    customerId: string
  ) => Promise<{ creditNote: { id: string } } | void>
  linkCustomerToIntegration: (
    customer: AdapterCustomerWithIntegrationsModel,
    integrationService: IntegrationService,
    existingExternalLinks: InvoiceEditorReducerState['data']['customer']['externalIds']
  ) => Promise<void | RecursivePartial<InvoiceEditorReducerState['data']>>
  sendTestInvoiceToEmail: (email: string) => Promise<void>
  updateCreditNoteLineItems: (
    creditNoteId: string,
    lineItemIds: string[]
  ) => Promise<void>
  updateInvoiceToSend: () => Promise<void | RecursivePartial<
    InvoiceEditorReducerState['data']
  >>
  updateInvoiceToFinalise: () => Promise<void | RecursivePartial<
    InvoiceEditorReducerState['data']
  >>
  updateInvoiceToRecalculate: () => Promise<void | RecalculateInvoiceRefreshPayload>
  updateInvoiceToVoid: () => Promise<void | RecursivePartial<
    InvoiceEditorReducerState['data']
  >>
  updateInvoiceToSendAndFinalise: () => Promise<void | RecursivePartial<
    InvoiceEditorReducerState['data']
  >>
  updateInvoiceToSendPaymentReminder: () => Promise<void>
  refetchInvoice: () => Promise<void>
}) => {
  modalStates: {
    confirmCreateCreditNoteModal: ConfirmationModal
    confirmUpdateCreditNoteLineItemsModal: CreditNoteLineItemsModal
    confirmFinaliseInvoiceModal: FinaliseInvoiceConfirmationModal
    confirmRecalculateInvoiceModal: ConfirmationModal
    confirmSendAndFinaliseInvoiceModal: FinaliseAndSendInvoiceConfirmationModal
    confirmSendInvoiceModal: ConfirmationModal
    confirmSendPaymentReminderModal: ConfirmationModal
    confirmSendTestInvoiceModal: SendTestInvoiceConfirmationModal
    confirmVoidInvoiceModal: ConfirmationModal
    customerIntegrationLinkModal: CustomerIntegrationLinkModal
  }
  functions: {
    createCreditNote: (
      currency: Currency,
      customerId: string
    ) => Promise<{ creditNote: { id: string } } | void>
    creditNoteLineItems: (creditNoteId: string) => Promise<void>
    createCustomerIntegrationLink: (
      integrationService: IntegrationService
    ) => Promise<RecursivePartial<InvoiceEditorReducerState['data'] | void>>
    sendInvoice: () => Promise<RecursivePartial<
      InvoiceEditorReducerState['data']
    > | void>
    finaliseInvoice: () => Promise<RecursivePartial<
      InvoiceEditorReducerState['data']
    > | void>
    recalculateInvoice: () => Promise<RecalculateInvoiceRefreshPayload | void>
    sendAndFinaliseInvoice: () => Promise<RecursivePartial<
      InvoiceEditorReducerState['data']
    > | void>
    sendPaymentReminder: () => Promise<void>
    sendTestInvoice: () => Promise<void>
    voidInvoice: () => Promise<RecursivePartial<
      InvoiceEditorReducerState['data']
    > | void>
  }
}

export const useConfirmationModals: UseConfirmationModals = ({
  createCreditNoteFromInvoice,
  linkCustomerToIntegration,
  sendTestInvoiceToEmail,
  updateCreditNoteLineItems,
  updateInvoiceToSend,
  updateInvoiceToFinalise,
  updateInvoiceToRecalculate,
  updateInvoiceToVoid,
  updateInvoiceToSendAndFinalise,
  updateInvoiceToSendPaymentReminder,
  refetchInvoice
}) => {
  const [
    confirmCreateCreditNoteModalState,
    setConfirmCreateCreditNoteModalState
  ] = useState<ConfirmationModalState>({
    active: false
  })
  const [
    confirmUpdateCreditNoteLineItemsModalState,
    setConfirmUpdateCreditNoteLineItemsModalState
  ] = useState<CreditNoteLineItemsModalState>({
    active: false
  })
  const [confirmSendInvoiceModalState, setConfirmSendInvoiceModalState] =
    useState<ConfirmationModalState>({
      active: false
    })
  const [
    confirmFinaliseInvoiceModalState,
    setConfirmFinaliseInvoiceModalState
  ] = useState<ConfirmationModalState>({
    active: false
  })
  const [
    confirmRecalculateInvoiceModalState,
    setConfirmRecalculateInvoiceModalState
  ] = useState<ConfirmationModalState>({
    active: false
  })
  const [confirmVoidInvoiceModalState, setConfirmVoidInvoiceModalState] =
    useState<ConfirmationModalState>({
      active: false
    })
  const [
    confirmSendAndFinaliseInvoiceModalState,
    setConfirmSendAndFinaliseInvoiceModalState
  ] = useState<ConfirmationModalState>({
    active: false
  })
  const [
    confirmSendPaymentReminderModalState,
    setConfirmSendPaymentReminderModalState
  ] = useState<ConfirmationModalState>({
    active: false
  })
  const [
    confirmSendTestInvoiceModalState,
    setConfirmSendTestInvoiceModalState
  ] = useState<SendTestInvoiceConfirmationModalState>({
    active: false
  })

  const [
    customerIntegrationLinkModalState,
    setCustomerIntegrationLinkModalState
  ] = useState<CustomerIntegrationLinkModalState>({
    active: false
  })

  const sendInvoice = useCallback(async () => {
    let resolveSave: (
      value: RecursivePartial<InvoiceEditorReducerState['data']> | void
    ) => void
    const saveComplete: Promise<RecursivePartial<
      InvoiceEditorReducerState['data']
    > | void> = new Promise(res => (resolveSave = res))

    setConfirmSendInvoiceModalState({
      active: true,
      onConfirm: () => {
        setConfirmSendInvoiceModalState({
          active: false
        })
        return updateInvoiceToSend()
          .catch(e => {
            Sentry.captureException(e)
          })
          .then(resolveSave)
      },
      onClose: () => resolveSave()
    })
    return await saveComplete
  }, [updateInvoiceToSend])

  const finaliseInvoice = useCallback(async () => {
    let resolveSave: (
      value: RecursivePartial<InvoiceEditorReducerState['data']> | void
    ) => void
    const saveComplete: Promise<RecursivePartial<
      InvoiceEditorReducerState['data']
    > | void> = new Promise(res => (resolveSave = res))

    setConfirmFinaliseInvoiceModalState({
      active: true,
      onConfirm: () => {
        setConfirmFinaliseInvoiceModalState({
          active: false
        })
        return updateInvoiceToFinalise()
          .catch(e => {
            Sentry.captureException(e)
          })
          .then(resolveSave)
      },
      onClose: () => resolveSave()
    })
    return await saveComplete
  }, [updateInvoiceToFinalise])

  const recalculateInvoice = useCallback(async () => {
    let resolveSave: (value: RecalculateInvoiceRefreshPayload | void) => void
    const saveComplete: Promise<RecalculateInvoiceRefreshPayload | void> =
      new Promise(res => (resolveSave = res))

    setConfirmRecalculateInvoiceModalState({
      active: true,
      onConfirm: () => {
        setConfirmRecalculateInvoiceModalState({
          active: false
        })
        return updateInvoiceToRecalculate()
          .catch(e => {
            Sentry.captureException(e)
          })
          .then(resolveSave)
      },
      onClose: () => resolveSave()
    })
    return await saveComplete
  }, [updateInvoiceToRecalculate])

  const voidInvoice = useCallback(async () => {
    let resolveSave: (
      value: RecursivePartial<InvoiceEditorReducerState['data']> | void
    ) => void
    const saveComplete: Promise<RecursivePartial<
      InvoiceEditorReducerState['data']
    > | void> = new Promise(res => (resolveSave = res))

    setConfirmVoidInvoiceModalState({
      active: true,
      onConfirm: () => {
        setConfirmVoidInvoiceModalState({
          active: false
        })
        return updateInvoiceToVoid()
          .catch(e => {
            Sentry.captureException(e)
          })
          .then(resolveSave)
      },
      onClose: () => resolveSave()
    })
    return await saveComplete
  }, [updateInvoiceToVoid])

  const sendAndFinaliseInvoice = useCallback(async () => {
    let resolveSave: (
      value: RecursivePartial<InvoiceEditorReducerState['data']> | void
    ) => void
    const saveComplete: Promise<RecursivePartial<
      InvoiceEditorReducerState['data']
    > | void> = new Promise(res => (resolveSave = res))

    setConfirmSendAndFinaliseInvoiceModalState({
      active: true,
      onConfirm: () => {
        setConfirmSendAndFinaliseInvoiceModalState({
          active: false
        })
        return updateInvoiceToSendAndFinalise()
          .catch(e => {
            Sentry.captureException(e)
          })
          .then(resolveSave)
      },
      onClose: () => resolveSave()
    })
    return await saveComplete
  }, [updateInvoiceToSendAndFinalise])

  const sendPaymentReminder = useCallback(async () => {
    let resolveSave: (value: unknown) => void
    const saveComplete = new Promise(res => (resolveSave = res))

    setConfirmSendPaymentReminderModalState({
      active: true,
      onConfirm: () => {
        setConfirmSendPaymentReminderModalState({
          active: false
        })
        return updateInvoiceToSendPaymentReminder()
          .catch(e => {
            Sentry.captureException(e)
          })
          .then(resolveSave)
      },
      onClose: () => resolveSave(false)
    })
    await saveComplete
    return
  }, [updateInvoiceToSendPaymentReminder])

  const createCreditNote = useCallback(
    async (currency: Currency, customerId: string) => {
      let resolveSave: (value: { creditNote: { id: string } } | void) => void
      const saveComplete: Promise<{ creditNote: { id: string } } | void> =
        new Promise(res => (resolveSave = res))

      setConfirmCreateCreditNoteModalState({
        active: true,
        onConfirm: () => {
          setConfirmCreateCreditNoteModalState({
            active: false
          })
          return createCreditNoteFromInvoice(currency, customerId)
            .catch(e => {
              Sentry.captureException(e)
            })
            .then(resolveSave)
        },
        onClose: () => resolveSave()
      })
      return await saveComplete
    },
    [createCreditNoteFromInvoice]
  )

  const creditNoteLineItems = useCallback(
    async (creditNoteId: string) => {
      let resolveSave: (value: unknown) => void
      const saveComplete = new Promise(res => (resolveSave = res))

      setConfirmUpdateCreditNoteLineItemsModalState({
        active: true,
        onConfirm: (lineItemIds: string[]) => {
          setConfirmUpdateCreditNoteLineItemsModalState({
            active: false
          })
          return updateCreditNoteLineItems(creditNoteId, lineItemIds)
            .catch(e => {
              Sentry.captureException(e)
            })
            .then(resolveSave)
        },
        onClose: () => resolveSave(false)
      })
      await saveComplete
      return
    },
    [updateCreditNoteLineItems]
  )

  const sendTestInvoice = useCallback(async () => {
    let resolveSave: (value: unknown) => void
    const saveComplete = new Promise(res => (resolveSave = res))

    setConfirmSendTestInvoiceModalState({
      active: true,
      onConfirm: (email: string) => {
        setConfirmSendTestInvoiceModalState({
          active: false
        })
        return sendTestInvoiceToEmail(email)
          .catch(e => {
            Sentry.captureException(e)
          })
          .then(resolveSave)
      },
      onClose: () => resolveSave(false)
    })
    await saveComplete
    return
  }, [sendTestInvoiceToEmail])

  const createCustomerIntegrationLink = useCallback(
    async (integrationService: IntegrationService) => {
      let resolveSave: (
        value: RecursivePartial<InvoiceEditorReducerState['data']> | void
      ) => void
      const saveComplete: Promise<RecursivePartial<
        InvoiceEditorReducerState['data']
      > | void> = new Promise(res => (resolveSave = res))

      setCustomerIntegrationLinkModalState({
        active: true,
        integrationService,
        onConfirm: (
          customer: AdapterCustomerWithIntegrationsModel,
          service: IntegrationService,
          existingExternalLinks: InvoiceEditorReducerState['data']['customer']['externalIds']
        ) => {
          setCustomerIntegrationLinkModalState({
            active: false
          })
          return linkCustomerToIntegration(
            customer,
            service,
            existingExternalLinks
          )
            .catch(e => {
              Sentry.captureException(e)
            })
            .then(resolveSave)
        },
        onClose: () => resolveSave()
      })
      return await saveComplete
    },
    [linkCustomerToIntegration]
  )

  const confirmSendInvoiceModal = useMemo(() => {
    return {
      active: confirmSendInvoiceModalState.active,
      onClose: () => {
        confirmSendInvoiceModalState.onClose?.()
        setConfirmSendInvoiceModalState({ active: false })
      },
      onConfirm: () => {
        confirmSendInvoiceModalState.onConfirm?.().catch(e => {
          Sentry.captureException(e)
        })
      }
    }
  }, [confirmSendInvoiceModalState])

  const confirmFinaliseInvoiceModal = useMemo(() => {
    return {
      active: confirmFinaliseInvoiceModalState.active,
      onClose: () => {
        confirmFinaliseInvoiceModalState.onClose?.()
        setConfirmFinaliseInvoiceModalState({ active: false })
      },
      onConfirm: (shouldRefetch: boolean) => {
        confirmFinaliseInvoiceModalState
          .onConfirm?.()
          .then(() => {
            if (shouldRefetch) {
              return refetchInvoice()
            }
          })
          .catch(e => {
            Sentry.captureException(e)
          })
      }
    }
  }, [confirmFinaliseInvoiceModalState, refetchInvoice])

  const confirmRecalculateInvoiceModal = useMemo(() => {
    return {
      active: confirmRecalculateInvoiceModalState.active,
      onClose: () => {
        confirmRecalculateInvoiceModalState.onClose?.()
        setConfirmRecalculateInvoiceModalState({ active: false })
      },
      onConfirm: () => {
        confirmRecalculateInvoiceModalState.onConfirm?.().catch(e => {
          Sentry.captureException(e)
        })
      }
    }
  }, [confirmRecalculateInvoiceModalState])

  const confirmVoidInvoiceModal = useMemo(() => {
    return {
      active: confirmVoidInvoiceModalState.active,
      onClose: () => {
        confirmVoidInvoiceModalState.onClose?.()
        setConfirmVoidInvoiceModalState({ active: false })
      },
      onConfirm: () => {
        confirmVoidInvoiceModalState.onConfirm?.().catch(e => {
          Sentry.captureException(e)
        })
      }
    }
  }, [confirmVoidInvoiceModalState])

  const confirmSendAndFinaliseInvoiceModal = useMemo(() => {
    return {
      active: confirmSendAndFinaliseInvoiceModalState.active,
      onClose: () => {
        confirmSendAndFinaliseInvoiceModalState.onClose?.()
        setConfirmSendAndFinaliseInvoiceModalState({ active: false })
      },
      onConfirm: (shouldRefetch: boolean) => {
        confirmSendAndFinaliseInvoiceModalState
          .onConfirm?.()
          .then(() => {
            if (shouldRefetch) {
              return refetchInvoice()
            }
          })
          .catch(e => {
            Sentry.captureException(e)
          })
      }
    }
  }, [confirmSendAndFinaliseInvoiceModalState, refetchInvoice])

  const confirmSendPaymentReminderModal = useMemo(() => {
    return {
      active: confirmSendPaymentReminderModalState.active,
      onClose: () => {
        confirmSendPaymentReminderModalState.onClose?.()
        setConfirmSendPaymentReminderModalState({ active: false })
      },
      onConfirm: () => {
        confirmSendPaymentReminderModalState.onConfirm?.().catch(e => {
          Sentry.captureException(e)
        })
      }
    }
  }, [confirmSendPaymentReminderModalState])

  const confirmCreateCreditNoteModal = useMemo(() => {
    return {
      active: confirmCreateCreditNoteModalState.active,
      onClose: () => {
        confirmCreateCreditNoteModalState.onClose?.()
        setConfirmCreateCreditNoteModalState({ active: false })
      },
      onConfirm: () => {
        confirmCreateCreditNoteModalState.onConfirm?.().catch(e => {
          Sentry.captureException(e)
        })
      }
    }
  }, [confirmCreateCreditNoteModalState])

  const confirmUpdateCreditNoteLineItemsModal = useMemo(() => {
    return {
      active: confirmUpdateCreditNoteLineItemsModalState.active,
      onClose: () => {
        confirmUpdateCreditNoteLineItemsModalState.onClose?.()
        setConfirmUpdateCreditNoteLineItemsModalState({ active: false })
      },
      onConfirm: (lineItemIds: string[]) => {
        confirmUpdateCreditNoteLineItemsModalState
          .onConfirm?.(lineItemIds)
          .catch(e => {
            Sentry.captureException(e)
          })
      }
    }
  }, [confirmUpdateCreditNoteLineItemsModalState])

  const confirmSendTestInvoiceModal = useMemo(() => {
    return {
      active: confirmSendTestInvoiceModalState.active,
      onClose: () => {
        confirmSendTestInvoiceModalState.onClose?.()
        setConfirmSendTestInvoiceModalState({ active: false })
      },
      onConfirm: (email: string) => {
        confirmSendTestInvoiceModalState
          .onConfirm?.(email)
          .catch(e => Sentry.captureException(e))
      }
    }
  }, [confirmSendTestInvoiceModalState])

  const customerIntegrationLinkModal = useMemo(() => {
    return {
      active: customerIntegrationLinkModalState.active,
      integrationService: customerIntegrationLinkModalState.integrationService,
      onClose: () => {
        customerIntegrationLinkModalState.onClose?.()
        setCustomerIntegrationLinkModalState({ active: false })
      },
      onConfirm: (
        customer: AdapterCustomerWithIntegrationsModel,
        integrationService: IntegrationService,
        existingExternalLinks: InvoiceEditorReducerState['data']['customer']['externalIds']
      ) => {
        customerIntegrationLinkModalState
          .onConfirm?.(customer, integrationService, existingExternalLinks)
          .catch(e => Sentry.captureException(e))
      }
    }
  }, [customerIntegrationLinkModalState])

  return {
    modalStates: {
      confirmCreateCreditNoteModal,
      confirmUpdateCreditNoteLineItemsModal,
      confirmFinaliseInvoiceModal,
      confirmRecalculateInvoiceModal,
      confirmSendAndFinaliseInvoiceModal,
      confirmSendInvoiceModal,
      confirmSendPaymentReminderModal,
      confirmSendTestInvoiceModal,
      confirmVoidInvoiceModal,
      customerIntegrationLinkModal
    },
    functions: {
      createCreditNote,
      creditNoteLineItems,
      createCustomerIntegrationLink,
      finaliseInvoice,
      recalculateInvoice,
      sendAndFinaliseInvoice,
      sendInvoice,
      sendPaymentReminder,
      sendTestInvoice,
      voidInvoice
    }
  }
}
