import { Box, Flex, Image } from '@chakra-ui/react'
import stripeImage from './stripe.webp'
import { GreyGrey90, Lato13Regular } from '@sequencehq/design-tokens'

export const StripeIntegrationDetail = () => {
  return (
    <Flex flexDirection="column" gap="20px">
      <Image
        src={stripeImage}
        width="100%"
        borderRadius="8px"
        draggable={false}
      />
      <Box {...Lato13Regular} color={GreyGrey90} lineHeight="18px">
        Seamlessly integrate Sequence with Stripe to automate payment
        collections and invoice reconciliation. Enable Stripe on your billing
        schedules to include payment links on invoices. Once a customer has paid
        their first Stripe invoice and a payment method is on file, future
        invoices are charged automatically. Sequence updates the payment status
        as soon as Stripe processes the payment, ensuring accurate records. Use
        the Stripe SDK to collect and associate customer payment details with
        Sequence.
      </Box>
    </Flex>
  )
}
