import {
  Button,
  Box,
  Divider,
  Flex,
  Text,
  Link as LinkChakraUI
} from '@chakra-ui/react'
import {
  GreyGrey30,
  GreyGrey40,
  GreyGrey60,
  GreyGrey70,
  GreyGrey80,
  GreyWhite,
  IndigoIndigo50,
  Lato14Bold,
  Lato14ExtendedBold,
  Lato14ExtendedRegular,
  Lato14Regular,
  Lato16Bold,
  LineHeights20
} from '@sequencehq/design-tokens'
import { Link } from 'react-router-dom'
import {
  useIntegrations,
  Integration
} from 'InvoiceEditor/components/Integrations/useIntegrations'
import { ReactNode } from 'react'
import { IntegrationsEmptyState } from 'InvoiceEditor/components/Integrations/IntegrationsEmptyState'
import ExclamationTriangleIcon from '@heroicons/react/20/solid/ExclamationTriangleIcon'
import ArrowsRightLeftIcon from '@heroicons/react/16/solid/ArrowsRightLeftIcon'
import ArrowPathIcon from '@heroicons/react/16/solid/ArrowPathIcon'
import { InfoPopover } from '@sequencehq/core-components'
import InformationCircleIcon from '@heroicons/react/16/solid/InformationCircleIcon'
import { useInvoiceEditorContext } from 'InvoiceEditor/hooks/useInvoiceEditorContext'
import { IntegrationsBrowserModal } from 'Integrations/index'

const IntegrationItem: (props: { integration: Integration }) => ReactNode = ({
  integration
}) => {
  const { data } = useInvoiceEditorContext()

  return (
    <Flex justify="space-between" align="center">
      <Flex align="center" gap="12px">
        {integration.logo}
        <Text {...Lato14Bold} color={GreyGrey80}>
          {integration.name}
        </Text>
      </Flex>
      {integration.actions.customer.available && (
        <Button
          variant="ghost2"
          color={IndigoIndigo50}
          padding="0px"
          height="auto"
          gap="8px"
          onClick={integration.actions.customer.actionHandler}
        >
          <ArrowsRightLeftIcon height="16px" width="16px" />
          Link customer
        </Button>
      )}
      {integration.actions.invoice.available && (
        <Button
          variant="ghost2"
          color={IndigoIndigo50}
          padding="0px"
          height="auto"
          gap="8px"
          onClick={integration.actions.invoice.actionHandler}
        >
          <ArrowPathIcon height="16px" width="16px" />
          Sync invoice
        </Button>
      )}
      {integration.actions.collection.available && (
        <Button
          variant="ghost2"
          color={IndigoIndigo50}
          padding="0px"
          height="auto"
          gap="8px"
          onClick={integration.actions.collection.actionHandler}
        >
          Add {integration.name} collection
        </Button>
      )}
      {integration.invoiceUrl && (
        <Link to={integration.invoiceUrl} target="_blank">
          <Text {...Lato14Regular} color={IndigoIndigo50}>
            View on {integration.name}
          </Text>
        </Link>
      )}
      {!integration.actions.customer.available &&
        integration.name !== 'Stripe' &&
        data.invoice.status === 'DRAFT' && (
          <InfoPopover
            variant="info"
            placement="right"
            body={
              <Box width={300}>
                This invoice will automatically be synced to{' '}
                <Box as="span" {...Lato14ExtendedBold} color={GreyGrey70}>
                  {integration.name}{' '}
                </Box>
                once finalized.{' '}
                <LinkChakraUI
                  href="https://docs.sequencehq.com/integrations/accounting-overview"
                  target="_blank"
                  {...Lato14ExtendedBold}
                  lineHeight={LineHeights20}
                  color={IndigoIndigo50}
                >
                  Learn More
                </LinkChakraUI>
              </Box>
            }
            icon={
              <InformationCircleIcon
                height={16}
                width={16}
                color={GreyGrey60}
              />
            }
          />
        )}
      {data.invoice.paymentOptions.includes('LINK') &&
        integration.name === 'Stripe' &&
        data.invoice.status === 'DRAFT' && (
          <InfoPopover
            variant="info"
            placement="right"
            body={
              <Box width={300}>
                If a payment method is on file, payment will be collected
                automatically once the invoice is sent.{' '}
                <LinkChakraUI
                  href="https://docs.sequencehq.com/integrations/stripe"
                  target="_blank"
                  {...Lato14ExtendedBold}
                  lineHeight={LineHeights20}
                  color={IndigoIndigo50}
                >
                  Learn More
                </LinkChakraUI>
              </Box>
            }
            icon={
              <InformationCircleIcon
                height={16}
                width={16}
                color={GreyGrey60}
              />
            }
          />
        )}
    </Flex>
  )
}

export const Integrations = () => {
  const { data } = useIntegrations()

  return (
    <Box
      border={`${GreyGrey30} 1px solid`}
      boxShadow="0px 4px 8px 0px rgba(0, 0, 0, 0.04), 0px 0px 4px 0px rgba(0, 0, 0, 0.02)"
      borderRadius="8px"
      backgroundColor={GreyWhite}
    >
      <Flex direction="column" gap="20px" padding="24px">
        {data.missingIntegrations.map(name => (
          <Flex
            key={name}
            borderRadius="8px"
            border={`1px solid ${GreyGrey40}`}
            padding="12px 16px"
            gap="8px"
          >
            <Flex>
              <ExclamationTriangleIcon width="20px" height="20px" />
            </Flex>
            <Text {...Lato14ExtendedRegular}>
              This invoice won't be synced to {name} because {data.customerName}{' '}
              isn't linked.
            </Text>
          </Flex>
        ))}

        <Text {...Lato16Bold}>Integrations</Text>

        {data.integrations.length === 0 && <IntegrationsEmptyState />}

        {data.integrations.length > 0 && (
          <Flex direction="column" gap="16px">
            {data.integrations.map(integration => (
              <IntegrationItem
                key={integration.name}
                integration={integration}
              />
            ))}
          </Flex>
        )}
      </Flex>

      <Divider color={GreyGrey30} />

      <Box px="12px" py="8px">
        <IntegrationsBrowserModal
          trigger={
            <Button width="100%" variant="secondary2" size="xs" fontSize="13px">
              Manage integrations
            </Button>
          }
        />
      </Box>
    </Box>
  )
}
