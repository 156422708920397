import { Currency } from '@sequencehq/core-models'
import { useGetSequenceAccountSettingsQuery } from 'features/api'
import { Toast } from '@sequencehq/core-components'
import { useToast } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'

type EnabledCurrenciesResponse =
  | {
      status: 'SUCCESS'
      enabledCurrencies: Currency[]
    }
  | {
      status: 'ERROR'
    }
  | {
      status: 'LOADING'
    }

type UseCurrenciesArgs = {
  toastOnError?: boolean
}

export const useEnabledCurrencies = ({
  toastOnError
}: UseCurrenciesArgs = {}): EnabledCurrenciesResponse => {
  const toast = useToast()
  // Possible next steps
  // - Enable currency sorting / prioritization

  const res = useGetSequenceAccountSettingsQuery()

  if (res.isLoading) {
    return {
      status: 'LOADING'
    }
  }

  if (res.isSuccess) {
    const settings = res.data?.value()

    const enabledCurrencies = settings?.enabledCurrencies

    if (enabledCurrencies?.length) {
      return {
        status: 'SUCCESS',
        enabledCurrencies: enabledCurrencies
      }
    }
  }

  if (toastOnError) {
    Sentry.captureException('Failed to load enabled currencies')
    toast({
      position: 'bottom',
      isClosable: false,
      render: () => (
        <Toast
          type="error"
          title="Failed to load enabled currencies"
          description="Please refresh page to try again"
          isClosable={false}
        />
      )
    })
  }

  return {
    status: 'ERROR'
  }
}

export const useDefaultCurrency = (): Currency | undefined => {
  const toast = useToast()
  // Possible next steps
  // - Enable currency sorting / prioritization

  const res = useGetSequenceAccountSettingsQuery()

  if (res.isError) {
    toast({
      position: 'bottom',
      isClosable: false,
      render: () => (
        <Toast
          type="error"
          title="Failed to load default currency"
          description="Please refresh page to try again"
          isClosable={false}
        />
      )
    })
  }

  const settings = res.data?.value()

  const defaultCurrency = settings?.enabledCurrencies?.[0]

  return defaultCurrency
}
