import {
  Dashboardv20240509Api,
  dashboardv20240509Client
} from '@sequencehq/api/dist/clients/dashboard/v20240509'
import {
  DashboardApi20240730,
  dashboard20240730Client
} from '@sequencehq/api/dashboard/v20240730'
import { CustomerModel } from '@sequencehq/core-models'
import { useCallback } from 'react'

export type v20240730ApiCustomer = DashboardApi20240730.GetCustomer.Customer & {
  aliases: { value: string; id: string; label?: string }[]
}

export type v1ApiCustomer = v20240730ApiCustomer

type UseLoadSingleCustomer = () => (props: {
  customerId: CustomerModel['id']
}) => Promise<v1ApiCustomer | null>

export const useLoadSingleCustomer: UseLoadSingleCustomer = () => {
  const load = useCallback(
    async ({ customerId }: { customerId: CustomerModel['id'] }) => {
      const [selectedCustomer, selectedCustomerAliases] = await Promise.all([
        customerId
          ? dashboard20240730Client
              .getCustomer({ id: customerId })
              .then(res => res.data)
          : Promise.resolve(),
        customerId
          ? dashboardv20240509Client
              .getCustomerAliases({ id: customerId })
              .then(res => res.data?.items ?? [])
          : Promise.resolve()
      ])

      if (!selectedCustomer) {
        return null
      }

      return {
        ...selectedCustomer,
        aliases: (selectedCustomerAliases ?? []).map(
          (alias: Dashboardv20240509Api.GetCustomerAliases.CustomerAlias) => ({
            value: alias.value,
            id: alias.id,
            label: alias.value
          })
        )
      }
    },
    []
  )

  return load
}
