import {
  Box,
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/react'
import { IntegrationService } from '@sequencehq/core-models'
import Spinner from 'components/Loading/Spinner'
import {
  GreyGrey0,
  GreyGrey100,
  Lato16Bold,
  GreyGrey80,
  Lato14ExtendedRegular
} from '@sequencehq/design-tokens'
import { useCustomerIntegrationLinkModal } from 'InvoiceEditor/components/modals/CustomerIntegrationLinkModal/useCustomerIntegrationLinkModal'
import { FC } from 'react'
import { SelectField } from '@sequencehq/core-components'
import { AdapterCustomerWithIntegrationsModel } from 'InvoiceEditor/domainManagement/invoiceEditorAdapter'
import { InvoiceEditorReducerState } from 'InvoiceEditor/domainManagement/invoiceEditor.types'

type CustomerIntegrationLinkModalProps = {
  integrationService: IntegrationService
  onClose: () => void
  onConfirm: (
    customer: AdapterCustomerWithIntegrationsModel,
    integrationService: IntegrationService,
    existingExternalServices: InvoiceEditorReducerState['data']['customer']['externalIds']
  ) => void
}

export const CustomerIntegrationLinkModal: FC<
  CustomerIntegrationLinkModalProps
> = ({ integrationService, onClose, onConfirm }) => {
  const { data, loading, onLinkCustomer } = useCustomerIntegrationLinkModal({
    integrationService,
    handlers: { onConfirm }
  })

  return (
    <Modal isOpen onClose={onClose} isCentered>
      <ModalOverlay>
        <ModalContent
          backgroundColor={GreyGrey0}
          padding="16px"
          borderRadius="8px"
        >
          <Box color={GreyGrey100} {...Lato16Bold} marginBottom="4px">
            Link {data.customerName} to {data.integrationName}
          </Box>
          <Box color={GreyGrey80} {...Lato14ExtendedRegular} marginBottom="8px">
            {loading && (
              <Box
                color={GreyGrey80}
                {...Lato14ExtendedRegular}
                marginBottom="24px"
              >
                Loading customers...
                <Spinner />
              </Box>
            )}

            {data.fields.externalId.options.length === 0 && !loading && (
              <Box
                color={GreyGrey80}
                {...Lato14ExtendedRegular}
                marginBottom="24px"
              >
                No customers were retrieved from {data.integrationName}. Please
                check if you have any customers in your {data.integrationName}{' '}
                account.
              </Box>
            )}

            {data.fields.externalId.options.length > 0 && (
              <SelectField
                label={`${data.integrationName} customer`}
                value={data.fields.externalId.value ?? ''}
                placeholder="Choose customer"
                options={data.fields.externalId.options}
                onChange={data.fields.externalId.onChange}
                validationErrors={data.fields.externalId.validationErrors}
              />
            )}
          </Box>
          <Flex gap="8px" justifyContent="space-between">
            <Button
              variant="secondary2"
              onClick={onClose}
              height="32px"
              width="100%"
            >
              Cancel
            </Button>
            <Button
              variant="primary2"
              onClick={onLinkCustomer}
              height="32px"
              width="100%"
            >
              Link
            </Button>
          </Flex>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
