import {
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Grid,
  Flex,
  DrawerCloseButton,
  GridItem,
  Spinner,
  Center,
  Box,
  Button
} from '@chakra-ui/react'
import {
  GreyGrey10,
  GreyGrey30,
  GreyGrey80,
  Lato16Bold,
  Spacing8
} from '@sequencehq/design-tokens'
import { useInvoicePdfPreview } from 'InvoiceEditor/components/drawers/InvoicePdfPreview/useInvoicePdfPreview'
import ArrowDownTrayIcon from '@heroicons/react/16/solid/ArrowDownTrayIcon'

type Props = {
  isOpen: boolean
  onClose: () => void
}

export const InvoicePdfPreview = ({ isOpen, onClose }: Props) => {
  const { previewDataUrl, download } = useInvoicePdfPreview({ isOpen })

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay
        display="flex"
        alignContent="center"
        justifyContent="center"
      />
      <DrawerContent
        margin="8px 12px"
        borderRadius="8px"
        style={{
          width: 'fit-content',
          maxWidth: '100%',
          minWidth: '550px',
          overflow: 'hidden'
        }}
      >
        <Grid
          templateAreas={`
            "header"
            "content"
          `}
          templateRows="56px 1fr"
          width="100%"
          height="100%"
        >
          <Flex
            gridArea="header"
            borderBottom={`1px solid ${GreyGrey30}`}
            {...Lato16Bold}
            height="56px"
            px="24px"
            alignItems="center"
            justify="space-between"
          >
            Preview invoice
            <Flex alignItems="center">
              {download.available && (
                <Button
                  variant="secondary2"
                  marginRight={Spacing8}
                  onClick={download.onClick}
                  height="36px"
                  color={GreyGrey80}
                >
                  <Box marginRight="6px">
                    <ArrowDownTrayIcon
                      height={16}
                      width={16}
                      color={GreyGrey80}
                    />
                  </Box>
                  Download PDF
                </Button>
              )}

              <DrawerCloseButton style={{ position: 'inherit' }} />
            </Flex>
          </Flex>

          <GridItem gridArea="content" overflow="auto" bg={GreyGrey10}>
            <Center width="100%" height="100%" position="relative">
              <Spinner />

              {previewDataUrl && (
                <Box
                  width="100%"
                  height="100%"
                  position="absolute"
                  top="0"
                  left="0"
                >
                  <iframe
                    src={previewDataUrl}
                    style={{ width: '100%', height: '100%' }}
                  ></iframe>
                </Box>
              )}
            </Center>
          </GridItem>
        </Grid>
      </DrawerContent>
    </Drawer>
  )
}
