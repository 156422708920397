import { Center } from '@chakra-ui/react'
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid'

import { Button, SequenceMenu } from '@sequencehq/core-components'
import { GreyGrey80, RedRed60 } from '@sequencehq/design-tokens'

import { useQuoteEditorKebabMenu } from './useQuoteEditorKebabMenu'
import { ConfirmDeleteLatestQuoteDraftModal } from '../modals/DeleteLatestQuoteDraftModal'
import { ConfirmArchiveQuoteModal } from '../modals/ArchiveQuoteModal'

export const QuoteEditorKebabMenu = () => {
  const { duplicateQuote, archiveQuote, discardChanges, modals } =
    useQuoteEditorKebabMenu()

  return (
    <>
      <SequenceMenu
        width={226}
        alignment="bottom-right"
        items={[
          ...(duplicateQuote.visible
            ? [{ label: 'Duplicate quote', onClick: duplicateQuote.onClick }]
            : []),
          ...(archiveQuote.visible
            ? [
                {
                  label: 'Archive quote',
                  onClick: archiveQuote.onClick,
                  divider: archiveQuote.divider
                }
              ]
            : []),
          ...(discardChanges.visible
            ? [
                {
                  label: 'Discard unpublished changes',
                  onClick: discardChanges.onClick,
                  style: { color: RedRed60 }
                }
              ]
            : [])
        ]}
        menuButton={
          <Button variant="ghost" size="32px">
            <Center flexShrink="0">
              <EllipsisHorizontalIcon
                width="20px"
                height="20px"
                color={GreyGrey80}
              />
            </Center>
          </Button>
        }
      />

      {modals.deleteLatestDraft.active && (
        <ConfirmDeleteLatestQuoteDraftModal
          submitting={modals.deleteLatestDraft.submitting}
          onClose={modals.deleteLatestDraft.onClose}
          onConfirm={modals.deleteLatestDraft.onConfirm}
        />
      )}

      {modals.archive.active && (
        <ConfirmArchiveQuoteModal
          submitting={modals.archive.submitting}
          onClose={modals.archive.onClose}
          onConfirm={modals.archive.onConfirm}
          showSignatureWarning={modals.archive.showSignatureWarning}
        />
      )}
    </>
  )
}
