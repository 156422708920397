import { SelectInput } from '@sequencehq/core-components'
import { Lato13Bold } from '@sequencehq/design-tokens'
import { ComponentProps } from 'react'

export const FilterSelectInput = (
  props: ComponentProps<typeof SelectInput>
) => {
  return (
    <SelectInput
      height="24px"
      sx={{
        '& div[role="combobox"]': {
          boxShadow: 'none',
          padding: 1
        },
        '& div[role="combobox"] > div:first-of-type': {
          ...Lato13Bold
        },
        '& div.chakra-select__icon-wrapper': {
          right: 1
        },
        '& li[role="option"]': {
          overflow: 'hidden'
        }
      }}
      {...props}
    />
  )
}
