import {
  AccountingDatePreference,
  AccountPaymentSettings,
  InvoiceRenderSettings,
  InvoiceSettings
} from './settings.types'

export const INITIAL_ACCOUNTING_DATE_PREFERENCE: AccountingDatePreference = {
  id: '',
  preference: 'INTENDED_INVOICE_CREATION_DATE'
}

export const INITIAL_INVOICE_RENDER_SETTINGS: InvoiceRenderSettings = {
  id: '',
  paymentLinkDisplay: 'SHOW_PAYMENT_LINK'
}

export const INITIAL_ACCOUNT_PAYMENT_SETTINGS: AccountPaymentSettings = {
  id: '',
  failureNotifications: {
    merchantNotifications: 'SEND_NOTIFICATIONS',
    customerNotifications: 'SEND_NOTIFICATIONS'
  }
}

export const INITIAL_DATA: InvoiceSettings = {
  accountingDatePreference: INITIAL_ACCOUNTING_DATE_PREFERENCE,
  renderSettings: INITIAL_INVOICE_RENDER_SETTINGS,
  accountPaymentSettings: INITIAL_ACCOUNT_PAYMENT_SETTINGS
}

export const ACCOUNTING_DATE_PREFERENCE_OPTIONS = [
  {
    value: 'END_OF_BILLING_PERIOD',
    label: 'End of billing period (for in-arrears)'
  },
  {
    value: 'INTENDED_INVOICE_CREATION_DATE',
    label: 'Billing date (recommended)'
  },
  {
    value: 'INVOICE_ISSUE_DATE',
    label: 'Invoice issue date'
  }
]
