import {
  Button,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Grid,
  GridItem
} from '@chakra-ui/react'
import { TextAreaField, TextField } from '@sequencehq/core-components'
import { GreyGrey30, Lato16Bold } from '@sequencehq/design-tokens'
import { TaxCategoryField } from 'common/drawers/PricingEditor/view/layout/listPrice/ProductForm/TaxCategoryField'
import { useAddProduct } from 'modules/Products/drawers/AddProduct/useAddProduct'

/**
 * @deprecated in favour of
 * modules/common/drawers/PricingEditor/view/layout/listPrice/ProductForm/ProductForm.tsx
 */
export const AddProductForm = (props: { onClose: () => void }) => {
  const {
    fieldsConfig,
    functions: { createProduct },
    canSave
  } = useAddProduct()

  const handleCreate = () => {
    void createProduct()?.then(() => {
      props.onClose()
    })
  }

  return (
    <Drawer isOpen onClose={props.onClose}>
      <DrawerOverlay
        display="flex"
        alignContent="center"
        justifyContent="center"
      />
      <DrawerContent
        margin="8px 12px"
        borderRadius="8px"
        style={{
          width: 'fit-content',
          maxWidth: '100%',
          minWidth: '550px'
        }}
      >
        <Grid
          templateAreas={`
            "header"
            "content"
            "footer"
          `}
          templateRows={'56px 1fr 64px'}
          width="100%"
          height="100%"
        >
          <Flex
            gridArea="header"
            borderBottom={`1px solid ${GreyGrey30}`}
            {...Lato16Bold}
            height="56px"
            px="24px"
            alignItems="center"
            justify="space-between"
          >
            Create Product
            <DrawerCloseButton
              style={{
                position: 'inherit'
              }}
            />
          </Flex>

          <GridItem gridArea="content" overflow="auto">
            <Flex gap="24px" padding="24px" direction="column">
              <Flex direction="column" data-testid="addProduct">
                <TextField
                  data-testid="addProduct.name"
                  label="Product name"
                  value={fieldsConfig.name.value}
                  onChange={fieldsConfig.name.onChange}
                  validationErrors={fieldsConfig.name.validationErrors}
                  isDisabled={fieldsConfig.name.disabled}
                  placeholder="Enter a product name"
                  autoFocus
                />
                <TextAreaField
                  label="Description"
                  value={fieldsConfig.label.value}
                  onChange={fieldsConfig.label.onChange}
                  validationErrors={fieldsConfig.label.validationErrors}
                  isDisabled={fieldsConfig.label.disabled}
                  placeholder="Enter a product description"
                />

                <TaxCategoryField
                  data-testid="addProduct.taxCategoryId"
                  field={fieldsConfig.taxCategoryId}
                  formChangeset={{}}
                />
              </Flex>
            </Flex>
          </GridItem>

          <Flex
            gridArea="footer"
            align="center"
            borderTop={`1px solid ${GreyGrey30}`}
            gap="8px"
            height="100%"
            justify="flex-end"
            padding="24px"
          >
            <Button variant="secondary2" onClick={props.onClose}>
              Cancel
            </Button>
            <Button
              data-testid="addProduct.create"
              variant="primary2"
              isDisabled={!canSave}
              onClick={handleCreate}
            >
              Create
            </Button>
          </Flex>
        </Grid>
      </DrawerContent>
    </Drawer>
  )
}
