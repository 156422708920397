import CreateTimeTravellerForm from 'components/CreateTimeTraveller/CreateTimeTravellerForm'
import {
  CreateTimeTravellerFormMutation,
  CreateTimeTravellerFormProps
} from 'components/CreateTimeTraveller/types'
import { usePostTimeTravellersMutation } from 'features/api'
import { createFormModal } from 'components/Form/createFormModal'

const CreateTimeTravellerModal = createFormModal<
  CreateTimeTravellerFormMutation,
  CreateTimeTravellerFormProps
>({
  FormComponent: CreateTimeTravellerForm,
  useMutation: usePostTimeTravellersMutation,
  modalTitle: 'Travel forwards to',
  successMessage: 'Time travel has begun',
  navigateTo: () => `/billing-schedules`,
  submitTitle: 'Begin time travel 🛸',
  formId: 'createTimeTravellerForm'
})

export default CreateTimeTravellerModal
