import { isValidDate } from '@sequencehq/validation'
import { useEffect, useState } from 'react'
import parse from 'date-fns/parse'
import { DateObj } from 'dayzed'
import { useDateRange } from 'components/FormInputs/Dates/useDateRange'
import { formatRangeDate } from '@sequencehq/formatters'

const FOREVER = 'Forever'

type UseDateRangeEndInput = {
  emitValue?: (value: Date) => void
}

export const useDateRangeEndInput = ({ emitValue }: UseDateRangeEndInput) => {
  const {
    startDateInput,
    endDateInput,
    endDateMeta,
    selectedDates,
    popoverOpen,
    setPopoverOpen
  } = useDateRange()

  const formattedDate = formatRangeDate(
    endDateInput.value,
    isValidDate(startDateInput.value) ? '' : ''
  )

  const isSelectableDate = (endDate: Date) => {
    return (
      !isValidDate(startDateInput.value) ||
      endDate.getTime() > startDateInput.value.getTime()
    )
  }

  const handleBlur = () => {
    const parsedEndDate = parse(localDateString, 'yyyy-MM-dd', new Date())

    if (!isValidDate(parsedEndDate)) {
      endDateInput.onChange(undefined)
      setLocalDateString(formattedDate)
      return false
    }

    if (isSelectableDate(parsedEndDate)) {
      endDateInput.onChange(parsedEndDate)
      return true
    }

    return false
  }

  const handleDateSelected = ({ selectable, date }: DateObj) => {
    if (!selectable) {
      return false
    }

    if (isSelectableDate(date)) {
      endDateInput.onChange(date)
      return true
    }

    return false
  }

  useEffect(() => {
    setLocalDateString(formattedDate)
  }, [formattedDate])

  useEffect(() => {
    if (emitValue) {
      emitValue(endDateInput.value)
    }
  }, [endDateInput.value, emitValue])

  const [localDateString, setLocalDateString] = useState<string>(formattedDate)

  const handleFocus = () => {
    if (localDateString === FOREVER) {
      setLocalDateString('')
    }
  }

  return {
    label: 'End date',
    focusedInput: 'end' as const,
    handleBlur,
    handleFocus,
    handleDateSelected,
    selectedDates,
    localDateString,
    setLocalDateString,
    popoverOpen,
    setPopoverOpen,
    meta: endDateMeta
  }
}
