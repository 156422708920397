import { UTCDate } from '@date-fns/utc'
import { getDateInUTC, getEndOfPreviousMonth } from '@sequencehq/utils'
import { isValid } from 'date-fns'
import set from 'date-fns/set'

export const getLatestRecurrenceDate =
  (recurrenceDayOfMonth: number) => (dateToCheck: UTCDate) => {
    if (!isValid(dateToCheck)) {
      return undefined
    }

    const dateOfStart = getDateInUTC(dateToCheck)

    if (dateOfStart < recurrenceDayOfMonth) {
      const endOfPreviousMonth = getEndOfPreviousMonth(dateToCheck)
      const endOfPreviousMonthDate = getDateInUTC(endOfPreviousMonth)

      const newDate = set(endOfPreviousMonth, {
        date:
          recurrenceDayOfMonth < endOfPreviousMonthDate
            ? recurrenceDayOfMonth
            : endOfPreviousMonthDate
      })

      /** Ensure we don't get any time shenanigans (notably related to DST) by ignoring the time part */
      return new UTCDate(
        newDate.getFullYear(),
        newDate.getMonth(),
        newDate.getDate()
      )
    }

    if (dateOfStart === recurrenceDayOfMonth) {
      return dateToCheck
    }

    const newDate = set(dateToCheck, {
      date: recurrenceDayOfMonth
    })

    /** Ensure we don't get any time shenanigans (notably related to DST) by ignoring the time part */
    return new UTCDate(
      newDate.getFullYear(),
      newDate.getMonth(),
      newDate.getDate()
    )
  }
