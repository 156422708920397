import {
  CubeReducerState,
  CubeStatus,
  VisibleEnabledFeatureAvailable
} from 'modules/Cube/domain/cube.domain.types'

export const getAcceptQuoteAvailable = (
  prevState: CubeReducerState
): VisibleEnabledFeatureAvailable => {
  const isArchived: boolean = prevState.data.common.isArchived
  const isSignaturesEnabled: boolean = prevState.data.quote.isSignaturesEnabled
  const isPublished: boolean =
    prevState.data.common.status === CubeStatus.QuotePublished

  if (isArchived || isSignaturesEnabled || !isPublished) {
    return {
      available: {
        visible: false,
        enabled: false
      },
      reasons: []
    }
  }

  return {
    available: {
      visible: true,
      enabled: true
    },
    reasons: []
  }
}
