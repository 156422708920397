import { StandardLayout } from '../common/RevRecLayout'
import { RevRecReportDetailContent } from './content/RevRecReportDetailContent'
import { RevRecReportDetailHeader } from './RevRecReportDetailHeader'

export const RevRecReportDetail = () => {
  return (
    <StandardLayout
      header={<RevRecReportDetailHeader />}
      content={<RevRecReportDetailContent />}
    />
  )
}
