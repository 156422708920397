import { BillingFrequency } from '@sequencehq/core-models'
import isAfter from 'date-fns/isAfter'
import { ResolvedPhase } from 'modules/Cube/domain/cube.domain.types'
import { getBillingDates } from 'modules/Cube/domain/postActionStages/queries/getPhaseAnalysis/utils/getBillingDates'

const FREQUENCY_MONTH_MAPPINGS: Record<BillingFrequency, number> = {
  ONE_TIME: 0,
  MONTHLY: 1,
  QUARTERLY: 3,
  YEARLY: 12
}

export const getNextBillingDateAfterEndDate = ({
  recurrenceDayOfMonth,
  phaseDates,
  prices
}: {
  recurrenceDayOfMonth?: number
  phaseDates: ResolvedPhase['dates']
  prices: ResolvedPhase['prices']
}) => {
  /**
   * Ongoing phases never end, so they are always valid. This is also the
   * case when we have no pricing data for the phase.
   */
  if (
    !phaseDates.duration ||
    !prices.length ||
    !phaseDates.absolute.end ||
    !phaseDates.absolute.start ||
    !recurrenceDayOfMonth
  ) {
    return null
  }

  const viableEndDates = getBillingDates({
    recurrenceDayOfMonth,
    phaseDates: {
      start: phaseDates.absolute.start,
      end: phaseDates.absolute.end
    }
  })

  const minimumPhaseBillingFrequency = prices.reduce(
    (acc: BillingFrequency, price) => {
      if (
        FREQUENCY_MONTH_MAPPINGS[price.billingFrequency] >
        FREQUENCY_MONTH_MAPPINGS[acc]
      ) {
        return price.billingFrequency
      }

      return acc
    },
    'ONE_TIME'
  )

  const nextBillingDateAfterEndDate = phaseDates.absolute.end
    ? viableEndDates[minimumPhaseBillingFrequency].find(date =>
        isAfter(date, phaseDates.absolute.end as Date)
      ) ?? null
    : null

  return nextBillingDateAfterEndDate
}
