import { PopoverCustomerCard } from '@sequencehq/quote-content'

import { usePopoverCustomer } from 'modules/Cube/view/layouts/quote/QuoteEditorControls/PopoverCustomerCard/usePopoverCustomerCard'

export const PopoverCustomer = () => {
  const hook = usePopoverCustomer()

  if (!hook.isVisible) {
    return undefined
  }

  return <PopoverCustomerCard {...hook} />
}
