import { useCallback } from 'react'
import {
  usePostTaxRegistrationsMutation,
  usePutCustomersByIdMutation,
  usePutTaxRegistrationsByIdMutation
} from 'features/api'
import {
  AddressModel,
  Country,
  CustomerModel,
  IntegrationService,
  TaxRegistrationModel,
  TaxStatus
} from '@sequencehq/core-models'

type UpdateCustomerData = {
  legalName: string
  address: AddressModel
  email: string
  billingEmails?: string[]
  customerAliases?: string[]
  taxStatus: TaxStatus
  taxRegistration?: {
    id?: string
    identifier: string
    country: Country
  }
  externalIds?: {
    key: IntegrationService
    value: string
  }[]
  label?: string
}

export const useSaveCustomer = () => {
  const [putCustomerMutator] = usePutCustomersByIdMutation()
  const [postTaxRegistrationMutator] = usePostTaxRegistrationsMutation()
  const [putTaxRegistrationMutator] = usePutTaxRegistrationsByIdMutation()

  const createOrUpdateTaxRegistration = useCallback(
    async (
      customerId: string,
      taxRegistration: NonNullable<UpdateCustomerData['taxRegistration']>
    ): Promise<{
      taxRegistration: TaxRegistrationModel | null
      success: boolean
    }> => {
      const taxRegistrationArgs = {
        customerId: customerId,
        country: taxRegistration.country,
        taxIdentifier: taxRegistration.identifier
      }

      const taxRegistrationResponse = taxRegistration.id
        ? await putTaxRegistrationMutator({
            id: taxRegistration.id,
            updateTaxRegistrationEndpointUpdateTaxRegistrationRequestModel:
              taxRegistrationArgs
          })
        : await postTaxRegistrationMutator({
            createTaxRegistrationEndpointCreateTaxRegistrationRequestModel:
              taxRegistrationArgs
          })

      if ('error' in taxRegistrationResponse) {
        return {
          taxRegistration: null,
          success: false
        }
      }

      const value = taxRegistrationResponse.data.value()

      return {
        taxRegistration: value ? { ...value, customerId } : null,
        success: true
      }
    },
    [postTaxRegistrationMutator, putTaxRegistrationMutator]
  )

  const update = useCallback(
    (customerId: string) =>
      async (
        customer: UpdateCustomerData
      ): Promise<{
        customer: CustomerModel | null
        success: boolean
      }> => {
        const customerResponse = await putCustomerMutator({
          id: customerId,
          updateCustomerRequest: {
            legalName: customer.legalName,
            billingEmails: customer.billingEmails,
            customerAliases: customer.customerAliases,
            address: customer.address,
            email: customer.email,
            taxStatus: customer.taxStatus,
            label: customer.label
          }
        })

        if ('error' in customerResponse) {
          return { customer: null, success: false }
        }

        if (customer.taxRegistration) {
          const taxRegistrationResult = await createOrUpdateTaxRegistration(
            customerId,
            customer.taxRegistration
          )

          return {
            customer: customerResponse.data.value() ?? null,
            success: taxRegistrationResult.success
          }
        }

        return {
          customer: customerResponse.data.value() ?? null,
          success: true
        }
      },
    [createOrUpdateTaxRegistration, putCustomerMutator]
  )

  const updateWithIntegrations = useCallback(
    (customerId: string) =>
      async (
        customer: UpdateCustomerData & {
          externalIds: NonNullable<UpdateCustomerData['externalIds']>
        }
      ): Promise<{
        customer: CustomerModel | null
        success: boolean
      }> => {
        const customerResponse = await putCustomerMutator({
          id: customerId,
          updateCustomerRequest: {
            legalName: customer.legalName,
            billingEmails: customer.billingEmails,
            customerAliases: customer.customerAliases,
            address: customer.address,
            email: customer.email,
            taxStatus: customer.taxStatus,
            externalIds: customer.externalIds,
            label: customer.label
          }
        })

        if ('error' in customerResponse) {
          return { customer: null, success: false }
        }

        return {
          customer: customerResponse.data.value() ?? null,
          success: true
        }
      },
    [putCustomerMutator]
  )

  return {
    update,
    updateWithIntegrations
  }
}
