import {
  Dashboardv20240509Api,
  dashboardv20240509Client
} from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { useCallback } from 'react'

export type v1ApiTaxRate = Dashboardv20240509Api.GetTaxRate.TaxRate
type UseLoadTaxRates = () => () => Promise<v1ApiTaxRate[]>

export const useLoadAllTaxRates: UseLoadTaxRates = () => {
  const load = useCallback(async () => {
    const taxRates =
      (
        await dashboardv20240509Client.getTaxRates({
          limit: 1000
        })
      )?.data?.items ?? []

    return taxRates
  }, [])

  return load
}
