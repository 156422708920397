import { Flex } from '@chakra-ui/react'
import { Inspector } from '@sequencehq/core-components'
import { FC } from 'react'
import { hubSpotIntegrationConfig } from '../hubspot.integration.config'
import { LinkCustomerToServiceWidget } from '../../common/LinkEntities/LinkCustomerToService'
import { useIntegrationActive } from 'Integrations/utils/useIntegrationActive'
import { SmallIcon } from 'Integrations/integrationsConfig/common/SmallIcon'

export interface HubSpotInspectorItemProps {
  customerId: string
  canUnlink?: boolean
}

export const LinkHubSpotCustomerWidget: FC<{
  customerId: string
  canUnlink?: boolean
}> = props => (
  <LinkCustomerToServiceWidget
    customerId={props.customerId}
    service="HubSpot"
    canUnlink={props.canUnlink}
    externalEntityLabel="company"
    sequenceEntityLabel="customer"
  />
)

export const HubspotInspectorItem: FC<{
  children: React.ReactNode
}> = props => {
  const integrationActive = useIntegrationActive('HubSpot')

  if (!integrationActive) {
    return null
  }

  return (
    <Inspector.IconItem
      data-testid="hubspot.inspector.item"
      content={
        <Inspector.Content
          title="HubSpot"
          data-testid="hubspot.inspector.content"
          icon={<SmallIcon url={hubSpotIntegrationConfig.smallLogoUrl} />}
        >
          <Flex flexDirection="column" gap="16px">
            {props.children}
          </Flex>
        </Inspector.Content>
      }
      tooltip="HubSpot"
      icon={<SmallIcon url={hubSpotIntegrationConfig.smallLogoUrl} />}
    />
  )
}
