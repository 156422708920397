import isEqual from 'date-fns/isEqual'
import { CubeReducerState } from 'modules/Cube/domain/cube.domain.types'
import { objectsAreEqualIgnoreUndefined } from 'modules/Cube/utils/objectsAreEqualIgnoreUndefined'

/**
 * This query is used to check whether or not critical path data is different vs.
 * the initial load. It should contain the minimal set of data to determine whether
 * or not an edit has been made, and otherwise ignore data.
 *
 * Therefore, 'supporting' data such as customer are not required here, since
 * the change of id in the critical path billing schedule will be enough to indicate
 * a difference.
 * @param prevData
 * @returns
 */
export const getCriticalPathData = (
  prevData: CubeReducerState['data']
): Partial<CubeReducerState['data']> => {
  return {
    common: prevData.common,
    schedule: prevData.schedule,
    phases: prevData.phases,
    minimums: prevData.minimums,
    discounts: prevData.discounts
  }
}

export const hasCubeBeenUpdated = (prevState: CubeReducerState) => {
  const cubeEqual = objectsAreEqualIgnoreUndefined(
    getCriticalPathData(prevState.initialData),
    getCriticalPathData(prevState.data)
  )

  if (
    !cubeEqual ||
    !prevState.initialData.common.startDate ||
    !prevState.data.common.startDate
  ) {
    return true
  }

  /**
   * Dates are not properly covered by dequal (which powers the base equality check).
   * We've recently discussed moving towards our own date library, which would be
   * string based, but until now we need to specifically account for any dates that
   * need comparing. If more than one or two dates get added before we shift to a
   * new library, then it may be worth doing a more involved, general, solution that
   * can detect and compare date objects within the structures.
   *
   * However, for now, since start date is the only date that can be directly edited
   * this is fine!
   */
  const startDateChanged = isEqual(
    prevState.initialData.common.startDate,
    prevState.data.common.startDate
  )

  return !startDateChanged
}
