import { BillingScheduleStatus } from '@sequencehq/core-models'
import { useCubeContext } from 'modules/Cube/communication/internal/cube.domain.context'
import { CubeStatus } from 'modules/Cube/domain/cube.domain.types'
import { scheduleStatusAdapter } from 'modules/Cube/utils/status.adapter'
import { useCallback, useMemo, useState } from 'react'
import { Location, useNavigate } from 'react-router-dom'

export enum BillingScheduleModals {
  ConfirmStart = 'confirmStart',
  Archive = 'archive'
}

type UseBillingScheduleHeader = () => {
  scheduleId: string
  isNew: boolean
  status: CubeStatus
  title: string
  isArchived: boolean
  save: {
    show: boolean
    disabled: boolean
    onClick: () => void
    text: string
  }
  saveDraft: {
    show: boolean
    disabled: boolean
    onClick: () => void
    text: string
  }
  cancel: {
    onClick: () => void
  }
  blocker: {
    enabled: boolean
    ignoreBlock: (nextLocation: Location, currentLocation?: Location) => boolean
    submitting: boolean
  }
  modals: {
    confirmStart: {
      active: boolean
      onClose: () => void
      onConfirm: () => Promise<void>
      submitting: boolean
    }
    archive: {
      active: boolean
      onClose: () => void
      onConfirm: () => Promise<void>
      submitting: boolean
      status: BillingScheduleStatus
    }
  }
  activateModal: (modal: BillingScheduleModals) => void
}

export const useBillingScheduleHeader: UseBillingScheduleHeader = () => {
  const cubeContext = useCubeContext()
  const navigate = useNavigate()
  const [activeModal, setActiveModal] = useState<BillingScheduleModals | null>(
    null
  )
  const [submitting, setSubmitting] = useState<boolean>(false)

  const activateModal = useCallback((modal: BillingScheduleModals) => {
    setActiveModal(modal)
  }, [])

  const title = useMemo(() => {
    if (cubeContext.queries.rawData.data.common.isArchived) {
      return 'Archived Schedule'
    }
    if (
      cubeContext.queries.rawData.data.common.status ===
      CubeStatus.ScheduleUnsaved
    ) {
      return 'Create a Billing Schedule'
    }

    return 'Billing Schedule'
  }, [cubeContext.queries.rawData.data.common])

  const isArchived = useMemo(
    () => cubeContext.queries.rawData.data.common.isArchived,
    [cubeContext.queries.rawData.data.common.isArchived]
  )

  const isNew = useMemo(
    () =>
      cubeContext.queries.rawData.data.common.status ===
      CubeStatus.ScheduleUnsaved,
    [cubeContext.queries.rawData.data.common.status]
  )

  const scheduleId = useMemo(
    () => cubeContext.queries.rawData.data.common.id,
    [cubeContext.queries.rawData.data.common.id]
  )

  const saveText = useMemo(() => {
    if (
      [CubeStatus.ScheduleActive, CubeStatus.SchedulePending].includes(
        cubeContext.queries.rawData.data.common.status
      )
    ) {
      return 'Update schedule'
    }
    return 'Start schedule'
  }, [cubeContext.queries.rawData.data.common.status])

  const saveDraftText = useMemo(() => {
    if (
      cubeContext.queries.rawData.data.common.status ===
      CubeStatus.ScheduleDraft
    ) {
      return 'Update draft'
    }
    return 'Save draft'
  }, [cubeContext.queries.rawData.data.common.status])

  const onSave = useCallback(() => {
    if (cubeContext.queries.validation.validationErrorsPresent.publish) {
      cubeContext.mutators.updateEditor({
        activeValidationSet: 'publish'
      })
      return
    }

    if (
      [CubeStatus.ScheduleDraft, CubeStatus.ScheduleUnsaved].includes(
        cubeContext.queries.rawData.data.common.status
      )
    ) {
      activateModal(BillingScheduleModals.ConfirmStart)
      return
    }

    void cubeContext.mutators.external.out.schedule?.activate()
  }, [cubeContext, activateModal])

  const onSaveDraft = useCallback(() => {
    if (cubeContext.queries.validation.validationErrorsPresent.save) {
      cubeContext.mutators.updateEditor({
        activeValidationSet: 'save'
      })
      return
    }
    void cubeContext.mutators.external.out.save({
      reload: true
    })
  }, [cubeContext])

  const onCancel = useCallback(() => {
    navigate('/billing-schedules')
  }, [navigate])

  const saveDraftDisabled = useMemo(() => {
    return !cubeContext.queries.availableFeatures.schedule.save.available
      .enabled
  }, [cubeContext])

  const saveDisabled = useMemo(() => {
    return !cubeContext.queries.availableFeatures.schedule.publish.available
      .enabled
  }, [cubeContext])

  const blocker = useMemo(() => {
    return {
      enabled: !saveDisabled && !saveDraftDisabled,
      submitting:
        cubeContext.queries.rawData.editor.savingDraft ||
        cubeContext.queries.rawData.editor.savingSchedule,
      ignoreBlock: ({ pathname }: Location, currentLocation?: Location) => {
        if (
          currentLocation?.pathname === '/billing-schedules/new/customers/new'
        ) {
          return true
        }

        if (
          cubeContext.queries.rawData.data.common.status ===
          CubeStatus.ScheduleUnsaved
        ) {
          return (
            pathname.startsWith(`/billing-schedules/new/`) ||
            pathname.startsWith(`/cube/schedules/new/`)
          )
        }

        return (
          pathname.startsWith(
            `/billing-schedules/${cubeContext.queries.rawData.data.common.id}`
          ) ||
          pathname.startsWith(
            `/cube/schedules/${cubeContext.queries.rawData.data.common.id}`
          )
        )
      }
    }
  }, [
    saveDisabled,
    saveDraftDisabled,
    cubeContext.queries.rawData.data.common,
    cubeContext.queries.rawData.editor.savingDraft,
    cubeContext.queries.rawData.editor.savingSchedule
  ])

  const modals = useMemo(() => {
    return {
      confirmStart: {
        active: activeModal === BillingScheduleModals.ConfirmStart,
        onClose: () => setActiveModal(null),
        onConfirm: async () => {
          setSubmitting(true)
          await cubeContext.mutators.external.out.schedule?.activate()
          setSubmitting(false)
          setActiveModal(null)
        },
        submitting
      },
      archive: {
        active: activeModal === BillingScheduleModals.Archive,
        onClose: () => setActiveModal(null),
        onConfirm: async () => {
          setSubmitting(true)
          await cubeContext.mutators.external.out.archive()
          setSubmitting(false)
          setActiveModal(null)
        },
        submitting,
        status:
          scheduleStatusAdapter.out(
            cubeContext.queries.rawData.data.common.status
          ) ?? 'DRAFT'
      }
    }
  }, [activeModal, cubeContext, submitting])

  return {
    scheduleId,
    isNew,
    status:
      cubeContext.queries.rawData.data.common.status ===
      CubeStatus.ScheduleUnsaved
        ? CubeStatus.ScheduleUnsaved
        : cubeContext.queries.rawData.data.common.status,
    title,
    save: {
      show: cubeContext.queries.availableFeatures.schedule.publish.available
        .visible,
      disabled: saveDisabled,
      onClick: onSave,
      text: saveText
    },
    saveDraft: {
      show: cubeContext.queries.availableFeatures.schedule.save.available
        .visible,
      disabled: saveDraftDisabled,
      onClick: onSaveDraft,
      text: saveDraftText
    },
    cancel: {
      onClick: onCancel
    },
    isArchived,
    blocker,
    modals,
    activateModal
  }
}
