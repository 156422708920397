import { Currency } from '@sequencehq/core-models'
import { StringDate } from '@sequencehq/utils'

const formatRecognizedRevenueData = (
  recognizedRevenuePerMonth:
    | Array<{ amount: number; periodStart: string }>
    | undefined
): Array<{
  value: number
  date: string
  subValues: {
    recognized: number
    deferred: number
  }
}> => {
  if (!recognizedRevenuePerMonth?.length) {
    return []
  }

  return recognizedRevenuePerMonth.map(({ amount, periodStart }) => ({
    value: amount,
    subValues: {
      recognized: amount,
      deferred: 0
    },
    date: periodStart
  }))
}

interface UseRecognizedRevenueGraphProps {
  dateRange: {
    start: StringDate
    end: StringDate
  }
  currency: Currency
}

export const useRecognizedRevenueGraph = (
  //Props will actually get used when this is a real hook.
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  props: UseRecognizedRevenueGraphProps
) => {
  /**
   * TODO: replace with API package call
   */
  const totalRecognizedRevenue = 1500
  const invoicedRevenueByMonth = [
    {
      amount: 100,
      periodStart: '2023-09-01'
    },
    {
      amount: 200,
      periodStart: '2023-10-01'
    },
    {
      amount: 400,
      periodStart: '2023-11-01'
    },
    {
      amount: 800,
      periodStart: '2024-01-01'
    }
  ]

  const transformedData = formatRecognizedRevenueData(invoicedRevenueByMonth)

  return {
    data: transformedData,
    total: totalRecognizedRevenue,
    isFetching: false
  }
}
