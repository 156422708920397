import {
  Box,
  Button,
  Flex,
  ModalContent,
  ModalOverlay,
  useToast,
  Modal as Dialogue
} from '@chakra-ui/react'
import { FormSuccess } from 'components/Form'
import { IntegratedFormModalProps } from 'components/Form/types'
import Modal from 'components/Modal'
import { closeOverlay } from 'features/overlay'
import { useDispatch } from 'features/store'
import { QueryParams } from 'lib/types'
import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  GreyGrey0,
  GreyGrey100,
  GreyGrey80,
  Lato14ExtendedRegular,
  Lato16Bold
} from '@sequencehq/design-tokens'

export const createFormModal =
  <Mutation extends QueryParams, FormProps>({
    FormComponent,
    useMutation,
    successMessage,
    modalTitle,
    navigateTo,
    submitTitle,
    formId,
    buttonVariant,
    overrideSubmitButton,
    successToast,
    errorToast,
    modalTitleFromProps,
    previousModal,
    leftButton,
    successContent,
    variant = 'default'
  }: IntegratedFormModalProps<Mutation, FormProps>): FC<
    FormProps & {
      onClose?: () => void
    }
  > =>
  props => {
    const [submitForm, result] = useMutation()

    const dispatch = useDispatch()
    const toast = useToast()

    const navigate = useNavigate()

    useEffect(() => {
      if (!result.isSuccess) {
        return
      }

      if (result.error && errorToast) {
        toast({
          id: `error-${formId}`,
          position: 'bottom',
          duration: 6000,
          render: () => errorToast
        })
      }

      const timeoutId = setTimeout(
        () => {
          const validatedData = result.data?.value()
          if (validatedData && navigateTo) {
            const url = navigateTo(validatedData)
            navigate(url)
          }

          if (!successContent) {
            dispatch(closeOverlay())
          }

          if (validatedData && successToast) {
            toast({
              id: `success-${formId}`,
              position: 'bottom',
              duration: 6000,
              render: () => successToast(validatedData, props)
            })
          }
        },
        successToast ? 0 : 1000
      )

      return () => clearTimeout(timeoutId)
    }, [result.isSuccess, dispatch, navigate, result, toast, props])

    const response = result.data?.value()

    const onClose = () => {
      if (props.onClose) {
        props.onClose()
      }

      dispatch(closeOverlay())
    }

    if (variant === 'dialogue') {
      return (
        <Dialogue isOpen onClose={onClose} isCentered>
          <ModalOverlay>
            <ModalContent
              backgroundColor={GreyGrey0}
              padding="16px"
              borderRadius="8px"
            >
              <Box color={GreyGrey100} {...Lato16Bold} marginBottom="4px">
                {modalTitleFromProps ? modalTitleFromProps(props) : modalTitle}
              </Box>
              <Box
                color={GreyGrey80}
                {...Lato14ExtendedRegular}
                marginBottom="24px"
              >
                {!toast && result.isSuccess && (
                  <FormSuccess message={successMessage} />
                )}
                {successContent && response && result.isSuccess && (
                  <>
                    <FormSuccess message={successMessage} />
                    <Flex py="20px" alignItems="center" flexDirection="column">
                      {successContent(response)}
                    </Flex>
                  </>
                )}
                {!result.isSuccess && (
                  <Box color={GreyGrey80} {...Lato14ExtendedRegular}>
                    <FormComponent
                      {...props}
                      submitForm={submitForm}
                      formId={formId}
                      result={result}
                    />
                  </Box>
                )}
              </Box>
              <Flex gap="8px" justifyContent="flex-end">
                <Flex basis="50%">
                  <Button
                    variant="secondary2"
                    isDisabled={result.isLoading}
                    onClick={onClose}
                    height="32px"
                    width="100%"
                  >
                    Cancel
                  </Button>
                </Flex>
                <Flex basis="50%">
                  <Button
                    variant="primary2"
                    isLoading={result.isLoading}
                    isDisabled={result.isLoading}
                    form={formId}
                    type="submit"
                    height="32px"
                    width="100%"
                  >
                    {submitTitle}
                  </Button>
                </Flex>
              </Flex>
            </ModalContent>
          </ModalOverlay>
        </Dialogue>
      )
    }

    return (
      <Modal
        previousModal={previousModal}
        leftButton={leftButton}
        title={modalTitleFromProps ? modalTitleFromProps(props) : modalTitle}
        submitTitle={submitTitle}
        showSuccess={result.isSuccess}
        formId={formId}
        buttonVariant={buttonVariant}
        isLoading={result.isLoading}
        overrideSubmitButton={overrideSubmitButton}
        showClose={(successContent && response && result.isSuccess) || false}
        onClose={props.onClose}
      >
        {!toast && result.isSuccess && <FormSuccess message={successMessage} />}
        {successContent && response && result.isSuccess && (
          <>
            <FormSuccess message={successMessage} />
            <Flex py="20px" alignItems="center" flexDirection="column">
              {successContent(response)}
            </Flex>
          </>
        )}
        {!result.isSuccess && (
          <FormComponent
            {...props}
            submitForm={submitForm}
            formId={formId}
            result={result}
          />
        )}
      </Modal>
    )
  }
