import { closeOverlay } from 'features/overlay'
import { useDispatch } from 'features/store'
import { FC } from 'react'
import { CreateCustomerForm } from 'components/Customers/components/drawers/CreateCustomer/CreateCustomerForm'
import { CustomersContextProvider } from 'components/Customers/hooks/useCustomersContext'

interface CreateCustomerModalFormProps {
  alias?: string
}

export const CreateCustomerModalForm: FC<CreateCustomerModalFormProps> = () => {
  const dispatch = useDispatch()

  return (
    <CustomersContextProvider>
      <CreateCustomerForm
        onSuccess={() => dispatch(closeOverlay())}
        onClose={() => dispatch(closeOverlay())}
      />
    </CustomersContextProvider>
  )
}
