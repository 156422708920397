import {
  Modal,
  ModalContextProvider,
  SimpleModalUI,
  withUnmountOnModalClose
} from '@sequencehq/core-components'
import { ReactNode, useState } from 'react'
import { Flex } from '@chakra-ui/react'
import { useForm, type FormFields } from '@sequencehq/utils'
import { required } from '@sequencehq/validation'
import AvalaraTaxCodeInput from 'Integrations/integrationsConfig/avalara/AvalaraConfigurationManagement/common/AvalaraTaxCodeInput'

type TaxCategoryFields = FormFields<{
  avalaraTaxCode: string
}>
type FormValues = {
  avalaraTaxCode: TaxCategoryFields['avalaraTaxCode']['value']
}

interface Props {
  trigger: ReactNode | ((openModal: () => void) => ReactNode)
  onAddTaxCode: (values: { avalaraTaxCode: string; taxName: string }) => void
}

const AddAvalaraTaxCodeToTaxCategoryModalUI = withUnmountOnModalClose(
  ({ onAddTaxCode }: Omit<Props, 'trigger'>) => {
    const { fields, queries } = useForm({
      value: {
        avalaraTaxCode: ''
      },
      fieldConfiguration: [
        {
          property: 'avalaraTaxCode',
          validation: [required]
        }
      ]
    })

    const [avalaraTaxCodeInfo, setAvalaraTaxCodeInfo] = useState<
      string | undefined
    >()

    const canSubmit = queries.isValid && avalaraTaxCodeInfo

    const handleSubmit = (formValues: FormValues) => {
      if (formValues.avalaraTaxCode && avalaraTaxCodeInfo) {
        onAddTaxCode({
          avalaraTaxCode: formValues.avalaraTaxCode,
          taxName: avalaraTaxCodeInfo
        })
      }
    }

    return (
      <SimpleModalUI
        title="Add tax code"
        cancelButtonText="Cancel"
        submitButtonText="Add tax code"
        submitDisabled={!canSubmit}
        closeOnCancel
        closeOnSubmit
        contentProps={{
          minHeight: '0px'
        }}
        onSubmit={() => {
          void handleSubmit({
            avalaraTaxCode: fields.avalaraTaxCode.value
          })
        }}
      >
        <Flex flexDirection="column" gap={2} mb="-17px">
          <AvalaraTaxCodeInput
            value={fields.avalaraTaxCode.value}
            onChange={value => {
              setAvalaraTaxCodeInfo(undefined)
              fields.avalaraTaxCode.onChange(value)
            }}
            onSuccess={setAvalaraTaxCodeInfo}
          />
        </Flex>
      </SimpleModalUI>
    )
  }
)

const AddAvalaraTaxCodeToTaxCategoryModal = ({ trigger, ...props }: Props) => {
  return (
    <ModalContextProvider>
      <Modal.Trigger>{trigger}</Modal.Trigger>
      <AddAvalaraTaxCodeToTaxCategoryModalUI {...props} />
    </ModalContextProvider>
  )
}

export default AddAvalaraTaxCodeToTaxCategoryModal
