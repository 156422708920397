import { useSearchParams } from 'react-router-dom'
import invariant from 'tiny-invariant'

/**
 * A simple hook to grab the dates being covered by this view.
 * @returns
 */
export const useSelectedDates = (): {
  startDate: string
  endDate: string
} => {
  const [searchParams] = useSearchParams()
  const startDate = searchParams.get('startDate')
  const endDate = searchParams.get('endDate')

  invariant(startDate && endDate, 'Accounting period dates are required')

  return {
    startDate,
    endDate
  }
}

export const useExpanded = (): {
  expanded: boolean
  toggleExpanded: () => void
} => {
  const [searchParams, setSearchParams] = useSearchParams()

  const expanded = searchParams.get('expanded') === 'true'

  const toggleExpanded = () => {
    const currentSearchParams = [...searchParams.entries()].reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value
      }),
      {}
    )

    setSearchParams({
      ...currentSearchParams,
      expanded: expanded ? 'false' : 'true'
    })
  }

  return {
    expanded,
    toggleExpanded
  }
}
