import { UserCircleIcon } from '@heroicons/react/16/solid'
import { Box, Divider, Flex, Text } from '@chakra-ui/react'
import {
  GreyGrey10,
  GreyGrey30,
  GreyGrey40,
  GreyGrey60,
  GreyGrey70,
  GreyGrey90,
  IndigoIndigo50,
  Lato13Bold,
  Lato13Regular,
  Lato24Bold
} from '@sequencehq/design-tokens'
import { EmptyState } from 'components/InsightsV2/common/EmptyState'
import { WidgetLoading } from 'components/InsightsV2/common/WidgetLoading/WidgetLoading'
import { Container } from 'components/InsightsV2/widgets/TotalCustomersWidget/Container'
import { ProgressBar } from 'components/InsightsV2/widgets/TotalCustomersWidget/ProgressBar'
import { useGetInsightsCustomerTotalsQuery } from 'features/api'

const TotalCustomersWidget: React.FC = () => {
  const { data, isLoading, error } = useGetInsightsCustomerTotalsQuery()
  const totalCustomersData = data?.value()

  if (isLoading || !totalCustomersData || error) {
    return <WidgetLoading />
  }

  const Tooltip = (
    <>
      <Box
        backgroundColor={GreyGrey10}
        padding="10px"
        borderBottom={`1px solid ${GreyGrey30}`}
      >
        <Text {...Lato13Bold} color="gray.70">
          Customer breakdown
        </Text>
      </Box>
      <Box padding="10px">
        <Flex justifyContent="space-between">
          <Text {...Lato13Regular}>Never billed before</Text>
          <Text {...Lato13Bold}>
            {totalCustomersData.numCustomersNeverBilled}
          </Text>
        </Flex>
        <Divider borderColor={GreyGrey30} margin="10px 0" />
        <Flex justifyContent="space-between">
          <Text {...Lato13Regular}>No longer billed</Text>
          <Text {...Lato13Bold}>
            {totalCustomersData.numCustomersNoLongerBilled}
          </Text>
        </Flex>
      </Box>
    </>
  )

  return (
    <Container>
      <Text color={GreyGrey60} {...Lato13Bold} mb={2}>
        Total Customers
      </Text>
      <Text color={GreyGrey90} {...Lato24Bold} mb={2}>
        {totalCustomersData.total}
      </Text>
      {totalCustomersData.total ? (
        <>
          <ProgressBar
            label="With active schedules"
            value={totalCustomersData.numCustomersWithActiveSchedule}
            total={totalCustomersData.total}
            color="white"
            backgroundColor={IndigoIndigo50}
          />
          <Box
            width="100%"
            height="1px"
            borderWidth="1px"
            borderStyle="dashed"
            borderColor={GreyGrey40}
            margin="16px 0"
          />
          <ProgressBar
            label="Without active schedules"
            value={
              totalCustomersData.numCustomersNeverBilled +
              totalCustomersData.numCustomersNoLongerBilled
            }
            total={totalCustomersData.total}
            color={GreyGrey70}
            backgroundColor={GreyGrey40}
            tooltip={Tooltip}
          />
        </>
      ) : (
        <EmptyState
          label="No customers"
          Icon={<UserCircleIcon width="16px" height="16px" />}
        />
      )}
    </Container>
  )
}

export default TotalCustomersWidget
