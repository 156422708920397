import { UTCDate } from '@date-fns/utc'
import formatInTimeZone from 'date-fns-tz/formatInTimeZone'
import toDate from 'date-fns-tz/toDate'

/**
 * The API returns a simple date with no timestamp or timezone, but has an implicit
 * time/zone of midnight UTC at the beginning of the specified date. We have to be
 * incredibly careful when parsing to ensure we land on the correct moment in time
 * when parsing the date as a result (since if we parse a date in PST, for example,
 * we would get a momnent of time with an 8 hour offset to what we intended).
 * @param apiDate
 * @returns
 */
export const parseDateFromApi = (apiDate: string): UTCDate | undefined => {
  if (!apiDate) {
    return
  }

  return new UTCDate(toDate(apiDate, { timeZone: 'UTC' }))
}

/**
 * Likewise we need to be careful when outputting to the API too. We do use UTCDate
 * internally to make it less likely to be an issue (since UTCDate format calls etc.
 * return the UTC date, not the local one), but it's still worth ensuring we do output
 * to the UTC format when saving.
 * @param date
 * @returns
 */
export const outputDateToApi = (date: UTCDate | undefined): string => {
  if (!date) {
    return ''
  }

  return formatInTimeZone(date, 'UTC', 'yyyy-MM-dd')
}

export const apiDatesAdapters = {
  in: parseDateFromApi,
  out: outputDateToApi
}
