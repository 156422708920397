import { PropsWithChildren, useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { Flex, Td, Text, Tr } from '@chakra-ui/react'
import { Popover } from '@sequencehq/core-components'
import { PriceMetadataModel } from '@sequencehq/core-models'
import {
  GreyGrey70,
  IndigoIndigo50,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { PriceTableRow } from 'modules/Products/types'
import { useGetPricesByIdMetadataQuery } from 'features/api'
import { toBillingFrequencyLabel } from '@sequencehq/utils'

const ProductPriceVariantCustomerPopover = ({
  priceMetadata,
  children
}: PropsWithChildren<{
  priceMetadata: PriceMetadataModel
}>) => {
  return (
    <Popover
      position="top"
      header="Customers"
      maxHeight="400px"
      variant="table"
      content={priceMetadata.billingSchedules.map(schedule => (
        <Tr
          key={schedule.billingScheduleId}
          sx={{
            _hover: {
              cursor: 'default!important',
              bg: 'inherit!important'
            }
          }}
        >
          <Td
            display={'flex'}
            alignItems="center"
            justifyContent="space-between"
          >
            <span {...Lato13Regular} color={GreyGrey70}>
              {schedule.customerLegalName}
            </span>
            <Link
              to={`/billing-schedules/${schedule.billingScheduleId}`}
              style={{
                ...Lato13Regular,
                fontWeight: '600',
                color: IndigoIndigo50
              }}
              onClick={e => e.stopPropagation()}
            >
              View schedule
            </Link>
          </Td>
        </Tr>
      ))}
    >
      {children}
    </Popover>
  )
}

export const ProductPriceVariant = ({
  priceRow,
  productId
}: {
  priceRow: PriceTableRow
  productId: string
}) => {
  const navigate = useNavigate()
  const { data, isFetching } = useGetPricesByIdMetadataQuery({
    id: priceRow.id
  })

  const priceRowFrequency = toBillingFrequencyLabel(priceRow.frequency)

  const priceMetadata = data?.value() as PriceMetadataModel

  const renderCustomerName = useMemo(() => {
    if (priceMetadata?.billingSchedules?.length > 0) {
      if (priceMetadata.billingSchedules.length === 1) {
        return <Td>{priceMetadata.billingSchedules[0].customerLegalName}</Td>
      } else {
        return (
          <Td>
            <Flex gap="6px">
              <Text {...Lato13Regular} color={GreyGrey70}>
                {priceMetadata.billingSchedules[0].customerLegalName}
              </Text>

              <ProductPriceVariantCustomerPopover priceMetadata={priceMetadata}>
                <Text
                  {...Lato13Regular}
                  fontWeight="600"
                  color={GreyGrey70}
                  borderBottom="1px dotted"
                  borderColor={GreyGrey70}
                >{`+ ${priceMetadata.billingSchedules.length - 1}`}</Text>
              </ProductPriceVariantCustomerPopover>
            </Flex>
          </Td>
        )
      }
    } else {
      return <Td {...Lato13Regular}>-</Td>
    }
  }, [priceMetadata])

  if (isFetching) {
    return null
  }

  return (
    <Tr
      key={priceRow.id}
      onClick={() => {
        navigate(
          `/products/${productId}/price/${priceRow.pricingModelUrlChunk}/${priceRow.id}`
        )
      }}
    >
      <Td textColor={IndigoIndigo50}>{priceRow.unitPrice}</Td>
      {renderCustomerName}
      <Td>{priceRow.currency}</Td>
      <Td>{priceRowFrequency}</Td>
      <Td>{priceRow.pricingModel}</Td>
      <Td>{priceRow.created}</Td>
    </Tr>
  )
}
