import { Text, Flex } from '@chakra-ui/react'
import { PlusIcon } from '@heroicons/react/16/solid'

import { Lato14Bold } from '@sequencehq/design-tokens'
import { Button } from '@sequencehq/core-components'

import { useCustomerContactsLayout } from './useCustomerContactsLayout'
import { CreateContactModal } from '../modals/CreateContactModal'
import { ContactTable } from './ContactTable/ContactTable'

export const CustomerContactsLayout = () => {
  const { buttonAddContact, contacts } = useCustomerContactsLayout()

  return (
    <>
      <Flex mb={-4} justifyContent="flex-end" alignItems="center">
        <Text flex={1} {...Lato14Bold}>
          Contacts
        </Text>
        <CreateContactModal
          trigger={
            <Button
              variant="ghost"
              leadingIcon={<PlusIcon width={16} />}
              onClick={buttonAddContact.onClick}
            >
              Add Contact
            </Button>
          }
          billingPreference={contacts.length ? undefined : 'PRIMARY'}
        />
      </Flex>
      <ContactTable />
    </>
  )
}
