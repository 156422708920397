import {
  forwardRef,
  Input as ChakraInput,
  InputGroup,
  useMultiStyleConfig
} from '@chakra-ui/react'
import type { InputProps as ChakraInputProps } from '@chakra-ui/react'
import { FC, MutableRefObject } from 'react'
import { SelectIcon } from '@sequencehq/forms'
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'

export type SelectInputProps = {
  isOpen: boolean
  inputRef: MutableRefObject<HTMLInputElement | null>
  disabled?: boolean
} & ChakraInputProps

export const SelectInput: FC<SelectInputProps> = forwardRef<
  SelectInputProps,
  'input'
>(({ width, inputRef, isOpen, disabled, ...props }, ref) => {
  const styles = useMultiStyleConfig('CustomSelect')

  return (
    <InputGroup width={width}>
      <ChakraInput
        variant="sequence-form"
        ref={el => {
          if (typeof ref === 'function') {
            ref(el)
          }
          inputRef.current = el
        }}
        {...props}
        disabled={disabled}
        style={{ cursor: 'default', userSelect: 'none' }}
      />
      <SelectIcon __css={styles.icon}>
        {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </SelectIcon>
    </InputGroup>
  )
})
