import { Box, Flex } from '@chakra-ui/react'
import {
  CreateTimeTravellerFormMutation,
  CreateTimeTravellerFormProps
} from 'components/CreateTimeTraveller/types'
import { FormErrors } from '@sequencehq/forms'
import { DateInputField } from 'components/FormFields'
import { handleFormResponse } from 'lib/formValidation'
import { noReturn } from 'lib/noReturn'
import { FC } from 'react'
import { Form } from 'react-final-form'
import { required } from '@sequencehq/validation'
import { MutationFormProps } from 'components/Form/types'

const CreateTimeTravellerForm: FC<
  MutationFormProps<CreateTimeTravellerFormMutation> &
    CreateTimeTravellerFormProps
> = ({ billingSchedule, submitForm, formId }) => {
  return (
    <Form
      keepDirtyOnReinitialize
      onSubmit={async (values, form) => {
        const res = await submitForm({
          createTimeTravellerEndpointCreateTimeTravellerRequestModel: {
            billingScheduleId: billingSchedule.id,
            frozenTime: values.frozenTime
          }
        })

        return handleFormResponse(res, form.getRegisteredFields())
      }}
      render={({ handleSubmit, submitError }) => {
        const dayzedDate = new Date()
        dayzedDate.setDate(dayzedDate.getDate() - 1)
        return (
          <Flex
            as="form"
            px={10}
            py={10}
            onSubmit={noReturn(handleSubmit)}
            id={formId}
          >
            <FormErrors formError={submitError} />
            <DateInputField
              fieldName="frozenTime"
              fieldLabel="Date"
              minDate={dayzedDate}
              isSelectable={() => true}
              validate={required}
            />
            <Box height={100} />
          </Flex>
        )
      }}
    />
  )
}

export default CreateTimeTravellerForm
