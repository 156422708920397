import { Grid, GridItem } from '@chakra-ui/react'
import { useIntegrationsContent } from './useIntegrationsContent'
import { IntegrationsHome } from './home/IntegrationsHome'
import { IntegrationList } from './integrationsList/IntegrationsList'
import { IntegrationDetail } from './integrationDetail/IntegrationDetail'

export const IntegrationsContent = () => {
  const hook = useIntegrationsContent()

  return (
    <Grid
      width="100%"
      height="100%"
      overflow="hidden"
      templateRows={'1fr auto'}
      templateColumns={'256px 1fr'}
      templateAreas={`
        "sidebar content" 
        "sidebar content"
      `}
    >
      {hook.showFeaturedIntegrations && (
        <GridItem
          gridArea="content"
          height="100%"
          overflow="auto"
          data-testid="integrationsBrowser.content"
        >
          {hook.activeIntegration ? (
            <IntegrationDetail integration={hook.activeIntegration} />
          ) : (
            <IntegrationsHome onSelectIntegration={hook.selectIntegration} />
          )}
        </GridItem>
      )}
      <GridItem
        gridArea="sidebar"
        height="100%"
        overflow="auto"
        data-testid="integrationsBrowser.sidebar"
      >
        <IntegrationList
          onSelectIntegration={hook.selectIntegration}
          activeIntegration={hook.activeIntegration?.id ?? null}
        />
      </GridItem>
    </Grid>
  )
}
