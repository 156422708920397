import { useParams } from 'react-router-dom'
import invariant from 'tiny-invariant'

import { Avatar, Flex, Grid, GridItem, Text } from '@chakra-ui/react'

import { Lato14Bold } from '@sequencehq/design-tokens'

import { CustomerContactsLayout } from 'CustomerContacts/view/layout/CustomerContactsLayout'
import { useEffect } from 'react'
import { useCustomerContactsContext } from 'CustomerContacts/communication/internal/CustomerContacts.domain.context'
import { useCustomerContext } from 'Customer/hooks/useCustomerContext'

export const ContactsPage = () => {
  const { customerId } = useParams<{ customerId: string }>()
  const { customer } = useCustomerContext()
  const ctx = useCustomerContactsContext()

  useEffect(() => {
    void ctx.mutators.external.in.core()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  invariant(customerId, 'customerId is required')

  return (
    <Grid templateColumns="minmax(0, 1024px)" justifyContent="center" flex={1}>
      <GridItem>
        <Flex p={5} flexDirection="column" gap="16px">
          <Avatar name={customer.legalName} mr={2} />
          <Text {...Lato14Bold} marginBottom={4}>
            {customer.legalName}
          </Text>

          <CustomerContactsLayout />
        </Flex>
      </GridItem>
    </Grid>
  )
}
