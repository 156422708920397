import { Grid, GridItem } from '@chakra-ui/react'
import { GreyGrey0, GreyGrey30 } from '@sequencehq/design-tokens'
import { InvoicedRevenueGraph } from './graphs/InvoicedRevenueGraph'
import { JournalReportTable } from './table/JournalReportTable'
import { RecognizedRevenueGraph } from './graphs/RecognizedRevenueGraph'
import {
  getFirstDateOfMonth,
  getLastDateOfMonth,
  getUtcDate,
  subtractMonths
} from '@sequencehq/utils'
import { useSelectedCurrency } from 'RevenueRecognition/view/utils/useSelectedCurrency'

const TODAY = getUtcDate(new Date())
const END_OF_THIS_MONTH = getLastDateOfMonth(TODAY)
const FIRST_OF_MONTH_ONE_YEAR_AGO = getFirstDateOfMonth(
  subtractMonths(TODAY, 12)
)

export const RevRevHomeContent = () => {
  const { currency, isLoading } = useSelectedCurrency()

  if (isLoading) {
    return null
  }

  return (
    <Grid height="100%" gridTemplateRows="auto 1fr">
      <Grid
        gridTemplateColumns="1fr 1fr"
        gridColumnGap="16px"
        padding="12px 16px"
        backgroundColor={GreyGrey0}
        borderBottom={`1px solid ${GreyGrey30}`}
      >
        <RecognizedRevenueGraph
          dateRange={{
            start: FIRST_OF_MONTH_ONE_YEAR_AGO,
            end: END_OF_THIS_MONTH
          }}
          currency={currency}
        />
        <InvoicedRevenueGraph
          dateRange={{
            start: FIRST_OF_MONTH_ONE_YEAR_AGO,
            end: END_OF_THIS_MONTH
          }}
          currency={currency}
        />
      </Grid>
      <GridItem overflow="auto">
        <JournalReportTable />
      </GridItem>
    </Grid>
  )
}
