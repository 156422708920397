import { isValidDate } from '@sequencehq/validation'
import { useEffect, useState } from 'react'
import parse from 'date-fns/parse'
import { DateObj } from 'dayzed'
import { useDateRange } from 'components/FormInputs/Dates/useDateRange'
import { formatRangeDate } from '@sequencehq/formatters'

type UseDateRangeStartInput = {
  emitValue?: (value: Date | undefined) => void
}

export const useDateRangeStartInput = ({
  emitValue
}: UseDateRangeStartInput) => {
  const {
    startDateInput,
    startDateMeta,
    endDateInput,
    selectedDates,
    popoverOpen,
    setPopoverOpen
  } = useDateRange()

  const isSelectableDate = (startDate: Date) => {
    return (
      !isValidDate(endDateInput.value) ||
      startDate.getTime() < endDateInput.value.getTime()
    )
  }

  const handleBlur = (): boolean => {
    const parsedStartDate = parse(localDateString, 'yyyy-MM-dd', new Date())

    if (!isValidDate(parsedStartDate)) {
      startDateInput.onChange(undefined)
      return false
    }

    if (isSelectableDate(parsedStartDate)) {
      startDateInput.onChange(parsedStartDate)
      return true
    }

    return false
  }

  const handleDateSelected = ({ selectable, date }: DateObj): boolean => {
    if (!selectable) {
      return false
    }

    if (isSelectableDate(date)) {
      startDateInput.onChange(date)
      return true
    }

    return false
  }

  useEffect(() => {
    setLocalDateString(formatRangeDate(startDateInput.value))
    if (emitValue) {
      emitValue(startDateInput.value ? startDateInput.value : undefined)
    }
  }, [startDateInput.value])

  const [localDateString, setLocalDateString] = useState<string>(
    formatRangeDate(startDateInput.value)
  )

  return {
    label: 'Start date',
    focusedInput: 'start' as const,
    handleBlur,
    handleFocus: () => {},
    handleDateSelected,
    selectedDates,
    localDateString,
    setLocalDateString,
    popoverOpen,
    setPopoverOpen,
    meta: startDateMeta
  }
}
