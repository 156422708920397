import { Text } from '@chakra-ui/react'
import { IdentificationIcon } from '@heroicons/react/16/solid'
import { DashboardApi20240730 } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { EntityLink, KebabMenu } from '@sequencehq/core-components'
import { CustomerModel } from '@sequencehq/core-models'
import { GreyGrey60 } from '@sequencehq/design-tokens'
import { dateTimeWithFormat } from '@sequencehq/formatters'
import {
  MagicTable,
  MagicTableCell,
  MagicTableCellBasic,
  MagicTableCellQuoteStatus,
  MagicTableFilterConfig
} from '@sequencehq/tables'
import MagicTableAutoLoader from 'components/AutoLoader/MagicTableAutoLoader'
import { CurrentUserId } from 'components/CurrentUserId/CurrentUserId'
import EmptyState, { EmptyStateContent } from 'components/Loading/EmptyState'
import { useDuplicateQuote } from 'components/Quotes/hooks/useDuplicateQuote'
import { useQuoteMagicTableLoader } from 'components/Quotes/hooks/useQuotesMagicTableLoader'
import { menuItemBuilders } from 'components/Quotes/menuItemBuilders'
import { QuoteView, QuotesFilters } from 'components/Quotes/types'
import {
  TagTypes,
  useGetCustomersByIdQuery,
  useGetUsersByIdQuery
} from 'features/api'
import { useSelector } from 'features/store'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useNotifications } from 'lib/hooks/useNotifications'
import { useGetMagicTableFilterOptions } from 'lib/magicTableSupport/useGetMagicTableFilterOptions'
import { ExtractQueryParams } from 'lib/types'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

export const contractLengthOptions = [
  {
    label: '6 months',
    value: '180'
  },
  {
    label: '1 year',
    value: '365'
  },
  {
    label: '2 years',
    value: '730'
  },
  {
    label: '3 years',
    value: '1095'
  }
]

type QuotesMagicTableProps = {
  emptyContent: EmptyStateContent
}

const QuotesMagicTable = ({ emptyContent }: QuotesMagicTableProps) => {
  const resetKey = useSelector(state => state.apiCaching.tags['Quotes'])
  const flags = useFlags()
  const notifications = useNotifications()
  const navigate = useNavigate()
  const { fetchCustomerNameOptions } = useGetMagicTableFilterOptions()
  const { magicTableLoader } = useQuoteMagicTableLoader()

  const duplicate = useDuplicateQuote()

  const duplicateQuote = useCallback(
    (quote: ListQuoteModel) => {
      notifications.displayNotification('Duplicating quote...', {
        type: 'info'
      })

      duplicate(quote.id)
        .then(duplicatedQuote => {
          notifications.displayNotification('Quote duplicated', {
            type: 'success'
          })

          navigate(`/quotes/${duplicatedQuote.id}`)
        })
        .catch(() => {
          notifications.displayNotification('Unable to duplicate quote', {
            type: 'error'
          })
        })
    },
    [duplicate, navigate, notifications]
  )

  const filters: MagicTableFilterConfig<QuotesFilters>[] = [
    {
      type: 'multiSelect',
      paramName: 'customerId',
      label: 'Customer',
      icon: IdentificationIcon,
      options: [],
      optionsFunc: fetchCustomerNameOptions,
      format: (value: string) => <Text>{value}</Text>
    },
    {
      paramName: 'includeArchived',
      type: 'toggle',
      label: 'Show archived quotes'
    }
  ]

  type ListQuoteModel = DashboardApi20240730.GetQuotes.Quote

  return (
    <CurrentUserId>
      {userId => (
        <>
          <MagicTable<QuoteView, TagTypes>
            entityNamePlural="quotes"
            sequenceUserId={userId}
            //@ts-expect-error - TODO: fix this once magic table is refactored not to be tightly coupled with rtk-query
            useLazyQuery={magicTableLoader}
            resetKey={resetKey}
            rowPath={quote => `/quotes/${quote.id}`}
            columns={[
              {
                id: 'title',
                accessorFn: row => ({
                  title: row.title,
                  archivedAt: row.archivedAt
                }),
                header: 'Title',
                cell: value => {
                  const { archivedAt, title } = value.getValue<{
                    archivedAt: ListQuoteModel['archivedAt']
                    title: ListQuoteModel['title']
                  }>()
                  return (
                    <MagicTableCell
                      textColor={archivedAt && GreyGrey60}
                      text={`${title || 'Untitled'}${
                        archivedAt ? ' (archived)' : ''
                      }`}
                    />
                  )
                }
              },
              {
                id: 'quoteNumber',
                accessorFn: row => ({
                  archivedAt: row.archivedAt,
                  quoteNumber: row.quoteNumber
                }),
                header: 'Number',
                cell: value => {
                  const { archivedAt, quoteNumber } = value.getValue<{
                    archivedAt: ListQuoteModel['archivedAt']
                    quoteNumber: ListQuoteModel['quoteNumber']
                  }>()
                  return (
                    <MagicTableCell
                      textColor={archivedAt && GreyGrey60}
                      text={quoteNumber}
                    />
                  )
                }
              },
              {
                id: 'customer',
                accessorKey: 'customerId',
                header: 'Customer',
                hasLink: true,
                cell: value => {
                  const customerId =
                    value.getValue<ListQuoteModel['customerId']>()
                  return customerId ? (
                    <MagicTableAutoLoader<
                      ExtractQueryParams<typeof useGetCustomersByIdQuery>,
                      CustomerModel | undefined
                    >
                      queryParams={{
                        id: customerId
                      }}
                      useQuery={useGetCustomersByIdQuery}
                      extract={customer => customer}
                    >
                      {customer =>
                        customer ? (
                          <MagicTableCellBasic>
                            <EntityLink to={`/customers/${customer.id}`}>
                              {`${customer.legalName}${
                                customer.archivedAt ? ' (archived)' : ''
                              }`}
                            </EntityLink>
                          </MagicTableCellBasic>
                        ) : null
                      }
                    </MagicTableAutoLoader>
                  ) : (
                    <MagicTableCellBasic>
                      <Text padding="3px 6px">Unknown</Text>
                    </MagicTableCellBasic>
                  )
                }
              },
              {
                id: 'status',
                header: 'Status',
                accessorFn: row => ({
                  archivedAt: row.archivedAt,
                  status: row.status
                }),
                cell: value => {
                  const { archivedAt, status } = value.getValue<{
                    archivedAt: ListQuoteModel['archivedAt']
                    status: ListQuoteModel['status']
                  }>()

                  return (
                    <MagicTableCellQuoteStatus
                      status={status}
                      isArchived={!!archivedAt}
                    />
                  )
                }
              },
              {
                id: 'user',
                accessorFn: row => ({
                  archivedAt: row.archivedAt,
                  createdBy: row.createdBy
                }),
                header: 'Owner',
                cell: value => {
                  const { archivedAt, createdBy } = value.getValue<{
                    archivedAt: ListQuoteModel['archivedAt']
                    createdBy: ListQuoteModel['createdBy']
                  }>()
                  return (
                    <MagicTableAutoLoader<
                      ExtractQueryParams<typeof useGetUsersByIdQuery>,
                      { email: string }
                    >
                      queryParams={{
                        id: createdBy
                      }}
                      useQuery={useGetUsersByIdQuery}
                      extract={user => ({
                        email: user?.email ?? '-'
                      })}
                    >
                      {({ email }) => (
                        <MagicTableCell
                          textColor={archivedAt && GreyGrey60}
                          text={email}
                        />
                      )}
                    </MagicTableAutoLoader>
                  )
                }
              },
              {
                id: 'expiresAt',
                accessorFn: row => ({
                  expiresAt: row.expiresAt,
                  expiryDays: row.expiryDays,
                  archivedAt: row.archivedAt
                }),
                header: 'Expires',
                cell: value => {
                  const { expiresAt, expiryDays, archivedAt } = value.getValue<{
                    expiresAt: ListQuoteModel['expiresAt']
                    expiryDays: ListQuoteModel['expiryDays']
                    archivedAt: ListQuoteModel['archivedAt']
                  }>()
                  return (
                    <MagicTableCell
                      textColor={archivedAt && GreyGrey60}
                      text={
                        expiresAt
                          ? dateTimeWithFormat(expiresAt, 'd MMM yyyy')
                          : expiryDays
                          ? `${expiryDays} days after publishing`
                          : ''
                      }
                    />
                  )
                }
              }
            ]}
            kebabMenu={(model: ListQuoteModel, props) => (
              <KebabMenu
                isLazy
                menuItems={menuItemBuilders(quote => {
                  void duplicateQuote(quote)
                })
                  .map(builder => builder(model))
                  .filter(builder => builder.status === 'LIVE')}
                renderListInPortal={true}
                flags={flags}
                {...props}
              />
            )}
            emptyState={props => (
              <EmptyState emptyContent={emptyContent} {...props} />
            )}
            filters={filters}
          />
        </>
      )}
    </CurrentUserId>
  )
}

export default QuotesMagicTable
