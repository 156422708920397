import type { TaxCategory } from 'Settings/domain/taxRates.types'
import {
  Box,
  Center,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text
} from '@chakra-ui/react'
import {
  GreyGrey30,
  GreyGrey60,
  IndigoIndigo50,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { EllipsisHorizontalIcon, PlusIcon } from '@heroicons/react/16/solid'
import {
  Button,
  SequenceMenu,
  SequenceMenuItem,
  Badge,
  type SequenceMenuItemProps
} from '@sequencehq/core-components'
import SpinnerContainer from 'components/Loading/SpinnerContainer'
import TaxCategoryModal from 'Settings/view/taxRatesSettings/modals/TaxCategoryModal'
import type { CreateTaxCategoryInput } from 'Settings/view/taxRatesSettings/hooks/useCreateTaxCategory'
import type { UpdateTaxCategoryInput } from 'Settings/view/taxRatesSettings/hooks/useUpdateTaxCategory'

interface Props {
  taxCategories: TaxCategory[] | undefined
  isLoading: boolean
  onMarkAsDefault: (taxCategory: TaxCategory) => void
  onCreateNewCategory: (
    taxCategoryInput: CreateTaxCategoryInput
  ) => Promise<void>
  onEditCategory: (
    taxCategory: TaxCategory,
    taxCategoryInput: UpdateTaxCategoryInput
  ) => Promise<void>
}

const TaxCategoriesTable = ({
  taxCategories = [],
  isLoading,
  onMarkAsDefault,
  onCreateNewCategory,
  onEditCategory
}: Props) => {
  if (isLoading) {
    return (
      <Flex justifyContent="center" align="center" width="100%" py={20}>
        <SpinnerContainer />
      </Flex>
    )
  }

  const sortedTaxCategories = taxCategories.sort((a, b) => {
    return a.name.localeCompare(b.name)
  })

  return (
    <TableContainer
      borderRadius="lg"
      overflow="hidden"
      border={`1px solid ${GreyGrey30}`}
      mt={4}
      width="100%"
    >
      <Table variant="v2" width="100%">
        <Thead>
          <Tr>
            <Th width="20%" borderRight={`1px solid ${GreyGrey30}`}>
              Name
            </Th>
            <Th>Description</Th>
          </Tr>
        </Thead>
        <Tbody>
          {sortedTaxCategories.length > 0 ? (
            sortedTaxCategories.map(taxCategory => (
              <TaxCategoryTableRow
                key={taxCategory.id}
                taxCategory={taxCategory}
                onEdit={onEditCategory}
                onMarkAsDefault={() => onMarkAsDefault(taxCategory)}
              />
            ))
          ) : (
            <Tr
              sx={{
                cursor: 'auto!important',
                _hover: {
                  bgColor: 'inherit!important'
                }
              }}
            >
              <Td colSpan={2}>
                <Flex width="full" placeContent="center" py={4}>
                  <Flex
                    width="265px"
                    whiteSpace="pre-line"
                    flexDirection="column"
                    textAlign="center"
                    gap={2}
                  >
                    <Text {...Lato13Bold}>No tax categories to display</Text>
                    <Text {...Lato13Regular} color={GreyGrey60}>
                      You have not created any tax categories yet.
                    </Text>
                    <Flex justifyContent="center">
                      <TaxCategoryModal
                        onSubmit={onCreateNewCategory}
                        trigger={
                          <Button
                            variant="primary"
                            leadingIcon={
                              <PlusIcon
                                width="16px"
                                height="16px"
                                color="inherit"
                              />
                            }
                          >
                            New tax category
                          </Button>
                        }
                      />
                    </Flex>
                  </Flex>
                </Flex>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

const TaxCategoryTableRow = ({
  taxCategory,
  onEdit,
  onMarkAsDefault
}: {
  taxCategory: TaxCategory
  onEdit: (
    taxCategory: TaxCategory,
    taxCategoryInput: UpdateTaxCategoryInput
  ) => Promise<void>
  onMarkAsDefault: () => void
}) => {
  return (
    <TaxCategoryModal
      taxCategory={taxCategory}
      onSubmit={async values => {
        await onEdit(taxCategory, values)
      }}
      trigger={openModal => {
        return (
          <Tr onClick={openModal}>
            <Td borderRight={`1px solid ${GreyGrey30}`}>
              <Flex justifyContent="space-between" align="center">
                <Text {...Lato13Bold} color={IndigoIndigo50}>
                  {taxCategory.name}
                </Text>{' '}
                {'isDefault' in taxCategory && taxCategory.isDefault ? (
                  <Badge sentiment="neutral" size="sm">
                    Default
                  </Badge>
                ) : null}
              </Flex>
            </Td>
            <Td>
              <Flex
                justify={
                  taxCategory.description.length > 0
                    ? 'space-between'
                    : 'flex-end'
                }
                align="center"
              >
                {taxCategory.description}
                <Box onClick={e => e.stopPropagation()}>
                  <SequenceMenu
                    width={226}
                    alignment="bottom-right"
                    menuButton={
                      <Button variant="ghost" size="24px">
                        <Center flexShrink="0">
                          <EllipsisHorizontalIcon
                            width="16px"
                            height="16px"
                            color={GreyGrey60}
                          />
                        </Center>
                      </Button>
                    }
                    items={[
                      (props: Partial<SequenceMenuItemProps>) => (
                        <TaxCategoryModal
                          key="tax-category-edit"
                          taxCategory={taxCategory}
                          onSubmit={async values => {
                            await onEdit(taxCategory, values)
                            return props?.setIsOpen?.(false)
                          }}
                          trigger={
                            <SequenceMenuItem
                              key="tax-category-edit"
                              uuid="edit"
                              label="Edit tax category"
                            />
                          }
                        />
                      ),
                      ...(!taxCategory.isDefault
                        ? [
                            {
                              label: 'Mark as default',
                              onClick: onMarkAsDefault
                            }
                          ]
                        : [])
                    ]}
                  />
                </Box>
              </Flex>
            </Td>
          </Tr>
        )
      }}
    />
  )
}

export default TaxCategoriesTable
