import type { FC } from 'react'
import { Badge, Inspector } from '@sequencehq/core-components'
import { Box, Flex, Icon, Text } from '@chakra-ui/react'
import { InformationCircleIcon } from '@heroicons/react/16/solid'
import { Lato14Bold, Lato14Regular } from '@sequencehq/design-tokens'
import { useStatusWidget } from 'Integrations/view/integrationManagement/IntegrationManagementSidebar/useStatusWidget'

export const AvalaraIntegrationManagementInspector: FC<
  Record<string, never>
> = () => {
  const { health } = useStatusWidget()

  return (
    <Inspector>
      <Inspector.ItemGroup>
        <Inspector.IconItem
          content={
            <Inspector.Content>
              <Box
                as={Flex}
                flex="1"
                gap={1}
                textAlign="left"
                borderRadius="8px"
                mb={4}
                {...Lato14Bold}
              >
                <span>Status</span>
              </Box>

              <Flex flexDirection="column" gap={2}>
                <Flex
                  height="24px"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                  alignContent="center"
                  {...Lato14Regular}
                >
                  <p>Health</p>
                  <Badge sentiment={health.sentiment} textStyle={Lato14Regular}>
                    <Icon as={health.icon} height="16px" width="16px" />
                    <Text {...Lato14Regular}>{health.label}</Text>
                  </Badge>
                </Flex>
              </Flex>
            </Inspector.Content>
          }
          icon={<InformationCircleIcon height={16} />}
        />
      </Inspector.ItemGroup>
    </Inspector>
  )
}
